<template>
  <div class="p-2 grid gap-4">
    <div class="bg-white rounded-md">
      <div class="text-sm font-bold rounded-t-md p-2.5 bg-primary-300">
        Trigger
      </div>
      <div
        class="rounded border-black-200 border-2 m-4"
        v-for="trigger in triggerBlocks"
        :key="trigger.blockId"
      >
        <div class="bg-primary-100 rounded flex justify-between p-3">
          <div>
            <select
              v-if="trigger.combineOperator"
              class="w-14 h-6 rounded-md bg-primary-300 text-gray-100"
              v-model="trigger.combineOperator"
            >
              <option
                class="p-2 rounded-md"
                v-for="item in combineOperatorList"
                :value="item.name"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            If
            <select class="w-14 h-6 rounded-md" v-model="trigger.blockOperator">
              <option
                class="p-2 rounded-md"
                v-for="item in operatorList"
                :value="item.name"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            of the following is true.
          </div>
          <!-- <ClientInformationIcon
            class="w-5 h-5 cursor-pointer"
            @click="handleTriggerBlockAdd"
            v-if="trigger.blockId == 1"
          /> -->
          <!-- <font-awesome-icon
            v-else
            icon="trash"
            color="red"
            class="cursor-pointer"
            @click="handleTriggerBlockRemove(trigger)"
          /> -->
        </div>
        <div v-for="triggerField in trigger.blockData" :key="triggerField.id">
          <FormulateForm class="p-2 w-full" v-if="!loader">
            <div class="grid grid-cols-2 gap-x-4">
              <FormulateInput
                v-model="triggerField.triggerType"
                label="Trigger Type"
                placeholder="Select Trigger Type"
                type="select"
                element-class="min-w-full"
                :options="triggerField.triggerTypesList"
                validation="required"
                @validation="validation($event, 'triggerType')"
                @input="
                  setConfigTriggers(
                    triggerField.triggerType,
                    trigger.blockId,
                    triggerField.id
                  )
                "
                :disabled="viewMode"
              />
              <div class="flex">
                <FormulateInput
                  v-model="triggerField.config"
                  label="Configuration"
                  placeholder="Configuration"
                  type="select"
                  element-class="min-w-full"
                  class="flex-grow"
                  validation="required"
                  :options="triggerField.configList"
                  :disabled="!triggerField.triggerType || viewMode"
                  @validation="validation($event, 'triggerConfigure')"
                />
                <!-- <font-awesome-icon
                  v-if="trigger.blockData.length > 1"
                  icon="minus-circle"
                  color="red"
                  class="m-2 cursor-pointer"
                  @click="handleRemoveTriggerField(trigger, triggerField)"
                />
                <font-awesome-icon
                  icon="plus-circle"
                  color="Dodgerblue"
                  class="cursor-pointer m-2"
                  @click="handleAddTriggerField(trigger)"
                /> -->
              </div>
            </div>
          </FormulateForm>
          <div v-else class="flex w-full justify-center items-center py-10">
            <Loader />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-md w-full">
      <div class="text-sm font-bold rounded-t-md p-2.5 bg-primary-300">
        Actions
      </div>
      <div class="rounded border-black-200 border-2 m-4">
        <div class="bg-primary-100 rounded flex p-3">
          Then perform the following.
        </div>
        <div v-for="action in actionsFields" :key="action.id">
          <FormulateForm class="p-2" v-if="!loader">
            <div class="grid grid-cols-2 gap-6">
              <FormulateInput
                element-class="min-w-full"
                label="Action Type"
                placeholder="Select Trigger Type"
                type="select"
                v-model="action.actionType"
                validation="required"
                :options="action.actionList"
                @input="setActionConfig(action)"
                @validation="validation($event, 'actionType')"
                :disabled="viewMode"
              />
              <div class="flex">
                <FormulateInput
                  element-class="min-w-full"
                  label="Configuration"
                  placeholder="Configuration"
                  type="select"
                  class="flex-grow"
                  validation="required"
                  v-model="action.config"
                  :options="action.configList"
                  :disabled="loadingConfig || viewMode"
                  @validation="validation($event, 'actionConfigure')"
                />
                <!-- <font-awesome-icon
                v-if="actionsFields.length > 1"
                icon="minus-circle"
                color="red"
                class="m-2 cursor-pointer"
                @click="handleRemoveActionsField(action)"
              />
              <font-awesome-icon
                icon="plus-circle"
                color="Dodgerblue"
                class="cursor-pointer m-2"
                @click="handleAddActionsField()"
              /> -->
              </div>
            </div>
          </FormulateForm>
          <div v-else class="flex w-full justify-center items-center py-10">
            <Loader />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-md h-20" v-if="!viewMode">
      <div class="flex justify-end p-4">
        <Button
          :text="this.getTriggerId ? 'save' : 'Save'"
          type="primary"
          @click="handleSubmit()"
          :disabled="createTriggerLoading || getDisabledState"
          :loader="createTriggerLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
//import ClientInformationIcon from "@/assets/icons/check-admin/add-field.svg";
import Button from "@shared/components/button";
import Loader from "@/components/loader";

import {
  getTriggerEvents,
  getTriggerActions,
  getTriggerActionsConfig,
  createTrigger,
  getTriggerDetails,
  updateTrigger,
} from "../service.js";
export default {
  name: "createTriggerActions",
  components: {
    Button,
    Loader,
  },
  data() {
    return {
      loadingConfig: true,
      selectedActionConfig: [],
      allTriggerActions: [],
      allTriggerEvents: [],
      combineOperatorList: [
        { name: "AND", id: 1 },
        { name: "OR", id: 2 },
      ],
      triggerOperator: "All",
      operatorList: [{ name: "All", id: 1 }],
      triggerBlocks: [
        {
          blockId: 1,
          blockData: [
            {
              triggerType: "",
              config: "",
              id: 1,
              triggerTypesList: [],
              configList: [],
            },
          ],
          blockOperator: "All",
        },
      ],
      actionsFields: [
        { actionType: "", config: "", id: 1, actionList: [], configList: [] },
      ],
      hasError: {
        triggerType: false,
        triggerConfigure: false,
        actionType: false,
        actionConfigure: false,
      },
      createTriggerLoading: false,
      loader: false,
    };
  },
  async mounted() {
    this.loader = true;
    await this.setTriggers();
    await this.setActions();
    if (this.getTriggerId) {
      await this.getTriggerDetail();
    }
    this.loader = false;
  },
  computed: {
    getAction() {
      return this.$route.params.action;
    },
    getType() {
      return this.$route.params.type;
    },
    getWorkflowId() {
      return this.$route.params?.workflowId;
    },
    getTriggerId() {
      return this.$route.params?.toolId;
    },
    getDisabledState() {
      let disabled = false;
      for (const key in this.hasError) {
        if (this.hasError[key]) {
          disabled = true;
          break;
        }
      }
      return disabled;
    },
    viewMode() {
      return this.$route.params.action === "view" ||
        this.$route.name == "check admin details" ||
        this.$route.name == "Package Tools"
        ? true
        : false;
    },
    // getDisableTriggerState() {
    //   return !this.triggerBlocks[0].blockData[0].triggerType || !this.triggerBlocks[0].blockData[0].triggerType;
    // },
    // getDisableActionState() {
    //   return !this.actionsFields[0].blockData[0].actionType || !this.actionsFields[0].blockData[0].actionType;
    // }
  },
  methods: {
    async getTriggerDetail() {
      // This will change when there will be multiple blocks and fields for trigger.
      const triggerDetails = await getTriggerDetails(
        this.getType,
        this.getWorkflowId,
        this.getTriggerId
      );
      const events = triggerDetails[0].events[0];
      const actions = triggerDetails[0].actions[0];
      this.triggerBlocks[0].blockData.forEach((res) => {
          res.triggerType = events.event_group_name
          this.setConfigTriggers(res.triggerType, 1, 1)
          res.config = events.event_name
      });
      this.actionsFields.forEach(async (res) => {
        res.actionType = actions.action_type;
        await this.setActionConfig(res)
        res.config = actions.status_name || actions.type;
      });
    },
    async setActions() {
      const data = await getTriggerActions(this.getType);
      this.allTriggerActions = data;
      this.actionsFields.forEach((action) => {
        action.actionList = data;
        // for initial case as we have only ony field by default selected
        // action.actionType = data[0];
      });
    },
    async setActionConfig(action) {
      this.loadingConfig = true;
      const config = await getTriggerActionsConfig(
        this.getType,
        action.actionType,
        this.getWorkflowId
      );
      this.selectedActionConfig = config;
      this.actionsFields.forEach((res) => {
        if (res.id == action.id) {
          res.configList = config.map((res) => res.name);
        }
      });
      this.loadingConfig = false;
    },
    async setTriggers() {
      const data = await getTriggerEvents(this.getType);
      this.allTriggerEvents = data;
      this.triggerBlocks.forEach((block) => {
        block.blockData.forEach((trigger) => {
          trigger.triggerTypesList = data.map((res) => res.event_group_name);
        });
      });
    },
    setConfigTriggers(event, blockId, triggerId) {
      const config = this.allTriggerEvents
        .find((res) => res.event_group_name == event)
        .events.map((res) => res.event_name);
      this.triggerBlocks.forEach((res) => {
        if (res.blockId == blockId) {
          res.blockData.forEach((tr) => {
            if (tr.id == triggerId) {
              tr.configList = config;
            }
          });
        }
      });
    },
    handleTriggerBlockAdd() {
      const finalBlock = this.triggerBlocks[this.triggerBlocks.length - 1];
      this.triggerBlocks.push({
        blockId: finalBlock.blockId + 1,
        blockData: [{ triggerType: "", config: "", id: 1 }],
        blockOperator: "All",
        combineOperator: "AND",
      });
    },
    handleTriggerBlockRemove(trigger) {
      this.triggerBlocks = this.triggerBlocks.filter(
        (res) => res.blockId != trigger.blockId
      );
    },
    handleAddTriggerField(trigger) {
      this.triggerBlocks.forEach((res) => {
        if (res.blockId == trigger.blockId) {
          res.blockData.push({
            triggerType: "",
            config: "",
            id: trigger.blockData[trigger.blockData.length - 1].id + 1,
          });
        }
      });
    },
    handleRemoveTriggerField(trigger, field) {
      this.triggerBlocks.forEach((res) => {
        if (res.blockId == trigger.blockId) {
          res.blockData = res.blockData.filter((r) => r.id != field.id);
        }
      });
    },
    handleAddActionsField() {
      this.actionsFields.push({
        actionType: "",
        config: "",
        id: this.actionsFields[this.actionsFields.length - 1].id + 1,
      });
    },
    handleRemoveActionsField(action) {
      this.actionsFields = this.actionsFields.filter(
        (res) => res.id != action.id
      );
    },
    validation(evt, type) {
      this.hasError[type] = evt.hasErrors;
      // console.log("evt", evt, type);

      // this.hasValidationError = evt.hasErrors;
    },
    async handleSubmit() {
      this.createTriggerLoading = true;
      let payload = {
        workflow_id: this.getWorkflowId,
        events: [],
        actions: [],
      };
      this.triggerBlocks.forEach((res) => {
        res.blockData.forEach((trigger) => {
          const eventIds = this.allTriggerEvents.find(
            (res) => res.event_group_name == trigger.triggerType
          ).events;
          eventIds.forEach((res) => {
            if (res.event_name == trigger.config) {
              payload.events.push({ event_id: res.event_id });
            }
          });
        });
      });
      this.actionsFields.forEach((field) => {
        const {id, name} = this.selectedActionConfig.find(
          (res) => res.name == field.config
        );
        payload.actions.push({
          action_type: field.actionType,
          action_config: field.actionType === 'Webhook' ? { type: name} :{ status_id: id} ,
        });
      });
      if (this.getTriggerId) {
        try {
          payload.trigger_id = this.getTriggerId;
          const response = await updateTrigger(this.getType, payload);
          if (response.status) {
            this.$toast.success(response?.data?.status_message || "Success");
          }
          this.$router.push({
            name: "Workflow Tools",
            params: {
              workflowTool: "triggerAndActions",
              workflowId: this.getWorkflowId,
            },
          });
          this.createTriggerLoading = false;
        } catch (err) {
          console.error("err", err);
          this.$toast.error(
            err?.response?.data?.status_message || "Something went wrong"
          );
          this.createTriggerLoading = false;
        }
      } else {
        try {
          const response = await createTrigger(this.getType, payload);
          if (response.status) {
            this.$toast.success(response?.data?.status_message || "Success");
            this.createTriggerLoading = false;
          }
          this.$router.push({
            name: "Workflow Tools",
            params: {
              workflowTool: "triggerAndActions",
              workflowId: this.getWorkflowId,
            },
          });
        } catch (err) {
          console.error("err", err);
          this.$toast.error(
            err?.response?.data?.status_message ||  err?.response?.data?.detail || "Something went wrong"
          );
          this.createTriggerLoading = false;
        }
      }
    },
  },
};
</script>

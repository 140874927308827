import axios from "@/axios";
import { orderBy } from "lodash";

export const ProfileStore = {

    state: {
        refChecks: []
    },

    mutations: {
        SET_REF_CHECKS: (state, payload) => {
            state.refChecks = payload;
        }
    },

    getters: {
        getRefChecks: state => state.refChecks,
    },

    actions: {
        fetchRefChecks: async ({ commit }, payload) => {
            try {
                const { data } = await axios(`case/${payload.caseId}/case_entity/${payload.entityId}/check`);

                let checksStatuses = [];
                try {
                    const promises = data.data.map(check => 
                        getCheckStatuses(check.id)
                    );

                    checksStatuses = await Promise.all(promises);
                } catch(error) {
                    console.log(error);
                }

                data.data = data.data.map(el => {
                    // adding reference to non reference cards 
                    let obj = el

                    let statuses = checksStatuses.find(x => x.id == el.id);
                    statuses = statuses?.data
                        ? orderBy(statuses.data, "status_order", "asc")
                        : [];

                    obj.statuses = statuses.map((status) => ({
                        ...status,
                        name: status.status_name,
                        label: status.status_name,
                        value: status.status_id,
                        id: status.status_id,
                        styles: {
                            backgroundColor: status.status_colour || "#0000FF",
                            color: status.text_color || "#fff",
                        },
                    }));

                    obj.references = []

                    const currStatus = obj.statuses.find(x => x.current)

                    if (currStatus) {
                        obj.status = currStatus;
                        obj.references.push({
                            status_color: currStatus.status_colour || 'blue',
                            status_name: currStatus.status_name
                        })
                    }

                    return obj 
                })
                if (payload.entityId==payload?.caseEntity?.id) {
                    data.data = data.data.filter((res)=>res.check_category!='Integrated Check')
                }
                const sortedChecks =  orderBy(data.data, [check => (check.check_name || '').toLowerCase()], ['asc']);
                commit('SET_REF_CHECKS', sortedChecks)
            } catch (error) {
                console.log(error, ">>>>Error")
            }
        },

    }

};
                
const getCheckStatuses = (checkId) => {
    try {
        return new Promise((res, rej) => {
            axios.get(`workflow/${checkId}/status`)
                .then(e => res({ status: true, id: checkId, data: e.data?.data }))
                .catch(err => {
                    console.log(`Error fetching Check Statuses`)
                    rej({ status: false, error: err.message })
                })
        })
    } catch(error) {
        console.log(error);
    }
}
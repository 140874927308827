<template>
  <div
    class="case-creation-flow h-full flex flex-col max-h-full overflow-hidden flex-grow"
  >
    <div v-if="isLoading" class="p-10 flex items-center justify-center w-full">
      <Loader />
    </div>
    <template v-else>
      <div v-if="!addEntityWorkflow && (!selectedPackage || packageLoaders[selectedPackageId])" 
        class="flex overflow-hidden h-full w-full">
        <PackageList
          :loading="loading"
          :list="packageList"
          :packageLoaders="packageLoaders"
          @select="selectPackage($event.id)"
        />
      </div>
      <div v-else class="flex flex-col w-full h-full overflow-hidden gap-5 flex-1">
        <NavBar
          v-if="!addEntityWorkflow"
          :timeline="timelineData"
          @back="onBack"
          @cancel="onCancel"
        />

        <EntityBar
          v-if="!addEntityWorkflow"
          :items="computedEntitiesList"
          :readonly="currentStepIndex > 1"
          :plus-disabled="!isAddAvailable"
          @remove="removeEntity"
          @select="onSelectEntity($event.id)"
          @add="showAddEntity"
          :handleEntityChangeStatus="handleEntityChangeStatus"
        />
        <div class="case-creation__body flex flex-col flex-1 w-full h-full overflow-hidden gap-5">
          <div
            v-if="loaders.entity || loaders.step"
            class="p-10 flex items-center justify-center w-full"
          >
            <Loader />
          </div>
          <template v-else>
            <template
              v-if="checkCurrentStep(CASE_CREATION_STEPS.ENTITY_DETAILS)"
            >
              <FormBuilder
                ref="case-creation-form-builder"
                v-if="selectedEntity?.form?.formData && !isEntityChangeNavShown && !showEntityChangeNav && !redirectionConfig"
                :data="selectedEntity?.form?.formData"
                :saveSectionMethod="saveEntitySection"
                :formStates="formStates"
                :case_id="tempCaseId"
                :actions-names="formBuilderActionsNames"
                :entity-id="selectedEntity?.id"
                :app="addEntityWorkflow ? app : appList.CREATE "
                @submit="submitEntityForm"
              />

              <div
                v-else-if="loaders.redirection"
                class="p-10 flex items-center justify-center w-full"
              >
                <Loader />
              </div>
              <EntityScreen
                v-else-if="redirectionConfig"
                :screenData="redirectionConfig.redirection_config"
                :case-id="tempCaseId"
                :entity-id="selectedEntity?.id"
                :case-entities="caseEntities"
                :case-entities-data="caseEntitiesData"
                :entity-checks="currentEntityCheckList"
                @submit="sumbitRedirectionScreen"
                @error="onRedirectionScreenError"
              />

              <EntityChangeNav
                v-else-if="isEntityChangeNavShown || showEntityChangeNav"
                class="flex-1"
                :add-options="entitiesToAdd"
                :allow-next="allowNextForm"
                :allow-submit="allowSubmitForm"
                @add="addEntity"
                @next="selectNextEntity"
                @submit="nextStep"
              />
            </template>
            <CollectInfo
              v-else-if="checkCurrentStep(CASE_CREATION_STEPS.COLLECT_INFO)"
              v-model="entityDataCollectionModel"
              :userOptions="collectInfoData.userList"
              :sequenceOptions="collectInfoData.sequenceList"
              :formOptions="collectInfoData.formList"
              :loading="loaders.form"
              :is-last="!isNextEntity"
              :all-saved="allDataCollectionsSaved"
              @addCollectInfoUser="addCollectInfoUser"
              @save="saveCollectInfo"
              @submit="saveCollectInfo"
            />
            <CheckList
              v-else-if="checkCurrentStep(CASE_CREATION_STEPS.CHECKS)"
              :items="currentEntityCheckList"
              :is-last="!isNextEntity"
              @skip="selectNextEntity"
              @next="nextStep"
            />
            <CheckList
              v-else-if="checkCurrentStep(CASE_CREATION_STEPS.SUBMIT)"
              :items="currentEntityCheckList"
              all-saved
              :loading="loaders.caseSubmission"
              @submit="submitCase"
            />
          </template>
          <StepControls
            v-if="stepControlsConfig?.show"
            :type="stepControlsConfig.type"
            :disabled="stepControlsConfig.disabled"
            :loading="stepControlsConfig.loading"
            @next="nextStep"
            @submit="submitCase"
          />
        </div>
      </div>
    </template>
    <ModalConfirm ref="confirm-popup" />
    <ModalConfirm ref="finish-popup">
      <template #content>
        <ul class="submit-confirmation-text">
          <li>Are you sure you've added all the entity details.</li>
        </ul>
      </template>
    </ModalConfirm>

    <CaseScoreBreakdown
      v-if="caseEntities.length > 1"
      :caseTypeEntityId="caseTypeEntityId"
      :caseEntities="caseEntities"
    />
  </div>
</template>

<script>
// TODO check if non-first step/entity may be selected initially

import { cloneDeep } from "lodash";
import axios from "@/axios";
import { mapMutations, mapActions, mapGetters } from "vuex";
import ModalConfirm from "@shared/modal-confirm/index.vue";
import Loader from "@shared/loader/index.vue";
import FormBuilder from "@shared/components/form-builder";
import {
  NavBar,
  PackageList,
  CheckList,
  EntityChangeNav,
  CollectInfo,
  StepControls,
  CaseScoreBreakdown,
} from "./components";
import { EntityBar } from "@shared/case-entities";
import EntityScreen from "@shared/EntityScreen";

import {
  EntityForm,
  getEntitiesCounter,
  getEntitiesToAdd,
} from "./utils";
import {
  checkPermission,
  checkClientPermission,
  getShortEntityData,
  getEntityTypeName, 
  getEntityStatuses
} from "@shared/utils/functions";
import {
  CASE_CREATION_STEPS,
  CHANGE_STATUSES,
  CASE_CREATION_STEPS_LIST,
  CASE_CREATION_STEPS_ENTITY_FLOW,
} from "./utils/constants.js";
import { appList } from '@shared/components/form-builder/utils/index.js';
import {
  subScreenList,
} from "@shared/EntityScreen/utils.js";
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import handleError from "@shared/mixins/handleError.js";

export default {
  name: "case-creation-flow",
  components: {
    NavBar,
    EntityBar,
    PackageList,
    Loader,
    FormBuilder,
    EntityChangeNav,
    ModalConfirm,
    CollectInfo,
    CheckList,
    StepControls,
    EntityScreen,
    CaseScoreBreakdown,
  },
  mixins: [handleError],
  props: {
    tenantId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    // is used when packages are loading:
    loading: {
      type: Boolean,
      default: false,
    },
    preselectedPackageId: {
      type: String,
    },
    packageList: {
      type: Array,
      default: () => [],
    },
    // is true for not a case creation flow
    addEntityWorkflow: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    app: "currentApp",
    isCurrentAppAllowed: "isCurrentAppAllowed",
  },
  provide () {
    return {
      sharedCaseState: () => this.sharedCaseState,
    }
  },
  data() {
    return {
      appList,
      isLoading: false, // global loader for this component
      CASE_CREATION_STEPS,
      selectedPackageId: null,
      tempCaseId: null,
      caseStatus: null,
      caseTypeEntityId: null,
      caseEntities: [],
      caseEntitiesData: null,
      caseEntityForms: {},
      caseEntityChecks: {},
      selectedEntityId: null,
      loaders: {
        case: false,
        caseSubmission: false,
        form: false, // use to set loading status for the current form
        redirection: false, // use for redirection config fetching
        entity: false,
        step: false,
      },
      addEntityLoaders: {},
      formStates: {
        loading: false,
        submitted: false,
      },
      packageLoaders: {},
      showEntityChangeNav: false, // forces Entity Change Nav show
      isEntityChangeNavShown: false, // for navigation between entities/steps
      isShowAddEntityOnly: false, // is only for entities adding
      collectInfoData: null, // data used for the Collect Info form
      entitiesCollectInfo: null, // collect info saved to the server
      entitiesCollectInfoUpdated: null, // updated & not saved collect info
      entitiesCollectInfoDeleted: [], // deleted & not saved collect info
      // use to determin what to do on "save & next"
      collectDataStatuses: {
        existOnInit: null,
        changeStatus: null,
      },
      currentCaseCreationStep: null,
      formBuilderActionsNames: {
        submit:  'Save',
        preview: 'Preview'
      },
      redirectionConfig: null,
    };
  },

  computed: {
    ...mapGetters({
      getRiskScoreData: "CaseCreationStore/getRiskScoreData",
    }),

    sharedCaseState() {
      return {
        case_id: this.tempCaseId,
        tenant_id: this.tenantId,
        client_id: this.clientId,
        package_id: this.selectedPackageId,
        entity_id: this.selectedEntityId,
      }
    },

    caseEntitiesInfo() {
      return this.caseEntitiesData?.case_entity_info || null;
    },

    caseEntityTypesInfo() {
      return this.caseEntitiesData?.case_entity_type_info || null;
    },

    headerItemsConfig() {
      if (!this.computedCaseData) return null;

      // TODO implement new status config
      // const statusKYC = {
      //   value: "KYC",
      //   label: "KYC",
      //   color: "blue-500",
      // }
      // const statusDataEntry = {
      //   value: "initial_data",
      //   label: "Initial data entry",
      //   color: "blue-500",
      // }

      // TODO reference to the case score data
      const scoreValue = {
        value: this.getRiskScoreData?.score || 0,
        label: this.getRiskScoreData?.score || 0,
        color: this.getRiskScoreData?.label_color || "",
      }

      let statusValue = null;
      const statusData = this.computedCaseData.status;
      if (statusData) {
        const currentCaseStatus = this.getCurrentStatus(statusData);
        statusValue = {
          value: currentCaseStatus.status_id,
          label: currentCaseStatus.status_name,
          color: currentCaseStatus.status_colour,
        }
      }

      return {
        name: {
          id: "case_id",
          title: "ID",
          value: this.computedCaseData?.name,
          type: "text",
        },
        // TODO to be implemented later:
        // status: {
        //   id: "status",
        //   title: "Status",
        //   value: this.isKYC ? statusKYC : statusDataEntry, // TODO the logic for dynamic statuses depending on current workflow to be implemented
        //   type: "menu",
        // },
        status: {
          id: "status",
          title: "Status",
          value: statusValue,
          type: "menu",
        },
        score: {
          id: "score",
          title: "Score",
          value: scoreValue,
          type: "menu",
          show: this.isCurrentAppAllowed([appList.TENANT]) && checkPermission('riskAssessment.read'),
          listeners: {
            open: () => {
              this.toggleRiskScoreModal();
            }
          },
          props: {
            dropdown: false,
          },
        },
      }
    },
    headerConfig() {
      if (!this.headerItemsConfig) return null;

      const headerItems = Object.values(this.headerItemsConfig)
        .filter((el) => !!el?.value)
        .filter((el) => el.show !== false);

      return {
        title: null,
        list: Object.values(headerItems),
      }
    },
    showCaseCreation() {
      return (
        this.selectedPackage &&
        !this.packageLoaders[this.selectedPackageId] &&
        this.computedCaseData
      );
    },
    selectedPackage() {
      return this.selectedPackageId
        ? this.packageList.find((el) => el.id === this.selectedPackageId)
        : null;
    },
    currentStepIndex() {
      // use positive value
      if (!this.currentCaseCreationStep) return 1;
      return (
        this.caseCreationSteps.findIndex(
          (el) => el.id === this.currentCaseCreationStep.id
        ) + 1
      );
    },
    timelineData() {
      if (this.isWLP5) return null;
      return {
        list: this.caseCreationSteps,
        current: this.currentCaseCreationStep,
        currentStepIndex: this.currentStepIndex,
      };
    },
    caseEntity() {
      // find an entity with the case temp data:
      return (
        this.caseEntities?.find((el) => el.id === this.caseTypeEntityId) || null
      );
    },
    companyEntityType() {
      // for the case creation, we use only "Company" entity
      // TODO: determine "Company" entity on the server
      if (!this.caseEntityTypesInfo) return null;
      const [id, typeData] = (
        Object.entries(this.caseEntityTypesInfo)
          ?.find(([, value]) => value.name === "Company")
          || []
        );
      if (!id) return null;
      return {
        ...typeData,
        id,
      }
    },
    // TODO check this conditions:
    isWLP5() {
      // currently, use only in the tenant app because client app require reworking
      return !this.addEntityWorkflow && this.isCurrentAppAllowed([appList.TENANT, appList.CLIENT]);
    },
    computedCaseData() {
      if (!this.caseEntity) return null;
      return {
        caseEntity: this.caseEntity,
        name: this.caseEntity.entity_name,
        status: this.caseStatus,
      };
    },
    caseCreationSteps() {
      // new WLP 5.0 case creation flow:
      if (this.isWLP5) {
        return CASE_CREATION_STEPS_ENTITY_FLOW;
      }

      const caseEntityTypesInfo = this.caseEntityTypesInfo
        ? Object.values(this.caseEntityTypesInfo)
        : [];
      const isCollectionDataNeeded = caseEntityTypesInfo.some(
        (x) => x.collect_data
      );
      let steps = CASE_CREATION_STEPS_LIST;
      steps = isCollectionDataNeeded
        ? steps
        : steps.filter(
            (x) => x.id != CASE_CREATION_STEPS.COLLECT_INFO
          );

      if (this.addEntityWorkflow)
        steps = steps.filter((x) => x.id === CASE_CREATION_STEPS.ENTITY_DETAILS)
      return steps;
    },
    // editable entities
    computedEntitiesList() {
      if (!this.caseEntity || !this.caseEntities) return null;

      // currently, we can select any entity
      // TODO this logic should be improved: we should monitor two moments: if form has been received valid and if it has been saved
      // TODO perfectly, we should autoselect first invalid entity on init
      // we can reselect the same entity if the Change Entity Nav is shown

      const isEntityDisabled = (el) => this.currentCaseCreationStep.id == CASE_CREATION_STEPS.COLLECT_INFO
        && !el.collect_data;

      const isEntitySelectable = (el) => this.currentCaseCreationStep.id !== CASE_CREATION_STEPS.ENTITY_DETAILS
        || (this.selectedEntityId !== el.id
        || this.isEntityChangeNavShown);

      const isEntityWarning = (el) => this.checkCurrentStep(CASE_CREATION_STEPS.COLLECT_INFO)
        && el.id !== this.selectedEntityId
        && !!this.collectDataStatuses[el.id]?.changeStatus; // highlight if isn't saved & not selected

      return this.caseEntities.map((el) => {
        return {
          data: el,
          monitoringData: getShortEntityData(this.caseEntitiesData, el), // add data necessary for monitoring tools
          id: el.id,
          collect_data: el.collect_data,
          type: this.caseEntityTypesInfo[el.entity_type_id]?.name,
          name: el.entity_name || "",
          readonly: el.id === this.caseTypeEntityId,
          disabled: isEntityDisabled(el),
          selectable: isEntitySelectable(el),
          deletable: !this.isWLP5,
          selected: (el.id === this.selectedEntityId) && !this.isShowAddEntityOnly, // if we force Add Entity screen display don't show entities as selected
          form: this.caseEntityForms[el.id] || null,
          checks: this.caseEntityChecks[el.id] || null,
          checked: this.checkCurrentStep(CASE_CREATION_STEPS.COLLECT_INFO) && this.collectDataStatuses[el.id]?.existOnInit,
          warning: isEntityWarning(el),
          statusList: el.statusList
        };
      });
    },
    selectedEntity() {
      if (!this.selectedEntityId || !this.computedEntitiesList) return null;
      return (
        this.computedEntitiesList.find(
          (el) => el.id === this.selectedEntityId
        ) || null
      );
    },
    entitiesCounter() {
      // calculate dynamic counts considering newly created entities
      if (!this.caseEntitiesData || !this.caseEntities) return null;

      return getEntitiesCounter(this.caseEntitiesData, this.caseEntities);
    },
    isAllFormsSubmitted() {
      return !Object.values(this.caseEntityForms).some((el) => !el.isSubmitted);
    },
    isNextEntity() {
      const index = this.getNextEntityIndex();
      return !!index;
    },
    isNextStep() {
      // steps numbering starts from 1 so it already contains +1 when we change the selected step:
      return !!this.caseCreationSteps[this.currentStepIndex];
    },
    allowNextForm() {
      if (!this.entitiesCounter?.entities) return false;
      return (
        this.isNextEntity &&
        this.caseEntityForms?.[this.selectedEntityId]?.isSubmitted &&
        !this.isShowAddEntityOnly // don't show "next" when we open a screen intended only for the new entity adding (click on "Plus" button)
      );
    },
    allowSubmitForm() {
      // unlike the "next" button, this one can be displayed regardless of the call source
      if (!this.entitiesCounter?.entities || !!this.allowNextForm || this.addEntityWorkflow) return false;
      return (
        this.isAllFormsSubmitted &&
        !Object.values(this.entitiesCounter.entities).some(
          (el) => el.count < el.min
        )
      );
    },
    entitiesToAdd() {
      if (!this.entitiesCounter?.entities) return [];

      const entitiesToAdd = getEntitiesToAdd(this.entitiesCounter);
      return entitiesToAdd.map((el) => ({
        ...el,
        loading: this.addEntityLoaders[el.id] || false,
      }));
    },
    isAddAvailable() {
      return !!this.entitiesToAdd.length && this.currentCaseCreationStep.id === CASE_CREATION_STEPS.ENTITY_DETAILS;
    },
    currentEntityCollectInfo() {
      return (
        (this.entitiesCollectInfoUpdated || []).find(
          (el) => el.case_entity_id === this.selectedEntityId
        ) || null
      );
    },
    entityDataCollectionModel: {
      get() {
        if (!this.currentEntityCollectInfo) {
          return {
            active: false,
            details: null,
          };
        } else {
          return {
            active: true,
            details: {
              form: this.currentEntityCollectInfo.forms[0]?.form_id,
              user: this.currentEntityCollectInfo.email,
              sequence: this.currentEntityCollectInfo.email_sequence_id,
              role: this.currentEntityCollectInfo.forms?.[0]?.role,
            },
          };
        }
      },
      set(val) {
        if (!val?.active && !!this.currentEntityCollectInfo) {
          // REMOVE
          const currentIndex = this.entitiesCollectInfoUpdated.findIndex(
            (el) => el.case_entity_id === this.selectedEntityId
          );

          if (this.entitiesCollectInfoUpdated[currentIndex].id) {
            this.entitiesCollectInfoDeleted.push(this.entitiesCollectInfoUpdated[currentIndex]);
          }
          this.entitiesCollectInfoUpdated.splice(currentIndex, 1);

          // save change status for this entity form:
          
          this.$set(this.collectDataStatuses, this.selectedEntityId, {
              existOnInit: false,
              changeStatus:null
          })
        } else if (val?.active && !!this.currentEntityCollectInfo) {
          // CHANGE
          Object.assign(this.currentEntityCollectInfo, {
            // we always send the same form but with changeable role selected is User field
            forms: [
              {
                ...this.currentEntityCollectInfo.forms[0],
                role: val.details?.role
              },
            ],
            email: val.details?.user,
            email_sequence_id: val.details?.sequence,
          });

          // save change status for this entity form:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "changeStatus",
            CHANGE_STATUSES.UPDATED
          );
        } else if (val?.active && !this.currentEntityCollectInfo) {
          // ADD
          this.entitiesCollectInfoUpdated.push({
            case_id: this.tempCaseId,
            // external_id: this.tenantId,
            // external_id_type: '',
            active: true,
            case_entity_id: this.selectedEntityId,
            // we always send the same form but with changeable role selected is User field
            forms: [
              {
                form_id: this.selectedEntity?.data?.form_id,
                role: val.details?.role,
                form_type: "CUSTOMIZED_CASE_ENTITY_FORM",
              },
            ],
            email: val.details?.user,
            email_sequence_id: val.details?.sequence,
          });

          // save change status for this entity form:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "changeStatus",
            CHANGE_STATUSES.ADDED
          );
        }
      },
    },
    allDataCollectionsSaved() {
      return !this.computedEntitiesList
        .filter((x) => x.disabled)
        .every((x) => !!this.collectDataStatuses?.[x.id]?.changeStatus);
    },
    currentEntityCheckList() {
      return this.caseEntityChecks[this.selectedEntityId] || [];
    },

    stepControlsConfig() {
      const config = {
        // config sample:
        // [CASE_CREATION_STEPS.ADVERSE_CHECK]: {
        //   show: true,
        //   type: "submit",
        //   loading: this.loaders.caseSubmission,
        //   disabled: !this.confirmedRecordList.adverse.length,
        // },
      }
      return config[this.currentCaseCreationStep.id] ?? null;
    },

    isRiskManagementEnabled() {
      return this.isCurrentAppAllowed([appList.TENANT]);
    },
  },
  async mounted() {
    const packageid = this.preselectedPackageId;
    this.resetPackage(packageid || null);

    if (this.preselectedPackageId) {
      // show a loader while fetching temp data for initially selected package
      // because loader of a particular package card isn't shown
      this.isLoading = true;
      await this.selectPackage(this.preselectedPackageId);
      this.isLoading = false;

      if (this.$route.query.entityId) {
        await this.onSelectEntity(this.$route.query.entityId);
      }

      if (this.addEntityWorkflow) {
        this.isEntityChangeNavShown = true;
      }
    }
  },
  created() {
    this.resetCreationStep();
  },
  beforeDestroy() {
      // reset header
      this.setHeaderData(null);
  },
  watch: {
    isEntityChangeNavShown(val) {
      // reset on Entity Change Nav close
      if (!val) this.isShowAddEntityOnly = false;
    },
    // update header data on package data change:
    headerConfig: {
      handler() {
        this.setHeaderData(this.headerConfig);
      },
      deep: true,
    },
    // update header data on package data change:
    headerItemsConfig: {
      handler() {
        this.setHeaderData(this.headerConfig);
      },
      deep: true,
    },
    // TODO: Temporary solution, may change in the future
    caseEntities: {
      async handler(val) {
        if (val?.length > 1) {
          await this.fetchRiskScore({ case_id: this.tempCaseId });
          await this.fetchCaseStatus();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchRiskScoreMethod: "CaseCreationStore/fetchRiskScore",
      toggleRiskScoreModal: "CaseCreationStore/toggleRiskScoreModal",
      fetchCaseData: "fetchCaseData",
      setSelectedEntity: "setSelectedEntity",
    }),
    ...mapMutations({
      SET_RISK_SCORE_DATA: "CaseCreationStore/SET_RISK_SCORE_DATA",
      SET_CUSTOM_HEADER: "SET_CUSTOM_HEADER",
      SET_SELECTED_ENTITY: "SET_SELECTED_ENTITY",
    }),

    checkPermission,
    checkClientPermission,

    getEntityTypeName(entity) {
      if (!this.caseEntityTypesInfo) return null;
      return this.caseEntityTypesInfo[entity.entity_type_id].name;
    },

    async handleEntityChangeStatus(payload) {
        const { entity: { id }, status: { status_id } } = payload;
        try {
            const url = `workflow/${id}/status/${status_id}`;
            await axios.post(url);

            // TODO refetch single changed entity status instead full data reset:
            await this.getCaseEntityData();
            this.$toast.success(`Entity status changed successfully!`);
        } catch (error) {
            console.log("error handleEntityChangeStatus :>> ", error);
        }
    },

    // populate header with the current case data:
    setHeaderData(payload = this.headerConfig) {
      if (!this.addEntityWorkflow) this.SET_CUSTOM_HEADER(payload);
    },

    checkCurrentStep(stepId) {
      return this.currentCaseCreationStep.id === stepId;
    },

    async onBack() {
      // currentStepIndex starts from 1 so we need to go to the -2 index in array
      if (this.currentStepIndex > 1) {
        await this.selectStep(
          this.caseCreationSteps[this.currentStepIndex - 2], 
          this.caseEntities[this.caseEntities.length - 1]?.id
        );
      } else {
        this.exitCaseCreation();
      }
    },
  
    async onCancel() {
      try {
        const cancelConfirmed = await this.$refs["confirm-popup"].show({
          title: "Are you sure?",
          message: "The case data will be lost.",
        });
        if (!cancelConfirmed) return;
        await this.deleteTempCaseData();
        this.exitCaseCreation();
      } catch (error) {
        this.handleError(error);
      }
    },

    getNotSubmittedForm() {
        let id;
        for (const key in this.caseEntityForms) {
          if (!this.caseEntityForms[key].isSubmitted) {
            id = key;
            break;
          }
        }
        return id;
    },

    async showAddEntity(payload = true) {
      //const isNewAndNotSubmitted = !this.caseEntityForms[this.selectedEntityId].isSubmitted;
      const notSubmittedId = this.getNotSubmittedForm();
      // if the form is new (not filled => invalid) or changed (dirty):
      if (notSubmittedId) {
          this.$toast.error("Please submit form");

          if (notSubmittedId != this.selectedEntityId) {
            await this.selectEntity(notSubmittedId);
          }
          return;
      }

      // const isSwitchable = this.checkSwitchable(notSubmittedId);
      // if (!isSwitchable) return;
      this.isEntityChangeNavShown = payload;
      this.isShowAddEntityOnly = payload;
      this.selectedEntityId = null;
      this.setRedirectionConfig();
    },
    async deleteTempCaseData() {
      await axios.delete(`/case/${this.tempCaseId}`);
    },

    setCollectionDataStatuses(listPermission) {
      if(listPermission) {
      if (!this.caseEntities?.length || !this.entitiesCollectInfoUpdated)
        return;
      this.caseEntities.forEach((entity) => {
        this.resetCollectDataStatuses(entity.id);
        this.$set(this.collectDataStatuses[entity.id], "changeStatus", null);
        this.$set(
          this.collectDataStatuses[entity.id],
          "existOnInit",
          this.entitiesCollectInfoUpdated.some(
            (el) => el.case_entity_id === entity.id
          )
        );
      });
      }
      else {
        this.caseEntities.forEach((entity) => {
        this.resetCollectDataStatuses(entity.id);
        this.$set(this.collectDataStatuses[entity.id], "changeStatus", null);
        this.$set(
          this.collectDataStatuses[entity.id],
          "existOnInit",
          null
        );
      });
      }
    },

    async selectStep(step, enityId) {
      // are called after the current step is changed to the new value
      const initStepMethods = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          const entityToSelectId = enityId ||
            (this.caseEntities.find((el) => el.active) || this.caseEntities[0])
              ?.id || null;
          if (entityToSelectId) await this.selectEntity(entityToSelectId);
          // await this.fetchRiskScore({ case_id: this.tempCaseId });
        },
        [CASE_CREATION_STEPS.COLLECT_INFO]: async () => {
          this.loaders.step = true;
          try {
            let permissionMapper= {
              'client': (arg) => this.checkClientPermission(arg),
              'tenant': (arg) => this.checkPermission(arg),
            }
            this.collectInfoData = {};
            const index = this.getNextEntityIndex("stepInited");
            const entity = this.computedEntitiesList[index];
            let hasPermission = (permissionMapper[this.portal]("sequence.list") || permissionMapper[this.portal]("sequence.templateList"));

            if (hasPermission) {
              // error message if any one permission is failed
              if(!permissionMapper[this.portal]("sequence.list")) {
                this.$toast.warning("You don't have permission for read email sequence list.")
              }
              if(!permissionMapper[this.portal]("sequence.templateList")) {
                this.$toast.warning("You don't have permission for read email template list.")

              }
             
            const initPromises = [
              axios(`email-sequence/${this.tempCaseId}/data-collection`),
              axios("email-sequence/template-sequence-linearlist", {
                params: { external_id: this.tenantId },
              }),
            ];
            const [entitiesCollectInfo, sequenceList] = await Promise.all(
              initPromises
            );
            this.entitiesCollectInfo = entitiesCollectInfo?.data?.data || null;
            this.entitiesCollectInfoUpdated = cloneDeep(
              this.entitiesCollectInfo
            );
            this.collectInfoData.sequenceList = sequenceList?.data || [];
            this.setCollectionDataStatuses(true);
            }
            else {
              this.entitiesCollectInfo = null;
              this.entitiesCollectInfoUpdated = cloneDeep(
                 this.entitiesCollectInfo
               );
              this.collectInfoData.sequenceList = []
              this.setCollectionDataStatuses(false);
               // error message if both permission is failed
              this.$toast.warning("You don't have permission for read email sequence and template list.")
            }
            await this.selectEntity(entity?.id);
          } catch (error) {
            console.log("error",error);
            this.handleError(error);
          }
          this.loaders.step = false;
        },
        [CASE_CREATION_STEPS.CHECKS]: async () => {
          const index = this.getNextEntityIndex("stepInited");
          const entity = this.computedEntitiesList[index];
          await this.selectEntity(entity?.id);
        },
        [CASE_CREATION_STEPS.SUBMIT]: () => {
          // on the last step, we keep the last entity selected
        },
      };

      // reset redirection config:
      this.setRedirectionConfig();

      this.currentCaseCreationStep = step;

      if (step) {
        const { id } = step;
        try {
          await initStepMethods[id]?.();
        } catch (error) {
          this.handleError(error);
        }
      } else 
        this.$emit("redirectToInput", false)
    },

    async nextStep() {
      // are called before the current step is changed to the new value
      const stepCallbacks = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          await this.submitCase();
          return false;
        },
      };

      // manage next step selection:
      let toProceed = true;
      const { id } = this.currentCaseCreationStep;
      try {
        toProceed = await stepCallbacks[id]?.() ?? true;
      } catch (error) {
        this.handleError(error);
      }
      if (!toProceed) return;

      // steps numbering starts from 1 so it already contains +1 when we change the selected step:
      await this.selectStep(this.caseCreationSteps[this.currentStepIndex]);
    },

    resetPackage(payload = null) {
      this.selectedPackageId = payload;
      this.currentCaseCreationStep = this.caseCreationSteps[0];
      this.caseEntitiesOrder = null;
      this.resetCaseCreation();
      // reset header:
      this.setHeaderData(null);

      // if (!payload) {
      //   this.$emit("cancel");
      // }
    },

    resetCaseCreation() {
      this.setSelectedEntity(null);
      this.tempCaseId = null;
      this.caseEntitiesData = null;
      this.caseEntities = [];
      this.caseEntityForms = {};
      this.caseEntityChecks = {};
      this.selectedEntityId = null;
      this.showEntityChangeNav = false;
      this.isEntityChangeNavShown = false;
      this.collectInfoData = null;
      this.SET_RISK_SCORE_DATA(null);
      this.resetCollectDataStatuses();
      // reset redirection config:
      this.setRedirectionConfig();
    },

    resetCreationStep() {
      this.currentCaseCreationStep = this.caseCreationSteps[0];
    },

    exitCaseCreation() {
      this.resetPackage();
      this.$emit("cancel");
      this.SET_RISK_SCORE_DATA(null);
    },

    async initCaseCreationProcess() {
      await this.selectStep(this.caseCreationSteps[0]);
    },

    async selectPackage(packageId) {
      this.selectedPackageId = packageId;
      this.$set(this.packageLoaders, packageId, true);

      try {
        this.resetCreationStep();

        if (this.addEntityWorkflow) {
          await this.addEntityToCase();
        } else {
          this.$router.push({
            query: { ...this.$route.query, packageid: packageId },
          });
          await this.createTempCase();
          await this.fetchRiskScore({ case_id: this.tempCaseId });
        }
        this.$set(this.packageLoaders, packageId, false);
        this.$emit("select:package", packageId);

        await this.initCaseCreationProcess();
      } catch (error) {
        this.handleError(error);
        this.resetPackage();
        this.$set(this.packageLoaders, packageId, false);
      }
    },

    checkSwitchable(showMessage = true) {
      // TODO check if switch should be prevented if !!this.redirectionConfig
      const formBuilder = this.$refs?.["case-creation-form-builder"];
      if (!formBuilder) return true;
      const isDirty = formBuilder.isDirty;
      const isInvalid = formBuilder.$v.$invalid;
      // if the form is new (not filled => invalid) or changed (dirty):
      const isSwitchable = !(isDirty || isInvalid);
      if (!isSwitchable && showMessage)
        this.$toast.error("Please save the section data");
      return isSwitchable;
    },

    resetCollectDataStatuses(key = null) {
      if (key) {
        this.$set(this.collectDataStatuses, key, {
          existOnInit: null,
          changeStatus: null,
        });
      } else {
        this.collectDataStatuses = {};
      }
    },

    async onSelectEntity(id) {
      const canChangeTab = this.checkSwitchable(this.selectedEntity);
      if (!canChangeTab) return;
      await this.selectEntity(id);
    },

    async addCollectInfoUser(payload) {
        this.collectInfoData.userList.splice(0, 0, payload)
    },

    async selectEntity(id, hideEntityChangeNav = true, submit = false) {
      // return callback to call it with the newly selected Entity:
      const initEntityMethods = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          await this.fetchEntityForm(id, submit);
          const checkData = await this.fetchEntityChecks(id);
          const checkList = checkData?.data;
          this.$set(this.caseEntityChecks, id, checkList ?? []);
        },
        [CASE_CREATION_STEPS.COLLECT_INFO]: async () => {
          const userList = await axios(`case/${id}/email`);
          this.collectInfoData.userList = userList?.data || [];
          this.collectInfoData.formList = [
            {
              label: "Customized Form",
              value: this.selectedEntity?.data?.form_id,
              id: this.selectedEntity?.data?.form_id,
            },
          ];
        },
        [CASE_CREATION_STEPS.CHECKS]: async () => {
          this.loaders.step = true;
          try {
            const checkData = await this.fetchEntityChecks(id);
            this.$set(this.caseEntityChecks, id, checkData);
          } catch (error) {
            this.handleError(error);
          }
          this.loaders.step = false;
        },
        // [CASE_CREATION_STEPS.SUBMIT]: () => {},
      };

      // reset redirection config:
      this.setRedirectionConfig();

      if (!id) this.selectedEntityId = null;
      else {
        this.selectedEntityId = id;

        // set selected entity globally:
        this.storeSelectedEntityGlobally();

        this.loaders.entity = true;
        try {
          const entityCreationCallback = await initEntityMethods[this.currentCaseCreationStep.id]?.();

          if (this.selectedEntity)
            await entityCreationCallback?.(this.selectedEntity);
        } catch (error) {
          this.handleError(error);
        }
        this.loaders.entity = false;
      }
      if (hideEntityChangeNav) {
        this.showEntityChangeNav = false;
        this.isEntityChangeNavShown = false;
      }
    },

    setCaseEntitiesOrder(case_entities) {
      if (!this.caseEntitiesOrder) {
        const caseEntityIndex = case_entities.findIndex(
          (el) => el.id === this.caseTypeEntityId
        );
        const resultList = [...case_entities];
        const caseEntity = resultList.splice(caseEntityIndex, 1);
        resultList.unshift(...caseEntity);
        this.caseEntitiesOrder = resultList.map((i) => i.id);
      } else {
        case_entities.forEach((i) => {
          if (!this.caseEntitiesOrder.includes(i.id)) {
            this.caseEntitiesOrder.push(i.id);
          }
        });
      }
      return case_entities.reduce((acc, v) => {
        const index = this.caseEntitiesOrder.findIndex((x) => x == v.id);
        acc[index] = v;
        return acc;
      }, []);
    },

  /**
   * Hadle entities data
   *
   * @param {Object} payload entities data.
   * @param {Boolean} init is called while initial data creation
   */
    storeEntityData(payload, init = false) {
      const { case_entities } = payload;
      this.caseEntities = this.setCaseEntitiesOrder(case_entities);
      this.caseEntitiesData = payload;
      if (init) {
        // create empty forms to start monitoring their states:
        this.caseEntities.forEach((el) => {
          const entity = new EntityForm();
          entity.submit(el.creation_form_submitted); // all entities received on init have already submitted forms
          // TODO we should handle valid/invalid form status to determin if the user must save it before moving to the next step
          this.$set(this.caseEntityForms, el.id, entity);
        })
      }
    },

    async getCaseEntityData(init = false) {
      const { data } = await axios(`case/${this.tempCaseId}/entity`);
      const promises = data.case_entities
          .filter(entity=> getEntityTypeName(data, entity) != ENTITY_TYPE_NAMES.CASE)
          .map(entity => getEntityStatuses(entity.id));
  
      const entityStatuses = await Promise.all(promises);

      data.case_entities = data.case_entities.map(el => {
          el.statusList = entityStatuses.find(x => x.id == el.id)?.data || []; 
          return el;
      })          

      for (const key in data.case_entity_type_info) {
        if (data.case_entity_type_info[key].name === ENTITY_TYPE_NAMES.CASE) {
          const entity = data.case_entities.find((ent) => ent.entity_type_id === key)
          this.caseTypeEntityId = entity.id;
          break;
        }
      }
      await this.storeEntityData(data, init);
    },

    // create case initial data
    async createTempCase() {
      this.tempCaseId = this.$route.query?.caseid;
      if (!this.tempCaseId) {
          const payload = {
            package_id: this.selectedPackageId,
            client_id: this.clientId,
          };
          const { data } = await axios.post(
            `case/create-multientity-case`,
            payload
          );
          // there are different ways to determine case id so we set it separately:
          this.tempCaseId = data?.id;

          this.$router.push({
                query: { ...this.$route.query, caseid: this.tempCaseId },
          });
      }
      
      const promises = ([
        this.fetchCaseStatus(),
        this.fetchCaseData(this.tempCaseId), // TODO can be removed if getCaseData no longer used for the Check Output screens
        this.getCaseEntityData(true),
      ]);

      await Promise.all(promises);
      
      // currently, isn't used
      // this.caseTypeEntityId = data.case_type_entity_id;
      // this.storeEntityData(data.entity_info, true);
    },

    async fetchStatus(id = this.tempCaseId) {
      // fetch entity's status list:
      const { data } = await getEntityStatuses(id);

      // update entity status:
      const relatedEntity = this.caseEntities.find((el) => el.id === id);
      if (relatedEntity)
        relatedEntity.statusList = data;

      return data;
    },

    getCurrentStatus(statusList) {
      return statusList.find((el) => el.current) ?? null;
    },

    async fetchCaseStatus() {
      try {
        this.caseStatus = await this.fetchStatus();
      } catch (error) {
        this.handleError(error, false);
        this.caseStatus = null;
      }
    },

    async addEntityToCase() {
      this.tempCaseId = this.$route.query.caseid;
      await this.getCaseEntityData();
    },

    async fetchEntityForm(case_entity_id, submit) {
      const { data } = await axios(
        `case/${this.tempCaseId}/entity/${case_entity_id}/case-entity-creation-form-v2`
      );

      // fill form of the entity which will be selected:
      this.caseEntityForms[case_entity_id]?.populateForm(data);

      // need to submit fetched entity when after entity was removed
      if (submit) this.selectedEntity?.form?.submit()
    },

    formatSections(data) {
      return { sections: [data.data] };
    },

    async saveEntitySection(payload) {
      this.formStates.loading = true;
      let sectionSaved = false;
      try {
        await axios.post(
          `case/${this.tempCaseId}/entity/${this.selectedEntity.id}/case-entity-creation-form`,
          this.formatSections(payload)
        );
        sectionSaved = true;
      } catch (error) {
        this.handleError(error);
        sectionSaved = false;
      }
      this.formStates.loading = false;
      return sectionSaved;
    },

    async onEntityFormSubmitted() {
      // errors are handler on a higher level

      // show loader while data is storing in case of WLP 5.0
      // skip the "Case" entity
      const toFetchWorkflow = (this.selectedEntityId !== this.caseEntity.id) && this.isWLP5;
      if (toFetchWorkflow) {
        this.loaders.redirection = true;
      }

      // reset redirection config:
      this.setRedirectionConfig();

      this.selectedEntity?.form?.submit();

      // for WLP 5.0, we no longer show the EntityChangeNav if there is no entities to add
      // if (!this.isWLP5 || (this.isWLP5 && this.entitiesToAdd.length)) {
        this.showEntityChangeNav = true;
        this.isEntityChangeNavShown = true;
      // }

      await this.getCaseEntityData(); 

      // reset globally stored entity:
      this.storeSelectedEntityGlobally();

      // fetch redirection config
      if (toFetchWorkflow) {
        await this.fetchEntityWorkflow();
        this.loaders.redirection = false;
      }

      if (this.isWLP5 && !this.entitiesToAdd.length && !this.addEntityWorkflow && !this.redirectionConfig) {
        // for the WLP 5.0 case creation, switch to the next step automatically
        // if we have a screen to redirect this screen must be viewed first
        this.nextStep();
      }

      this.$emit("redirectToInput", true)
      // In case we need updated entity form data we should store updated data from the FormBuilder. Currently, all updated data is available until FormBuilder is rerendered
    },

    async submitEntityForm() {
      this.formStates.loading = true;
      try {
        await axios.post(
          `case/${this.tempCaseId}/entity/${this.selectedEntity.id}/case-entity-creation-form/submit`
        );
        await this.onEntityFormSubmitted();
        this.$router.push({
          query: { ...this.$route.query, entityId: this.selectedEntity.id },
        });
      } catch (error) {
        this.handleError(error);
      }
      this.formStates.loading = false;
    },

    async addEntity(payload) {
      const { id, entity_name } = payload; // entity_type_id
      try {
        const currentEntityIndex = this.caseEntities.findIndex(
          (el) => el.id === this.selectedEntityId
        ) || this.caseEntities.length - 1;

        this.$set(this.addEntityLoaders, id, true);
        const { data } = await axios.post(`case/entity`, {
          case_id: this.tempCaseId,
          entity_type_id: id,
        });

        const newEntity = {
          ...this.selectedEntity?.data,
          id: data.case_entity_id,
          form_id: data.form_id,
          active: false,
          created_at: null,
          entity_name: entity_name ?? null,
          entity_type_id: id,
          form_status_id: null,
          form_status_updated_at: null,
        };

        delete newEntity.statusList;

        this.caseEntities.splice(currentEntityIndex + 1, 1, newEntity);
        this.$emit("addEntity", {
            id: newEntity.id,
            name: newEntity.entity_name || "",
            entity_type:  this.caseEntityTypesInfo[newEntity.entity_type_id]?.name,
        });

        // create empty form to start monitoring their states:
        const entity = new EntityForm();
        this.$set(this.caseEntityForms, newEntity.id, entity);

        // store entity check list:
        this.$set(this.caseEntityChecks, newEntity.id, []);

        await this.selectEntity(newEntity.id);
        await this.fetchRiskScore({ case_id: this.tempCaseId });
      } catch (error) {
        console.log(error);
        this.handleError(error);
      }
      this.$set(this.addEntityLoaders, id, false);
    },

    async removeEntity(payload) {
      const { id } = payload; // entity_type_id
      const isCurrent = this.selectedEntityId === id;
      const currentEntityIndex = this.caseEntities.findIndex(
        (el) => el.id === id
      );
      const currentComputedEntityIndex = this.computedEntitiesList.findIndex(
        (el) => el.id === id
      );
      try {
        const removeConfirmed = await this.$refs["confirm-popup"].show({
          title: "Are you sure?",
          message:
            "This entity will be deleted permanently. Please confirm to continue deleting this entity.",
        });
        if (removeConfirmed) {
          await axios.delete(`case/${this.tempCaseId}/entity/${id}`);
          // select previous entity if removed was selected
          if (isCurrent)
            await this.selectEntity(this.caseEntities[currentComputedEntityIndex - 1].id, false, true);
          // remove entity from list:
          this.caseEntities.splice(currentEntityIndex, 1);
          this.caseEntitiesOrder = this.caseEntitiesOrder.filter(x => x!=id);
          // remove related form:
          delete this.caseEntityForms[id];
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    getNextEntityIndex(action) {
      let nextIndex = 0;
      if (action != "stepInited") {
        const currentEntityIndex = this.computedEntitiesList.findIndex(
          (el) => el.id === this.selectedEntityId
        );
        nextIndex = currentEntityIndex + 1;
      }

      let enabledIndex;
      for (let i = nextIndex; i < this.computedEntitiesList.length; i++) {
        if (!this.computedEntitiesList[i].disabled) {
          enabledIndex = i;
          break;
        }
      }
      return enabledIndex;
    },

    async selectNextEntity() {
      const entityIndex = this.getNextEntityIndex();
      if (entityIndex) {
        await this.selectEntity(this.computedEntitiesList[entityIndex].id);
        return;
      }

      const nextStep =
        this.caseCreationSteps.findIndex(
          (x) => x.id == this.currentCaseCreationStep.id
        ) + 1;
      if (nextStep) {
        this.selectStep(this.caseCreationSteps[nextStep], true);
      }
    },

    async deleteEntityDataCollection() {
        const array = this.entitiesCollectInfoDeleted;

        if (!Array.isArray(array)) return;
        const idsToDelete = (array).map(i => i.id);
        const promises = idsToDelete.map(id => axios.delete(`email-sequence/data-collection/${id}`));
        await Promise.all(promises);
        this.entitiesCollectInfo = this.entitiesCollectInfo.filter(x => !idsToDelete.includes(x.id));
        this.entitiesCollectInfoDeleted = [];
    },

    async saveCollectInfo() {
      this.loaders.form = true;
      try {
        const { existOnInit, changeStatus } = this.collectDataStatuses[
          this.selectedEntityId
        ];
        const checkStatus = (status) => status === changeStatus;

        if (
          !existOnInit &&
          (checkStatus(CHANGE_STATUSES.ADDED) ||
            checkStatus(CHANGE_STATUSES.UPDATED))
        ) {

          await this.deleteEntityDataCollection();
          // post data collection:
          const { data } = await axios.post(`email-sequence/data-collection`, [
            this.currentEntityCollectInfo,
          ]);
          // update status saved on server:
          this.$set(
            this.collectDataStatuses[this.selectedEntityId],
            "existOnInit",
            true
          );
          // add new entity to the original list:
          const newCollection = cloneDeep(this.currentEntityCollectInfo);

          // save the ID received from the response to the newCollection:
          const savedCollection = data.data.find(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          newCollection.id = savedCollection.id;
          this.entitiesCollectInfo.push(newCollection);
          const addedEntityIndex = this.entitiesCollectInfoUpdated.findIndex(x=> x.case_entity_id === newCollection.case_entity_id);
          this.entitiesCollectInfoUpdated[addedEntityIndex] = {
             active: false,
             ...newCollection
          }
        } else if (
          existOnInit &&
          (checkStatus(CHANGE_STATUSES.ADDED) ||
            checkStatus(CHANGE_STATUSES.UPDATED))
        ) {
          // update data collection:
          await axios.put(
            `email-sequence/data-collection`,
            this.currentEntityCollectInfo
          );

          // update current entity in original list:
          const entityIndex = this.entitiesCollectInfo.findIndex(
            (el) => el.case_entity_id === this.selectedEntityId
          );
          this.entitiesCollectInfo.splice(
            entityIndex,
            1,
            cloneDeep(this.currentEntityCollectInfo)
          );
        } else if (this.entitiesCollectInfoDeleted.length) {
          await this.deleteEntityDataCollection();
        }
        // else skip action and go to the next entity

        // reset change status:
        this.$set(
          this.collectDataStatuses[this.selectedEntityId],
          "changeStatus",
          null
        );

        await this.selectNextEntity();
      } catch (error) {
        this.handleError(error);
      }
      this.loaders.form = false;
    },

    async submitCase() {
      const stepCallbacks = {
        // [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
        //   // ...
        // },
      };
      const { id: stepID } = this.currentCaseCreationStep;

      try {
        if (this.isWLP5) {
          await stepCallbacks[stepID]?.();
          this.$router.push({ name: "dashboard.view" });
        } else {
          await stepCallbacks[stepID]?.();
          const submissionConfirmed = await this.$refs["finish-popup"].show({
            title: "Are you sure?",
          });
          if (submissionConfirmed) {
            this.loaders.caseSubmission = true;
            const response = await axios.post(
              `case/multi-entity/${this.tempCaseId}/submit`
            );
            this.$toast.success(
              response?.data?.message || "Case Created Successfully!"
            );
            this.exitCaseCreation();
          }
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loaders.caseSubmission = false;
      }
    },

    startCaseCreation() {
      this.currentCaseCreationStep = this.caseCreationSteps[0];
    },

    async fetchEntityWorkflow(object_id = this.selectedEntityId) {
      try {
        this.loaders.redirection = true;
        const { data } = await axios.get(
          `workflow/next-ui`,
          { params: { object_id } }
        );

        const redirectionData = data?.data;
        const redirectionConfig = redirectionData.redirection_config;
        const configurationName = redirectionConfig?.configuration;

        const subConfiguration = redirectionConfig?.sub_configuration;
        const subConfigurationName = subConfiguration
          ? (typeof subConfiguration === "string" ? subConfiguration : subConfiguration.name) // may be either an object or a string
          : null;

        // logic is described in "./screens/utils"
        const isScreenConfigured = !!(configurationName && (subScreenList[configurationName] === true || !!subScreenList[configurationName]?.[subConfigurationName]));

        if (isScreenConfigured)
          this.setRedirectionConfig(redirectionData);
        else
          this.ackEntityScreen(redirectionData.ack_id); // fallback
      } catch (error) {
        this.setRedirectionConfig();
        if (error.response?.data?.status !== false) throw error;
        // else there is no redirection/workflow configured for this entity
      } finally {
        this.loaders.redirection = false;
      }
    },

    // acknowledge redirection screen (according to configured workflow to te entity)
    async ackEntityScreen(ack_id = this.redirectionConfig?.ack_id) {
      if (!ack_id) return;
      await axios.post(
          `workflow/next-ui-ack?ack_id=${ack_id}`,
          // { ack_id }
        );
    },

    setRedirectionConfig(payload = null) {
      this.redirectionConfig = payload;
    },

    async sumbitRedirectionScreen() {
      try {
        await this.ackEntityScreen();

        // refetch current entity status:
        await this.fetchStatus(this.selectedEntityId);
      } catch (error) {
        this.handleError(error);
      }
      this.setRedirectionConfig();

      const promises = [
        this.fetchEntityWorkflow(), // check if there is another redirection config
        this.fetchCaseStatus(), // refetch case status
      ];
      await Promise.all(promises);
    },

    async onRedirectionScreenError(error) {
      this.handleError(error);
      // skip current workflow:
      await this.ackEntityScreen();

      this.setRedirectionConfig();

      // check if there is another redirection config:
      await this.sumbitRedirectionScreen();
    },

    storeSelectedEntityGlobally() {
      this.setSelectedEntity({
        ...this.selectedEntity?.monitoringData,
        ...this.selectedEntity?.data,
      });
    },

    async fetchRiskScore(...args) {
      if (!this.isRiskManagementEnabled) return;
      await this.fetchRiskScoreMethod(...args);
    },

    async fetchEntityChecks(entity_id) {
      let url = `case/${this.tempCaseId}/case_entity/${entity_id}/check`;
      if (this.app === appList.CLIENT) {
        url = `case/${this.tempCaseId}/case_entity/${entity_id}/check/client`;
      }
      const { data } = await axios(url);
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.submit-confirmation-text {
  list-style: disc;
  padding-left: toRem(20px);
}
</style>

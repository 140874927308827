<template>
  <BaseScreen :loading="isLoading">
		<FormBuilder
			v-if="formPreviewData"
			:data="formPreviewData"
			:saveSectionMethod="saveSection"
			:case_id="caseId"
			:entity-id="entityId"
			:app="app"
			@submit="submitForm"
		/>
	</BaseScreen>
</template>

<script>
// TODO check provided app
// TODO show Customized Forms conditionally to allow this component usage on the Case Page > Input Tab
// TODO add apiUrlMap from profile-view.js to utils

import axios from "@/axios";
import BaseScreen from "./components/BaseScreen.vue";
import FormBuilder from "@shared/components/form-builder/index.vue"
import {
  formatSections,
  formatDataForFormPreview,
  prefillFormPreview,
} from "@shared/case-entities/utils";
import { appList } from "@shared/components/form-builder/utils/index.js";

export default {
	components: {
		BaseScreen,
		FormBuilder,
	},
	props: {
    caseId: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
    app: {
      type: String,
      deafult: appList.CREATE,
    },
	},
	data() {
		return {
			isLoading: true,
			formBuilderKey: 1,
			formPreviewData: null,
			selected_dbs_form: null,
		}
	},
	async mounted() {
		// TODO fetch form data
		await this.fetchFormPreview();
	},
	methods: {
		resetNewEntityInfo() {
			this.$emit("update:entities");
		},
		async init() {
      await this.fetchFormPreview();
      // await this.fetchAdditionalForms(); // TODO
      // await this.setCustomizedFormData(); // TODO
      // await this.fetchDataCollections(); // TODO
      // await this.setCustomizedFormEntityData(); // TODO
      // await this.fetchDataCollectionSequences(); // TODO
		},
		// TODO
		async fetchFormPreview() {
      try {
				this.isLoading = true;

        const data = await axios.get(this.getApiUrl("fetchFormPreview"));
        const formattedData = formatDataForFormPreview(data?.data);

        if (this.getNewEntityInfo?.entity_name?.length > 0) {
          this.formPreviewData = prefillFormPreview(
            formattedData,
            this.getNewEntityInfo.entity_name
          );
          this.resetNewEntityInfo();
        } else {
          this.formPreviewData = formattedData;
        }

        this.formBuilderKey++;
      } catch (error) {
        console.error("[+] error in fetching form data", error);
        this.$toast.error(error.response.data.detail);
      } finally {
				this.isLoading = false;
      }
		},
    async saveSection(payload) {
      let sectionSaved = false;
      const url =
        this.selected_dbs_form?.id && this.showClearFiltersButton
          ? `case/${this.caseId}/entity/${this.entityId}/additional-forms/${this.selected_dbs_form.id}`
          : this.getApiUrl("saveSection");

      try {
        const response = await axios.post(url, formatSections(payload));
        this.gapErrorMsg = "";
        sectionSaved = true;

        if (response) {
          if (this.tabs.selected === 1) {
            // update entities data
            await this.updateCaseEntities(true);
            // update current entity data:
            this.setSelectedEntity(this.currentEntity);
          }
          this.$toast.success("Form section successfully updated!");
        }
      } catch (error) {
        const { error: resp } = error?.response?.data?.detail || {};
        if (resp) {
          if (resp.code === "ERROR.GAP_VALIDATION") {
            this.gapErrorMsg = resp.code;
            this.scrollToInvalidField();
            this.gapErrors = {
              message: resp.message,
              gaps: resp.gaps,
              gapErrorsView: true,
            };
          }
        } else {
          this.$toast.error(error.response.data.detail);
        }
        sectionSaved = false;
        throw error;
      }
      return sectionSaved;
    },
    scrollToInvalidField() {
      setTimeout(() => {
        this.$nextTick(() => {
          document
            .querySelectorAll(".form-field--invalid")[0]
            ?.scrollIntoView({ behavior: "smooth" });
        });
      }, 0);
    },
    async submitCaseForm() {
      const url = this.getApiUrl("submitForm");

      try {
        const response = await axios.post(url);

        if (response) {
          this.$toast.success(response.data.message || "Form Saved!");
        }
      } catch (error) {
        this.$toast.error(error.response.data.detail);
        return false;
      }
    },
    async saveForm() {
      try {
        if (this.tabs.selected !== 2) {
          await this.submitCaseForm(this.caseId);
        }
        this.showFormSubmissionNotification = true;
        this.gapErrors = {
          gapErrorsView: false,
        };

        if (this.$route.query.caseid) {
          const entity = this.getEntityById(this.entityId);
          await this.updateCaseEntities();
          this.needRedirectToOverviewCheck = false;
          this.handleEntityTabClick(entity);
        }
      } catch (error) {
        this.formSubmissionStatusMessage =
          "Could not process form at the moment.please contact administrator.";
      }
    },
	},
};
</script>

<style lang="scss" scoped>
// TODO
</style>
<template>
  <div class="flex flex-col w-full h-full gap-4">
    <div
      class="flex justify-end items-center w-full"
      v-if="!toggle.createStatus && !viewMode"
    >
      <div class="">
        <v-button
          text="Create Status"
          class="py-2"
          type="primary"
          :disabled="viewMode"
          @click="handleCreate"
        />
      </div>
    </div>

    <div class="flex max-w-full flex-col" v-if="toggle.createStatus">
      <create-status
        @close="closeCreateStatus"
        @submitForm="submitStatus"
        :colorOptions="colorOptions"
        workflowType="globalCase"
      />
    </div>
    <!--    -->
    <div class="flex flex-col max-w-full gap-4">
      <list-header :headers="headers" :viewOnlyMode="viewOnlyMode"/>
      <v-draggable
        v-if="!loader.transitionList && statusList.length"
        class="list-group flex flex-col gap-2 transition-all duration-200 overflow-auto scroll-bar"
        style="max-height: 50vh"
        :list="statusList"
        :sort="true"
        v-bind="dragOptions"
        @change="handleChanged"
      >
        <transition-group
          type="transition"
          name="flip-list"
          class="flex flex-col space-y-2"
        >
          <div v-for="(el, index) in statusList" :key="index">
            <!-- {{  viewOnlyMode }} -->
            <div class="flex w-full h-8 rounded-lg justify-center items-center">
              <div class="flex w-8 bg-white p-1" v-if="!viewOnlyMode">
                <font-awesome-icon
                  icon="grip-vertical"
                  class="text-base-content-300 text-gray-500"
                />
              </div>
              <div class="grid w-full bg-gray-100 items-center p-1 rounded-md border-2">
                <div class="flex gap-4 w-full text-sm">
                  <div class="flex w-2/12 px-2 truncate" >
                    <div class="flex items-center gap-1">
                      <div
                        class="rounded-full h-4 w-4"
                        :style="`background-color: ${el.colour}`"
                      ></div>
                      <span class="" :title=" el.name">{{ el.name }}</span>
                    </div>
                  </div>
                  <!-- description -->
                  <div class="flex w-3/12 truncate "  v-if="!viewOnlyMode" :title=" el.description">
                    {{ el.description || '' }}
                  </div>
                  <!-- source -->
                  <div class="flex w-3/12 truncate"  v-if="!viewOnlyMode">
                    <!-- <span class="px-2 rounded-md bg-green-500">{{
                      el.name
                    }}</span> -->
                    {{ el.is_default ? 'Yes' :'No' }}
                  </div>
                  <div class="flex w-2/12 truncate px-1.5"  v-if="!viewOnlyMode">
                    {{ el.configuration }}
                    <!-- {{ FormatDateNew(new Date(el.created_at)) }} -->
                  </div>
                  <!-- Active -->
                  <!-- <div class="flex w-1/12 truncate justify-center">
                    <input type="checkbox" /> -->
                  <!-- {{ FormatDateNew(new Date(el.created_at)) }} -->
                  <!-- </div> -->
                  <!-- Actions -->
                  <div class="truncate flex items-center text-primary justify-center w-1/12"  v-if="!viewOnlyMode">
                    <span
                      class="px-2 ml-3"
                      :class="
                        viewMode ? 'cursor-not-allowed' : ' cursor-pointer'
                      "
                      @click="editStatus(el)"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        :fill="viewMode ? '#6b7280' : 'currentColor'"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_368_32080)">
                          <path
                            d="M11.0531 0.508618C10.5398 -0.00466309 9.71016 -0.00466309 9.19687 0.508618L8.49141 1.21174L10.7859 3.50627L11.4914 2.80081C12.0047 2.28752 12.0047 1.45784 11.4914 0.944556L11.0531 0.508618ZM4.04062 5.66487C3.89766 5.80784 3.7875 5.98362 3.72422 6.17815L3.03047 8.2594C2.9625 8.46096 3.01641 8.68362 3.16641 8.83596C3.31641 8.98831 3.53906 9.03987 3.74297 8.9719L5.82422 8.27815C6.01641 8.21487 6.19219 8.10471 6.3375 7.96174L10.2586 4.03831L7.96172 1.74143L4.04062 5.66487ZM2.25 1.50002C1.00781 1.50002 0 2.50784 0 3.75002V9.75002C0 10.9922 1.00781 12 2.25 12H8.25C9.49219 12 10.5 10.9922 10.5 9.75002V7.50002C10.5 7.08518 10.1648 6.75002 9.75 6.75002C9.33516 6.75002 9 7.08518 9 7.50002V9.75002C9 10.1649 8.66484 10.5 8.25 10.5H2.25C1.83516 10.5 1.5 10.1649 1.5 9.75002V3.75002C1.5 3.33518 1.83516 3.00002 2.25 3.00002H4.5C4.91484 3.00002 5.25 2.66487 5.25 2.25002C5.25 1.83518 4.91484 1.50002 4.5 1.50002H2.25Z"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_368_32080">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span
                      class="px-2 ml-3"
                      :class="
                        viewMode || el.is_default
                          ? 'cursor-not-allowed'
                          : ' cursor-pointer'
                      "
                      @click="deleteTransitionStatus(el)"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        :fill="
                          viewMode || el.is_default ? '#6b7280' : '#FF0000'
                        "
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.91875 0.414844L3.75 0.75H1.5C1.08516 0.75 0.75 1.08516 0.75 1.5C0.75 1.91484 1.08516 2.25 1.5 2.25H10.5C10.9148 2.25 11.25 1.91484 11.25 1.5C11.25 1.08516 10.9148 0.75 10.5 0.75H8.25L8.08125 0.414844C7.95469 0.159375 7.69453 0 7.41094 0H4.58906C4.30547 0 4.04531 0.159375 3.91875 0.414844ZM10.5 3H1.5L1.99688 10.9453C2.03438 11.5383 2.52656 12 3.11953 12H8.88047C9.47344 12 9.96562 11.5383 10.0031 10.9453L10.5 3Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </v-draggable>
      <div
        v-else-if="!statusList.length && !loader.transitionList"
        class="flex justify-center items-start"
      >
        <span>No Data Found</span>
      </div>
      <div
        v-else-if="loader.transitionList"
        class="flex justify-center items-start"
      >
        <Loader />
      </div>
      <!-- <recursive-status-list :status-list="statusList" workflowType="globalCase"/> -->
    </div>

    <div class="flex justify-end pt-4" v-if="!viewMode">
      <div class="">
        <v-button
          text="Save & Next"
          class="py-2"
          type="primary"
          :disabled="getDisableState"
          @click="handleSubmit"
        />
      </div>
    </div>
    <modal-confirm 
        title="Are you sure?" 
        message="Please confirm you're about to delete the field." 
        ref="confirm-popup"
    />
  </div>
</template>

<script>
import Button from "@shared/components/button";
import CreateStatus from "@shared/workflow/components/create-status";
import ListHeader from "./list-header.vue";
import draggable from "vuedraggable";
import Loader from "@/components/loader";
import {
  reorderedTransitionList,
  deleteStatus,
} from "@shared/workflow/service.js";
const modalConfirm = () => import("../../../tenant-app/src/components/modal-confirm");

// import RecursiveStatusList from "@shared/workflow/components/recursive-status-list.vue";

export default {
  name: "stateTransition",
  components: {
    "v-button": Button,
    "list-header": ListHeader,
    "v-draggable": draggable,
    CreateStatus,
    Loader,
    modalConfirm

    // RecursiveStatusList,
  },
  props: {
    workflowType: {
      type: String,
      default: "globalCase",
    },
  },
  data() {
    return {
      toggle: {
        createStatus: false,
      },
      colorOptions: [],
      headers: [
        {
          label: "Status Name",
          customClass: "",
        },
        {
          label: "Description",
          customClass: "",
        },
        {
          label: "Default",
          customClass: "",
        },
        {
          label: "Configuration",
          customClass: "",
        },
        // {
        //   label: "Active",
        //   customClass: "",
        // },
        {
          label: "Actions",
          customClass: "",
        },
      ],
      dragOptionForList: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      viewOnlyMode: false,
    };
  },
  computed: {
    statusList() {
      return this.$store.getters.getWorkflowStateTransitionList;
    },
    dragOptions() {
      return this.dragOptionForList;
    },
    getDisableState() {
      return false;
    },
    loader() {
      return this.$store.getters.getLoaders;
    },
    getAction() {
      return this.$route.params.action;
    },
    getType() {
      return this.$route.params.type;
    },
    getWorkflowId() {
      return (
        this.$route.params?.workflowId || this.$route.params?.packagetoolId
      );
    },
    viewMode() {
      return this.$route.params.action === "view"
        || this.$route.name == "check admin details"
        || this.$route.name == "Package Tools";
    },
  },
  async mounted() {
    if(this.$route.name === "check admin details" || this.$route.name == "Package Tools") {
      let viewHeaders = ['Status Name'];
      this.headers = this.headers.filter((el) => viewHeaders.includes(el.label));
      this.viewOnlyMode = true;
    }
    await this.$store.dispatch("transitionList", {
      type: this.getType,
      workflowId: this.getWorkflowId,
    });
    await this.$store.dispatch("fetchAllColors");
    if (this.viewMode) {
      this.dragOptionForList.disabled = true;
    }
   
  },
  methods: {
    handleCreate() {
      this.toggle.createStatus = true;
    },
    closeCreateStatus() {
      this.toggle.createStatus = false;
    },
    submitStatus(form) {
      console.log("submit", form);
    },
    editStatus(el) {
      if (this.viewMode) return;
      this.$router.push({
        name: "Case Status Configure",
        params: { statusId: el.id, workflowId: this.getWorkflowId },
      });
    },

    async handleChanged(evt) {
      try {
        let statuses = [];
        this.statusList.forEach((el, index) =>
          statuses.push({
            status_id: el.id,
            order: index + 1,
          })
        );
        if (evt.moved) {
          let payload = {
            workflow_id: this.getWorkflowId,
            statuses: statuses,
          };
          const response = await reorderedTransitionList(this.getType, payload);
          if (response.status) {
            this.$toast.success(response?.data?.status_message || "success");
          }
        }
      } catch (err) {
        console.error("err", err);
        this.$toast.success(
          err?.response?.data?.details || "Something went wrong"
        );
      }
    },
    handleSubmit() {
      this.$emit("saveAndNext", { from: "stateTransitions" });
    },
    async deleteTransitionStatus(status) {

      if (this.viewMode || status.is_default) return;

      const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Status  will be deleted permanently. Please confirm to continue deleting this status.",
            });
     if (promise) {
      try {
        let payload = {
          status_id: status.id,
          workflow_id: this.getWorkflowId,
        };
        const response = await deleteStatus(this.getType, payload);
        if (response.status) {
          this.$toast.success(
            response?.data?.status_message || "Something went wrong"
          );
          await this.$store.dispatch("transitionList", {
            type: this.getType,
            workflowId: this.getWorkflowId,
          });
        }
      } catch (err) {
        console.error("err", err);
        this.$toast.error(
          err?.response?.data?.status_message || "Something went wrong"
        );
      }
     }
     this.$refs["confirm-popup"].close();
    },
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import "keylines";
// import './style.scss'
import '@shared/assets/styles/style.scss'
// import routes from "./routes";
import store from "./store";
import router from './routes';
import "./plugins/fontawesome"
import "./plugins/index"
import "@/plugins/"
import "@shared/plugins/formulate"
import { directive as onClickOutside } from "@directives/clickoutside.js";
import titleMixin from '@shared/mixins/title'
import "@shared/directives/index.js"
import { Section, ContentBox, SplitViewModal } from "@shared/components/ui";

import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";

Vue.use(VueTippy, {
    directive: "tippy",
});

import VTooltip from "v-tooltip";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import {BootstrapVue, BSpinner, BootstrapVueIcons, BButton, BImg, BSidebar, VBToggle, DropdownPlugin, BDropdown, ModalPlugin, VBModal, PopoverPlugin} from "bootstrap-vue";

Vue.component('UISection', Section);
Vue.component('UIContentBox', ContentBox);
Vue.component('UISplitViewModal', SplitViewModal);
Vue.mixin(titleMixin)
Vue.config.productionTip = false;
Vue.directive("on-click-outside", onClickOutside);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.use(PopoverPlugin);
Vue.use(VTooltip);
Vue.component("b-button", BButton);
Vue.component("b-spinner", BSpinner);
Vue.component("b-img", BImg);
Vue.component("b-sidebar", BSidebar);
Vue.component("b-dropdown", BDropdown);
Vue.directive("b-modal", VBModal);
Vue.directive("b-toggle", VBToggle);

export const EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

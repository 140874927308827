import neoSwitch from "@shared/components/toggle-switch";
import {mapGetters} from "vuex";
import Loader from "@shared/loader";
import {DateTime} from "luxon";
import ResultList from "@shared/saas-components/result-list";

export default {
    components: {
        neoSwitch,
        "neo-loader": Loader,
        ResultList,
    },
    props: {
        timeline: {
            type: Object,
            default: () => {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            monitoringHistoryIndex: null,
        };
    },
    methods: {
        getFormattedTime(ts) {
            if (ts) {
                console.log("rr")
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
    },
    computed: {
        ...mapGetters([]),
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
    },
};

<template>
  <div>
    <ModalContent
      ref="score-modal-content"
      name="score-modal-content"
      title="Risk Score Details"
      submit-button-text="Ok"
      v-bind="$attrs"
      v-on="$listeners"
      @submit="onContentHide"
      @hidden="onContentHide"
    >
      <template #content>
        <div class="score-modal-content w-full flex flex-col items-stretch gap-2">
          <div class="risk-grid grid grid-cols-4 bg-thead rounded-lg px-4 pr-6 py-1 font-medium">
            <p>Entity Name</p>
            <p>Risk</p>
            <p>Score</p>
          </div>

          <div
            v-for="entity in entities"
            :key="entity.id"
          >
            <div
              class="risk-grid grid grid-cols-4 items-center bg-gray-200 px-4 pr-6 py-1 font-medium cursor-pointer"
              :class="{
                'rounded-t-lg': riskCategoriesView[entity.id],
                'rounded-lg': !riskCategoriesView[entity.id],
              }"
              @click="showRiskCategories(entity.id)"
            >
              <p>{{ entity.name }}</p>
              <p :style="{ color: entity.label_color }">
                {{ entity.label_name }}
              </p>
              <p>{{ entity.score }}</p>
              <font-awesome-icon
                icon="chevron-down"
                class="ml-auto text-main text-sm"
                :class="{ 'rotate': riskCategoriesView[entity.id] }"
              />
            </div>

            <div
              v-if="riskCategoriesView[entity.id]"
              class="p-2 border border-solid border-gray-100 rounded-b-lg flex flex-col items-stretch gap-1 w-full"
            >
              <div
                v-for="category in entity.categories"
                :key="`${entity.id}-${category.id}`"
                class="risk-grid grid grid-cols-3 auto-rows-fr items-center bg-gray-100 rounded-lg px-4 py-1 cursor-pointer"
                @click="showRiskCategoryModal(entity.id, category.id)"
              >
                <p class="flex items-center gap-2">
                  <span
                    :style="{ backgroundColor: category.color }"
                    class="w-4 h-4 rounded-full flex-shrink-0"
                  />
                  <span>{{ category.name }}</span>
                </p>
                <p :style="{ color: category.label_color }">
                  {{ category.label_name }}
                </p>
                <p>{{ category.score }}</p>
                <!-- <font-awesome-icon
                  icon="chevron-right"
                  class="ml-auto text-main text-sm"
                /> -->
              </div>
              <!-- <div class="grid grid-cols-3 auto-rows-fr bg-gray-300 rounded-lg px-4 py-1 font-medium">
                <p>Entity overall risk score: </p>
                <p>{{ overallRiskScore }}</p>
              </div> -->
            </div>
          </div>

          <div class="risk-grid grid auto-rows-fr px-4 pr-6 py-1 bg-gray-300 rounded-lg font-medium">
            <p>Total</p>
            <p :style="{ color: getRiskScoreData?.label_color }">
              {{ getRiskScoreData?.label_name }}
            </p>
            <p>{{ overallRiskScore }}</p>
          </div>
        </div>
      </template>
    </ModalContent>

    <ModalContent
      ref="risk-category-modal"
      name="risk-category-modal"
      title="Category"
      submit-button-text="Ok"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!-- TODO -->
      <!-- <template #content>
        <div class="grid grid-cols-2 auto-rows-fr bg-gray-100 rounded-t-lg px-4 py-1 font-medium">
          <p class="flex items-center gap-2">
            <span
              :style="{ backgroundColor: getRiskCategoryColor(riskCategoryKey) }"
              class="w-4 h-4 rounded-full"
            />
            <span>{{ riskCategoryKey }}</span>
          </p>
          <p>{{ riskCategoryValue?.totalScore }}</p>
        </div>
        <div class="p-2 border border-solid border-gray-100 rounded-b-lg">
          <div class="grid grid-cols-2 auto-rows-fr mb-2 bg-gray-300 rounded-lg px-4 py-1 font-medium">
            <p>Check name</p>
            <p>Score</p>
          </div>

          <div
            v-for="(item, idx) in riskCategoryValue?.items"
            :key="`${item?.evaluation_type}-${idx}`"
            class="grid grid-cols-2 auto-rows-fr bg-gray-100 rounded-lg mb-2 px-4 py-1 cursor-pointer"
          >
            <p class="flex items-center gap-2">
              <span>{{ item?.evaluation_type }}</span>
            </p>
            <p>{{ item?.score }}</p>
          </div>
        </div>
      </template> -->
    </ModalContent>
  </div>
</template>

<script>
/*
  RISK SCORE STRUCTURE
  Score <- Entity <- Category <- Assessment
*/

// TODO remove old implementation code

import { mapGetters } from "vuex";
import ModalContent from "@shared/modal-content";

export default {
    name: "CaseScoreModal",

    components: {
      ModalContent,
    },

    props: {
      caseTypeEntityId: {
        type: String,
        required: true,
      },
      caseEntities: {
        type: Array,
        required: true,
      }
    },

    data() {
      return {
        isRiskCategoriesShown: false,
        selectedRiskCategory: null,
        riskCategoriesView: {},
      }
    },

    computed: {
      ...mapGetters({
        getRiskScoreData: "CaseCreationStore/getRiskScoreData",
      }),

      caseEntity() {
        return this.caseEntities?.find(ce => ce.id === this.caseTypeEntityId) ?? null;
      },

      // entities() {
      //   return this.caseEntities?.filter(ce => ce.id !== this.caseTypeEntityId) ?? [];
      // },

      entities() {
        return (this.getRiskScoreData?.entities || []).filter(ce => ce.id !== this.caseTypeEntityId); 
      },

      // breakdown() {
      //   return this.getRiskScoreData?.breakdown ?? [];
      // },

      // TODO; Can be util function
      // groupedRiskCategories() {
      //   return Object.entries(
      //     this.breakdown.reduce((acc, current) => {
      //     const { risk_category, evaluation_type, score} = current;

      //     if (!acc[risk_category]) {
      //       acc[risk_category] = { items: [], totalScore: 0, };
      //     }

      //     acc[risk_category].items.push({ risk_category, evaluation_type, score });
      //     acc[risk_category].totalScore = current.score + acc[risk_category].totalScore;

      //     return acc;
      //   }, {}));
      // },

      // riskCategory() {
      //   return this.groupedRiskCategories.find(([key]) => key === this.selectedRiskCategory) ?? [];
      // },

      // riskCategoryKey() {
      //   return this.riskCategory[0];
      // },

      // riskCategoryValue() {
      //   return this.riskCategory[1];
      // },

      overallRiskScore() {
        return this.getRiskScoreData?.score ?? 0;
      },

      // TODO: Can be a constant
      // riskCategoryToColorMap() {
      //   return new Map([
      //     ["Inherit", "#979fa9"],
      //     ["Screening", "#f7aa00"],
      //     ["EDD", "#f3f4f6"],
      //     ["DDQ", "#00aefc"],
      //     ["Unknown", "#fff"],
      //   ]);
      // },
    },

    methods: {
      showRiskCategories(entity_id) {
        this.$set(this.riskCategoriesView, entity_id, !this.riskCategoriesView[entity_id]);
      },

      resetRiskView() {
        this.riskCategoriesView = {};
      },

      // TODO set combination of entity's id and risk category's id
      showRiskCategoryModal() {
        // TODO enable when template is ready
        // this.selectedRiskCategory = {};
        // this.$refs["risk-category-modal"].showModal();
      },

      getRiskCategoryColor(riskCategory) {
        const keyToGet = this.riskCategoryToColorMap.has(riskCategory) ? riskCategory : "Unknown";
        return this.riskCategoryToColorMap.get(keyToGet);
      },

      onContentHide() {
        this.resetRiskView();
        this.isRiskCategoriesShown = false;
      },
    },
}
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}

.risk-grid {
  grid-template-columns: 1fr 100px 100px 25px;
  & > * {
    &:not(:last-child) {
      @apply pr-1;
    }
    &:not(:first-child) {
      @apply pl-1;
    }
  }
  p, span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import EditGreen from "@/assets/icons/email-admin/icon-editgreen.svg";
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";
import TrashRed from "@/assets/icons/email-admin/icon-trash-red.svg";
import ViewBlue from "@/assets/icons/ico-show.svg";
import ArrowIcon from "@/assets/icons/email-admin/icon-arrow.svg";
import EditIcon from "@/assets/icons/email-admin/icon-edit.svg";
import DownIcon from "@/assets/icons/email-admin/icon-down.svg";
import foldericon from "@/assets/icons/folder_open.svg";
import { format } from "date-fns";
import axios from "@/axios";
import BrowseDir from "@/components/browse-dir";
import emailTempModal from "@/components/emailtemplatemodal";
const modalConfirm = () => import("@/components/modal-confirm");
import renameFolderModal from "@/components/renameFolder";
// import dropdown from "@shared/components/dropdown-base";
import dropdown from '@/components/dropdown-base';
import sortIcon from "@/assets/icons/dashboard/dropdown-double.svg";
import Dialog from "@/components/dialog";
import { uniq } from "lodash";
import Loader from "@shared/loader";
import { checkPermission } from "@shared/utils/functions";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "email-template",
    components: {
        TextTemplate,
        EditGreen,
        TrashRed,
        ViewBlue,
        FolderIcon,
        ArrowIcon,
        EditIcon,
        DownIcon,
        BrowseDir,
        emailTempModal,
        renameFolderModal,
        dropdown,
        sortIcon,
        foldericon,
        "dialog-modal": Dialog,
        Loader,
        modalConfirm
    },
    props: {
        isDirModalOpen: Boolean,
        isModalOpen: Boolean,
        onModelClose: Function,
        onDirModalClose: Function,
    },
    data() {
        return {
            isLoading: false,
            allTemplates: [],
            rows: [],
            format,
            folderPath: [],
            isTemplateModalOpen: this.isModalOpen,
            isBrowseDirModalOpen: this.isDirModalOpen,
            populateData: {
                id: "",
                content: "",
                name: "",
                subject: "",
                description: "",
                path: "/",
            },
            errorMessage: "",
            directoryToastMessage: null,
            renameFolder: false,
            folderData: null,
            filters: {
                name: [],
                description: [],
                created_at: [],
                modified_at: []
            },
            reserved:{},
            sortParams: null,
            placeholders: [],
            props: {},
            formData: {
                path: "",
            }
        };
    },
    computed: {
        ...mapGetters(["getFormRows"]),
        columns() {
            return [
                {
                    label: "Action",
                    field: "actions",
                    width: "6%",
                    sortable: false
                },
                {
                    label: "Lock",
                    field: "lock",
                    width: "8%",
                },
                {
                    label: "In use",
                    field: "in_use",
                    width: "8%",
                },
                {
                    label: "Name",
                    field: "name",
                    width: "25%",
                    filterOptions: {
                        enabled: true,
                        placeholder: "All",
                        filterDropdownItems: this.currentPathTemplates?.map((x) => x.name).filter((x) => x),
                        formatValue: this.formatFilterValue,
                    },
                },
                {
                    label: "Description",
                    field: "description",
                    width: "25%",
                    filterOptions: {
                        enabled: true,
                        placeholder: "All",
                        filterDropdownItems: this.currentPathTemplates?.map((x) => x.description)?.filter((x) => x),
                        formatValue: this.formatFilterValue,
                    },
                },
                {
                    label: "Created By",
                    field: "created_by",
                    width: "10%",
                },
                {
                    label: "Created At",
                    field: "created_at",
                    width: "10%",
                    filterOptions: {
                        enabled: true,
                        placeholder: "All",
                        filterDropdownItems: uniq(
                            this.currentPathTemplates
                                ?.map((x) => {
                                    if (x?.created_at) {
                                        return format(new Date(x?.created_at), "dd MMM yyyy");
                                    }
                                    return "";
                                })
                                .filter((x) => x)
                        ),
                        formatValue: this.formatFilterValue,
                    },
                },
                {
                    label: "Updated By",
                    field: "modified_by",
                    width: "15%",
                },
                {
                    label: "Updated At",
                    field: "modified_at",
                    width: "10",
                    filterOptions: {
                        enabled: true,
                        placeholder: "All",
                        filterDropdownItems: uniq(
                            this.currentPathTemplates
                                ?.map((x) => {
                                    if (x?.modified_at) {
                                        return format(new Date(x?.modified_at), "dd MMM yyyy");
                                    }
                                    return "";
                                })
                                .filter((x) => x)
                        ),
                        formatValue: this.formatFilterValue,
                    },
                },
              
                // {
                //     label: "",
                //     field: "actions",
                //     width: "10%",
                // },
            ];
        },
         currentPathTemplates() {
            let result = this.allTemplates;
            for (let i = 0; i < this.folderPath.length; i++) {
                let requiredPath = result.find(pth => pth.id == this.folderPath[i].id)
                // result = result[this.folderPath[i].index]?.content || [];
                result = requiredPath?.content || []
            }
            return result;
        },
        emailTemplates() {
            let result = this.currentPathTemplates;
            console.log("result",result);
            for (let filter in this.filters) {
                if (this.filters[filter]?.length) {
                    if (filter === "created_at" || filter === "modified_at") {
                        result = result.filter((el) => {
                            const date = el[filter] ? format(new Date(el[filter]), "dd MMM yyyy"):"";
                            return this.filters[filter].includes(date);
                        });
                    } else result = result.filter((el) => this.filters[filter].includes(el[filter]));
                }
            }
            this.rows = result?.map((x) => ({
                ...x,
                created_at: x?.created_at ? format(new Date(x?.created_at), "dd MMM yyyy") : "",
                modified_at: x?.modified_at ? format(new Date(x?.modified_at), "dd MMM yyyy") : "",
            }));
            // console.log("this.rows",this.rows);
            return this.rows;
        },
    },
    watch: {
        isModalOpen: function (val) {
            if (val !== this.isTemplateModalOpen) {
                this.isTemplateModalOpen = val;
                this.renameFolder = false;
                this.folderData = null;
            }
        },
        isTemplateModalOpen: function (val) {
            if (this.isModalOpen !== val) {
                this.$emit("onModelClose");
            }
        },
        isDirModalOpen: function (val) {
            if (val !== this.isBrowseDirModalOpen) {
                this.isBrowseDirModalOpen = val;
            }
        },
        isBrowseDirModalOpen: function (val) {
            if (this.isDirModalOpen !== val) {
                this.$emit("onDirModalClose");
            }
            this.directoryToastMessage = null;
        },
    },
    async mounted() {
        const storedFolderPath = localStorage.getItem('folderPath');
        if (storedFolderPath) {
            this.folderPath = JSON.parse(storedFolderPath);
        }
        await this.fetchTemplateList();
        await this.fetchFormRows();
        this.placeholders = this.getFormRows || [];
    },
    updated(){
        console.log(">>>>",this.filters);
    },
    methods: {
        ...mapActions(["fetchFormRows"]),
        checkPermission,
        async fetchTemplateList() {
            this.isLoading = true;
            try {
                const url = `/email-template/list?external_id=${this.$store.getters.getTenantId}`;
                const result = await axios.get(url, { params: {...this.sortParams } });
                let data = result?.data?.content;
                this.allTemplates = data;
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
            } finally {
                this.isLoading = false;
            }
            // console.log("templates data --->>", data);
            console.log("all templates list --->>", this.allTemplates );
        },
        handleBreadcrumbsPath(index) {
            this.folderPath.splice(index + 1);
            localStorage.setItem('folderPath', JSON.stringify(this.folderPath));
        },
        handlePath(props) {
            console.log("props :>> ", props);
            const { ...rest } = props.row;
            if (rest.type === "directory") {
                this.folderPath.push({ ...rest, index: props.index });
                localStorage.setItem('folderPath', JSON.stringify(this.folderPath));
                this.reserved = this.filters;
                this.filters ={name: [],
                    description: [],
                    created_at: [],
                    modified_at: []};
            }
        },
        backPath() {
            this.folderPath.pop();
            localStorage.setItem('folderPath', JSON.stringify(this.folderPath));
        },
        changeFolderName() {
            const data = this.folderPath[this.folderPath.length - 1];
            this.editclick(data);
        },
        async editclick(data,option=null) {
            if (!this.checkPermission("email.read")) return;
            if (data?.type === "resource") {
                this.renameFolder = false;
                this.folderData = null;
                if((option != 'view')){
                    if(option === 'folder'){
                      return
                    }
                    const lockResult = await this.setEditmodeLock(data.id)
                    if(lockResult == false){
                        this.isTemplateModalOpen = false;
                        await this.fetchTemplateList();
                        return
                    }
                }
                try {
                    const res = await axios.get(`email-template/${data.id}`);
                    
                    this.isTemplateModalOpen = true;
                    this.populateData = {
                        id: res.data.id,
                        content: res.data.body,
                        path: res.data.path,
                        templateName: res.data.name,
                        templateSubject: res.data.subject,
                        description: res.data.description,
                        system_default: data.system_default,
                        placeholder_metadata: res.data.populateData || [],
                        mode:option
                    };
                    
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
                }
            } else if (data?.type === "directory") {
                this.renameFolder = true;
                data.mode=option
                this.folderData = data;
                this.folderData.templateName = data.name;
            }
        },
        async setEditmodeLock(templateId, option = null) {
            try {
                if (templateId === null || templateId === "") {
                    return;
                  }
                let payload = { lock_status: true }
                if (option !== null) {
                    payload = { lock_status: false }
                }
                let url = `/email-template/locked/${templateId}`;
                await axios.post(url, payload);
                return true;
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to lock this Template");
                return false; // Return false when there's an error
            }
        },
        async setEditModeUnlock(){
            if(this.$route.params.action === 'view'){
                return
            }
            let query = this.$route;
            if(query.params.id){
                await this.setEditmodeLock(query.params.id,'backMode')
            }
        },
        async deleteclick(id, type) {
            let messages="This Template will be deleted permanently. Please confirm to continue deleting this Template."
            if(type == "directory"){
                messages="This Folder will be deleted permanently. Please confirm to continue deleting this Folder."
            }
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: messages,
            });
            if(promise){
                if (id) {
                    try {
                        await axios.delete(`email-template/${id}`);
                        await this.fetchTemplateList();

                        this.$toast.success(type === "directory" ? "Folder deleted successfully!" : "Template deleted successfully!");
                    } catch (error) {
                        this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
                    }
                }
                if (this.isTemplateModalOpen) this.toggleModal();
            }    
            this.$refs["confirm-popup"].close();
        },
        async handleCreateFolder(data) {
            if (!this.checkPermission("email.create")){
                this.$toast.error("You don't have the permissions to create a folder !!");
                return
            }
            this.directoryToastMessage = null;
            try {
                if (data.name.includes("/")) {
                    this.directoryToastMessage = {
                        type: "error",
                        message: `Folder name can't have / `,
                    };
                    return;
                }
                await axios.post("/email-template/create", data);
                this.$toast.success("Folder created successfully!");
                await this.fetchTemplateList();
                this.directoryToastMessage = {
                    type: "success",
                    message: "Folder created successfully!",
                };
                this.browseToggleModal()
            } catch (error) {
                // this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
                this.directoryToastMessage = {
                    type: "error",
                    message: error?.response?.data?.detail || "Something went wrong. Please try again",
                };
            }
        },
        async onSortChange(data) {
          if (data && data.length && data[0].type !== 'none') {
              data = data[0]
              this.sortParams = {
              sort: data.field + ':' + data.type
              },
              await this.fetchTemplateList()
          }else{
              this.sortParams = null
              await this.fetchTemplateList()
          }

      },
        // replaceQuillPlaceholders(content) {
        //     let delta = content.replaceAll("<strong style=\"color: rgb(13, 105, 213);\">", "");
        //     delta = delta.replaceAll("</strong>", "");
        //     return delta;
        // },
        async saveTemplate(formData) {
            formData.templateName=formData.templateName.trim();
            this.errorMessage = "";
            if(!formData.templateName){
                this.$toast.error("Please enter a template name");
                return
            }else
            if(!formData.templateSubject){
                this.$toast.error("Empty email subject not allowed");
                return
            }else
            if(!formData.content){
                this.$toast.error("Empty email body not allowed");
                return
            }
            // const getAllFormTemps = (str) => {
            //   return str.match(/{{(.*?)}}/g).map(function(val){
            //     return val.replace(/{{?b}}/g,'');
            //  });
            // }
            // let { placeholder_metadata } = formData;
            let form_tags;
            if (formData && formData.content) {
                form_tags = formData.content.match(/{{(.*?)}}/g) || [];
                form_tags = form_tags.length ? form_tags.map((el) => el.split("{{")[1].split("}}")[0]) : [];
                // placeholder_metadata = placeholder_metadata?.filter((ele) => !form_tags.includes(ele.name));
            }
        
            let placeholder_metadata = [];
            for (const tag in form_tags) {
                let match;
                match = this.placeholders.find((pl) => pl.name === form_tags[tag])
                if (match)
                    placeholder_metadata.push({
                        "placeholder": match.name,
                        "internal_name": match.internal_name,
                        "type": "form",
                        "id": match.id
                    });
            }

            let subject_tags = formData.templateSubject.match(/<(.*?)>/g);
            let slash_tags = ["\t", "\n"];
            for (const index in subject_tags) {
                const text = subject_tags[index];
                formData.templateSubject = formData.templateSubject.replaceAll(text, "");
            }
            for (const index in slash_tags) {
                const text = slash_tags[index];
                formData.templateSubject = formData.templateSubject.replaceAll(text, "");
            }
            // if templateSubject contain &nbsp; then replace with space
            formData.templateSubject = formData.templateSubject.replaceAll('&nbsp;', " ");
            
            if (formData.templateName) {
                if (formData.templateName.includes("/")) {
                    this.directoryToastMessage = {
                        type: "error",
                        message: `Template name can't have / `,
                    };
                    return;
                }
                try {
                    if (formData.id) {
                        await axios.post("email-template/update", {
                            //tenant id or candidate
                            id: formData.id,
                            path: `${formData?.path}/${formData.templateName}`.replace("//", "/"),
                            subject: formData.templateSubject,
                            body: formData.content,
                            name: formData.templateName,
                            description: formData.description,
                            placeholder_metadata,
                        });
                        await this.fetchTemplateList();
                        this.$toast.success("Template updated successfully!");
                        this.toggleModal();
                    } else {
                        await axios.post("email-template/create", {
                            //tenant id or candidate
                            external_id: this.$store.getters.getTenantId,
                            path: `${this.formData?.path}/${formData.templateName}`.replace("//", "/"),
                            name: formData.templateName,
                            description: formData.description,
                            resource_type: "resource",
                            subject: formData.templateSubject,
                            body: formData.content,
                            placeholder_metadata,
                        });
                        this.$toast.success("Template created successfully!");
                        this.toggleModal();
                        await this.fetchTemplateList();
                    }
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
                }
             
            } else {
                this.errorMessage = "Please enter a template name";
            }
        },
        async saveFolderName(formData) {
            this.errorMessage = "";
            if (formData.templateName) {
                if (formData.templateName.includes("/")) {
                    this.directoryToastMessage = {
                        type: "error",
                        message: `Folder name can't have / `,
                    };
                    return;
                }
                try {
                    let path = `${formData?.path}/${formData.templateName}`.replace("//", "/");
                    await axios.patch(`email-template/rename?template_id=${formData.id}&new_name=${formData?.templateName}&new_path=${path}`);
                    this.renameFolder = false;
                    this.$toast.success("Folder renamed successfully!");
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
                }
                await this.fetchTemplateList();

                let data = this.folderPath[this.folderPath.length - 1];
                if (data.id === formData.id)
                    this.folderPath[this.folderPath.length - 1] = {
                        ...data,
                        ...{
                            name: formData.templateName,
                            path: `${formData?.path}/${formData.templateName}`.replace("//", "/"),
                        },
                    };

                this.toggleModal();
            } else {
                this.errorMessage = "Please enter a folder name";
            }
        },
        toggleModal() {
            this.populateData = {
                id: "",
                content: "",
                name: "",
                subject: "",
                description: "",
                path: "/",
            };
            this.errorMessage = "";
            this.directoryToastMessage = null;
            this.successMessage = "";
            this.isTemplateModalOpen = false;
            this.renameFolder = false;
            this.folderData = null;
        },
        browseToggleModal() {
            this.isBrowseDirModalOpen = !this.isBrowseDirModalOpen;
        },
        formatFilterValue(valuesArray) {
            console.log('formatFilterValue: ');
            console.log("..",valuesArray.map((value) => value.id).join(","));
            return valuesArray.map((value) => value.id).join(",");
        },
        updateFilters(column, value) {
            console.log("up",column, value);
            
            if (this.filters?.[column.field]) this.filters[column.field] = value;

        },
        formDataPath(val){
            this.formData.path = val;
        }
    },
};

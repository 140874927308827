<template>
    <modal-content ref="display-work-allocation-modal" :showFooter="false" :max-width="480" name="addfield" :title="`${workData.type || 'Teams'} assigned to Client`" @close="$emit('closeModal')">
        <template #content>
            <div class="py-28 flex justify-center items-center" v-if="isLoading">
                <Loader :loading="isLoading" />
            </div>
            <div v-else>
                <ListView :icon="`${workData.type === 'teams' ? 'users' : 'user'}`" @handleFilterResult="handleFilterResult($event)" :valueKey="`${workData.type === 'teams' ? 'name': 'user_name' }`"  :listItems="filteredResult" :showIcon="true" :showSearch="true" />
            </div>
        </template>
    </modal-content>
</template>
<script>
import Loader from "@shared/loader";
import modalContent from "@/components/modal-content";
import ListView from "@shared/components/list-view"

export default {
    name: "display-work-allocation-modal",
    components: {
        Loader,
        modalContent,
        ListView
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        workData: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            searchValue: ''
        };
    },
    mounted() {
        this.closeModal();
    },
    computed: {
        filteredResult(){
            if(this.searchValue && this.searchValue.length > 0){
                return this.workData.data.filter(el => {
                    if(this.workData.type === 'teams'){
                        if(el.name.toLowerCase().includes(this.searchValue)){
                            return el
                        }
                    }else{
                        if(el.user_name.toLowerCase().includes(this.searchValue)){
                            return el 
                        }
                    }
                })
            }else{
                return this.workData.data
            }
        }
    },
    methods: {
        closeModal() {
            this.$refs["display-work-allocation-modal"].hideModal();
        },
        showWorkModal() {
            this.searchValue = ""
            this.$refs["display-work-allocation-modal"].showModal();
        },
        handleFilterResult(value){
            this.searchValue = value
        }
    },
};
</script>
<style scoped></style>

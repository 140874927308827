
import {DateTime} from "luxon";
import companyInfo from "@shared/tools/company-identifier/db/companyInfo";
import uboList from "@shared/tools/company-identifier/db/uboList";
import ownership from "@shared/tools/company-identifier/db/ownershipInsights";
import financialStrength from "@shared/tools/company-identifier/db/financialStrength";
import axios from "@shared/light-ray/axios/osint-axios";
import VueMultiselect from "@shared/saas-components/vue-multiselect/index";
export default {
    name: "db",
    components: {
        VueMultiselect,
        companyInfo,
        financialStrength,
        uboList,
        ownership,
    },
    props: {
        companyDetails: Object,
        licenseLevels: {
            type: Object,
            default: () => {},
        },
        fetchMoreDetails: {
            type: Object,
            default: () => {},
        },
        fetchMoreLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            levels: [
                {
                    group: "level",
                    categories: [
                        {
                            text: "L1",
                            value: "L1",
                        },
                        {
                            text: "L2",
                            value: "L2",
                            $isDisabled: true,
                        },
                        {
                            text: "L3",
                            value: "L3",
                        },
                    ],
                },
            ],
            companyInfoLevel: [],
            financialsInfoLevel: [],
            hierarchyConnection: [],
            pricipalLevel: [],
            ownershipInsightLevel: [],
            uboLevel: [],
            companyInfo: [],
            principalsAndContacts: [],
            financialStrengthInsightsInfo: [],
            principalsAndContactsTabs: ["Principals", "Summary"],
            selectedPrincipalTab: "Principals",
            companyInfoTabs: [],
            disableFetchMore: false,
            disableFetchCompanyInfo: false,
            disableFetchHierarchy: false,
            disableUboList: false,
            companyFetchmore: false,
            fetchprincipals: false,
            fetchfinancials: false,
            fetchCompanyExpanded: false,
            fetchPrincipalExpanded: false,
            fetchFinancialExpanded: false,
            financialsLevel: {},
            companyLevel: {},
            corporateLinkage: [],
            fetchCorporateExpanded: false,
            ownershipInsights: [],
            fetchOwnership: false,
            fetchOwnershipExpanded: false,
            ubocmpbol: [],
            fetchubocmpbol: false,
            disableFetchuboList: false,
            disableFetchOwnership: false,
            companyLicenseLevel: [],
            financialStengthLevel: [],
            ownershipLicense: [],
            uboListLicense: [],
            hierarchyConnectionsLicense: [],
            principalsContactsLicense: [],
            previousOwnershipLevel: "",
            previousHirerarchyLevel: "",
            previousUboLevel: "",
            previousPricipalsLevel: "",
        };
    },
    methods: {
        async getMoreCompanyInfo() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.companyInfoLevel.value,
            };
            let response = await axios.post("/api/v1/db/data_block/company_info", requestBody);
            if (response && response.data) {
                this.companyInfo = response.data.company_info.organization;
                this.companyFetchmore = true;
                this.fetchCompanyExpanded = true;
                this.companyLevel = this.companyInfoLevel;
                if (this.companyInfoLevel.value === this.licenseLevels["company_info"][this.licenseLevels["company_info"].length - 1]) this.disableFetchCompanyInfo = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                if (this.companyInfoLevel.value === this.licenseLevels["company_info"][this.licenseLevels["company_info"].length - 1]) this.disableFetchCompanyInfo = true;
            }
        },
        getDisplayBlock(blockName) {
            Object.keys(this.licenseLevels).forEach((el) => {
                if (el === blockName) {
                    return true;
                }
            });
        },
        dnbLicenseLevel() {
            let levels;
            Object.keys(this.licenseLevels).forEach((el) => {
                levels = this.licenseLevels[el].map((el1) => ({text: el1, value: el1}));
                if (el === "company_info") {
                    this.companyLicenseLevel = [{group: "level", categories: levels}];
                    this.companyInfoLevel = this.companyLicenseLevel[0].categories[0];
                } else if (el === "financial_detail") {
                    this.financialStengthLevel = [{group: "level", categories: levels}];
                    this.financialsInfoLevel = this.financialStengthLevel[0].categories[0];
                } else if (el === "hierarchy_connections") {
                    this.hierarchyConnectionsLicense = [{group: "level", categories: levels}];
                    this.hierarchyConnection = this.hierarchyConnectionsLicense[0].categories[0];
                } else if (el === "principals_contacts") {
                    this.principalsContactsLicense = [{group: "level", categories: levels}];
                    this.pricipalLevel = this.principalsContactsLicense[0].categories[0];
                } else if (el === "ownership_insights") {
                    this.ownershipLicense = [{group: "level", categories: levels}];
                    this.ownershipInsightLevel = this.ownershipLicense[0].categories[0];
                } else if (el === "cmpbol") {
                    this.uboListLicense = [{group: "level", categories: levels}];
                    this.uboLevel = this.uboListLicense[0].categories[0];
                }
            });
            this.showMoreData();
        },
        showMoreData() {
            if (Object.keys(this.fetchMoreDetails).includes("cmpbol_detail") && this.uboLevel.value === "L2") {
                this.disableFetchuboList = true;
                this.ubocmpbol = this.fetchMoreDetails.cmpbol_detail.organization;
            }
            if (Object.keys(this.fetchMoreDetails).includes("company_info_L1") && this.companyInfoLevel.value === "L1") {
                this.companyLevel = this.companyInfoLevel;
                this.companyFetchmore = true;
                this.companyInfo = this.fetchMoreDetails.company_info_L1.organization;
                if (this.companyInfoLevel.value === this.licenseLevels["company_info"][this.licenseLevels["company_info"].length - 1]) this.disableFetchCompanyInfo = true;
            }
            if (Object.keys(this.fetchMoreDetails).includes("company_info_L3") && this.companyInfoLevel.value === "L3") {
                this.companyLevel = this.companyInfoLevel;
                this.companyFetchmore = true;
                this.companyInfo = this.fetchMoreDetails.company_info_L3.organization;
                if (this.companyInfoLevel.value === this.licenseLevels["company_info"][this.licenseLevels["company_info"].length - 1]) this.disableFetchCompanyInfo = true;
            }
            if (Object.keys(this.fetchMoreDetails).includes("financial_detail_L1") && this.financialsInfoLevel.value === "L1") {
                this.financialsLevel = this.financialsInfoLevel;
                this.fetchfinancials = true;
                if (this.financialsInfoLevel.value === this.licenseLevels["financial_detail"][this.licenseLevels["financial_detail"].length - 1]) this.disableFetchMore = true;
                this.financialStrengthInsightsInfo = this.fetchMoreDetails.financial_detail_L1.organization;
            }
            if (Object.keys(this.fetchMoreDetails).includes("financial_detail_L3") && this.financialsInfoLevel.value === "L3") {
                this.financialsLevel = this.financialsInfoLevel;
                this.fetchfinancials = true;
                if (this.financialsInfoLevel.value === this.licenseLevels["financial_detail"][this.licenseLevels["financial_detail"].length - 1]) this.disableFetchMore = true;
                this.financialStrengthInsightsInfo = this.fetchMoreDetails.financial_detail_L3.organization;
            }
            if (Object.keys(this.fetchMoreDetails).includes("hierarchy_connections_L1") && this.hierarchyConnection.value === "L1") {
                this.disableFetchHierarchy = true;
                this.corporateLinkage = this.fetchMoreDetails.hierarchy_connections_L1.organization;
            }
            if (Object.keys(this.fetchMoreDetails).includes("ownership_insights_L1") && this.ownershipInsightLevel.value === "L1") {
                this.disableFetchOwnership = true;
                this.ownershipInsights = this.fetchMoreDetails.ownership_insights_L1.organization;
            }
            if (Object.keys(this.fetchMoreDetails).includes("principals_contacts_L2") && this.pricipalLevel.value === "L2") {
                this.fetchprincipals = true;
                this.principalsAndContacts = this.fetchMoreDetails.principals_contacts_L2.organization;
            }
            if (Object.keys(this.fetchMoreDetails).includes("principals_contacts_L1") && this.pricipalLevel.value === "L1") {
                this.fetchprincipals = true;
                this.principalsAndContacts = this.fetchMoreDetails.principals_contacts_L1.organization;
            }
        },
        handlePanelExpand(panel) {
            if (panel === "companyInfo") this.fetchCompanyExpanded = !this.fetchCompanyExpanded;
            if (panel === "principalContact") this.fetchPrincipalExpanded = !this.fetchPrincipalExpanded;
            if (panel === "financial") this.fetchFinancialExpanded = !this.fetchFinancialExpanded;
            if (panel === "ownershipInsight") this.fetchOwnershipExpanded = !this.fetchOwnershipExpanded;
            if (panel === "ubocmpbol") this.fetchubocmpbol = !this.fetchubocmpbol;
            if (panel === "corporateLinkage") this.fetchCorporateExpanded = !this.fetchCorporateExpanded;
        },
        async getCorporateLinkage() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.hierarchyConnection?.value,
            };
            let response = await axios.post("/api/v1/db/data_block/hierarchy_connections", requestBody);
            if (response && response.data) {
                this.corporateLinkage = response.data.hierarchy_connections.organization;
                this.previousHirerarchyLevel = this.hierarchyConnection?.value;
                if (this.hierarchyConnection?.value === this.licenseLevels["hierarchy_connections"][this.licenseLevels["hierarchy_connections"].length - 1]) this.disableFetchHierarchy = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                this.previousHirerarchyLevel = this.hierarchyConnection?.value;
                if (this.hierarchyConnection?.value === this.licenseLevels["hierarchy_connections"][this.licenseLevels["hierarchy_connections"].length - 1]) this.disableFetchHierarchy = true;
            }
            this.fetchCorporateExpanded = true;
        },
        async getPrincipalsContacts() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.pricipalLevel?.value,
            };
            let response = await axios.post("/api/v1/db/data_block/principals_contacts", requestBody);
            if (response && response.data) {
                this.principalsAndContacts = response.data.principals_contacts.organization;
                this.fetchPrincipalExpanded = true;
                if (this.pricipalLevel?.value === this.licenseLevels["principals_contacts"][this.licenseLevels["principals_contacts"].length - 1]) this.fetchprincipals = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                if (this.pricipalLevel?.value === this.licenseLevels["principals_contacts"][this.licenseLevels["principals_contacts"].length - 1]) this.fetchprincipals = true;
            }
        },
        async getOwnershipInsights() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.ownershipInsightLevel?.value,
            };
            let response = await axios.post("/api/v1/db/data_block/ownership_insight", requestBody);
            if (response && response.data) {
                this.ownershipInsights = response.data.ownership_insights.organization;
                this.fetchOwnershipExpanded = true;
                this.fetchOwnership = true;
                this.previousOwnershipLevel = this.ownershipInsightLevel?.value;
                if (this.ownershipInsightLevel?.value === this.licenseLevels["ownership_insights"][this.licenseLevels["ownership_insights"].length - 1]);
                this.disableFetchOwnership = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                this.previousOwnershipLevel = this.ownershipInsightLevel?.value;
                if (this.ownershipInsightLevel?.value === this.licenseLevels["ownership_insights"][this.licenseLevels["ownership_insights"].length - 1]);
                this.disableFetchOwnership = true;
            }
        },
        async getUbocmpbol() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.uboLevel?.value,
                productId: "cmpbol",
                versionId: "v1",
                tradeUp: "",
                customerReference: "",
                ownershipType: "",
                ownershipPercentage: 0,
                excludeUndisclosedHoldings: true,
                degreeOfSeparation: 0,
                appliedControlType: 0,
                pageNumber: 0,
            };
            let response = await axios.post("/api/v1/db/data_block/ubo/cmpbol", requestBody);
            if (response && response.data) {
                this.ubocmpbol = response.data.cmpbol_detail.organization;
                this.previousUboLevel = this.uboLevel?.value;
                if (this.uboLevel?.value === this.licenseLevels["cmpbos"][this.licenseLevels["cmpbos"].length - 1]);
                this.disableFetchuboList = true;
            } else if (response && response.data?.length === 0) {
                this.previousUboLevel = this.uboLevel.value;
                if (this.uboLevel?.value === this.licenseLevels["cmpbos"][this.licenseLevels["cmpbos"].length - 1]);
                this.disableFetchuboList = true;
                this.$toast.error("No data found");
            }
            this.fetchubocmpbol = true;
        },
        async getFinancialStrength() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.financialsInfoLevel?.value,
            };
            let response = await axios.post("/api/v1/db/data_block/financial_strength", requestBody);
            if (response && response.data) {
                this.financialStrengthInsightsInfo = response.data.financial_detail.organization;
                this.fetchfinancials = true;
                this.fetchFinancialExpanded = true;
                this.financialsLevel = this.financialsInfoLevel;
                if (this.financialsInfoLevel.value === this.licenseLevels["financial_detail"][this.licenseLevels["financial_detail"].length - 1]) this.disableFetchMore = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                this.financialsLevel = this.financialsInfoLevel;
                if (this.financialsInfoLevel.value === this.licenseLevels["financial_detail"][this.licenseLevels["financial_detail"].length - 1]) this.disableFetchMore = true;
            }
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
        selectedInfo(info) {
            this.selectedPrincipalTab = info;
        },
        fetchMoreButton(block) {
            if (block === "company") this.companyFetchmore = false;
            if (block === "financials") this.fetchfinancials = false;
            this.showMoreData();
        },
    },
    async mounted() {
        this.dnbLicenseLevel();
    },
};

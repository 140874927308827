<template>
  <RiskDetail v-if="getRiskId || $route.params.action" />

  <div class="flex-1 flex flex-col max-h-full" v-else>
    <sub-header
      :handleSearchFilter="handleSearchFilter"
      buttonText="Add Risk Assessment"
      :handleClick="addRiskAssessment"
      :showBreadCrumbs="true"
      :showBtn="checkPermission('riskAssessment.create')"
    />

    <div
      class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"
    >
      <Table
        id="riskTable"
        :columns="columnOption"
        :key="pageData?.total"
        :rows="risks"
        @handleClone="handleCloneCheck($event)"
        :isLoading="isLoading"
        :filters="serverParams"
        :filterOptions="columnFilterOptions"
        :filterOptionsAsync="columnFilterOptionsAsync"
        @per-page-change="onPageChange($event, true)"
        @page-change="onPageChange($event)"
        @column-filter="onColumnFilters($event)"
        :paginationOptions="paginationOptions"
        :totalRows="pageData?.total"
        @filters-clear="clearFilters"
        sortingEnabled
        @sort-change="onSortChange"
        @search-filter="getSearchOptionsTick($event)"
      >
        <template #link="customData">
          <div class="truncate max-w-full whitespace-nowrap cursor-pointer">
            <span v-tippy :content="customData.customData.name">
              {{ customData.customData.name }}
            </span>
          </div>
        </template>
        <template #actions="customData">
          <div class=" flex justify-around">
            <!-- <button
              class="h-6 w-4 mx-2 mt-1 disabled:opacity-50"
              @click="handleCloneCheck(customData.customData)"
              v-tippy
              :content="'Clone'"
            >
              <font-awesome-icon
                icon="clone"
                class="text-blue-600 text-lg fa-2x"
                :class="
                  customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500'
                "
              />
            </button> -->
            <router-link
              :to="
                customData.customData.lock || !customData.customData.editable || customData.customData.lock
                  ? {}
                  : {
                      path: `${$route.path}/edit/${customData.customData.id}?tab=details`,
                    }
              "
            >
              <button
                class="h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer"
                v-tippy
                :content="customData.customData.lock ? 'Assessment in use': 'Edit'"
                :disabled="!checkPermission('riskAssessment.edit') || !customData.customData.editable || customData.customData.lock"
              >
                <font-awesome-icon
                  icon="edit"
                  class="text-blue-600 text-lg fa-3x"
                  :class="
                    customData.customData
                      ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                      : 'text-gray-500'
                  "
                />
              </button>
            </router-link>
            <router-link
              :to="{
                path: `${$route.path}/view/${customData.customData.id}?tab=details`,
              }"
            >
              <button
                class="h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer"
                v-tippy
                :content="'View'"
                :disabled="!checkPermission('riskAssessment.read')"
              >
                <font-awesome-icon
                  icon="eye"
                  class="text-blue-600 text-lg fa-3x"
                  :class="
                    customData.customData
                      ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                      : 'text-gray-500'
                  "
                />
              </button>
            </router-link>
            <button
              class="h-8 w-4 disabled:opacity-50 mx-2 text-danger whitespace-nowrap cursor-pointer"
              v-tippy
              :content="customData.customData.lock ? 'Assessment in use': 'Delete'"
              :disabled="customData.customData.lock"
              @click="deleteRisk(customData.customData)"
            >
              <font-awesome-icon
                icon="trash"
                class="text-red-600 text-lg fa-3x"
                :class="
                  customData.customData
                    ? 'text-danger hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500'
                "
              />
            </button>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Table from "@shared/dashboard-components/table-large";
import RiskDetail from "./risk-assessment-details";
import axios from "@/axios";
import { debounce } from "lodash";
// import Button from "@/components/button";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { EventBus } from "@/main.js";
import { DateTime } from "luxon";
import { FormatDateNew } from "@/plugins/functions";
import { mapActions, mapState } from "vuex";
import {checkPermission} from "@shared/utils/functions"


export default {
  name: "risk-admin",
  title: "Risk Assessment",

  components: {
    SubHeader,
    Table,
    RiskDetail,
    // Button,
  },
  props: {},
  data: () => ({
    searchTimeout: null,
    searchLoading: {},
    columnFilterOptionsAsync: null,
    isEntityTypesLoading: false,
    columns: [
      {
        label: "Action",
        field: "actions",
        config: { filter: false, type: "actions" },
        tdClass: "truncate max-w-xs",
        width: "180px",
        sortable: false,
      },
      {
        label: "Lock",
        field: "lock",
        config: { filter: false, type: "lock" , tab:"Assessment in use" },
        tdClass: "truncate max-w-sm",
        sortable: false,
      },
      {
        label: "In Use",
        field: "in_use",
        config: { filter: false, type: "locin_usek" },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Risk Assessment",
        field: "name",
        config: { filter: true, type: "link" },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Description",
        field: "description",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Created at",
        field: "created_at",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-xs",
        width: "120px",
      },
      {
        label: "Last Updated By",
        field: "modified_by",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-xs",
        width: "120px",
      },
      {
        label: "Last Updated At",
        field: "modified_at",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-xs",
        width: "120px",
      },
      // {"label":"Automatic/Manual","field":"is_automatic"},
    ],
    serverParams: null,
    risks: [],
    isLoading: false,
    isCloning: false,
    searchValue: "",
    paginationOptions: {
      enabled: true,
      mode: "remote",
      perPage: 10,
    },
    pageData: {
      total: 0,
    },
    currentPageData: {
      page: 1,
      count: 10,
    },
    fetchingCheckPackages: false,
    searchParams: {
      package: "",
    },
    sortParams: null,
  }),
  async created() {
    EventBus.$on("fetchRisksList", () => {
      this.fetchRisksList();
    });
  },
  computed: {
    ...mapState({
      entityTypeOptions: "entityTypeList",
    }),
    getRiskId() {
      console.log("checkpermission", checkPermission('assessment.create'))
      return this.$route.params?.id;
    },

    isViewMode() {
      return this.$route.params.action == "view" ? true : false;
    },
    columnFilterOptions() {
      let cols = this.getFilterOptions(this.columns, this.risks);
      return cols;
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
  },

  async mounted() {
    // this.$router.push({
    //     path: `${this.$route.path}/${id}/edit?tab=details`,
    // })
    await this.fetchRisksList();
  },

  methods: {
    ...mapActions(["fetchEntityTypeList"]),
    checkPermission,
    FormatDateNew,
    currentPageDataOpt() {
      return { ...this.currentPageData, perPage: this.currentPageData.count };
    },

    async fetchRisksList() {
      this.isLoading = true;
      let qParams = new URLSearchParams(this.currentPageData);
      let filterParams = {};

      const getFilterValue = (key) => {
        if (["source"].includes(key)) {
          return this.serverParams[key]["value"];
        } else return this.serverParams[key]["name"];
      };
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.serverParams[key]
            ? getFilterValue(key)
            : delete filterParams[key];
        });
        if (filterParams.created_at) {
          filterParams.created_at = DateTime.fromFormat(
            filterParams.created_at,
            "dd MMM yyyy"
          )
            .plus({ days: 0 })
            .toISODate();
        }
        if (filterParams.modified_at) {
          filterParams.modified_at = DateTime.fromFormat(
            filterParams.modified_at,
            "dd MMM yyyy"
          )
            .plus({ days: 0 })
            .toISODate();
        }
      }
      let url = `/risk-assessment?${qParams}`;
      let { data } = await axios.get(url, {
        params: {
          ...filterParams,
          ...this.sortParams,
        },
      });
      try {
        let risksData = data?.data?.map((el) => {
          if (el.created_at || el.modified_at) {
            el.created_at = FormatDateNew(new Date(el.created_at));
            el.modified_at = FormatDateNew(new Date(el.modified_at));
            return el;
          }
          if (el.parameters && el.parameters !== "null") {
            let data = JSON.parse(el.parameters);
            return { ...el, parameters: data.parameters };
          } else {
            return { ...el };
          }
        });
        this.risks = risksData;
        this.pageData = data?.page;
        this.paginationOptions.perPage = data?.page?.per_page;
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.isLoading = false;
    },

    addRiskAssessment() {
      this.$router.push({
        path: 'risk-assessment/create?tab=details'
      })
    },

    async handleCloneCheck(risk) {
        let url = `/tenant-check/${risk.id}/clone`;
        let payload = {
          tenant_id: this.$store.getters.getTenantId,
          name: risk.name,
        };
        try {
          let { data } = await axios.post(url, payload);
          this.$router.push({
            name: "risk details",
            params: { id: data.id, action: "edit" },
          });
          this.$toast.success(data.message);
        } catch (error) {
          this.$toast.error(
            error.response.data.detail || "Unable to clone risk"
          );
        }
    },

    handleSearchFilter: debounce(function(value) {
      this.searchValue = value;
    }, 1000),

    async onPageChange(data, resetPage = false) {
      this.currentPageData = {
        page: data.currentPage,
        count: data.currentPerPage,
      };
      if (resetPage) this.currentPageData.page = 1;
      if (!resetPage) {
        await this.$nextTick();
        const scrollTarget = document.getElementById("riskTable");
        if (scrollTarget) {
          scrollTarget.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      await this.fetchRisksList();
    },

    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },

    async getSearchOptions(payload) {
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          const requestData = {
            ...this.serverParams,
            [payload.column.query_key || payload.column.field]: payload.value,
          };
          const { data } = await axios.get(
            `/tenant-check/${this.$store.getters.getTenantId}`,
            {
              params: requestData,
            }
          );
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columns,
            data.data
          );
        } catch (error) {
          console.log("getSearchOptions :>> ", error);
        }

        this.$set(this.searchLoading, payload.column.field, false);
      }
    },

    async onColumnFilters({ columnFilters }) {
      this.currentPageData.page = 1;
      this.paginationOptions.perPage = this.currentPageData.count;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchRisksList();
    },

    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },

    async clearFilters() {
      this.serverParams = null;
      await this.fetchRisksList();
    },

    handleFilterResult(data) {
      this.searchParams.package = data;
    },

    async onSortChange(data) {
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        this.sortParams = {
          sort: data.field + ":" + data.type,
        };
        await this.fetchRisksList();
      } else {
        this.sortParams = null;
        await this.fetchRisksList();
      }
    },

    async deleteRisk(risk) {
      if (risk.lock) return;
      let url = `/risk-assessment/${risk.id}`;
      this.isLoading = true;
      try {
          let result = await axios.delete(url);
          this.$toast.success(result?.data?.message || "Risk assessment deleted successfully!");
          await this.fetchRisksList();
      } catch (error) {
          this.$toast.error(error.response.data.detail || "Something went wrong!");
      }
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss">
.tooltip-text {
  display: none;
  position: absolute;
  z-index: 999 !important;
  width: auto;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  display: block;
}

#right {
  left: 70px;
  top: -30px;
}

.hover-text {
  position: relative;
}
</style>

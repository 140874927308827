import NeoNewsExpanded from "@shared/saas-components/news-expanded";
import MorePersonIdentifier from "@shared/saas-components/more-person-identifier"; // moved
import AdverseMediaExpand from "@shared/saas-components/adverse-media-expand"; // moved

export default {
    name: "result-expanded",
    components: {NeoNewsExpanded, MorePersonIdentifier, AdverseMediaExpand},
    props: {
        content: {
            type: Object,
            default: {},
        },
        cardComponent: {
            type: String,
            required: true,
        },
        expandedLoading: {
            type: Boolean,
            default: false,
        },
        allLanguages: {
            type: Array,
            default: () => [],
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
    },
};

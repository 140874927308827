import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import axios from "@/axios";
import loader from "@shared/loader";
import ModalContent from "@shared/modal-content";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
const modalConfirm = () => import("@/components/modal-confirm");
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import { uuid } from "vue-uuid";
// Static use Only Remove after Proper API
import checkGapValidationInput from "../../../checkGapValidationInput.vue";
import { fetchAllRoles, fetchReportVisibility } from "@/modules/field-editor/services.js";
import sectionLists from "../sectionLists.vue";
import { cloneDeep } from "lodash";
import { mapState, mapActions } from 'vuex'
const addSectionModal = () => import("../check-modals/add-section-modal.vue");
const expandedPanel = () => import("../expanded-panel.vue");
const addBlockModal = () => import("../check-modals/add-block-modal.vue");
import FieldSettingsModal from "@shared/components/modals/field-settings-modal.vue";
import GapValidationModal from "@shared/components/modals/gap-validation-modal.vue";
const AddBlockFieldModal = () => import("../../../../block-admin/components/addBlockAndFieldModal.vue");
import { checkPermission } from "@shared/utils/functions";
import LoaderFull from "@/components/loader-full";
const GapTimeLineForBlock = () => import("@shared/gapTimeLine");
import VueCookies from "vue-cookies";

// end
export default {
    name: "check-output-form-editor",
    display: "Transitions",
    props: ['getCheckDetails', 'currentTab'],
    order: 16,
    components: {
        SubHeader,
        VueMultiselect,
        "neo-input": Input,
        loader,
        ModalContent,
        modalConfirm,
        Button,
        Checkbox,
        checkGapValidationInput,
        sectionLists,
        addSectionModal,
        expandedPanel,
        addBlockModal,
        FieldSettingsModal,
        GapValidationModal,
        AddBlockFieldModal,
        GapTimeLineForBlock,
        LoaderFull,
    },
    data() {
        return {
            shouldShowAdditionalContent: true,
            gapValidationPayload: {},
            isLoading: true,
            drag: false,
            sectionName: "",
            showSidePanel: false,
            activeFieldPanel: null,
            fieldLists: [],
            fieldToAdd: {
                section: null,
                block: null,
                field: null,
                permisions: null,
                report_visibility: null,
            },
            activeSection: null,
            blockName: "",
            tenantId: null,
            oldSectionName: "",
            oldFieldName: "",
            allRoles: [],
            fetchingRoles: false,
            allReportsRoles: [],
            selected_report_option: "",
            loadingState: {
                addFields: false,
                getDependent: false,
            },
            dependentsFields: [],
            activeFieldSettings: {
                field: null,
                section: null,
            },
            loadingLists: false,
            blockLists: [],
            blockSearchValue: "",
            activeGapValidation: {
                field: null,
                section: null,
            },
            customCrumbs: [],
            showBlockAddModal: false,
            listType: {
                type: "field",
                listModalTitle: "Add Field ",
                permissionModalTitle: "Field Settings",
            },
            filterFiledValue: "",
            currentAddedBlockGapField: null,
            currentAddedBlock: null,
            dateTypeFields: [],
            loadingStates: {
                dateType: false,
            },
            isGlobalLoader: {
                status: false,
                message: ''
            },
            fieldTypeFilter: [],
        };
    },
    async mounted() {
        this.isLoading = true;
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenantId = this.$store.getters.getTenantId;
        await this.getCheckDetails();
        await this.fetchAllAvailableRoles();
        await this.fetchAvailableReportVisibility();
    },
    computed: {
        ...mapState({
            checkData: state => state.checksAdmin.checksData,
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.checksAdmin.isDirty
        }),
        sectionList() {
            return this.checkData.sections
        },
        isGapValidationField() {
            if (this.fieldToAdd.field?.meta) {
                const json_meta = JSON.parse(this.fieldToAdd.field.meta);
                return json_meta.use_in_gap_validation === true;
            }
        },
        filterBlockLists() {
            return this.blockLists.filter((el) => el.name?.toLowerCase().includes(this.blockSearchValue.toLowerCase()) || el.label?.toLowerCase().includes(this.blockSearchValue.toLowerCase()));
        },
        filteredFieldList() {
            return this.fieldLists.filter((el) => el.name?.toLowerCase().includes(this.filterFiledValue.toLowerCase()));
        },
        isBlockGapValidation() {
            return this.currentAddedBlock.use_in_gap_validation || false;
        },
        isUserPermission() {
            let permissions = {
                SHOW_ALL_ACTIONS: this.checkPermission("check.edit"),
                SHOW_FIELD_ADD_ACTIONS: this.checkPermission("field.read"),
                SHOW_BLOCK_ADD_ACTIONS: this.checkPermission("block.read"),
            };
            return permissions;
        },
        addFieldFilters() {
            const list = []
            const entityTypeFilter = {
                id: 'entity_type',
                options: this.entityTypeOptions,
                value: this.fieldTypeFilter,
                // check if any selected type mathches any element's type:
                check: (el) => {
                    if (!this.fieldTypeFilter.length) return true
                    return el.entity_types.length == this.fieldTypeFilter.length && el.entity_types.every((type) => {
                        return !!this.fieldTypeFilter.find((filter) => filter.id === type.id)
                    })
                },
                reset: (payload) => {
                    this.fieldTypeFilter = payload || [];
                },
                props: {
                    variant: 'alt',
                    config: {
                        label: 'name',
                        trackBy: 'id',
                    },
                },
            }
            list.push(entityTypeFilter)
            return list
        }
    },
    methods: {
        ...mapActions([
            'setCheckData'
        ]),
        handleScroll() {
            const scrollableDiv = this.$refs.scrollableDiv;
            const scrollTop = scrollableDiv.scrollTop;
            const windowHeight = window.innerHeight;
      
            const topThreshold = windowHeight * 2.6;
            if (scrollTop < topThreshold) {
              this.shouldShowAdditionalContent = true;
            } else {
              scrollableDiv.style.overflowY = "hidden";
              scrollableDiv.scrollTop = topThreshold;
              this.shouldShowAdditionalContent = false;
              setTimeout(() => {
                scrollableDiv.style.overflowY = "auto";
              }, 100);
            }
          },
        checkPermission,
        handleGapValidationPayload(payload) {
            this.gapValidationPayload = payload;
        },
        async fetchFieldsList() {
            let payload = {
                tenant_id: this.tenantId,
                req_offset: 0,
                req_limit: 1,
                strict_entity_type_filter: false,
                entity_type: this.checkData.entity_types.map(i => (i.id))
            };
            let url = `/fields/${this.tenantId}/all`;
            let result = await axios.get(url, {
                params: payload,
                paramsSerializer: { indexes: null } 
            });
            return result.data.fields;
        },

        // Fetching Roles
        async fetchAllAvailableRoles() {
            this.fetchingRoles = true;
            this.allRoles = await fetchAllRoles();
            this.fetchingRoles = false;

            // result.data.fields;
        },
        async fetchAvailableReportVisibility() {
            this.allReportsRoles = await fetchReportVisibility();
        },
        // Add Block

        async addBlock(block) {
            this.handleControlGlobalLoader(true, 'Adding new Block...')
            this.currentAddedBlock = null;
            this.currentAddedBlockGapField = null;
            let section = this.activeSection;
            try {
                let url = `/tenant-check/section/${section.id}/block/add`;
                let payload = {
                    block_id: block.id,
                };
                let { data } = await axios.post(url, payload);
                let blockData = {
                    ...block,
                    block_details: data || null,
                    block_multi: data.multi || false,
                    entity_type: "block",
                    expanded: true,
                    fields: data.fields,
                    name: data.name,
                    id: data.id,
                    edited: false,
                    max_count: data.max_count,
                    min_count: data.min_count,
                    label: data.label,
                };
                this.sectionList.find((sec) => sec.id === section.id).fields.push(blockData);
                // this.sectionList = this.sectionList.map(el => {
                //     if(el.id === section.id){
                //         el.fields.push(blockData)
                //     }
                // })
                this.currentAddedBlock = blockData;
                this.currentAddedBlockGapField = blockData?.fields.find((el) => el.use_in_gap_validation);
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, overlap_allowed: false, relative: false, start_date_field: null, start_date_label: "", end_date_field: null, end_date_label: "", till_present: false };
                this.$toast.success(data.message || "Block added to check");
                this.$refs["block-modal"].closeModal();
                this.showGapTimeLineModal();
                await this.getCheckDetails(); 
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to add block");
            }
            // // let check_id = this.checkData.id
            // // let url = `/tenant-check/${check_id}/section/${section.id}/block`;
            // let payload = {
            //     fields: [],
            //     id: uuid.v4(),
            //     expanded: true,
            //     entity_type: "block",
            //     created: true,
            //     edited: false,
            //     name: blockName,
            //     block_multi: false,
            //     min_count: 1,
            //     max_count: 1,
            // };
            // this.sectionList.find((sec) => sec.id === section.id).fields.push(payload);
            // this.$toast.success("Block Added");
            // this.$refs["block-modal"].closeModal();
            this.handleControlGlobalLoader(false)

        },

        // End Add Block

        // Remove Block

        async handleRemoveBlock(section, block) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Block  will be deleted permanently. Please confirm to continue deleting this Block.",
            });
            if (promise) {
                if(typeof section == 'object' && block) {
                    block = section?.element || block
                    section = section?.section || section
                }
                this.handleControlGlobalLoader(true, 'Removing Block...')
                let check_id = this.checkData.id;
                let url = `/tenant-check/${check_id}/section/${section.id}/block/${block.id}`;
                if (block.created) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            let fields = sec.fields.filter((el) => el.id !== block.id);
                            sec = sec.fields = fields;
                        }
                    });
                } else {
                    try {
                        let { data } = await axios.delete(url);
                        this.hideSidePanel();
                        this.sectionList.map((sec) => {
                            if (sec.id === section.id) {
                                let fields = sec.fields.filter((el) => el.id !== block.id);
                                sec = sec.fields = fields;
                            }
                        });

                        this.$toast.success(data.message);
                    } catch (error) {
                        this.$toast.error(error?.response?.data?.detail || "Failed to delete block");
                    }
                }
            }
            await this.getCheckDetails();
            this.$refs["confirm-popup"].close();
            this.handleControlGlobalLoader(false)

        },

        // End Remove Block

        // section Modal control
        closeSectionModal() {
            this.$refs["show-section-modal"].closeModal();
        },

        showSectionModal() {
            console.log("1111")
            this.$refs["show-section-modal"].showAddSectionModal();
        },

        // End Section Modal control

        // section add field Modal control
        closefieldAddModal() {
            // this.$refs["section-addfield-modal"].closeModal();
            this.showBlockAddModal = false;
        },
        onChangeFilterResult(event) {
            this.filterFiledValue = event;
        },

        async showfieldAddModal(section, block) {
            this.loadingState.addFields = true;
            this.filterFiledValue = "";
            this.fieldTypeFilter = [];
            // this.$refs["section-addfield-modal"].showAddFieldModal();
            this.showBlockAddModal = true;
            this.fieldToAdd.field = null;
            this.fieldLists = await this.fetchFieldsList();
            const validTypes = ['Text', 'Date', 'Checkbox', 'Radio', 
                                'Dropdown Multi Selectable', 'Dropdown Single Selectable',
                                'Email', 'Phone', 'Number']
            this.fieldLists = this.fieldLists.filter((res)=>validTypes.includes(res.type) && res.is_cascaded==false)
            await this.fetchAvailableReportVisibility();
            let permisions =
                this.allRoles &&
                this.allRoles?.map((permision) => ({
                    ...permision,
                    title: permision.title,
                    role_read: false,
                    role_write: false,
                    role_visible: false,
                    role_mandatory: false,
                    select_all: false,
                }));
            this.fieldToAdd = {
                section: section,
                block: block ? block : null,
                field: null,
                permisions: permisions,
                all_reports_visibilty: this.allReportsRoles,
                report_visibility: null,
            };
            this.loadingState.addFields = false;
        },

        // End Section add field Modal control

        handleSelectAllPermission(role) {
            let permisions = this.fieldToAdd.permisions;
            if (role.select_all) {
                permisions.map((el) => {
                    if (el.id === role.id) {
                        el.role_read = true;
                        el.role_write = true;
                        el.role_visible = true;
                        el.role_mandatory = true;
                    }
                });
            } else {
                permisions.map((el) => {
                    if (el.id === role.id) {
                        el.role_read = false;
                        el.role_write = false;
                        el.role_visible = false;
                        el.role_mandatory = false;
                    }
                });
            }
            this.fieldToAdd.permisions = permisions;
        },

        handleSelectAllFieldPermission(role) {
            this.activeFieldPanel.field.roles.map((el) => {
                // NOTE: FIX THIS

                if (el.id === role.id) {
                    if (role.select_all) {
                        el.role_read = true;
                        el.role_write = true;
                        el.role_visible = true;
                        el.role_mandatory = true;
                    } else {
                        el.role_read = false;
                        el.role_write = false;
                        el.role_visible = false;
                        el.role_mandatory = false;
                    }
                }
            });
        },

        // Remove section

        async handleRemoveSection(section) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This section  will be deleted permanently. Please confirm to continue deleting this section.",
            });
            if (promise) {
                this.handleControlGlobalLoader(true, 'Removing section...')
                let url = `/tenant-check/output-section`;
                let payload = {
                    check_id: this.checkData.id,
                    section_id: section.id,
                };

                try {
                    let { data } = await axios.delete(url, { data: payload });
                    this.hideSidePanel();
                    this.$toast.success(data.message);
                    let newList = this.sectionList.filter((ele) => ele.id !== section.id);
                    this.sectionList = newList;
                    this.$refs["confirm-popup"].close();
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to remove section");
                    this.$refs["confirm-popup"].close();
                }
            }
            await this.getCheckDetails();
            this.$refs["confirm-popup"].close();
            this.handleControlGlobalLoader(false)

        },
        async onAddField({ payload, field }) {
            this.handleControlGlobalLoader(true, 'Adding new field...')
            const { roles, report_visibility, use_in_gap_validation } = payload;
            const { label, id, is_cascaded } = field;
            const { section, block } = this.fieldToAdd;
            if (!report_visibility) {
                this.$toast.error(`Please select a report visibility option before Adding new field`);
                return;
            }
            const payloadBody = {
                check_name: label,
                field_id: id,
                tenant_check_id: this.checkData.id,
                collect_data_from :"Existing Case Data",
                field_details: {
                    field_global_id: field.id,
                    section_id: section.id,
                    internal_use_only: false,
                    block_id: block ? block.id : null,
                    multi: false,
                    max_field_or_block_count: 1,
                    min_field_or_block_count: 1,
                    is_top_level: false,
                    report_visibility,
                },
                permissions: roles,
                is_cascaded,
            };
            if (use_in_gap_validation) {
                const { history, valid_gap } = payload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                payloadBody.field_details = { ...payloadBody.field_details, ...gapValid };
            }
            let url = `/tenant-check/section/${section.id}/output-field/add`;
            try {
                let { data } = await axios.post(url, payloadBody);
                if (data.section_field_id) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            if (block) {
                                sec.fields.map((blockData) => {
                                    if (blockData.id === block.id) {
                                        // NOTE: FIX THIS
                                        blockData.fields.push({
                                            ...payloadBody,
                                            ...payloadBody.field_details,
                                            type: field.type,
                                            report_visibility,
                                            block_id: block.id,
                                            entity_type: "field",
                                            id: data.section_field_id,
                                            label: field.label,
                                            name: field.name,
                                            placeholder: field.placeholder,
                                            roles: roles,
                                            ui_label: field.ui_label,
                                            is_custom: field.is_custom,
                                            is_restricted: field.is_restricted,
                                            use_in_case_creation: false,
                                        });
                                    }
                                });
                            } else {
                                // NOTE: FIX THIS
                                sec.fields.push({
                                    ...payloadBody,
                                    ...payloadBody.field_details,
                                    entity_type: "field",
                                    id: data.section_field_id,
                                    label: field.label,
                                    name: field.name,
                                    type: field.type,
                                    placeholder: field.placeholder,
                                    report_visibility,
                                    roles: roles,
                                    ui_label: field.ui_label,
                                    is_custom: field.is_custom,
                                    is_restricted: field.is_restricted,
                                    use_in_case_creation: false,
                                });
                            }
                        }
                    });
                }
                await this.getCheckDetails()
                this.$toast.success(data.message);
                this.closefieldAddModal();
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Error while adding field to sections");
            }
            this.handleControlGlobalLoader(false)
        },
        // End Remove section

        // handle Add Field to Sections
        async addFieldToSection({ payload: paramPayload, field: paramfield }) {
            this.handleControlGlobalLoader(true, 'Adding new field...')
            if (!paramPayload.report_visibility) {
                this.$toast.error(`Please select a report visibility option before Adding new field`);
                return;
            }
            let { block, section, field, permisions, report_visibility } = paramfield;
            let new_permisions = [];

            new_permisions = permisions?.map((permision) => {
                const payload_permision = {
                    role_id: permision.id,
                    role_read: permision.role_read,
                    role_write: permision.role_write,
                    role_visible: permision.role_visible,
                    role_mandatory: permision.role_mandatory,
                };
                return payload_permision;
            });

            let payload = {
                check_name: field.label,
                field_id: field.id,
                tenant_check_id: this.checkData.id,
                collect_data_from :"Existing Case Data",
                field_details: {
                    field_global_id: field.id,
                    section_id: section.id,
                    internal_use_only: false,
                    block_id: block ? block.id : null,
                    multi: false,
                    max_field_or_block_count: 1,
                    min_field_or_block_count: 1,
                    is_top_level: false,
                    report_visibility,
                },
                permissions: new_permisions,
                is_cascaded: field.is_cascaded,
            };
            if (this.isGapValidationField) {
                const { history, valid_gap } = this.gapValidationPayload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                payload.field_details = { ...payload.field_details, ...gapValid };
            }
            if (block && block.created) {
                this.addFieldToNewBlock(block, section, field, payload, permisions);
            } else {
                let url = `/tenant-check/section/${section.id}/field/add`;
                try {
                    let { data } = await axios.post(url, payload);
                    if (data.section_field_id) {
                        this.sectionList.map((sec) => {
                            if (sec.id === section.id) {
                                if (block) {
                                    sec.fields.map((blockData) => {
                                        if (blockData.id === block.id) {
                                            // NOTE: FIX THIS
                                            blockData.fields.push({
                                                ...payload,
                                                ...payload.field_details,
                                                type: field.type,
                                                report_visibility,
                                                block_id: block.id,
                                                entity_type: "field",
                                                id: data.section_field_id,
                                                label: field.label,
                                                name: field.name,
                                                placeholder: field.placeholder,
                                                roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                                                ui_label: field.ui_label,
                                                is_custom: field.is_custom,
                                                use_in_case_creation: false,
                                            });
                                        }
                                    });
                                } else {
                                    // NOTE: FIX THIS
                                    sec.fields.push({
                                        ...payload,
                                        ...payload.field_details,
                                        entity_type: "field",
                                        id: data.section_field_id,
                                        label: field.label,
                                        name: field.name,
                                        type: field.type,
                                        placeholder: field.placeholder,
                                        report_visibility,
                                        roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                                        ui_label: field.ui_label,
                                        is_custom: field.is_custom,
                                        use_in_case_creation: false,
                                    });
                                }
                            }
                        });
                        // await this.getCheckDetails()
                        this.$toast.success(data.message);
                    }
                    this.closefieldAddModal();
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Error while adding field to sections");
                }
            }
            this.handleControlGlobalLoader(false)
        },
        hideSidePanel() {
            this.showSidePanel = false;
            this.activeFieldPanel = null;
        },
        // Add Field to new added Block
        addFieldToNewBlock(block, section, field, payload, permisions) {
            this.handleControlGlobalLoader(true, 'Adding new field...')
            let fieldData = {
                field_id: payload.field_id,
                internal_use_only: false,
                max_field_or_block_count: payload.field_details.max_field_or_block_count,
                min_field_or_block_count: payload.field_details.min_field_or_block_count,
                is_top_level: payload.field_details.is_top_level,
                permissions: payload.permissions,
                report_visibility: payload.field_details.report_visibility,
                is_cascaded: payload.is_cascaded,
            };

            // If a gap validation field is being added to block
            // it needs to have an additional parameter.
            if (this.isGapValidationField) {
                // fieldData.history = this.gapValidationPayload.history;
                // fieldData.valid_gap = this.gapValidationPayload.valid_gap;
                // fieldData.use_in_gap_validation = true;
                const { history, valid_gap } = this.gapValidationPayload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                fieldData = { ...fieldData, ...gapValid };
            }

            this.sectionList.map((sec) => {
                if (sec.id === section.id) {
                    sec.fields.map((fie) => {
                        if (fie.id === block.id) {
                            fie.fields.push({
                                ...fieldData,
                                created_with_block: true,
                                block_id: block.id,
                                entity_type: "field",
                                id: uuid.v4(),
                                label: field.label,
                                name: field.name,
                                placeholder: field.placeholder,
                                roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                            });
                        }
                    });
                }
            });
            this.handleControlGlobalLoader(false)
        },

        // End New block Field add

        // Save Block with Field

        async saveBlockFields(section, element) {
            this.handleControlGlobalLoader(true, 'Adding new block...')
            let check_id = this.checkData.id;
            let url = `/tenant-check/${check_id}/section/${section.id}/block`;
            let payload = {
                tenant_id: this.tenantId,
                block_name: element.name,
                block_multi: element.block_multi,
                field_list: [],
                max_count: parseInt(element.max_count),
                min_count: parseInt(element.min_count),
            };
            this.hideSidePanel();
            element.fields.forEach((ele) => {
                payload.field_list.push(ele);
            });

            //NOTE(deependar)
            // element.fields.map(ele => payload.field_list.push(
            //     {
            //         field_id: ele.field_id,
            //         internal_use_only: ele.internal_use_only,
            //         max_field_or_block_count: ele.max_field_or_block_count,
            //         min_field_or_block_count: ele.min_field_or_block_count,
            //         is_top_level: ele.is_top_level,
            //         permissions: ele.permissions,
            //         report_visibility: ele.report_visibility
            //         }
            //     ))
            try {
                let { data } = await axios.post(url, payload);
                element.fields = data.fields;
                element.created = false;
                element.id = data.block_id;
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to create block");
            }
            this.handleControlGlobalLoader(false)
        },
        async updateSectionFields(section) {
            // TODO connect to API
            await console.log("save section fields", section);
        },

        // End save Block with fields

        // Update block

        async handleUpdateBlock(section, block) {
            this.handleControlGlobalLoader(true, 'Updating Block attributes...')
            let check_id = this.checkData.id;
            let url = `/tenant-check/${check_id}/section/${section.id}/block/${block.id}`;
            let payload = {
                tenant_id: this.tenantId,
                name: block.name,
                multi: block.block_multi,
                min_count: block.min_count,
                block_title:block.block_details.block_title,
                max_count: block.max_count,
            };
            if (block.fields) payload.fields = block.fields;
            try {
                let { data } = await axios.put(url, payload);
                block.edited = false;
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to update block details");
            }
            this.handleControlGlobalLoader(false)
        },

        // end update Block

        handleCollapseSection(section) {
            this.sectionList.filter((ele) => {
                if (ele.id === section.id) {
                    ele.expanded = !ele.expanded;
                }
            });
        },

        async addNewSection(sectionName) {
            this.handleControlGlobalLoader(true, 'Adding new section...')
            if (!sectionName) return this.$toast.error("Section Name required");
            let url = `/tenant-check/${this.checkData.id}/output-section`;
            let payload = {
                name: sectionName,
            };
            try {
                let { data } = await axios.post(url, payload);
                let newSection = {
                    id: data.section_id,
                    name: sectionName,
                    expanded: true,
                    blocks: [],
                    fields: [],
                };
                this.sectionList.push(newSection);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to add new section");
            }
            await this.getCheckDetails();
            this.closeSectionModal();
            this.handleControlGlobalLoader(false)
        },

        // handle Delete Field form section and Block

        async handleDeleteField(field, section) {
            if(typeof field == 'object' && !section) {
                section = field?.section
                field = field?.field
            }
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This field  will be deleted permanently. Please confirm to continue deleting this field.",
            });

            if (promise) {
                this.handleControlGlobalLoader(true, 'Removing Field...')
                let url = `/tenant-check/output-field`;
                let payload = {
                    check_id: this.checkData.id,
                    section_field_id: field.id ? field.id : field?.field?.id,
                    tenant_id: this.tenantId,
                };
                if (field.block_id && field.created_with_block) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            sec.fields.map((block) => {
                                if (block.id === field.block_id) {
                                    return (block.fields = block.fields.filter((el) => el.id !== field.id));
                                }
                            });
                        }
                    });
                } else {
                    try {
                        let { data } = await axios.delete(url, { data: payload });
                        this.hideSidePanel();
                        if (field.block_id) {
                            this.sectionList.map((sec) => {
                                if (sec.id === section.id) {
                                    sec.fields.map((block) => {
                                        if (block.id === field.block_id) {
                                            return (block.fields = block.fields.filter((el) => el.id !== field.id));
                                        }
                                    });
                                }
                            });
                        } else {
                            this.sectionList.map((sec) => {
                                if (sec.id === section.id) {
                                    return (sec.fields = sec.fields.filter((el) => el.id !== field.id));
                                }
                            });
                        }
                        this.$toast.success(data.message);
                    } catch (error) {
                        this.$toast.error(error?.response?.data?.detail || "Failed to delete field");
                    }
                }
                await this.getCheckDetails();
            }
            this.$refs["confirm-popup"].close();
            this.handleClosePanel();
            this.handleControlGlobalLoader(false)
        },

        // End Delete Field from section and block

        // update Section Label
        async updateSectionLabel(sectionData) {
            console.log("uuuuuu")
            this.handleControlGlobalLoader(true, 'Updating section attributes...')
            if (this.oldSectionName !== sectionData.name) {
                const url = `/tenant-check/${this.checkData.id}/output-section/rename`;
                let payload = {
                    section_id: sectionData.id,
                    name: sectionData.name,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to rename section name!");
                }
            }
            this.handleControlGlobalLoader(false)
        },

        // Update field Name

        async updateFieldLabel(sectionData, checkData, silent = false) {
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            if (this.oldFieldName !== checkData.label) {
                const url = `/tenant-check/${this.checkData.id}/output-section/${sectionData.id}/field`;
                const checkDataEl = checkData?.element || checkData;
                let payload = {
                    section_field_id: checkDataEl?.id,
                    label: checkDataEl?.label,
                    permissions: checkDataEl?.roles,
                    report_visibility: checkDataEl?.id,
                    history: checkDataEl?.history,
                    valid_gap: checkDataEl?.valid_gap,
                    use_in_gap_validation: checkDataEl?.use_in_gap_validation,
                };
                try {
                    const { data } = await axios.put(url, payload);
                    if (checkData.use_in_gap_validation) {
                        const sectionList = cloneDeep(this.sectionList);
                        sectionList.forEach((section, ind1) => {
                            if (section.id === checkData.section_id) {
                                section.fields.forEach((field, ind2) => {
                                    if (field.id === checkData.block_id) {
                                        field.fields.forEach((_, ind3) => {
                                            if (_.id === checkData.id) {
                                                sectionList[ind1].fields[ind2].fields[ind3] = {
                                                    ..._,
                                                    history: checkData.history,
                                                    valid_gap: checkData.valid_gap,
                                                };
                                            }
                                        });
                                    } else {
                                        if (checkData.id === field.id) {
                                            sectionList[ind1].fields[ind2] = {
                                                ...field,
                                                history: checkData.history,
                                                valid_gap: checkData.valid_gap,
                                            };
                                        }
                                    }
                                });
                            }
                        });
                        this.sectionList = sectionList;
                        this.$refs.gapValidationModal.closeModal();
                    }
                    // }
                    if (!silent) this.$toast.success(data.message);
                } catch (error) {
                    this.oldFieldName = "";
                    this.handleControlGlobalLoader(false)
                    this.$toast.error(error?.response?.data?.detail || "Failed to rename field name!");
                    throw error;
                }
            }
            this.handleControlGlobalLoader(false)

        },

        fieldNameClick(checkData) {
            this.oldFieldName = checkData.label;
        },

        async handleFieldChange(e) {
            const { event: _e, section: _section } = e;
            
            const _event = _e.added || _e.moved || _e.removed;

            if (_event) {
                this.handleControlGlobalLoader(true, 'Reordering...')
                const field_lists = [];
    
                this.sectionList.forEach((section) => {
                    const _fieldList = section.fields;

                    if (_e.added && _section.id == section.id) {
                        _e.added.element.form_section_id = _section.id;

                        if (_e.added.element.fields) {
                            _e.added.element.fields.forEach(i => {
                                i.form_section_id = _section.id;
                            });
                        }

                        _fieldList.splice(_e.added.newIndex, 0, _e.added.element);

                    } else {

                        const elementIndex = section.fields.findIndex(x=> x.id === _event?.element?.id);
    
                        if (elementIndex != -1) {
                            if (_e.moved) {
                                let removed = _fieldList.splice(_e.moved.oldIndex, 1);
                                _fieldList.splice(_e.moved.newIndex, 0, ...removed);
                            } else if (_e.removed && _section.id == section.id) {
                                _fieldList.splice(_e.removed.oldIndex, 1);
                            }
                        }
                    }

                    _fieldList.forEach((field) => {
                        const _fields = field.fields || [field];
                        _fields.forEach((item) =>  {
                            field_lists.push({ section_field_id: item.id, section_id: item.form_section_id || item.section_id, order: field_lists.length + 1 })
                        });
                    });
                });

                /*
                    'added' and 'removed' events are triggered in a sequence.
                    that's why after 'added' event only array modification is performed.
                    api request comes after 'removed' event
                */
                if (_e.added) return;

                const url = `/tenant-check/${this.checkData.id}/output-fields/reorder`;
                let payload = {
                    tenant_id: this.tenantId,
                    field_list: field_lists,
                };
                try {
                    let {data} = await axios.post(url, payload);
                    this.$toast.success(event.element?.entity_type === 'block' ?  'Block reordered' : 'Field reordered' || data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to reorder!");
                }
                this.handleControlGlobalLoader(false)
            }
        },

        // reorder section
        async hanldeSectionReorder(e) {
            console.log("inside reorder")
            this.handleControlGlobalLoader(true, 'Reordering...')
            let section_list = []
            const removed = this.sectionList.splice(e.moved.oldIndex, 1);
            this.sectionList.splice(e.moved.newIndex, 0, ...removed);
            this.sectionList.forEach((i, ind) => {
                i.order = ind;
                section_list.push({ section_id: i.id, order: ind + 1 })
            })
            if (e.moved) {
                const url = `/tenant-check/${this.checkData.id}/output-section/reorder`;
                let payload = {
                    tenant_id: this.tenantId,
                    section_list: section_list,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to reorder section!");
                }
                // this.$toast.success("Section Reordered");
            }
            this.handleControlGlobalLoader(false)

        },

        // end reorder section
        handleAddFieldChange(e) {
            console.log(e, "add field Event");
        },

        onShowPanel(element, section) {
            if (element.is_cascaded) return;
            this.handleShowPanel(element, section);
        },

        async handleShowPanel(element, section) {
            if (!element.fields) {
                element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
                this.showSidePanel = true;
                this.activeFieldPanel = {
                    field: element,
                    section: section,
                    is_cascaded: element.is_cascaded,
                };
                if (element.is_cascaded) {
                    this.loadingState.getDependent = true;
                    try {
                        const { data } = await axios.get(`/tenant-check/section/${section.id}/field/${element.id}/dependents`);
                        this.dependentsFields = data.dependents;
                    } catch (error) {
                        console.log("error :>> ", error);
                    }
                    this.loadingState.getDependent = false;
                }
            }
        },
        showFieldSettingsModal(element = null, section) {     
            if (!element) {

                this.activeFieldSettings = {
                    field: null,
                    section: null,
                };
                return;
            }
            element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
            this.activeFieldSettings = {
                field: element,
                section: section,
                is_cascaded: element.is_cascaded,
            };
            this.$refs.fieldSettingsModal.showModal();
        },
        showGapValidationModal(element = null, section) {
            // if(this.$route.params.action == 'view'){
            //     return
            // }
            if(typeof element == 'object' && !section) {
                section = element?.section
                element = element?.field
            }
            if (!element) {
                this.activeGapValidation = {
                    field: null,
                    section: null,
                };
                return;
            }
            this.activeGapValidation = {
                field: element,
                section,
            };
            this.activeSection = section;
            this.currentAddedBlockGapField = element;
            this.currentAddedBlock = element;
            this.showGapTimeLineModal();
            // this.$refs.gapValidationModal.showModal();
        },
        handleClosePanel() {
            this.showSidePanel = false;
            this.activeFieldPanel = {
                field: null,
                section: null,
            };
        },

        // mandatory permission select karne pe write/read/visible selected hona chahiye by default
        // write permission select karne pe read & visibel select hone chahiye by default
        // read karne be visible select hona chahiye by default

        PermissionChange(role) {
            if (role.role_mandatory) {
                role.role_read = true;
                role.role_write = true;
                role.role_visible = true;
                role.select_all = true;
            } else if (role.role_write) {
                role.role_read = true;
                role.role_visible = true;
                role.select_all = false;
            } else if (role.role_read) {
                role.role_visible = true;
                role.select_all = false;
            }
        },

        // Expand all sections


        async showBlockModal(section) {
            this.activeSection = section;
            this.fieldTypeFilter = [];
            this.blockSearchValue = "";
            this.$refs["block-modal"].showBlockModal();
            await this.fetchBlockLists();
        },
        async fetchBlockLists() {
            this.loadingLists = true;
            let payload = {
                strict_entity_type_filter: false,
                entity_type: this.checkData.entity_types.map(i => (i.id))
            };
            try {
                let url = `/blocks`;
                let { data } = await axios.get(url, {
                    params: payload,
                    paramsSerializer: { indexes: null } 
                });
                this.$nextTick(function () {
                    this.blockLists = data.data || [];
                })
            } catch (error) {
                console.log(error, ">>>>>error");
            }

            this.loadingLists = false;
        },

        onChangehandleFilterResult(searchValue) {
            this.blockSearchValue = searchValue;
        },

        // Update Dependendta Field label
        async updateDependentsFieldLabel(field) {
            console.log()
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            const url = `/tenant-check/${this.checkData.id}/output-section/${field.section_id}/field`;
            let payload = {
                section_field_id: field.id,
                label: field.name,
                permissions: field.permissions,
                report_visibility: false,
            };
            try {
                const { data } = await axios.put(url, payload);
                this.$toast.success(data.message);
            } catch (error) {
                this.handleControlGlobalLoader(false)
                this.$toast.error(error?.response?.data?.detail || "Failed to rename field name!");
            }
            this.handleControlGlobalLoader(false)
        },
        async getDependents(field_id, option_value) {
            const result = await axios.get(`tenant-check/field/${field_id}/options/${option_value}/dependents`);
            return result;
        },
        async getOptions(field_id) {
            const result = await axios.get(`tenant-check/field/${field_id}/options`);
            return result;
        },
        async changeCaseCreation(payload) {
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            try {
                const result = await axios.patch(`tenant-check/caseform/${payload.id}`, {
                    state: payload.use_in_case_creation || false,
                });
                if (result) {
                    this.$toast.success(payload.use_in_case_creation ? `Using in case creation` : `Removed from case creation`);
                }
            } catch (error) {
                this.handleControlGlobalLoader(false)
                this.$toast.error(error.response.data.detail || `Something went wrong`);
                const setCreationVal = (fld) => {
                    return fld.id === payload.id ? { ...fld, use_in_case_creation: !payload.use_in_case_creation || false } : fld;
                };
                this.sectionList = this.sectionList.map((sec) => {
                    return {
                        ...sec,
                        fields: sec.fields.map((fld) => {
                            if (fld.entity_type === "field") {
                                return setCreationVal(fld);
                            } else if (fld.entity_type === "block") {
                                // for field type returning use_in_case_creation as false
                                return { ...fld, fields: fld.fields.map((el) => setCreationVal(el)) };
                            }
                        }),
                    };
                });
            }
            this.handleControlGlobalLoader(false)
        },
        async changeblockVisibile(payload) {
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            try {
                const result = await axios.patch(`tenant-check/block_visibility/${payload.id}`, {
                    block_visibility: payload.block_visibility || false,
                })
                if (result) {
                    this.$toast.success(payload.block_visibility ? `Using in block visibility` : `Removed from block visibility`);
                }
            } catch (error) {
                this.handleControlGlobalLoader(false)
                this.$toast.error(error.response.data.detail || `Something went wrong`);
                const setblockVisibleVal = (fld) => {
                    return fld.id === payload.id ? { ...fld, block_visibility: !payload.block_visibility || false } : fld;
                };
                this.sectionList = this.sectionList.map((sec) => {
                    return {
                        ...sec,
                        fields: sec.fields.map((fld) => {
                            if (fld.entity_type === "field") {
                                return setblockVisibleVal(fld);
                            } else if (fld.entity_type === "block") {
                                // for field type returning block_visibility as false
                                return { ...fld, fields: fld.fields.map((el) => setblockVisibleVal(el)) };
                            }
                        }),
                    };
                });
            }
            this.handleControlGlobalLoader(false)
        },
        closeGapTimeLineModal() {
            this.$modal.hide("gap-time-line-modal");
        },
        async showGapTimeLineModal() {
            await this.getDateTypeFields();
            let { start_date_field, end_date_field } = this.currentAddedBlockGapField;
            if (start_date_field || end_date_field) {
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, start_date_field: this.dateTypeFields.find((el) => el.id === start_date_field), end_date_field: this.dateTypeFields.find((el) => el.id === end_date_field) };
            }
            this.$nextTick(() => {
                this.isBlockGapValidation && this.$modal.show("gap-time-line-modal");
                // this.isBlockGapValidation && this.dateTypeFields && this.dateTypeFields.length > 0 && this.$modal.show('gap-time-line-modal');
            });
        },
        async getDateTypeFields() {
            this.loadingStates.dateType = true;
            let token = VueCookies.get("token");
            const { data } = await axios.get(`tenant-check/${this.tenantId}/${this.$route.params.id}/fields/dates`,
             {headers: {
                'Authorization':`Bearer ${token}`
              }}
            );
            this.dateTypeFields = data.date;
            this.loadingStates.dateType = false;
        },
        handleResetGapTimelineFields() {
            this.currentAddedBlockGapField = {
                ...this.currentAddedBlockGapField,
                relative: false,
                valid_gap: null,
                history: null,
                overlap_allowed: false,
                start_date_field: null,
                start_date_label: "",
                end_date_field: null,
                end_date_label: "",
                till_present: false,
            };
        },
        async handleUpdateBlockFieldGapTimeLine(copyCurrentBlockField) {
            let { id, roles, history, valid_gap, block_id, label, report_visibility, overlap_allowed, relative, start_date_field, start_date_label, end_date_field, end_date_label, till_present } = copyCurrentBlockField;
            let payload = {
                section_field_id: id,
                permissions: roles,
                label,
                report_visibility,
                overlap_allowed: overlap_allowed || false,
                valid_gap,
                relative: relative || false,
            };
            if (relative) {
                if (till_present) {
                    payload = {
                        ...payload,
                        start_date_field: (start_date_field && start_date_field.id) || null,
                        start_date_label,
                        end_date_field: null,
                        end_date_label: null,
                        till_present: till_present || false,
                        history: null,
                    };
                } else {
                    payload = {
                        ...payload,
                        start_date_field: (start_date_field && start_date_field.id) || null,
                        start_date_label,
                        end_date_field: (end_date_field && end_date_field.id) || null,
                        end_date_label,
                        till_present: till_present || false,
                        history: null,
                    };
                }
            } else {
                payload = {
                    ...payload,
                    history,
                    start_date_field:  null,
                    start_date_label: null,
                    end_date_field: null,
                    end_date_label: null,
                    till_present: false,
                };
            }
            try {
                const url = `/tenant-check/${this.$route.params.id}/section/${this.activeSection.id}/field`;
                const { data } = await axios.put(url, payload);
                this.$toast.success(data.message);
                const sectionList = cloneDeep(this.sectionList);
                sectionList.forEach((section, ind1) => {
                    if (section.id === this.activeSection.id) {
                        section.fields.forEach((field, ind2) => {
                            if (field.id === block_id) {
                                field.fields.forEach((_, ind3) => {
                                    if (_.id === id) {
                                        sectionList[ind1].fields[ind2].fields[ind3] = {
                                            ..._,
                                            ...payload,
                                        };
                                    }
                                });
                            }
                        });
                    }
                });
                this.sectionList = sectionList;
                this.closeGapTimeLineModal();
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to update field!");
            }
        },
        updateOption({ option, key }) {
            this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, [key]: option };
        },
        updateLabel({input, key}){
            this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, [key]: input };
        },

        updateTillPreset({input,startDateLabel}) {
            if (input === "till_present") {
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, till_present: true, end_date_field:null, end_date_label:"",start_date_label: startDateLabel};
            } else {
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, till_present: false };
            }
        },
        updateActiveTab(event) {
            let relative_status = event === "relative" ? true : false;
            this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, relative: relative_status };
        },
        handleControlGlobalLoader(status, message){
            this.isGlobalLoader = {
                status,
                message:message || 'Loading...'
            }
        }
    },
};

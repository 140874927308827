<template>
  <div class="w-full flex flex-col items-stretch gap-5">
		<div
			v-if="loading"
			class="p-10 flex items-center justify-center w-full"
		>
			<Loader />
		</div>
		<template v-else>
			<slot />
			<UIContentBox
				v-if="showFooter"
				class="flex items-center justify-end gap-5"
			>
				<slot name="footer:before" />
				<Button
					type="primary"
					text="Next"
					:disabled="disabledSubmit"
					@click="$emit('submit')"
				/>
				<slot name="footer:after" />
			</UIContentBox>
		</template>
	</div>
</template>

<script>
import Loader from "@shared/loader/index.vue";
import Button from "@shared/components/button";

export default {
	components: {
		Loader,
		Button,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		disabledSubmit: {
			type: Boolean,
			default: false,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
	}
};
</script>

<style lang="scss" scoped>
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-1 flex-col max-h-full w-full"},[_c('Table',{key:_vm.pageData?.total,attrs:{"id":"riskTable","columns":_vm.columnOption,"rows":_vm.risks,"isLoading":_vm.isLoading,"filters":_vm.serverParams,"filterOptions":_vm.columnFilterOptions,"filterOptionsAsync":_vm.columnFilterOptionsAsync,"paginationOptions":_vm.paginationOptions,"totalRows":_vm.pageData?.total,"sortingEnabled":""},on:{"per-page-change":function($event){return _vm.onPageChange($event, true)},"page-change":function($event){return _vm.onPageChange($event)},"column-filter":function($event){return _vm.onColumnFilters($event)},"filters-clear":_vm.clearFilters,"sort-change":_vm.onSortChange,"search-filter":function($event){return _vm.getSearchOptionsTick($event)}},scopedSlots:_vm._u([{key:"link",fn:function(customData){return [_c('div',{staticClass:"truncate max-w-full whitespace-nowrap cursor-pointer"},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"content":customData.customData.name}},[_vm._v(" "+_vm._s(customData.customData.name)+" ")])])]}},{key:"actions",fn:function(customData){return [_c('div',{staticClass:"flex justify-around"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer",attrs:{"content":'Edit',"disabled":_vm.isViewMode || !_vm.checkPermission('riskAssessment.edit')},on:{"click":function($event){return _vm.editAssessment(customData.customData)}}},[_c('font-awesome-icon',{staticClass:"text-primary text-lg fa-3x",attrs:{"icon":"edit"}})],1),_c('router-link',{attrs:{"to":{
            path: `${_vm.$route.path}/view/${customData.customData.id}`,
          }}},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer",attrs:{"content":'View'}},[_c('font-awesome-icon',{staticClass:"text- text-lg fa-3x",class:customData.customData
                  ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                  : 'text-gray-500',attrs:{"icon":"eye"}})],1)]),_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2 text-danger whitespace-nowrap cursor-pointer",attrs:{"content":'Delete',"disabled":_vm.isViewMode || !_vm.checkPermission('riskAssessment.edit')},on:{"click":function($event){return _vm.deleteAssessment(customData.customData)}}},[_c('font-awesome-icon',{staticClass:"text-red-600 text-lg fa-3x",attrs:{"icon":"trash"}})],1)],1)]}}])}),(!_vm.isViewMode)?_c('div',{staticClass:"flex justify-end pt-4"},[_c('div',{},[_c('Button',{staticClass:"py-2",attrs:{"text":"Save and Next","type":"primary","disabled":!_vm.risks.length},on:{"click":_vm.handleSubmit}})],1)]):_vm._e(),_c('modalContent',{ref:"addAssessmentModal",staticClass:"modal-class",attrs:{"name":"addAssessmentModal","title":"Add New Risk Assessment","max-width":400,"show-footer":false},on:{"hidden":_vm.handleCloseModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('FormulateForm',{staticClass:"min-h-full flex-1"},[_c('div',{staticClass:"add-check__modal-content h-full overflow-hidden"},[_c('div',{staticClass:"form flex w-full flex-col flex-1 overflow-auto scroll-bar pr-1"},[_c('FormulateInput',{staticClass:"w-full",attrs:{"validation":"required","label":"Assessment Name","placeholder":"Assessment Name","type":"text","variant":"alt","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},model:{value:(_vm.assessmentData.name),callback:function ($$v) {_vm.$set(_vm.assessmentData, "name", $$v)},expression:"assessmentData.name"}}),_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"px-0.5 pb-1",attrs:{"for":"#"}},[_vm._v(" Risk Category "),_c('span',{staticClass:"text-red-500 ml-1 text-lg leading-3 mr-auto"},[_vm._v(" * ")])]),_c('Dropdown',{attrs:{"reposition":"","searchable":"","placeholder":"Select Category","options":_vm.options.categories,"colors":{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                },"config":{ label: 'name', trackBy: 'id' },"value":_vm.assessmentData.category,"limit":1,"minWidth":"102px","maxWidth":"100%"},on:{"input":(value) => _vm.setValue(value, 'category')}})],1),_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"px-0.5 pb-1",attrs:{"for":"#"}},[_vm._v(" Type "),_c('span',{staticClass:"text-red-500 ml-1 text-lg leading-3 mr-auto"},[_vm._v(" * ")])]),_c('Dropdown',{attrs:{"reposition":"","searchable":"","placeholder":"Select Type","options":_vm.options.types,"colors":{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  },"config":{ label: 'name', trackBy: 'name' },"value":_vm.assessmentData.type,"limit":1,"minWidth":"102px","maxWidth":"100%"},on:{"input":(value) => _vm.setValue(value, 'type')}})],1),(_vm.assessmentData.type === 'Field')?_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"px-0.5 pb-1",attrs:{"for":"#"}},[_vm._v(" Field "),_c('span',{staticClass:"text-red-500 ml-1 text-lg leading-3 mr-auto"},[_vm._v(" * ")])]),_c('Dropdown',{attrs:{"reposition":"","searchable":"","placeholder":"Select Field","options":_vm.options.fields,"colors":{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  },"config":{ label: 'name', trackBy: 'name' },"value":_vm.assessmentData.field,"limit":1,"minWidth":"102px","maxWidth":"100%"},on:{"input":(value) => _vm.setValue(value, 'field')}})],1):_vm._e(),(_vm.assessmentData.type === 'Field')?_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"px-0.5 pb-1",attrs:{"for":"#"}},[_vm._v(" Calculations "),_c('span',{staticClass:"text-red-500 ml-1 text-lg leading-3 mr-auto"},[_vm._v(" * ")])]),_c('Dropdown',{attrs:{"reposition":"","searchable":"","placeholder":"Select calculation","options":_vm.options.calculations,"colors":{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  },"config":{ label: 'name', trackBy: 'name' },"value":_vm.assessmentData.calculation,"limit":1,"minWidth":"102px","maxWidth":"100%"},on:{"input":(value) => _vm.setValue(value, 'calculation')}})],1):_vm._e(),(_vm.assessmentData.type === 'Check')?_c('div',{staticClass:"flex flex-col mb-4"},[_c('label',{staticClass:"px-0.5 pb-1",attrs:{"for":"#"}},[_vm._v(" Check "),_c('span',{staticClass:"text-red-500 ml-1 text-lg leading-3 mr-auto"},[_vm._v(" * ")])]),_c('Dropdown',{attrs:{"reposition":"","searchable":"","placeholder":"Select check","options":_vm.options.checks,"colors":{
                  text: '#282828',
                  bg: 'white',
                  svg: '#282828',
                  border: '#bcbcbc',
                },"config":{ label: 'name', trackBy: 'id' },"value":_vm.assessmentData.check,"limit":1,"minWidth":"102px","maxWidth":"100%"},on:{"input":(value) => _vm.setValue(value, 'check')}})],1):_vm._e()],1),_c('div',{staticClass:"card-actions justify-end"},[_c('Button',{attrs:{"loader":_vm.createAssessmentLoading,"text":"Create","type":"primary","disabled":!_vm.assessmentData.name || !_vm.assessmentData.category ||
                !_vm.assessmentData.type || 
                (_vm.assessmentData.type === 'Field' && (!_vm.assessmentData.field || !_vm.assessmentData.calculation)) ||
                (_vm.assessmentData.type === 'Check' && !_vm.assessmentData.check)},on:{"click":function($event){return _vm.handleCreateAssessment()}}})],1)])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
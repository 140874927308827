<template>
<div>
<div class="custom-container" >
   <section class="brand">
        <div id="bg">
            <img :src="bgImg" class="loginImg"/>
        </div>
    </section>
    <section class="auth">
        <template >
        <div class="w-full form-cnt min-h-full">
            <form @submit.prevent="submitForm" class="pr-10">
                <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                    <div v-if="loginInfo" :style="{paddingBottom:'6px'}">
                        <!-- <Logo1/> -->
                        <img v-if="loginScreenInfo.logo_url" :src="loginScreenInfo.logo_url" class="custom_logo">
                        <img v-else :src="Logo1" class="logo">
                        <div class="tagline_cnt">
                            <span class="tagline">{{ loginScreenInfo.tagline}}</span>
                        </div>
                    </div>
                    <div>
                        <p class="block mb-2 text-black text-2xl font-semibold">Login to your account</p>
                    </div>
                    <div>
                        <div class="mb-8 relative">
                            <label for="Email" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">Email</label>
                            <div class="flex items-center relative">
                                <input type="text" id="Email" v-model="formData.email" class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :class=" ( showError && !isEmailValid )  ? 'border-red-600' : 'border-gray-300'" placeholder="Enter Email" :disabled="isEmailFilled" @change="disableError">
                            </div>
                            <span :class="{'hideError':!(showError && !isEmailValid)}" class="text-red-600 text-xs error">Invalid Email</span>
                        </div>
                        <div class="my-8 relative">
                            <label for="password" class="block mb-2 text-sm font-normal text-gray-900 dark:text-white">Password</label>
                            <div class="flex items-center relative">
                                <input type="password" ref="passRef" id="password" v-model="formData.password" autocomplete="new-password" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" placeholder="Enter Password" @blur="handleInputBlur">
                                <EyeClosed class="absolute right-4 cursor-pointer " @click="togglePass" v-if="!isPassVisible"/>
                                <Eye class="absolute right-4 cursor-pointer " @click="togglePass" v-else/>
                            </div>
                            <span :class="{'hideError': !(showError && !isPassValid && formData.password)}"  class="text-red-600 text-xs error">Should be a minimum of 12 characters</span>
                            <div class="text-right" v-if="!isEmailFilled">
                                <router-link to="/forgot-password"  class="text-primary text-right text-xs mt-2 cursor-pointer no-underline forget-pass">Forgot Password?</router-link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="form-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer" :class="{'btnDisable':!isFormFilled || disabled }" type="submit" :disabled="!isFormFilled || disabled">
                            <Loader v-if="disabled" class="h-8 w-8" :size="8"></Loader>
                            {{ !disabled ? 'Continue' : '' }}
                        </button>
                        <div class="flex justify-center items-center powered_by_cnt" v-if="loginScreenInfo.powered_by && loginInfo">
                            <span class="poweredBy">Powered by</span>
                            <img :src="Logo1" class="poweredBy_logo"/>
                            <!-- <img class="poweredBy_logo" :src="poweredByImgUrl"/> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </template>
    </section>
</div>
</div>
</template>
<script>
    // import  LoginImg from "@/assets/svg/loginScreen.svg";
    import  Eye from "@/assets/svg/eye.svg";
    import  EyeClosed from "@/assets/svg/closeeye.svg";
    // import LoginImg1 from "@/assets/svg/auth-bg.svg";
    import { LoginImg, Logo1 ,bgImg} from "@/plugins/common.js";
    import { sendOtpForLogin ,getLoginInfo} from "./services";
    import Loader from "@shared/loader";
    import sections from "./store";

    export default {
    components: {
        Eye,
        EyeClosed,
        Loader,
        // LoginImg1,
    },
    data(){
        return {
            LoginImg,
            Logo1,
            bgImg,
            disabled: false,
            requestId:null,
            formData: {
                email: '',
                password: ''
            },
            showError: false,
            isEmailFilled: false,
            isPassVisible: false,
            loginInfo: false,
        }
    },
    async mounted() {
        if(this.$route?.params?.email){
            this.formData.email = this.$route?.params?.email;
            this.isEmailFilled = true;
        }
        await getLoginInfo();
        this.loginInfo = true;
    },
    methods: {
        async submitForm() {
            try {
                this.disabled = true
                this.showError = true
                if(!this.isEmailValid || !this.isPassValid) return this.disabled = false;
                const data = await sendOtpForLogin(this.formData)
                if (data.status == true) {
                    this.requestId = data.data.request_id
                    if(data.status_code=="1007") {
                        this.disabled = false
                        this.$router.push(`/update-password?request_id=${this.requestId}`)
                    }
                    else {  
                        this.disabled = false
                        this.$toast.success(data.message || 'OTP send successfully!')
                        this.moveToPath()
                    }
                }
                else {
                    this.disabled = false
                    this.$toast.error(data.message || 'Something went wrong!')
                }
            } catch (error) {
                this.disabled = false
                this.$toast.error('Something went wrong!')
            }
        },
        disableError(){
            this.disabled = false
        },
        togglePass(){
                this.$refs.passRef.type = this.$refs.passRef.type==='text' ? 'password' : 'text';
                this.isPassVisible = !this.isPassVisible;
        },
        handleInputBlur(){
            // this.$refs.passRef.type = 'password';
        },
        moveToPath(){
            this.$router.push({
                name: "otp-login",
                query: { requestId: this.requestId },
            })
        },
        isValidEmail(email){
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailRegex.test(email);
        }
  },
  computed: {
    isFormFilled(){
        return this.formData?.email && this.formData?.password
    },
    isEmailValid(){
        return this.isValidEmail(this.formData.email)
    },
    isPassValid(){
        return this.formData?.password.length > 11 
    },
    loginScreenInfo(){
        return sections.login_screen_info
    },
    poweredByImgUrl(){
        // return sections.login_screen_info.logo_url
        return `data:image/png;base64,${this.loginScreenInfo.logo}`
    }
  }
}
</script>
<style lang="scss">
      .custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;   
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }
  
    // .loginImage-cnt {
    //     width: 50%; 
    //     height: 100vh; 
    //     // overflow: hidden; 
    //     display: flex;
    // }
    .form-cnt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
        background-color: white;
    }
    .logo{
        width: 200px;
        height: 72px;
    }
    .btnDisable{
        background-color: #DDD;
        color: #858585;
    }
    // .rounded{
    //     border-radius: 10px;
    // }
    .form-cnt-div{
        padding: 3rem;
    }
    input{
        font-size: 16px;
        border-radius: 10px;
        // border: 1px solid  #d8dbe0;
    }
    input:focus{
        outline: 1px solid rgb(59,130,246);
    }
    input::placeholder{
        // color: #49454F;
    }
    button{
        border-radius: 10px;
        border-width: 0px;
        font-size: 16px;
        background-color:  #0D69D5;
        font-weight: 700;
    }
    .forget-pass{
        color: rgb(13, 106, 213);
    }
    *{
        font-family: "Poppins", sans-serif !important;
    }
    .auth{
        overflow-y : scroll;
    }
    form{
        max-width: 36rem;
    }
    .hideError{
        visibility: hidden;
    }
    .error{
        position: absolute;
    }
    .loginImg{
        width: 100%;
        height: 100%;
    }
    .tagline{
        font-size: 16px;
        font-weight: 600;
    }
    .poweredBy{
        font-size: 12px;
    }
    .poweredBy_logo{
        max-height: 28px;
        min-height: 20px;
        margin-left: 10px;
    }
    .powered_by_cnt{
        margin-top: 15px;
    }
    .tagline_cnt{
        margin-top: 19px;
    }
    .custom_logo{
        max-height: 130px;
        min-height: 72px;
    }
}
</style>

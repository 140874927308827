import { render, staticRenderFns } from "./more-person-identifier.html?vue&type=template&id=30624036&scoped=true&external"
import script from "./more-person-identifier.js?vue&type=script&lang=js&external"
export * from "./more-person-identifier.js?vue&type=script&lang=js&external"
import style0 from "./more-person-identifier.scss?vue&type=style&index=0&id=30624036&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30624036",
  null
  
)

export default component.exports
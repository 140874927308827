<template>
    <div class="flex flex-col overflow-hidden max-h-full">
        <div class="py-6 px-6 flex items-center justify-center" v-if="isLoading">
            <Loader :loading="isLoading" class="text-md" />
        </div>
        <div class="py-4 flex flex-col overflow-hidden max-h-full" v-else>
            <div class="flex gap-4 mt-4 overflow-hidden max-h-full">
                <div class="border rounded-md flex-1 flex flex-col">
                    <p class="text-gray-700 text-md bg-gray-100 px-3 py-1.5">Teams assigned to client</p>
                    <div class="search_container m-4">
                        <Input v-model="searchTeams" placeholder="search teams" icon="search" />
                    </div>
                    <div class="p-4 flex gap-1 max-h-96 overflow-y-auto scroll-bar flex-col" v-if="getTeamsList && getTeamsList.length > 0">
                        <div class="flex items-center gap-1" v-for="(team, index) in getTeamsList" :key="index">
                            <Checkbox :disabled="assigningWorkAllocation && assigningWorkAllocation.team" @input="$emit('selectTeam', team)" v-model="team.allotted" :label="team.name" class="flex flex-row-reverse justify-end gap-2 mt-2.5" />
                            <div @click="handleClickGroup(team)" class="hover:bg-gray-200 h-6 w-6 rounded-full flex items-center justify-center mt-2 cursor-pointer">
                                <svg class="h-3 w-3 text-blue-600" fill="rgba(37, 99, 235, 1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>

                            </div>
                        </div>
                    </div>
                    <div class="p-4 flex justify-center items-center text-gray-600 border border-dashed m-4 rounded py-10" v-else>
                        <Empty text="No result found" />
                    </div>
                </div>
                <div class="border rounded-md flex-1 flex flex-col">
                    <p class="text-gray-700 text-md bg-gray-100 px-3 py-1.5">Users assigned to client</p>
                    <div class="search_container m-4">
                        <Input v-model="searchUsers" placeholder="search users" icon="search" />
                    </div>
                    <div class="p-4 flex gap-1 max-h-96 overflow-y-auto scroll-bar flex-col" v-if="getUsersList && getUsersList.length > 0">
                        <div class="flex items-center space-x-2" v-for="(user, index) in getUsersList" :key="index">
                            <Checkbox :disabled="assigningWorkAllocation && assigningWorkAllocation.user" @input="$emit('selectUser', user)" v-model="user.allotted" :label="user.user_name" class="disabled:opacity-60 flex flex-row-reverse justify-end gap-2 mt-2.5" />
                            <div @click="handleClickUser(user)" class="hover:bg-gray-200 h-6 w-6 rounded-full flex items-center justify-center mt-2 cursor-pointer">
                                <svg class="h-3 w-3 text-blue-600" fill="rgba(37, 99, 235, 1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg>

                            </div>
                        </div>
                    </div>
                    <div class="p-4 flex justify-center items-center text-gray-600 border border-dashed m-4 rounded py-10" v-else>
                        <Empty text="No result found" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@shared/loader";
import Checkbox from "@/components/checkbox";
import Input from "@/components/input";
import Empty from "@shared/empty"

export default {
    name: "work-allocation-display",
    components: {
        Loader,
        Checkbox,
        Input,
        Empty
    },
    props: {
        workAllocationData: {
            type: Object,
            default: () => null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        assigningWorkAllocation:{
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            searchUsers: "",
            searchTeams: ""
        };
    },
    mounted() {},
    computed: {
        getUsersList() {
            if(this.searchUsers){
                return this.workAllocationData?.users.filter(el => el.user_name.toLowerCase().includes(this.searchUsers.toLowerCase()));
            }
            return this.workAllocationData?.users;
        },
        getTeamsList() {
            if(this.searchTeams){
                return this.workAllocationData?.teams.filter(el => el.name.toLowerCase().includes(this.searchTeams.toLowerCase()));
            }
            return this.workAllocationData?.teams
        },
    },
    methods: {
        handleClickUser(data) {
            let route = this.$router.resolve({ name: "User Setting", params: { id: data.id } });
            window.open(route.href)
        },
        handleClickGroup(data) {
            let route = this.$router.resolve({ name: "Group Setting", params: { id: data.id } });
            window.open(route.href)
        },
    },
};
</script>
<style scoped></style>

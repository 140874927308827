import { render, staticRenderFns } from "./news-expanded.html?vue&type=template&id=4ddca79f&scoped=true&external"
import script from "./news-expanded.js?vue&type=script&lang=js&external"
export * from "./news-expanded.js?vue&type=script&lang=js&external"
import style0 from "./news-expanded.scss?vue&type=style&index=0&id=4ddca79f&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddca79f",
  null
  
)

export default component.exports
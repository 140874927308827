import axios from "axios";
import neoAxios from "@/axios";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
const FilePond = vueFilePond(FilePondPluginFileValidateType);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import Tags from "@shared/components/tags";
import Button from "@/components/button";
import Input from "@/components/input";
import ExtLink from "@shared/components/ext-link";
import Pagination from "@/components/pagination";

import {mapGetters} from "vuex";






export default {
    name: "modal-company-details-search",
    props: ["companyDetails"],
    data() {
        return {
            requireHeading: false,
            flags: [],
            finalTags: [],
            uploadedFiles: [],
            savedFile: "",
            imageCaption: "",
            postingDate: "",
            originalFile: null,
            filterKeyword: "",
            filterKeywordFinal: "",
            totalPageCount: 0,
            currentPage: 1,
            stabilizing: false,
        };
    },
    methods: {
      
      
   
        filterTweets() {
            this.filterKeywordFinal = this.filterKeyword;
        },
        clearFilterTweets() {
            this.filterKeyword = "";
            this.filterKeywordFinal = "";
        },
        getTags(tags) {
            return tags.map((tag) => {
                return {tag};
            });
        },
        getDetectedLanguages(data) {
            return data?.languages_detected?.languages
                .filter((e) => e.code != "un")
                .map((f) => f.name)
                .join(", ");
        },
        handleFilePondInit: function () {},
        saveData() {
            let screenshotObj = {
                available: false,
            };
            if (this.savedFile != "") {
                screenshotObj.available = true;
                screenshotObj.location = this.savedFile;
                screenshotObj.file_name = this.originalFile.name;
                screenshotObj.file_mime_type = this.originalFile.type;
                screenshotObj.size = this.originalFile.size;
            }
            let final_data = {
                platform: this.matchingProfile.platform,
                processing: false,
                username: this.matchingProfile.username,
                posting_date: this.postingDate,
                hidden: false,
                deleted: false,
                content: "",
                tweet_id: "",
                language: {},
                metadata: {
                    retweet: false,
                },
                nlp_analysis: {
                    watson: {},
                    nlp_model: {},
                },
                screenshot: screenshotObj,
                image_caption: this.imageCaption,
                categories: this.finalTags,
                editMode: false,
            };
            this.$store.dispatch("addExtraContent", final_data);
        },
        process(fieldName, file, load, progress, abort) {
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            axios
                .post(`${process.env.VUE_APP_PORTAL_URL}/orca/operator/case/${this.getNeoCaseId}/file/upload`, formData, {
                    headers: {"Content-Type": "multipart/form-data"},
                    onUploadProgress: (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                })
                .then((response) => {
                    load(response.data);
                    this.savedFile = response.data;
                    this.originalFile = file;
                })
                .catch(() => {
                    return {
                        abort: () => {
                            abort();
                        },
                    };
                });
        },
        handleError(error, e) {
            switch (e.code) {
                case "storage/canceled":
                    break;
                default:
                    error(e.message);
            }
        },
        includeReport(item) {
            item.flagged = true;
            item["analyst_added"] = true;
            this.$store.dispatch("includeInReport", item);
            this.updateContent(item);
        },
        revertChanges(item) {
            item.flagged = false;
            item["analyst_added"] = false;
            item.categories.analyst = item.categories.system;
            this.$store.dispatch("removeFromReport", item);
            this.updateContent(item, item.categories.system);
        },
        updateContent(item, categories = null) {
            neoAxios
                .put(`/social-platform-data`, {
                    case_id: this.getNeoCaseId,
                    platform: this.getMatchingProfile.platform,
                    username: this.getMatchingProfile.username,
                    content_id: item._id,
                    analyst_added: item?.analyst_added ?? false,
                    include_in_report: item.include_in_report,
                    flagged: item.flagged,
                    categories: categories == null ? item.categories.analyst : categories,
                    translation: item.language?.text_translated_edited ?? item.language?.text_translated ?? item.content,
                })
                .then(() => {});
        },
        onTagChanges(event, item) {
            let fTags = event.map((e) => e.tag);
            item.categories.analyst = fTags;
            if (item.include_in_report) {
                this.updateContent(item);
            }
        },
        klReady(chart) {
            this.chart = chart;
            this.chart.options({handMode: true});
            this.chart.layout("sequential", {tightness: 3, orientation: "down"});
        },
        chainDataCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity?.filter((n) => n.owner).length && companyDetails?.company?.chain_data?.entity?.filter((n) => n.ultimate_beneficiary).length;
        },
        graphCheck(companyDetails) {
            return companyDetails?.company?.chain_data?.entity.length > 0 && companyDetails?.company?.chain_data?.entity.length > 0;
        },
        async StabilizeGraph(klId) {
            this.stabilizing = true;
            await window.KeyLines.charts[`kl_corporate_records${klId}`].layout("sequential", {tightness: 3, orientation: "down"});
            this.stabilizing = false;
        },
    },
    computed: {
        matchingProfile() {
            return this.$store.getters.getMatchingCaseData;
        },
        socialData() {
            return this.$store.getters.getMatchingSocialData;
        },
        socialContent() {
            let data = this.$store.getters.getMatchingSocialData;
            let filteredData = [];
            if (data) {
                if (this.filterKeywordFinal == "") {
                    filteredData = data.data;
                    // return data.data
                } else {
                    filteredData = data.data.filter((tweet) => {
                        if (tweet.content.toLowerCase().includes(this.filterKeywordFinal.toLowerCase())) return tweet;
                    });
                }
            }

            let count = filteredData.length / 5;
            if (count > Math.floor(count)) {
                count = Math.floor(count) + 1;
            }

            this.totalPageCount = count;
            return filteredData.slice(this.currentPage * 5 - 5, this.currentPage * 5);
        },

        getTotalPageCount() {
            return this.totalPageCount;
        },
        ...mapGetters(["getMatchingProfile", "getNeoCaseId"]),
    },
    components: {
        FilePond,
        "neo-datepicker": DatePicker,
        "neo-tags": Tags,
        "neo-button": Button,
        "neo-input": Input,
        "neo-ext-link": ExtLink,
        "neo-pagination": Pagination,
        
    },
};

<template>
    <div class="print-d-block flex flex-col space-y-4 overflow-y-auto px-2 overflow-x-hidden">
        <!-- {{expandedCard.sources}} -->
        <div @click="handleExpandAll" class="print-hidden text-blue-600 ml-auto cursor-pointer hover:text-blue-400 inline-flex">
            <template v-if="!adverseCard.expandedPanel">Expand All</template>
            <template v-else>Collapse All</template>
        </div>

        <div>
            <template v-if="profileData">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'aliases')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Profile Data</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.aliasesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-show="adverseCard.aliasesExpanded">
                    <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Names</div>
                    <ul class="mb-4 pl-3 space-y-2">
                        <li v-for="(alias, index) in get(adverseCard, 'data.EntityDetails.AKAs', [])" :key="index" :identifier="`['data']['AKAs'][${index}]`" class="list-none">{{ alias.Name.Full }} ({{ getCategory(alias.Category) }})</li>
                    </ul>
                    <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">ID Numbers</div>
                    <ul class="mb-4 pl-3 space-y-2">
                        <li v-for="(alias, index) in get(adverseCard, 'data.EntityDetails.IDs', [])" :key="index" :identifier="`['data']['IDs'][${index}]`" class="list-none">{{ alias.Number }}</li>
                    </ul>
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" identifier="['data']['position'][0]">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Position</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <span class="space-y-2" v-if="data.Type === 'Occupation'">
                                        <template>{{ data.Value }}</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" identifier="['data']['place_of_birth']">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Place of Birth</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <span class="space-y-2" v-if="data.Type === 'PlaceOfBirth'">
                                        <template>{{ data.Value }}</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" identifier="['data']['dob']">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">DOB</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <span class="space-y-2" v-if="data.Type === 'DOB'">
                                        <template v-if="data.Value">{{ data.Value }}</template>
                                        <template v-else>-</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" identifier="['data']['EntityDetails']['Gender']" v-show="get(adverseCard, 'data.EntityDetails.Gender', null)">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Gender</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2">
                                        <template>{{adverseCard.data.EntityDetails.Gender}}</template>
                                     
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">ProprietaryUID</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center flex-wrap" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.IDs', [])" :key="index">
                                    <template v-if="data.Type === 'ProprietaryUID'">
                                        <span class="space-y-2" :identifier="`['data']['ProprietaryUID'][${index}]`" v-if="data.Number">
                                            {{ data.Number }}
                                        </span>
                                        <span class="space-y-2" v-else>-</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['data']['EntityDetails']['Addresses'][0]['Country']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Address</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center flex-wrap" v-for="(addDet, addIndex) in getAddress(adverseCard.data.EntityDetails.Addresses)" :key="addIndex">
                                    <span class="space-y-2 mb-2" :identifier="`['data']['Address'][${addIndex}]['Country']`">
                                        {{
                                            get(addDet, "Street1", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "City", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "PostalCode", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "StateProvinceDistrict", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                        {{
                                            get(addDet, "Country", "")
                                                .split(";")
                                                .join(" ")
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-for="(info, index) in adverseCard.data.EntityDetails?.AdditionalInfo" :key="index" class="mb-2 flex-col space-x-2" identifier="['data']['EntityDetails']['AdditionalInfo']">
                            <div class="mb-2 flex space-x-2" v-show="info?.Type=='Citizenship'">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-20 text-gray-400">Citizenship</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 ml-10">
                                        <template>{{ info.Value }}</template>
                                    </span>
                                </div>
                            </div>
                            </div>

                            <div class="mb-2 flex space-x-2" v-show="info?.Type=='Nationality'">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-20 text-gray-400">Nationality</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 ml-10">
                                        <template>{{ info.Value }}</template>
                                    </span>
                                </div>
                            </div>
                            </div>

                            

                            
                            
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Profile Data</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.aliasesExpanded"></div>
            </template>
        </div>
        <div>
            <div v-if="screeningListData">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'screening')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Screening List</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.screeningExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-show="adverseCard.screeningExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['File']['Name']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Screening List Name</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        <template> {{ get(adverseCard.data, "File").Name }}</template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['data']['File']['Build']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">File Build:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                       {{ getFormattedTime(get(adverseCard.data, "File").Build) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['DateListed']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Entity Created:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ getFormattedTime(adverseCard.data.EntityDetails.DateListed) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['DateModified']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Entity Last Updated:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ getFormattedTime(adverseCard.data.DateModified) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['ReasonListed']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Reason Listed:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ adverseCard.data.ReasonListed }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-for="(info, index) in adverseCard.data.EntityDetails?.AdditionalInfo" class="mb-2 flex space-x-2" :key="index" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']${index}`">
                            <div v-show="info?.Type=='Position'" class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Position</div>
                            <div v-show="info?.Type=='Position'" class="w-full p-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2 mb-2">
                                        {{ info.Value }}
                                    </span>
                                </div>
                            </div>
                        </div>
                         <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['Status']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Entity Status</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-show="adverseCard.data?.Status">
                                    <span class="space-y-2 mb-2">
                                        {{ adverseCard.data?.Status }}
                                    </span>
                                </div>
                                <div class="flex justify-between items-center" v-show="!adverseCard.data?.Status">
                                    <span class="space-y-2 mb-2">
                                        -
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Screening List</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.screeningExpanded"></div>
            </div>
        </div>
        <!-- Segments section -->
        <template>
             <div
          ref="refAliases"
          @click="fetchMoreInfo(adverseCard, 'segments')"
          class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md"
        >
          <div class="text-lg font-medium">Segments</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
              :class="{ 'rotate-180': adverseCard.segmentsExpanded }"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        <div class="tabActions flex justify-between items-center"  v-show="adverseCard.segmentsExpanded">
            <div class="tabContainer">
                <div class="tabs space-x-2 flex-nowrap">
                    <div
                        @click="selectedInfo(tab)"
                        v-for="(tab, index) in segmentTabs"
                        :key="index"
                        class="tab px-6"
                        :style="{
                            borderBottomColor: selected === tab ? 'var(--brand-color)' : '',
                        }"
                        @mouseover="hoveredOver = true"
                        @mouseleave="hoveredOver = false"
                    >
                        <span :class="{'text-blue-600': selected === tab, 'text-brand': ''}" class="tab-name">
                            {{ tab }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="shadow rounded border border-solid border-gray-300 overflow-auto h-64" v-show="selected=='Enforcements' && adverseCard.segmentsExpanded">
            <table
              id="principals"
              class="principalsTable w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subcategory
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Source
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(en,index) in adverseCard.data.Enforcements" :key="index" :class="{'bg-gray-100': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.Country}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.SubCategories.join(",")}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.Source}}</td>
                  </tr>

              </tbody>
              
            </table>
        </div>

        <div class="shadow rounded border border-solid border-gray-300 overflow-auto h-64" v-show="selected=='PEP' && adverseCard.segmentsExpanded">
            <table
              id="principals"
              class="principalsTable w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Admin Unit
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Admin Level
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subcategories
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Country Role
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Governing Institution
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Governing Role
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Effective Date
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Expiration Date
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(en,index) in adverseCard.data.PEPs" :key="index" :class="{'bg-gray-100': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.Status}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.IsPrimary}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.Country}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.AdminUnit}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.AdminLevel}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.SubCategories.join(",")}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.CountryRole}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.GoverningInstitution}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.GoverningRole}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.EffectiveDate}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.ExpirationDate}}</td>
                      <!-- <td class="px-6 py-2 text-sm text-gray-500 break-words">{{en?.EffectiveDate}}</td> -->
                      
                  </tr>

              </tbody>
              
            </table>
        </div>

        <div class="shadow rounded border border-solid border-gray-300 overflow-auto h-64" v-show="selected=='SOE' && adverseCard.segmentsExpanded">
            <table
              id="principals"
              class="principalsTable w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Ownership %
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subcategory
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Domain
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(soe,index) in adverseCard.data.SOEs" :key="index" :class="{'bg-gray-100': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{soe?.IsActive}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{soe?.SubCategories.join(",")}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{soe?.Domain.join(",")}}</td>
                  </tr>

              </tbody>
              
            </table>
        </div>

        <div class="shadow rounded border border-solid border-gray-300 overflow-auto h-64" v-show="selected=='Adverse Media' && adverseCard.segmentsExpanded">
            <table
              id="principals"
              class="principalsTable w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Subcategory
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(adv,index) in adverseCard.data.AdverseMedias" :key="index" :class="{'bg-gray-100': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{adv?.Description}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{adv?.SubCategories.join(",")}}</td>
                  </tr>

              </tbody>
              
            </table>
        </div>




        </template>


        <!-- Relationship section -->
        <div>
      <template v-if="get(adverseCard.data, 'Relationships', []).length > 0">
        <div
          ref="refAliases"
          @click="fetchMoreInfo(adverseCard, 'relationship')"
          class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md"
        >
          <div class="text-lg font-medium">Relationships</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
              :class="{ 'rotate-180': adverseCard.commentsExpanded }"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        <div
          class="p-2 headingBlock-sibling"
          v-show="adverseCard.relationshipExpanded"
        >
          <div class="shadow rounded border border-solid border-gray-300 overflow-auto h-64">
            <table
              id="principals"
              class="principalsTable w-full divide-y divide-gray-200"
            >
              <thead class="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Entity Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Relationship Group
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Ownership Percentage
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Segment
                  </th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(relation,index) in adverseCard.data.Relationships" :key="index" :class="{'bg-gray-100': index % 2 === 0, 'bg-white': index % 2 !== 0}">
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{relation?.EntityName}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{relation?.Group}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{relation?.Type}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{relation?.OwnershipPercentage}}</td>
                      <td class="px-6 py-2 text-sm text-gray-500 break-words">{{relation?.Segments}}</td>
                  </tr>

              </tbody>
              
            </table>
          </div>
        </div>
      </template>
    </div>



    
        <div>
            <template v-if="get(adverseCard.data.EntityDetails, 'Comments', '').length > 0">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'comments')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Comments</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.commentsExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-show="adverseCard.commentsExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['Comments']`">
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard.data.EntityDetails, 'Comments', '').split('|')" :key="index">
                                    <span class="space-y-2 mb-2">
                                        <span v-html="getProfileNotes(data)"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Comments</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.commentsExpanded"></div>
            </template>
        </div>

        <!-- <div class="mb-2">
            <template v-if="get(adverseCard, 'data.EntityDetails.Addresses', []).length > 0">
                <div ref="refAddressSection" @click="fetchMoreInfo(adverseCard, 'addressesLexisBridger')" class="headingBlock flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Addresses</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.addressesLexisBridgerExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.addressesLexisBridgerExpanded">
                    <template v-if="adverseCard.data.EntityDetails.EntityType == 'Individual' || adverseCard.data.EntityDetails.EntityType == 'Business'">
                        <div class="print-overflow-table mb-2 flex flex-col addressesDowjones">
                            <table class="min-w-full divide-y divide-gray-200 border border-solid border-gray-300 mb-2">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Line
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            City
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            State
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Country
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(addDet, addIndex) in getAddress(adverseCard.data.EntityDetails.Addresses)" :key="addIndex">
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['Street1']`">
                                            {{
                                                get(addDet, "Street1", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['City']`">
                                            {{
                                                get(addDet, "City", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['StateProvinceDistrict']`">
                                            {{
                                                get(addDet, "StateProvinceDistrict", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                        <td class="px-6 py-3 text-left" :identifier="`['data']['Address'][${addIndex}]['Country']`">
                                            {{
                                                get(addDet, "Country", "")
                                                    .split(";")
                                                    .join(" ")
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Addresses</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.addressesLexisBridgerExpanded"></div>
            </template>
        </div> -->
        <div>
            <template v-if="get(adverseCard, 'data.EntityDetails.AdditionalInfo', []).length > 0">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'otherinfo')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Additional Information</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.otherinfoExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-show="adverseCard.otherinfoExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Other Information:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <div class="space-y-2" v-if="data.Type === 'Other' && data.Value !== 'Sources of Record Information'">
                                        <span class="space-y-2 mb-2" :identifier="`['data']['EntityDetails']['AdditionalInfo'][0]['Value']`">
                                            <template>{{ data.Value }}</template>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']`">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">Comments:</div>
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <div class="space-y-2" v-if="data.Type === 'Other' && data.Value !== 'Sources of Record Information'">
                                        <span class="space-y-2 mb-2" v-if="data.Comments">
                                            <a :href="data.Comments.split('|')[1]" class="text-blue-600 hover:text-blue-900 no-underline" target="_blank">
                                                <span class="break-words"> {{ data.Comments.split("|")[1] }} </span>
                                            </a>
                                        </span>
                                        <span class="space-y-2 mb-2" v-else>-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Additional Information</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.otherinfoExpanded"></div>
            </template>
        </div>
        <div>
            <template v-if="get(adverseCard, 'data.EntityDetails.AdditionalInfo', []).length > 0">
                <div ref="refAliases" @click="fetchMoreInfo(adverseCard, 'sources')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-2 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Record Sources</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.sourcesExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-show="adverseCard.sourcesExpanded">
                    <div class="mt-2">
                        <div class="mb-2 flex space-x-2" :identifier="`['adverseCard']['data']['EntityDetails']['AdditionalInfo']`">
                            <div class="w-full p-2">
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.EntityDetails.AdditionalInfo', [])" :key="index">
                                    <div class="space-y-2" v-if="data.Type === 'Other' && data.Value === 'Sources of Record Information' && data.Comments">
                                        <div class="flex justify-between items-center" v-for="(data1, index) in data.Comments.split('|')" :key="index">
                                            <span class="space-y-2 mb-2">
                                                <a :href="data1" class="text-blue-600 hover:text-blue-900 no-underline" target="_blank">
                                                    <span class="break-words"> {{ data1 }} </span>
                                                </a>
                                                <!-- <template><a>{{ data1 }}</a></template> -->
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="flex justify-between items-center" v-for="(data, index) in get(adverseCard, 'data.SourceItems',[])" :key="index">
                                    <div class="space-y-2">
                                        <div class="flex justify-between items-center">
                                            <span class="space-y-2 mb-2">
                                                <a :href="data1" class="text-blue-600 hover:text-blue-900 no-underline" target="_blank">
                                                    <span class="break-words"> {{ data.SourceURI }} </span>
                                                </a>
                                                <!-- <template><a>{{ data1 }}</a></template> -->
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Record Sources</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.sourcesExpanded"></div>
            </template>
        </div>
    </div>
</template>
<script>
// import { DateTime } from "luxon";
import {get} from "lodash";
import {DateTime} from "luxon";
import adverseMixin from "@shared/tools/mixins/adverseMixin";
import printDataMixin from "@shared/tools/mixins/printDataMixin";
import highlighterMixin from "@shared/mixins/highlighterMixin";


export default {
    data() {
        return {
            selected: "Enforcements",
            segmentsTabs: ["Enforcements", "SOE", "Adverse Media"],
            urlFields: ["related url", "locationurl"],
            isPrintLoader: false,
            refBtnClick: {
                advDetails: true,
                advAssociates: true,
                advPEP: true,
                advPepClass: true,
                advFitness: true,
                advImages: true,
                advAdverseMedia: true,
                advMediaData: true,
                refNameSection: true,
                refcontryTerritoryDetails: true,
                refDatesSection: true,
                refAddressSection: true,
                refImagesSection: true,
                refRolesSection: true,
                refRelativeCloseDowjonesSection: true,
                refProfileNotesDowjonesSection: true,
                refSourceSection: true,
                refworldcheck: true,
                refAliases: true,
                refCitizenships: true,
                refLocations: true,
                refFurtherInfo: true,
                refInformationSources: true,
            },
            printDone: false,
        };
    },
    mixins: [adverseMixin, printDataMixin, highlighterMixin],
    props: {
        expandedCard: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        source: {
            type: String,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        segmentTabs() {
            console.log(this.adverseCard)
            let tabs = ["Enforcements", "SOE", "Adverse Media"]
            if (this.adverseCard?.data?.PEPs) {
                tabs = ["Enforcements", "Adverse Media", "PEP"]
            }
            return tabs
        },
        isMedia() {
            return this.adverseCard?.doc.media && this.adverseCard?.doc.media.length > 0;
        },
        adverseCardKey() {
            return this.expandedCard?.key;
        },
        adverseCard() {
            return this.expandedCard?.api_all_data ?? {};
        },

        profileData() {
            return get(this.adverseCard, "data.EntityDetails.AKAs", []).length > 0 || get(this.adverseCard, "data.EntityDetails.AdditionalInfo", []).length || get(this.adverseCard, "data.EntityDetails.IDs", []).length || get(this.adverseCard, "data.EntityDetails.Addresses", []).length;
        },

        screeningListData() {
            return Object.keys(get(this.adverseCard.data, "File", "")).length > 0 || this.adverseCard.data.DateModified || this.adverseCard.data.EntityDetails.DateListed || this.adverseCard.data.ReasonListed;
        },
    },
    watch: {},
    mounted() {
    
    },
    methods: {
        get,
        selectedInfo(info) {
            this.selected = info;
        },
        getIdentifierMapping(key, index, listLength) {
            let mapping = "";
            if (key === "dateDetails")
                mapping = `['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Day'] 
                ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Month'] 
                ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Year']`;
            else if (key === "countryDetails") {
                for (let i = 0; i < listLength; i++) mapping += ` ['data']['${this.source}']['CountryDetails'][${index}]['CountryValue'][${i}]['@name']`;
            } else if (key === "dates") {
                if (listLength >= 0)
                    mapping = `['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue'][${listLength}]['@Day']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue'][${listLength}]['@Month']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue'][${listLength}]['@Year']`;
                else
                    mapping = `['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Day']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Month']
                    ['data']['${this.source}']['DateDetails']['Date'][${index}]['DateValue']['@Year']`;
            } else if (key === "SanctionRefs") mapping = `['data']['${this.source}']['SanctionsReferences'][${index}]['data']['@name'] ['data']['${this.source}']['SanctionsReferences'][${index}]['Value']['#text']`;
            else if (key === "SanctionRefsSince") mapping = `['data']['${this.source}']['SanctionsReferences'][${index}]['@SinceDay'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@SinceMonth'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@SinceYear']`;
            else if (key === "SanctionRefsTo") mapping = `['data']['${this.source}']['SanctionsReferences'][${index}]['@ToDay'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@ToMonth'] ['data']['${this.source}']['SanctionsReferences'][${index}]['@ToYear']`;
            return mapping;
        },

        getCategory(category) {
            if (category === "None") return "AKA";
            else return category + " AKA";
        },

        getImages(adverseCard) {
            let img_arr = [];
            let img = adverseCard.Images;
            if (img) {
                if (Array.isArray(img)) {
                    img_arr = img;
                    // return img
                } else {
                    img_arr = img.Image;
                    // return img.Image
                }
            }
            return img_arr;
        },
        get_image_url(img) {
            if (img["@URL"] !== undefined) {
                return img["@URL"];
            } else {
                return "";
            }
        },
        async printPageLexis(id, name, print, noPrint) {
            this.printDone = false;
            this.isPrintLoader = true;
            await this.handleExpandAll(true);
            if (!print) {
                let htmlPDF = await this.printData(id, name, noPrint);
                this.$emit("updatePrintStatus", "");
                this.isPrintLoader = false;
                if (noPrint) {
                    return htmlPDF;
                }
            }
        },
        getAddress(address) {
            if (address) {
                if (Array.isArray(address)) return address;
                else {
                    let obj = [address];
                    return obj;
                }
            } else return [];
        },
        getProfileNotes(data) {
            /* eslint-disable no-useless-escape */
            /// ADDING A NEWLINE CHARACTER AT STARTING OF THE NOTES TO MAKE IT EASIER TO MATCH HEADING TEXT
            /// THEN REPLACING IT WITH BOLD HTML
            /// REMOVE THE NEWLINE CHARACTER ADDED IN FIRST STEP
            /// REPLACE ALL NEWLINE CHARACTERS TO LINE-BREAK
            let notes = "\n" + data;
            notes = notes.replaceAll(/\n.+?\:/g, (str) => {
                return `\n <span class='font-bold inline-block tracking-wider'> ${str} </span>`;
            });
            /* eslint-enable no-useless-escape */
            return notes.replace("\n", "").replaceAll("\n", "<br />");
        },
        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
    },
};
</script>


<style scoped>
   .tabActions {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
}
/* .scroll-bar {
    height: 10px;
} */
.tabs {
    width: 100%;
    border-bottom: 3px solid var(--brand-accent-neutral);
    cursor: pointer;
    margin: 0.4rem 0;
    font-family: var(--brand-font);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    display: flex;
}
.tabContainer {
    width: 100%;
}

.tab {
    transform: translateY(3px);
    border-bottom: 3px solid transparent;
    transition: var(--transition-mnml);
    border-radius: var(--component-border) var(--component-border) 0 0;
    font-size: 16px;
    text-align: center;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}
::-webkit-scrollbar:vertical {
     background-color: gray; /* Blue thumb */
  border-radius: 10px;
  border: 2px solid #f1f1f1; 
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: gray; /* Blue thumb */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Optional border to make it stand out */
}
@media print {
    .rotate-180 {
        display: none !important;
    }
    .searchIcon {
        display: none !important;
    }
}
</style>
<style>
.cascading-highlighter {
        background-color: #f6f1bb;
    }
@media print {
    .Vue-Toastification__container {
        display: none !important;
    }
    .print-hidden {
        display: none !important;
    }
    .searchIcon {
        display: none !important;
    }
    .relatives-close-associates {
        overflow-x: hidden !important;
    }
    .print-d-block {
        display: block !important;
    }
    table {
        page-break-inside: auto !important;
    }
    table tr {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table th {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table tr div {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table tr:before {
        min-height: 60px !important;
    }
    div {
        page-break-inside: auto !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
        box-sizing: content-box !important;
    }
    tr {
        page-break-inside: avoid !important;
    }
    table {
        table-layout: fixed !important;
    }
    /* table, */
    thead,
    /* tbody, */
    /* tr  */
    /* td, */
    th {
        display: block !important;
    }
    table thead tr {
        top: -9999px !important;
        left: -9999px !important;
        display: none !important;
    }

    table tr:nth-child(odd) {
        background: #f6f8fc !important;
    }
    table tr {
        /* border: none !important;
        border-bottom: 1px solid #eee !important;
        position: relative !important;
        align-items: center !important;
        display: flex !important;
        white-space: normal !important;
        word-wrap: break-word !important;
        padding: 5px 15px !important; */
        width: 100%;
    }
}
</style>

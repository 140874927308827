import axios from "@/axios";

export const CaseCreationStore = {
    namespaced: true,

    state: {
        riskScoreData: null,
        isRiskScoreModalShown: false,
    },

    mutations: {
        SET_RISK_SCORE_DATA(state, payload) {
            state.riskScoreData = payload;
        },
        SET_RISK_SCORE_MODAL_SHOWN(state) {
            state.isRiskScoreModalShown = !state.isRiskScoreModalShown;
        },
    },

    getters: {
        getRiskScoreData: state => state.riskScoreData,
        getIsRiskScoreModalShown: state => state.isRiskScoreModalShown,
    },

    actions: {
        fetchRiskScore: async({ commit }, payload) => {
            const { case_id, params = {} } = payload;

            try {
                const { data } = await axios.get(`risk-assessment/case/${case_id}`, {
                    params,
                });
                commit('SET_RISK_SCORE_DATA', data);
            } catch (error) {
                console.log("error>>>", error);
            }
        },

        toggleRiskScoreModal: ({ commit }) => {
            commit("SET_RISK_SCORE_MODAL_SHOWN");
        },
    },
};

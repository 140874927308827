var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 flex flex-col max-h-full"},[_c('sub-header',{attrs:{"showBtn":_vm.showCreateButton,"handleClick":_vm.createCaseWorkflow,"buttonText":"Create Workflow"}}),_c('div',{staticClass:"flex flex-1 flex-col flex-grow max-w-full overflow-auto scroll-bar"},[_c('v-table',{ref:"vueGoodTable",attrs:{"id":"caseStatusTable","mode":'records',"columns":_vm.columns,"rows":_vm.filteredRows,"isLoading":_vm.listLoader,"pagination-options":_vm.paginationOptions,"total-rows":_vm.getTotalRows,"filters":_vm.filterTerm,"filterOptions":_vm.filterOptions,"sortingEnabled":""},on:{"handleClone":function($event){return _vm.handleCloneWorkflow($event)},"column-filter":function($event){return _vm.onColumnFilters($event)},"page-change":_vm.onPageChange,"filters-clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"actions",fn:function(customData){return [_c('div',{staticClass:"flex justify-around"},[_c('router-link',{attrs:{"to":{
              name: 'Workflow Engine Details',
              params: {
                workflowId: customData?.customData?.id,
                action: 'edit',
                type: _vm.entityType,
              },
            }}},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2",attrs:{"content":'Edit',"disabled":!_vm.checkPermission('workflow.edit')}},[_c('font-awesome-icon',{staticClass:"text-blue-600 text-lg fa-3x",class:customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500',attrs:{"icon":"edit"}})],1)]),_c('router-link',{attrs:{"to":{
              name: 'Workflow Engine Details',
              params: {
                workflowId: customData?.customData?.id,
                action: 'view',
                type: _vm.entityType,
              },
            }}},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2",attrs:{"content":'View'}},[_c('font-awesome-icon',{staticClass:"text-blue-600 text-lg fa-3x",class:customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500',attrs:{"icon":"eye"}})],1)])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- :backRedirectionUrl="backRedirectionUrl" -->
  <div class="min-h-full relative max-h-full flex flex-col text-base-content">
    <sub-header
      class="mb-4"
      :customCrumbLists="customBreadCrumbs"
      :showBtn="showCreateButton"
      buttonText="Add New"
      :backButton="true"
      :backRedirectionUrl="backRedirectionUrl"
      :handleClick="handleCreate"
    />
    <div class="justify-between flex-grow max-h-full overflow-y-auto">
      <div class="flex h-full gap-4 flex-1 overflow-y-auto">
        <div
          v-if="currentComponent?.name != 'createTriggerActions'"
          class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"
        >
          <div
            @click="handleCurrentTab(nav)"
            v-for="(nav, idx) in navigation"
            :key="idx"
            class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3 cursor-pointer"
            :class="{
              'bg-primary-300 text-white-text': currentTab === nav.nav,
              'hover:bg-gray-100 hover:text-base-content-600': currentTab !== nav.nav,
              'border-t border-gray-400 border-0': idx != 0,
              'cursor-not-allowed pointer-events-none bg-gray-100 border-gray-300 text-gray-700': nav.disabled,
            }"
          >
            <!-- :style="{borderColor: currentTab === nav.nav ? '#cdcdcd': '' }" -->
            <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
            {{ nav.title }}
            <!-- <font-awesome-icon class="text-gray-500 absolute right-2" v-if="currentTab === nav.nav" icon="arrow-right" /> -->
          </div>
        </div>
        <div
          v-if="currentTab"
          :class="
            isTriggersRoute
              ? 'flex-1 relative'
              : 'flex-1 relative bg-card-bg rounded-md shadow'
          "
        >
          <div
            v-if="!isTriggersRoute"
            class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium"
            :style="`max-height: 20vh;`"
          >
            <span class="font-bold">{{ TabLabel }} </span>
            <!-- {{ packageData.package_name }} -->
          </div>

          <div :class="isTriggersRoute ? 'h-full' : 'p-3 flex pb-4'">
            <component
              v-bind:is="currentComponent"
              @saveAndNext="handleSaveAndNext"
            >
            </component>
            <!-- <div>helloooo</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import CreateWorkflow from "@shared/workflow/components/create-workflow.vue";
import StateTransition from "@shared/workflow/components/state-transition.vue";
import TriggersActions from "@shared/workflow/components/triggersActions.vue";
import CreateTriggersAndActions from "@shared/workflow/components/CreateTriggersAndActions";
// import CreateTriggerActions from "@shared/workflow/components/createTriggers.vue";
import { checkPermission } from "@shared/utils/functions"

export default {
  title: "Workflow",
  name: "case-status",
  components: {
    SubHeader,
    CreateWorkflow,
    StateTransition,
    TriggersActions,
    CreateTriggersAndActions,
    //CreateTriggerActions,
  },
  data() {
    return {
      customBreadCrumbs: [
        { name: this.$route.params.type },
        { name: `Workflow` },
      ],
      currentTab: null,
      navigation: [
        {
          title: "Workflow Details",
          nav: "workflowDetails",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
          disabled: false,
        },
        {
          title: "State Transition",
          nav: "stateTransitions",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
          disabled: true,
        },
        {
          title: "Triggers & Actions",
          nav: "triggerAndActions",
          icon: "file-lines",
          subnav: false,
          iconPrefix: "far",
          disabled: true,
        },
      ],
      screeningMenuWrapper: {
        workflowDetails: CreateWorkflow,
        stateTransitions: StateTransition,
        triggerAndActions: TriggersActions,
        // createTriggerActions: CreateTriggerActions,
        createTriggerActions: CreateTriggersAndActions,
      },
    };
  },
  created() {
    console.log("created");
    let params = this.$route.params;
    if (params?.workflowId) {
      this.navigation.forEach((res) => {
        res.disabled = false;
      });
    }

    if (params.workflowTool) {
      this.currentTab = params.workflowTool;
      this.$route.meta.customCrumbs = [{ name: params.workflowTool }];
      this.$forceUpdate();
    } else {
      this.currentTab = this.navigation[0].nav;
      this.$router.push({
        name: "Workflow Tools",
        params: {
          workflowId: params.workflowId,
          workflowTool: this.navigation[0].nav,
        },
      });
      // this.setBredCrumbs(this.currentTab);
      this.$forceUpdate();
    }
    this.setBredCrumbs(this.currentTab);
  },
  computed: {
    TabLabel() {
      if (this.currentTab === "workflowDetails") {
        return "Workflow";
      } else if (this.currentTab === "stateTransitions") {
        return "Status";
      } else {
        return "Trigger And Action";
      }
    },
    currentComponent() {
      return this.screeningMenuWrapper[this.$route.params.workflowTool];
    },
    isTriggersRoute() {
      return this.$route.params.workflowTool == "triggerAndActions" ||
        this.$route.params.workflowTool == "createTriggerActions"
        ? true
        : false;
    },
    getWorkflowId() {
      return this.$route.params?.workflowId;
    },
    getType() {
      return this.$route.params.type;
    },
    backRedirectionUrl() {
      // WOrkaround to redirect to the package instead of workflow admin
      if (this.$route.params.type === "case" && this.$route.params.prevPath) {
        return this.$route.params.prevPath;
      }

      if (this.$route.params.workflowTool === "createTriggerActions") {
        return `/workflow-engine/${this.$route.params.type}/edit/triggerAndActions/${this.getWorkflowId}`;
      } else {
        return `/workflow-engine/${this.getType}`;
      }
    },
    showCreateButton() {
      console.log("check", checkPermission('workflow.create'));
      return  this.currentComponent?.name == 'triggerAndActions' &&
        this.$route.params.action != 'view'
    },
    getMode() {
      console.log("this.$route.params.action", this.$route.params.action);
      return this.$route.params.action;
    }
  },
  methods: {
    handleCreate() {
      if (this.currentComponent?.name == "triggerAndActions") {
        this.setBredCrumbs(this.currentTab);
        this.$router.push({
          name: "Workflow Tools", 
          params: {
            workflowTool: "createTriggerActions",
            workflowId: this.getWorkflowId,
          },
        });
      }
    },
    handleCurrentTab(tab, workflowId = "") {
      let paramsId = this.$route.params.workflowId;
      let id = workflowId || paramsId;
      if (this.currentTab === tab.nav) return;
      this.currentTab = tab.nav;
      this.setBredCrumbs(this.currentTab);
      this.$router.push({
        name: "Workflow Tools",
        params: { workflowId: id, workflowTool: this.currentTab },
      });
    },
    setBredCrumbs(crumb) {
      crumb =
        crumb === "workflowDetails"
          ? "Workflow Details"
          : crumb === "stateTransitions"
          ? "State Transition"
          : crumb === "triggerAndActions"
          ? "Trigger & Action"
          : "";
      (this.customBreadCrumbs = [
        { name: this.$route.params.type },
        { name: `Workflow` },
      ]),
        this.customBreadCrumbs.push({ name: crumb });
      // this.customBreadCrumbs[this.customBreadCrumbs.length] = { name: crumb };
    },
    handleSaveAndNext(menu) {
      this.navigation.forEach((res) => {
        res.disabled = false;
      });
      if (menu.from === "stateTransitions") {
        this.handleCurrentTab(this.navigation[2]);
      } else if (menu.from === "workflowDetails") {
        this.handleCurrentTab(this.navigation[1], menu.workflow_id);
      }
    },
    // async getWorkflowDetail() {
    //     try {
    //         this.workflowDetailLoading = true;
    //         const response  = await fetchStatusList('case', this.$route.params.workflowId);
    //         if(response) {
    //             console.log(response);
    //             this.
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }

    // }
  },
};
</script>

<template>
  <UISplitViewModal :config="modalConfig">
    <template v-slot:[detailsSlots.title]> Risk Score Details</template>
    <template v-slot:[detailsSlots.content]>
      <div class="w-full flex flex-col items-stretch gap-2">
          <div class="risk-grid grid grid-cols-4 bg-thead rounded-lg px-4 pr-6 py-1 font-medium">
            <p>Entity Name</p>
            <p>Risk</p>
            <p>Score</p>
          </div>

          <div
            v-for="entity in entities"
            :key="entity.id"
          >
            <div
              class="risk-grid grid grid-cols-4 items-center bg-gray-200 px-4 pr-6 py-1 font-medium cursor-pointer"
              :class="{
                'rounded-t-lg': riskCategoriesView[entity.id],
                'rounded-lg': !riskCategoriesView[entity.id],
              }"
              @click="showRiskCategories(entity.id)"
            >
              <p>{{ entity.name }}</p>
              <p :style="{ color: entity.label_color }">
                {{ entity.label_name }}
              </p>
              <p>{{ entity.score }}</p>
              <font-awesome-icon
                icon="chevron-down"
                class="ml-auto text-main text-sm"
                :class="{ 'rotate': riskCategoriesView[entity.id] }"
              />
            </div>

            <div
              v-if="riskCategoriesView[entity.id]"
              class="p-2 border border-solid border-gray-100 rounded-b-lg flex flex-col items-stretch gap-1 w-full"
            >
              <div
                v-for="category in entity.categories"
                :key="`${entity.id}-${category.id}`"
                class="risk-grid grid grid-cols-3 auto-rows-fr items-center bg-gray-100 rounded-lg px-4 py-1 cursor-pointer"
                @click="showCategoryBreakdown(category.id, category)"
              >
                <p class="flex items-center gap-2">
                  <span
                    :style="{ backgroundColor: category.color }"
                    class="w-4 h-4 rounded-full flex-shrink-0"
                  />
                  <span>{{ category.name }}</span>
                </p>
                <p :style="{ color: category.label_color }">
                  {{ category.label_name }}
                </p>
                <p>{{ category.score }}</p>
                <template v-if="category.assessments.length > 0">
                  <font-awesome-icon
                    v-if="category.id === selectedCategoryId"
                    icon="chevron-left"
                    class="ml-auto text-main text-sm"
                  />
                  <font-awesome-icon
                    v-else
                    icon="chevron-right"
                    class="ml-auto text-main text-sm"
                  />
                </template>
              </div>
            </div>
        </div>

        <div class="risk-grid grid auto-rows-fr px-4 pr-6 py-1 bg-gray-300 rounded-lg font-medium">
            <p>Total</p>
            <p :style="{ color: getRiskScoreData?.label_color }">
              {{ getRiskScoreData?.label_name }}
            </p>
            <p>{{ overallRiskScore }}</p>
        </div>

        <div v-if="!isRiskCategoryBreakdownShown" class="flex justify-end mt-4">
          <Button
            text="Ok"
            type="primary"
            @click="closeModal"
          />
        </div>
      </div>
    </template>

    <template v-slot:[breakdownSlots.title]> Entity's Risk Category Score Details</template>
    <template v-slot:[breakdownSlots.content]>
        <div class="flex flex-col w-full">
          <div class="breakdown-grid grid  items-center bg-gray-200 px-4 pr-6 py-1 rounded-t-lg font-medium">
            <p>{{ categoryBreakdownName }}</p>
            <p>{{ categoryBreakdownScore }}</p>
          </div>

          <div class="p-2 border border-solid border-gray-100 rounded-b-lg flex flex-col items-stretch gap-1 w-full">
            <div class="breakdown-grid grid  auto-rows-fr items-center bg-gray-300 rounded-lg px-4 py-1 text-sm">
              <p>Risk assessment name</p>
              <p>Score</p>
            </div>
            <div
              v-for="assessment in categoryBreakdownAssessments"
              :key="assessment.id"
              class="breakdown-grid grid auto-rows-fr items-center bg-gray-100 rounded-lg px-4 py-1"
            >
              <p>{{ assessment.name }}</p>
              <p>{{ assessment.score }}</p>
            </div>
          </div>
          <div class="flex justify-end mt-auto">
            <Button
              text="Ok"
              type="primary"
              @click="resetRiskCategory"
            />
          </div>
        </div>
    </template>
  </UISplitViewModal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Button from "@/components/button";

export default {
  name: "CaseScoreBreakdown",

  components: {
    Button,
  },

  props: {
    caseTypeEntityId: {
      type: String,
      required: true,
    },
    caseEntities: {
      type: Array,
      required: true,
    },
  },

    data() {
      return {
        riskCategoriesView: {},
        riskCategoryBreakdownView: {},
        selectedCategoryId: null,
        isRiskCategoryBreakdownShown: false,
      };
    },

    computed: {
      ...mapGetters({
        getRiskScoreData: "CaseCreationStore/getRiskScoreData",
        isRiskScoreModalShown: "CaseCreationStore/getIsRiskScoreModalShown",
      }),

      caseEntity() {
        return this.caseEntities?.find(ce => ce.id === this.caseTypeEntityId) ?? null;
      },

      entities() {
        return (this.getRiskScoreData?.entities || []).filter(ce => ce.id !== this.caseTypeEntityId); 
      },

      overallRiskScore() {
        return this.getRiskScoreData?.score ?? 0;
      },

      categoryBreakdownName() {
        return this.riskCategoryBreakdownView[this.selectedCategoryId]?.name;
      },

      categoryBreakdownScore() {
        return this.riskCategoryBreakdownView[this.selectedCategoryId]?.score;
      },

      categoryBreakdownAssessments() {
        return this.riskCategoryBreakdownView[this.selectedCategoryId]?.assessments;
      },

      modalConfig() {
        return [
          {
            name: "details",
            show: this.isRiskScoreModalShown,
            onClose: this.closeModal,
          },
          {
            name: "breakdown",
            show: this.isRiskCategoryBreakdownShown,
            onClose: this.resetRiskCategory,
          }
        ];
      },

      detailsSlots() {
        const [ details ] = this.modalConfig;
        return {
          title: `${details.name}:title`,
          content: `${details.name}:content`,
        };
      },

      breakdownSlots() {
        const [ , breakdown ] = this.modalConfig;
        return {
          title: `${breakdown.name}:title`,
          content: `${breakdown.name}:content`,
        };
      },
    },

    methods: {
      ...mapActions({
        toggleRiskScoreModal: "CaseCreationStore/toggleRiskScoreModal",
      }),
  
      showRiskCategories(entity_id) {
        this.$set(this.riskCategoriesView, entity_id, !this.riskCategoriesView[entity_id]);
      },

      showCategoryBreakdown(category_id, category) {
        if (!category.assessments.length) return;
        if (category_id === this.selectedCategoryId) {
          return this.resetRiskCategory();
        }

        this.isRiskCategoryBreakdownShown = true;
        this.selectedCategoryId = category_id;
        this.$set(this.riskCategoryBreakdownView, category_id, category);
      },

      closeModal() {
        this.toggleRiskScoreModal();
        this.riskCategoriesView = {};
        this.resetRiskCategory();
      },

      resetRiskCategory() {
        this.selectedRiskCategory = {};
        this.isRiskCategoryBreakdownShown = false;
        this.selectedCategoryId = null;
      },
    }
}
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}

.risk-grid {
  grid-template-columns: 1fr 100px 100px 25px;

  & > * {
    &:not(:last-child) {
      @apply pr-1;
    }
    &:not(:first-child) {
      @apply pl-1;
    }
  }
}

.breakdown-grid {
  grid-template-columns: 1fr 75px;
}

.risk-grid,
.breakdown-grid {
    p, span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>

import {RISK_CATERGORIES} from "@shared/utils/constants";
import Tag from "@shared/components/tag";
import Radios from "@shared/components/radios";
import Button from "@shared/components/button";
import NewMultiSelect from "@shared/components/multiselect";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "neo-tags",
    components: {
        // "neo-multi-select": MultiSelect,
        "neo-multiselect": NewMultiSelect,
        "neo-tag": Tag,
        "neo-radios": Radios,
        "neo-button": Button,
        multiselect: VueMultiselect,
    },
    props: {
        tags: [Array],
        add: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: false,
        },
        ragstatus: {
            type: String,
        },
        ragradio: {
            type: Boolean,
            default: true,
        },
        maxHeight: {
            type: Number,
            default: 300,
        },
        openDirection: {
            default: "",
            type: String,
        },
        showRadio: {
            type: Boolean,
            default: false,
        },
    },
    // props: ["tags", "add", "interactive"],
    data() {
        return {
            addNew: false,
            categories: [],
            selectedCategory: [],
            categoriesList: RISK_CATERGORIES,
            radioOptions: ["Red", "Amber"],
            radioValue: "",
        };
    },
    watch: {
        tags(val) {
            if (typeof val === "object") {
                this.categories = val.map((el) => {
                    return {category: el.tag};
                });
            } else {
                this.categories = val;
            }
        },
    },
    computed: {
        getTags() {
            if (this.categories.length > 0) {
                let cat = this.categories.map((el) => {
                    return el.category;
                });
                return cat;
            } else {
                if (typeof this.tags === "object") {
                    return this.tags.map((el) => {
                        return el.category;
                    });
                } else {
                    return this.tags;
                }
            }
        },
        getCategoriesList() {
            return this.$store.getters.getRiskCategories;
        },
        ragRadioCheck() {
            let categoriesList = this.getCategoriesList.find((cat) => cat.group === "Risk Categories");
            let radio = false;
            if (categoriesList?.categories) {
                radio = categoriesList?.categories.some((elem) => this.categories.some((cat) => cat.category === elem.category));
            }
            return radio;
        },
        ragStatusCheck() {
            return ((this.add && this.ragradio && this.getTags.length > 0) || this.showRadio) && this.ragRadioCheck;
        },
    },
    created() {
        if (this.tags)
            this.categories = this.tags.map((el) => {
                return {category: el.tag};
            });
        this.radioValue = this.ragstatus ? this.ragstatus : "Red";
    },
    mounted() {},
    methods: {
        onAddNew() {
            this.newCategory = "";
            this.addNew = true;
        },
        onSelect(item) {
            this.categories.push(item);
            this.$emit("newCategory", item);
            this.$emit(
                "changes",
                this.categories.map((el) => {
                    return {tag: el.category};
                })
            );

            this.newCategory = "";
            this.addNew = false;
        },
        onRemoveTag(tag) {
            const index = this.categories.findIndex((item) => item.category === tag);
            this.categories.splice(index, 1);
            this.$emit(
                "changes",
                this.categories.map((el) => {
                    return {tag: el.category};
                })
            );
            this.$emit("remove", tag);
        },
        onCancle() {
            this.newCategory = "";
            this.addNew = false;
        },
        onRadioChange() {
            this.$emit("ragChange", this.radioValue);
        },
        getTagColor(tag) {
            const groupName = this.getCategoriesList.find((catList) => catList.categories.some((elem) => elem.category === tag));
            if (groupName?.group === "Risk Categories") {
                if (this.radioValue && this.radioValue.toLowerCase() === "amber") {
                    return {background: "#FFF6DE", textColor: "#D19D00", iconBg: "#FFDC86"};
                } else {
                    this.radioValue = "Red";
                    return {background: "var(--color-danger-bg)", textColor: "var(--color-danger)", iconBg: "#faccd4"};
                }
            } else if (groupName?.group === "Non-Risk Categories") {
                return {background: "rgb(220 252 231)", textColor: "rgb(21 128 61)", iconBg: "rgb(134 239 172)"};
            } else {
                return {};
            }
        },
    },
};

import Radio from "@shared/components/radio";
export default {
    name: "neo-radios",
    components: {
        "neo-radio": Radio,
    },
    props: {
        options: {
            type: Array,
        },
        value: String,
        label: String,
        size: {
            type: String,
            default: "12px",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            controls: {},
        };
    },
    computed: {},
    mounted() {
        this.controls = this.options.reduce((map, option) => ({...map, [option]: false}), {});
        this.controls["value"] = true;
        if (this.value) {
            this.controls[this.value] = this.options.includes(this.value);
        }
    },
    methods: {
        onInput(option) {
            Object.keys(this.controls).forEach((key) => (this.controls[key] = false));
            this.controls[option] = true;
            this.$emit("input", option);
        },
    },
};

import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";

export class EntityForm {
	constructor(caseFormData = null) {
		this.formData = caseFormData;
		this.isSubmitted = false;
	}
	submit(payload = true) {
		this.isSubmitted = payload;
	}
	populateForm(payload = null) {
		this.formData = payload;
	}
}

/**
 * @typedef {Object} CaseEntityData
 * @property {Object[]} case_entity_info
 * @property {Object} case_entity_type_info
 * @property {Object} case_entities
 * 
 * @typedef {Object[]} CaseEntities
 * 
 * @typedef {Object} EntitiesCounterTotal
 * @property {Number} max
 * @property {Number} count
 * @property {Number} remaining
 * 
 * @typedef {Object} EntitiesCounter
 * @property {Object[]} entities
 * @property {EntitiesCounterTotal} total
 * 
 * @param {CaseEntityData} caseEntityData 
 * @param {CaseEntities} caseEntities 
 * @returns {EntitiesCounter} entities result counter
 */
export const getEntitiesCounter = (caseEntityData, caseEntityList) => {
	if (!caseEntityData) return null;

	const {
		case_entity_type_info,
		case_entity_info,
		case_entities,
	} = caseEntityData;

	const caseEntities = caseEntityList ?? case_entities;

	if (!(case_entity_type_info && case_entity_info && caseEntities)) return null;

	const caseTypeId = (Object.entries(case_entity_type_info).find(([, value]) => value.name === ENTITY_TYPE_NAMES.CASE) ?? [])[0];

		// find entity with 'Case' type:
		const caseEntity = caseEntities.find(
			(el) => el.entity_type_id === caseTypeId
		);

		const resultCounter = {};
		// consider minimum counts of other entities
		const totalMinCount = Object.values(case_entity_type_info).reduce(
			(result, current) => {
				return result + current.min;
			},
			0
		);
		let maxEntitiesCount = case_entity_info.max_case_entity_count;
		// consider "Case" entity
		const totalCount = caseEntities.length;
		let totalRemaining = maxEntitiesCount - totalCount;

		resultCounter.entities = Object.entries(case_entity_type_info)
			.filter(([key]) => key !== caseEntity.entity_type_id)
			.reduce((result, [key, entityInfo]) => {
				const otherTotalMinCount = totalMinCount - entityInfo.min;
				result.push({
					...entityInfo,
					id: key,
					max: entityInfo.max,
					calculatedMax: maxEntitiesCount - otherTotalMinCount,
					min: entityInfo.min,
					count: caseEntities.filter((el) => el.entity_type_id === key)
						.length, // consider other entities' min count that must be added to the case
					remaining: Math.min(
						entityInfo.max -
							caseEntities.filter((el) => el.entity_type_id === key)
								.length,
						totalRemaining
					),
				});
				return result;
			}, []);

		resultCounter.total = {
			max: maxEntitiesCount,
			count: totalCount,
			remaining: totalRemaining,
		};

		return resultCounter;
}

/**
 * @typedef {Object} EntityToAdd
 * @property {String} id
 * @property {String} name
 * @property {Object} entity
 * @property {Boolean} disabled
 * 
 * @param {EntitiesCounter} entitiesCounter 
 * @returns {EntityToAdd[]} list of entities to add
 */
export const getEntitiesToAdd = (entitiesCounter) => {
	if (!entitiesCounter?.entities) return [];

	const isTotalMaxReached = entitiesCounter?.total.count >= entitiesCounter?.total.max;
	// uncomment and use if we should consider other entity typesx counts:
	// const isDisabled = el.count >= el.calculatedMax // max hasn't been reached but creation of new entity will lead to invalid entities structure
	return (
		entitiesCounter.entities
			.filter((el) => el.count < el.max) // remove if we need to handle item's visibility within child components
			.map((el) => ({
				id: el.id,
				name: el.name,
				entity: el,
				disabled: isTotalMaxReached, // max hasn't been reached but creation of new entity will lead to invalid entities structure
				// visible: el.count < el.max, // add instead of .filter() if we need to handle item's visibility within child components
			}))
	);
}
import axios from "./axios";

export async function createRiskAssessment(payload) {
  const url = `/risk-assessment`;
  return await axios.post(url, payload);
}

export async function updateRiskAssessment(id, payload) {
  const url = `/risk-assessment/${id}`;
  return await axios.put(url, payload);
}

export async function fetchRiskAssessmentDetail(configId) {
  let url = configId ? `risk-assessment?config_id=${configId}` :`risk-assessment`;
  return await axios.get(url);
}

export async function fetchCategoryList(id) {
  const url = `risk-assessment/${id}/category`;
  return await axios.get(url);
}

export async function createRiskCategory(id, payload) {
  const url = `risk-assessment/${id}/category`;
  return await axios.post(url, payload);
}
export async function updateRiskCategory(riskAssessmentId, riskCategoryId, payload) {
  const url = `risk-assessment/${riskAssessmentId}/category/${riskCategoryId}`;
  return await axios.put(url, payload);
  
}

export async function updateDefaultRiskCategory(id,payload) {
  const url = `/risk-assessment/${id}`;
  return await axios.put(url, payload);
}


export async function deleteRiskCategory(payload) {
  const url = `risk-assessment/${payload.riskAssessmentId}/category/${payload.riskCategoryId}`;
  return await axios.delete(url);
  
}
export async function fetchRiskAssessmentMasterColor() {
  const url = `/risk-assessment/master/color`;
  return await axios.get(url);
}

export async function createRiskLabel(id, payload) {
  const url = `risk-assessment/${id}/label`;
  return await axios.post(url, payload);
}

export async function getRiskLabel(id) {
  const url = `risk-assessment/${id}/label`;
  return await axios.get(url);
}

export async function updateRiskLabel(id, risk_label_id, payload) {
  const url = `risk-assessment/${id}/label/${risk_label_id}`;
  return await axios.put(url, payload);
}

export async function deleteRiskLabel(payload) {
  const url = `risk-assessment/${payload.risk_assessment_id}/label/${payload.risk_label_id}`;
  return await axios.delete(url);
  
}
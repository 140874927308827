<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { appList } from "@shared/components/form-builder/utils/index.js";

export default {
  name: 'App',

  data() {
    return {
      currentApp: appList.TENANT,
    }
  },

  provide () {
    return {
      currentApp: this.currentApp,
      isCurrentAppAllowed: this.isCurrentAppAllowed,
    }
  },

  methods: {
    isCurrentAppAllowed(list) {
      return list.includes(this.currentApp);
    },
  },
}
</script>

<style>
#app {
  height: 100vh;
}
</style>

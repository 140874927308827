<template>
    <div class="print-d-block flex flex-col space-y-4 overflow-y-auto px-2 overflow-x-hidden">
        <div @click="handleExpandAll" class="print-hidden text-blue-600 ml-auto cursor-pointer hover:text-blue-400 inline-flex">
            <template v-if="!adverseCard.expandedPanel">Expand All</template>
            <template v-else>Collapse All</template>
        </div>
        <!-- <div class="text-base text-gray-700 mb-2 font-bold mt-4" v-if="getMasterFields(adverseCard.doc.fields).length > 0 && adverseCard.doc">Details</div>
        <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2" v-if="getMasterFields(adverseCard.doc.fields).length > 0 && adverseCard.doc">
            <div v-for="field in getMasterFields(adverseCard.doc.fields)" class="break-words" :key="field.value">
                <span class="font-medium">{{ field.name }}: </span>
                <template v-if="urlFields.includes(field.name.toLowerCase())">
                    <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                </template>
                <template v-else>{{ field.value }}</template>
            </div>
        </div> -->

        <div class="mb-2">
            <template v-if="adverseCard.doc && getMasterFields(adverseCard.doc.fields).length > 0">
                <div @click="fetchMoreInfo(adverseCard, 'detailsComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Details</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.detailsComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.detailsComplyExpanded">
                    <!-- <span v-for="keyword in adverseCard.keywords" :key="keyword.KEYWORD">
                        {{ get(keyword, "FULL_NAME_OF_SOURCE", "N/A") }}
                    </span> -->
                    <div class="flex space-x-2" v-for="(field, index) in getMasterFields(adverseCard.doc.fields)" :key="field.value">
                        <template v-if="field.name == 'Countries'">
                            <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400" :identifier="`['data']['fields'][${index}]['name']`">
                                {{ field.name }}
                            </div>
                            <div class="w-2/3 lg:h-3/4 pl-3 pr-3 py-2">
                                <div class="flex justify-between items-center">
                                    <span class="space-y-2" :identifier="`['data']['fields'][${index}]['value']`">
                                        <!--<template v-if="urlFields.includes(field.name.toLowerCase())">
                                        <a class="no-underline tlext-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                    </template>-->
                                        <template>{{ field.value }}</template>
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Details</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.detailsComplyExpanded"></div>
            </template>
        </div>

        <div class="mb-2">
            <template v-if="adverseCard.doc.associates">
                <div @click="fetchMoreInfo(adverseCard, 'associatesComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Associates</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.associatesComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.associatesComplyExpanded">
                    <div class="gap-y-1 gap-x-2 mt-2" v-if="adverseCard.doc.associates">
                        <div v-for="(associate, index) in adverseCard.doc.associates" class="break-words capitalize" :key="associate.association">
                            <div class="flex">
                                <div class="inline-block flex-wrap mb-1">
                                    <span class="font-medium break-words" :identifier="`['data']['associates'][${index}]['name']`">
                                        {{ associate.name }}
                                    </span>
                                    <span :identifier="`['data']['associates'][${index}]['association']`"> ({{ associate.association }}) </span>
                                </div>
                                <neo-analyse class="ml-2" v-if="associate.name" source="name" :value="associate.name"></neo-analyse>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Associates</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.associatesComplyExpanded"></div>
            </template>
        </div>

        <div class="mb-2">
            <template v-if="checkSanction(adverseCard.doc.source_notes)">
                <div @click="fetchMoreInfo(adverseCard, 'sanctionsComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Sanctions</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.associatesComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.sanctionsComplyExpanded">
                    <div class="grid grid-cols-1 gap-y-2 gap-x-2" v-if="checkSanction(adverseCard.doc.source_notes)">
                        <template v-for="(i, j) in adverseCard.doc.source_notes">
                            <article v-if="i.aml_types && i.aml_types.includes('sanction')" :key="j">
                                <template>
                                    <div class="border border-solid border-gray-300 rounded-md p-2">
                                        <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium" :identifier="`['data']['source_notes']['${j}']['name'] ['data']['source_notes']['${j}']['url'] `">
                                            {{ i.name }}
                                        </a>
                                        <div class="mt-1 text-sm">
                                            Listed:
                                            <span class="font-medium" :identifier="`['data']['source_notes']['${j}']['listing_started_utc'] ['data']['source_notes']['${j}']['listing_ended_utc']`"> {{ parseDate(i.listing_started_utc) }} - {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }} </span>
                                            <span class="ml-8" v-if="i.listing_ended_utc">
                                                Removed:
                                                <span class="font-medium" :identifier="`['data']['source_notes']['${j}']['listing_ended_utc']`">
                                                    {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                            <div v-for="(field, index) in getFilteredFields(adverseCard.doc.fields, j)" class="break-words" :key="field.value + '0' + index">
                                                <span class="font-medium" :identifier="`['data']['fields'][${index}]['name']`">{{ field.name }}: </span>
                                                <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                    <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :identifier="`['data']['fields'][${index}]['value']`" :href="field.value">{{ field.value }}</a>
                                                </template>
                                                <template v-else>
                                                    <span :identifier="`['data']['fields'][${index}]['value']`" class="">
                                                        {{ field.value }}
                                                    </span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </article>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Sanctions</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.sanctionsComplyExpanded"></div>
            </template>
        </div>
        <!-- <div class="text-base text-gray-700 mb-2 font-bold mt-4" v-if="checkSanction(adverseCard.doc.source_notes)">Sanctions</div>
        <div class="print-d-block grid grid-cols-1 gap-y-2 gap-x-2" v-if="checkSanction(adverseCard.doc.source_notes)">
            <article v-for="(i, j) in adverseCard.doc.source_notes" :key="j">
                <template v-if="i && i.aml_types[0] == 'sanction'">
                    <div class="border border-solid border-gray-300 rounded-md p-2">
                        <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">{{ i.name }}</a>
                        <div class="mt-1 text-sm">
                            Listed: <span class="font-medium">{{ parseDate(i.listing_started_utc) }} - {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }}</span>
                            <span class="ml-8" v-if="i.listing_ended_utc">
                                Removed: <span class="font-medium">{{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }}</span>
                            </span>
                        </div>
                        <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                            <div v-for="field in getFilteredFields(adverseCard.doc.fields, j)" class="break-words" :key="field.value">
                                <span class="font-medium">{{ field.name }}: </span>
                                <template v-if="urlFields.includes(field.name.toLowerCase())">
                                    <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                </template>
                                <template v-else>{{ field.value }}</template>
                            </div>
                        </div>
                    </div>
                </template>
            </article>
        </div> -->

        <div class="mb-2">
            <template v-if="checkPepList(adverseCard.doc.source_notes)">
                <div @click="fetchMoreInfo(adverseCard, 'warningComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">PEP / Warnings Details</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.warningComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.warningComplyExpanded">
                    <div class="gap-y-1 gap-x-2 mt-2" v-if="checkPepList(adverseCard.doc.source_notes)">
                        <template v-for="(i, j) in adverseCard.doc.source_notes">
                            <article :key="j" v-if="i.aml_types && i.aml_types.includes('warning')">
                                <template>
                                    <div class="border border-solid border-gray-300 rounded-md p-2 mb-3">
                                        <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium" :identifier="`['data']['source_notes']['${j}']['name'] ['data']['source_notes']['${j}']['url']`">
                                            {{ i.name }}
                                        </a>
                                        <div class="mt-1 text-sm">
                                            Listed:
                                            <span class="font-medium" :identifier="`['data']['source_notes']['${j}']['listing_started_utc'] ['data']['source_notes']['${j}']['listing_ended_utc']`"> {{ parseDate(i.listing_started_utc) }} - {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }} </span>
                                            <span class="ml-8" v-if="i.listing_ended_utc">
                                                Removed:
                                                <span class="font-medium" :identifier="`['data']['source_notes']['${j}']['listing_ended_utc']`">{{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }}</span>
                                            </span>
                                        </div>
                                        <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                            <div v-for="(field, index) in getFilteredFields(adverseCard.doc.fields, j)" class="break-words" :key="field.value + index">
                                                <span class="font-medium" :identifier="`['data']['fields'][${index}]['name']`"> {{ field.name }}: </span>
                                                <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                    <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :identifier="`['data']['fields'][${index}]['value']`" :href="field.value">
                                                        {{ field.value }}
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <span class="" :identifier="`['data']['fields'][${index}]['value']`">
                                                        {{ field.value }}
                                                    </span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </article>
                        </template>
                    </div>
                </div>
            </template>
            <template>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">PEP / Warnings Details</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.warningComplyExpanded"></div>
            </template>
        </div>

        <!-- <div class="text-base text-gray-700 mb-2 font-bold mt-4" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-1') && adverseCard.doc">Pep Class 1</div>
        <div class="print-d-block grid grid-cols-1 gap-y-2 gap-x-2" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-1')">
            <section v-for="(i, j) in adverseCard.doc.source_notes" :key="j">
                <template v-if="i.aml_types[0] == 'pep-class-1'">
                    <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                        <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">{{ i.name }}</a>
                        <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                            <div v-for="field in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name" class="break-words">
                                <span class="font-medium">{{ field.name }}: </span>
                                <template v-if="urlFields.includes(field.name.toLowerCase())">
                                    <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                </template>
                                <template v-else>{{ field.value }}</template>
                            </div>
                        </div>
                    </div>
                </template>
            </section>
        </div> -->
        <div class="mb-2">
            <template v-if="adverseCard.doc && (checkPep4(adverseCard.doc.source_notes, 'pep-class-1') || checkPep4(adverseCard.doc.source_notes, 'pep-class-2') || checkPep4(adverseCard.doc.source_notes, 'pep-class-3') || checkPep4(adverseCard.doc.source_notes, 'pep-class-4') || checkPep4(adverseCard.doc.source_notes))">
                <div @click="fetchMoreInfo(adverseCard, 'pepDetails')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Pep Listing</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.pepDetailsExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.pepDetailsExpanded">
                    <div class="text-base mb-3 mt-4 font-bold">Pep</div>
                    <template v-if="adverseCard.doc">
                        <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2">
                            <section v-for="(i, j, index) in adverseCard.doc.source_notes" :key="j">
                                <template v-if="i.name == 'ComplyAdvantage PEP Data'">
                                    <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                                        <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium" :identifier="`['data']['source_notes']['${j}']['name']`">
                                            {{ i.name }}
                                        </a>
                                        <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                            <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name + '___' + idx" class="break-words">
                                                <span class="font-medium">{{ field.name }}: </span>
                                                <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                    <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value" :identifier="`['data']['source_notes']['${j}']['value']`">{{ field.value }}</a>
                                                </template>
                                                <template v-else>{{ field.value }}</template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <template v-if="Object.keys(adverseCard.doc.source_notes).length - 1 == index && checkComplyAdvantage(adverseCard.doc.source_notes) == 0"> No data found! </template>
                                </template>
                            </section>
                        </div>
                    </template>
                    <template v-else> No data found! </template>
                    <div class="text-base my-3 font-bold">Pep Class 1</div>
                    <!-- <div class="mb-2 flex space-x-2" v-for="field in getMasterFields(adverseCard.doc.fields)" :key="field.value">
                        <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400" v-if="field.name!='Countries'">{{ field.name }}</div>
                        <div class="w-2/3 lg:h-3/4 pl-3 pr-3 py-2" v-if="field.name!='Countries'">
                            <div class="flex justify-between items-center">
                                <span class="space-y-2">
                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                    </template>
                                    <template v-else>{{ field.value }}</template>
                                </span>
                            </div>
                        </div>
                    </div> -->
                    <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-1') && adverseCard.doc">
                        <div class="grid grid-cols-1 mb-2 gap-y-2 gap-x-2" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-1')">
                            <template v-for="(i, j) in adverseCard.doc.source_notes">
                                <section v-if="i.aml_types && i.aml_types.includes('pep-class-1') && i.name != 'ComplyAdvantage PEP Data'" :key="j">
                                    <template>
                                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                                            <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">{{ i.name }}</a>
                                            <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                                <div v-for="(field, j) in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name" class="break-words">
                                                    <span class="font-medium" :identifier="`['data']['source_notes']['${j}']['name']`">{{ field.name }} {{ adverseCard.doc.source_notes.length }}: </span>
                                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :identifier="`['data']['source_notes']['${j}']['value']`" :href="field.value">
                                                            {{ field.value }}
                                                        </a>
                                                    </template>
                                                    <template v-else>{{ field.value }}</template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </section>
                                <template v-else>
                                    <template v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-1') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-1')">No data found!</template>
                                </template>
                            </template>
                        </div>
                    </template>
                    <template v-else> No data found! </template>

                    <div class="text-base mb-3 mt-4 font-bold">Pep Class 2</div>
                    <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-2') && adverseCard.doc">
                        <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-2')">
                            <template v-for="(i, j) in adverseCard.doc.source_notes">
                                <section v-if="i.aml_types && i.aml_types.includes('pep-class-2') && i.name != 'ComplyAdvantage PEP Data'" :key="j">
                                    <template>
                                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                                            <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium" :identifier="`['data']['source_notes']['${j}']['name']`">
                                                {{ i.name }}
                                            </a>
                                            <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                                <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name + '___' + idx" class="break-words">
                                                    <span class="font-medium">{{ field.name }}: </span>
                                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :identifier="`['data']['fields'][${idx}}]['value']`" :href="field.value">{{ field.value }}</a>
                                                    </template>
                                                    <template v-else>{{ field.value }}</template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </section>
                                <template v-else>
                                    <template v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-2') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-2')">No data found!</template>
                                </template>
                            </template>
                        </div>
                    </template>
                    <template v-else> No data found! </template>

                    <div class="text-base mb-3 mt-4 font-bold">Pep Class 3</div>
                    <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-3') && adverseCard.doc">
                        <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-3')">
                            <template v-for="(i, j) in adverseCard.doc.source_notes">
                                <section v-if="i.aml_types && i.aml_types.includes('pep-class-3') && i.name != 'ComplyAdvantage PEP Data'" :key="j">
                                    <template>
                                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                                            <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium" :identifier="`['data']['source_notes']['${j}']['name']`">
                                                {{ i.name }}
                                            </a>
                                            <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                                <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name + idx" class="break-words">
                                                    <span class="font-medium">{{ field.name }}: </span>
                                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value" :identifier="`['data']['fields'][${idx}]['value']`">
                                                            {{ field.value }}
                                                        </a>
                                                    </template>
                                                    <template v-else>{{ field.value }}</template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </section>
                                <template v-else>
                                    <template v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-3') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-3')">No data found!</template>
                                </template>
                            </template>
                        </div>
                    </template>
                    <template v-else> No data found! </template>

                    <div class="text-base mb-3 mt-4 font-bold">Pep Class 4</div>
                    <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-4') && adverseCard.doc">
                        <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-4')">
                            <template v-for="(i, j) in adverseCard.doc.source_notes">
                                <section v-if="i.aml_types && i.aml_types.includes('pep-class-4') && i.name != 'ComplyAdvantage PEP Data'" :key="j">
                                    <template>
                                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                                            <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium" :identifier="`['data']['source_notes']['${j}']['name']`">
                                                {{ i.name }}
                                            </a>
                                            <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                                <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name + idx" class="break-words">
                                                    <span class="font-medium" :identifier="`['data']['fields'][${idx}]['name']`">{{ field.name }}: </span>
                                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                                    </template>
                                                    <template v-else>{{ field.value }}</template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </section>
                                <template v-else>
                                    <template v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-4') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-4')">No data found!</template>
                                </template>
                            </template>
                        </div>
                    </template>
                    <template v-else> No data found! </template>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">PEP Listings</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.pepDetailsExpanded"></div>
            </template>
        </div>
        <!-- 
        <div class="mb-2">
            <div ref="refpep2Comply" @click="fetchMoreInfo(adverseCard, 'pep2Comply')" class="print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                <div class="text-lg font-medium">Pep Class 2</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{ 'rotate-180': adverseCard.pep2ComplyExpanded }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div class="p-2" v-if="adverseCard.pep2ComplyExpanded">
                <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-2') && adverseCard.doc">
                    <div class="print-d-block grid grid-cols-1 gap-y-2 gap-x-2" v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-2')">
                        <section v-for="(i, j) in adverseCard.doc.source_notes" :key="j">
                            <template v-if="i.aml_types[0] == 'pep-class-2'">
                                <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                                    <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">{{ i.name }}</a>
                                    <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                        <div v-for="field in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name" class="break-words">
                                            <span class="font-medium">{{ field.name }}: </span>
                                            <template v-if="urlFields.includes(field.name.toLowerCase())">
                                                <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                            </template>
                                            <template v-else>{{ field.value }}</template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </section>
                    </div>
                </template>
                <template v-else> No data found! </template>
            </div>
        </div>

        <div class="mb-2">
            <div ref="refpep3Comply" @click="fetchMoreInfo(adverseCard, 'pep3Comply')" class="print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                <div class="text-lg font-medium">Pep Class 3</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{ 'rotate-180': adverseCard.pep3ComplyExpanded }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div class="p-2" v-if="adverseCard.pep3ComplyExpanded">
               
            </div>
        </div>

        <div class="mb-2">
            <div ref="refpep4Comply" @click="fetchMoreInfo(adverseCard, 'pep4Comply')" class="print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                <div class="text-lg font-medium">Pep Class 4</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{ 'rotate-180': adverseCard.pep4ComplyExpanded }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div class="p-2" v-if="adverseCard.pep4ComplyExpanded">
                
            </div>
        </div> -->

        <div class="mb-2">
            <template v-if="checkFitnessProbityList(adverseCard.doc.source_notes)">
                <div @click="fetchMoreInfo(adverseCard, 'fitnessComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Fitness Probity</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.fitnessComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.fitnessComplyExpanded">
                    <article v-for="(i, j) in adverseCard.doc.source_notes" :key="j" class="mb-2">
                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i.aml_types && i.aml_types.includes('fitness-probity')">
                            <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">{{ i.name }}</a>
                            <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                                <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)" class="break-words" :key="field.value + idx">
                                    <span class="font-medium" :identifier="`['data']['fields'][${idx}]['name']`">{{ field.name }}: </span>
                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :identifier="`['data']['fields'][${idx}]['value']`" :href="field.value">
                                            {{ field.value }}
                                        </a>
                                    </template>
                                    <template v-else>{{ field.value }}</template>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Fitness Probity</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.fitnessComplyExpanded"></div>
            </template>
        </div>

        <div class="mb-2">
            <template v-if="adverseCard.doc.assets">
                <div @click="fetchMoreInfo(adverseCard, 'imagesComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Assets</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.imagesComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="px-2 headingBlock-sibling" v-if="adverseCard.imagesComplyExpanded">
                    <div class="py-4" v-if="getImages(adverseCard.doc.assets).images.length">
                        <h5 class="text-sm text-gray-600">Images</h5>
                        <img referrerpolicy="no-referrer" class="h-60" :identifier="`['data']['assets'][${idx}]['images']['url']`" v-for="(image, idx) in getImages(adverseCard.doc.assets).images" :src="image.url" :key="image.url + idx" />
                    </div>
                    <div class="py-4" v-if="getImages(adverseCard.doc.assets).pdf.length">
                        <h5 class="text-sm text-gray-600">Documents</h5>
                        <a :href="assets.public_url" referrerpolicy="no-referrer" :identifier="`['data']['assets'][${idx}]['pdf']['public_url']`" target="_blank" class="text-brand hover:text-purple-700 block mb-2" :key="assets.public_url + idx" v-for="(assets, idx) in getImages(adverseCard.doc.assets).pdf">
                            {{ assets.public_url }}
                        </a>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Assets</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.imagesComplyExpanded"></div>
            </template>
        </div>

        <div class="mb-2">
            <template v-if="isMedia && getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media').length > 0">
                <div @click="fetchMoreInfo(adverseCard, 'adverseComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Adverse Media List</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.adverseComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.adverseComplyExpanded">
                    <!-- <div class="border border-solid border-gray-300 rounded-md p-2">
                        <a target="_blank" rel="noreferrer" class="text-blue-600 no-underline font-medium"> ComplyAdvantage Adverse-Media</a> -->
                    <div class="mb-2 flex space-x-2" v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media')" :key="field.name">
                        <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400" :identifier="`['data']['fields'][${idx}]['name']`">
                            {{ field.name }}
                        </div>
                        <div class="w-2/3 lg:h-3/4 pl-3 pr-3 py-2">
                            <div class="flex justify-between items-center">
                                <span class="space-y-2">
                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :identifier="`['data']['fields'][${idx}]['value']`" :href="field.value">{{ field.value }}</a>
                                    </template>
                                    <template v-else>{{ field.value }}</template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Adverse Media List</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.adverseComplyExpanded"></div>
            </template>
        </div>

        <div class="mb-2">
            <template v-if="isMedia">
                <div @click="fetchMoreInfo(adverseCard, 'mediaComply')" class="headingBlock print-d-block flex items-center py-1 px-2 bg-blue-50 mt-4 justify-between border border-solid border-blue-200 rounded cursor-pointer hover:shadow-md">
                    <div class="text-lg font-medium">Adverse Media ({{ isMedia ? sorted.length : 0 }})</div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand" :class="{'rotate-180': adverseCard.mediaComplyExpanded}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </div>
                </div>
                <div class="p-2 headingBlock-sibling" v-if="adverseCard.mediaComplyExpanded">
                    <div v-for="(media, idx) in sorted" :key="media.url + idx" class="border rounded border-solid border-gray-300 p-2 mb-2">
                        <div>
                            <!-- doc.media -->
                            <a target="_blank" rel="noreferrer" :href="media.url" class="no-underline text-blue-600 text-base font-medium" :identifier="`['data']['media'][${idx}]['title'] ['data']['media'][${idx}]['url']`">
                                {{ media.title }}
                            </a>
                        </div>
                        <div class="text-sm text-gray-500 mt-0.5" :identifier="`['data']['media'][${idx}]['date']`">Published: {{ parseDate(media.date) }}</div>
                        <div class="mt-2 text-justify" :identifier="`['data']['media'][${idx}]['snippet']`">
                            {{ media.snippet }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded">
                    <div class="text-lg font-medium">Adverse Media</div>
                </div>
                <div class="headingBlock-sibling" v-if="adverseCard.mediaComplyExpanded"></div>
            </template>
        </div>
        <!-- <div class="text-base text-gray-700 mb-2 font-bold mt-4" v-if="isMedia">Adverse Media ({{ adverseCard.doc.media.length }})</div>
        <div class="border border-solid border-gray-300 rounded-md p-2 mb-2" v-if="isMedia && getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media').length > 0">
            <div class="print-d-block grid grid-cols-4 gap-y-1 gap-2">
                <div v-for="field in getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media')" class="break-words" :key="field.name">
                    <span class="font-medium">{{ field.name }}: </span>
                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                    </template>
                    <template v-else>{{ field.value }}</template>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import {get} from "lodash";
import neoAnalyse from "@shared/components/analyse";

export default {
    components: {neoAnalyse},
    data() {
        return {
            urlFields: ["related url", "locationurl", "picture"],
            isPrintLoader: false,
            sorted: [],
            refBtnClick: {
                refNameSection: true,
                refcontryTerritoryDetails: true,
                refDatesSection: true,
                refAddressSection: true,
                refImagesSection: true,
                refRolesSection: true,
                refRelativeCloseDowjonesSection: true,
                refProfileNotesDowjonesSection: true,
                refSourceSection: true,
                refworldcheck: true,
                refAliases: true,
                refCitizenships: true,
                refLocations: true,
                refFurtherInfo: true,
                refInformationSources: true,
                refDetailsComply: true,
                refassociatesComply: true,
                refsanctionsComply: true,
                refwarningComply: true,
                refpep1Comply: true,
                refpep2Comply: true,
                refpep3Comply: true,
                refpep4Comply: true,
                reffitnessComply: true,
                refimagesComply: true,
                refadverseComply: true,
                refmediaComply: true,
            },
        };
    },
    props: {
        expandedCard: {
            type: Object,
        },
        isResultExpanded: {
            type: Boolean,
        },
        isComparisonModeOn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isMedia() {
            return true
            // if (this.adverseCard && this.adverseCard.doc.media) {
            //     let mediaDate = [];
            //     let noDate = [];
            //     this.sorted = mediaDate.sort((a, b) => {
            //         if (a.date && b.date) {
            //             const date1 = a.date.split("T");
            //             const date2 = b.date.split("T");
            //             const aDate = new Date(date1[0]);
            //             const bDate = new Date(date2[0]);
            //             return bDate.getTime() - aDate.getTime();
            //         }
            //     });
            //     if (noDate) {
            //         noDate.map((e) => {
            //             this.sorted.push(e);
            //         });
            //     }
            //     return this.sorted
            // }
            // else{
            //     return this.sorted && this.sorted.length > 0;
            // }
            
        },
        adverseCardKey() {
            return this.expandedCard?.key;
        },
        adverseCard() {
            return this.expandedCard?.api_all_data ?? {};
        },
    },
    watch: {},
 
    mounted() {
       
    },
    methods: {
        get,
        async printPage(value, filename, noPrint) {
            this.isPrintLoader = true;
            await this.handleExpandAll(true);
            const htmlPDF = this.printData(value, filename, noPrint);
            this.$emit("updatePrintStatus", "");
            this.isPrintLoader = false;
            if (noPrint) {
                return htmlPDF;
            }
        },
        getImages(assetsList) {
            let pdf = [];
            let images = [];
            if (assetsList.length) {
                pdf = assetsList.filter((ass) => ass?.type === "pdf");
                images = assetsList.filter((ass) => ass?.type !== "pdf");
                // let others = assetsList
            }
            return {pdf, images};
        },
    },
};
</script>

<style scoped>
@media print {
    svg {
        display: none;
    }
    table {
        page-break-inside: auto !important;
    }
    table td {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table th {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    table td div {
        page-break-inside: avoid !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    div {
        page-break-inside: auto !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
        box-sizing: content-box !important;
    }
    article {
        margin-bottom: 0.5rem;
    }
    span {
        page-break-inside: auto !important;
        page-break-after: auto !important;
        page-break-before: auto !important;
    }
    .break-words {
        display: block;
    }
    .print-d-block {
        display: block !important;
    }
}
</style>
<style scoped>
/* @media print {
        table {
            page-break-inside: auto !important;
        }
        table td {
            page-break-inside: avoid !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
        }
        table th {
            page-break-inside: avoid !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
        }
        table td div{
            page-break-inside: avoid !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
        }
        div {
            page-break-inside: auto !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
            box-sizing: content-box !important;
        }
        span {
            page-break-inside: auto !important;
            page-break-after: auto !important;
            page-break-before: auto !important;
        }
        .searchIcon {
            display: none !important;
        }
        .print-d-block {
            display: block !important;
        }
        .print-d-block article{
            margin-top: 10px !important;
        }
        .print-d-block div{
            margin-top: 10px !important;
        }
        .normal-print-table tr {
            page-break-inside: avoid !important;
        }
    }     */
</style>

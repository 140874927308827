<template>
    <div class="scrollbar relative scroll-bar min-h-full flex flex-col">
        <div class="overflow-y-auto scroll-bar ">
            <table class="table-zebra shadow-md w-full whitespace-no-wrap  overflow-y-auto bg-white">
                <thead class="text-left sticky z-10 top-0">
                    <tr >
                        <th class="py-2 px-3 border-b align-text-top text-white-text bg-thead" v-for="(col, idx) in columns" :key="idx">
                            <div class="flex  flex-col gap-2 justify-items-start min-h-full">
                                <label class=" text-sm inline-flex  items-center gap-2  px-2 py-1 truncate">
                                        {{ col.label }}
                                        <font-awesome-icon class="text-white-text hover:text-gray-700" v-if="showSorting && col.config && col.config.sort" icon="sort" @click="sortList(col.field)" />
                                </label>
                                <div v-if="col.field_type === 'role' " class="flex items-center  gap-5 text-gray-200">
                                    <font-awesome-icon class="text-sm" icon="eye-slash" v-tippy content="read " />
                                    <font-awesome-icon class="text-sm" icon="pen"  v-tippy content="write" />
                                    <font-awesome-icon class="text-sm" icon="eye" v-tippy content="visible " />
                                </div>
                                <template v-if="showFilters">
                                    <div style="z-index:2">
                                        <slot name="filters">
                                            <Dropdown
                                                reposition
                                                searchable
                                                :multiple="col && col.config && col.config.multi"
                                                v-if="col.config && col.config.type === 'select' "
                                                class="filter-dropdown"
                                                placeholder="Select"
                                                :options="col.filterOptions.filterDropdownItems"
                                                :colors="{
                                                    text: '#282828',
                                                    bg: 'white',
                                                    svg: '#282828',
                                                    border: 'transparent',
                                                }"          
                                                v-model="col.filterOptions.filtervalue"
                                                :value="col.filterOptions.filtervalue"
                                                @input="(value) => filterData(value, col)"
                                                :config="{ limit: 1 }"
                                                minWidth="102px"
                                                ref="dv-table-dropdown"
                                            />
                                            

                                            <Input v-if="col.config && col.config.type === 'search' " class="text-gray-700 h-9 w-full 2xl:w-8/12" v-model="col.filterOptions.filtervalue" :placeholder=" 'Search ' + col.field " @input="filterData($event, col)" bg="white" />
                                        </slot>
                                    </div>
                                </template>
                            </div>
                        </th>
                    </tr>
                    <template v-if="showFilters">
                        <tr v-if="filterApplied">
                        <th :colspan="numColumns" class="py-2 px-3 sticky border-b border-gray-200 bg-gray-100 ">
                            <div class="w-full flex justify-center">
                                    <button @click="resetFilter" class="text-sm flex items-center justify-center gap-2 py-1  hover:bg-dvbrandhoveron bg-primary text-white-text rounded-full px-4" >
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 " fill="white" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
                                        Reset Filters
                                    </button>
                            </div>
                        </th>
                        </tr>
                    </template>
                </thead>
                <tbody class="bg-white">
                    <template v-if="isLoading">
                        <tr>
                        <td :colspan="numColumns">
                            <slot name="loading">
                            <div class="flex justify-center items-center gap-2 w-full">
                                <Loader class="my-5" size="8" isLoading="isLoading" />
                                Loading
                            </div>
                            </slot>
                        </td>
                        </tr>
                    </template>
            
                    <template v-else-if="!filteredResult.length">
                        <tr>
                        <td :colspan="numColumns">
                            <slot name="no-data">
                            <div class="p-5">
                                <div
                                class="
                                    alert
                                    border border-blue-100
                                    alert-info
                                    justify-center
                                "
                                >
                                No Data Found
                                </div>
                            </div>
                            </slot>
                        </td>
                        </tr>
                    </template>

                    <template v-else-if="numRows > 0 && isClickable" >
                        <tr
                        @click="handleRowClick(row)"
                        class="
                        
                            cursor-pointer
                            border-b 
                            border-gray-200
                        "
                        style="width:100%"
                        v-for="(row, idx) in filteredResult "
                        :key="idx"
                        >
                        <slot name="items" :row="row"></slot>
                        </tr>
                    </template>

                    <template v-else-if="numRows > 0 && !isClickable" >
                        <tr
                        class="
                            border-b 
                            border-gray-200
                        "
                        v-for="(row, idx) in filteredResult"
                        :key="idx"
                        >
                        <slot name="items" :row="row"></slot>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <template >
            <tr class="sticky bottom-0 border-b bg-thead" style="border-bottom-left-radius:30px; border-bottom-right-radius:30px; ">
              <td class="p-0">
                  <div class="h-5 bg-thead">
                      
                  </div>
              </td>
            </tr>
          </template>
	</div>
</template>

<script>
import Loader from "@shared/loader";
import Dropdown from '@/components/dropdown-base';
import Input from "@/components/input";
// import { debounce } from "lodash";


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


export default {
  name: "dv-table",
  components: {
    Loader,
    Dropdown,
    Input
  },

  props: {
    headers: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    isClickable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showFilters: {
      type: Boolean,
      default() {
        return false;
      },
    },
    redirectUrl: {
      type: String,
      default() {
        return "";
      },
    },
    showFooter: {
      type: Boolean,
      default:  false
    },
    handleRowClick: {
      type: Function,
      default() {
        return "";
      },
    },
    handleFilter:{
        type: Function,
        default(){
            return "";
        }
    },
    showSorting:{
        type: Boolean,
        default: false
    }
  },
  data() {
        return {
            filteredResult : [],
            sortedbyASC: true,
            columns: []
        };
    },
    watch : {  
        rows:function(val) {  
            this.filteredResult = val;  
        }, 
    }, 


  computed: {
    numRows() {
        return this.rows.length;
    },
    numColumns() {
        return this.headers.length;
    },
  

    getRows(){
        return this.filterData()
    },
    filterApplied(){
      let filterlength = 0
       this.columns.map(el => {
        if(el.filterOptions && el.filterOptions.filtervalue && el.filterOptions.filtervalue.length) {
          filterlength += 1
        }
      })
      if(filterlength > 0){
        return true
      }else{
        return false
      }
    }
    


  },
  mounted() {
      this.getColumns()
      this.filteredResult = this.rows
  },
    methods: {
      getColumns() {
            this.columns =  this.headers.map(col => {
                const filtered = (  col.config && col.config?.filter) ? true : false;
                if(col.config && col.config.searchType === 'boolean'){
                    return {
                    ...col,
                    filterOptions: filtered ? {
                        enabled: true,
                        placeholder: 'All',
                        filtervalue: null,
                        filterDropdownItems: col.config.filterValue,
                    } : undefined,
                }
                }else{
                    return {
                        ...col,
                        filterOptions: filtered ? {
                            enabled: true,
                            placeholder: 'All',
                            filtervalue: null,
                            filterDropdownItems: this.rows
                                .filter(row => row[col.field])
                                .map(row => row[col.field])
                                .filter(onlyUnique),
                        } : undefined,
                    }
                }
            });
    },
    
        filterData(){
            let filterFieldlength = 0
            this.columns.forEach(column => {
                if(column.config && column.config.filter && column.filterOptions.filtervalue && !column.config.multi){filterFieldlength += 1}
                else if(column.config && column.config.filter && column.config.multi && column.filterOptions.filtervalue && column.filterOptions.filtervalue.length > 0){filterFieldlength += 1}
            })
            let rows = this.rows
            if(filterFieldlength  > 0 && this.showFilters){
                let filteredData = rows
                this.columns.map(column => {    
                    if(column.config && column.config.filter && column.config.multi && column.filterOptions.filtervalue && column.filterOptions.filtervalue.length){
                        let filterValues = []
                        if(column.config.searchType === 'number'){
                            filteredData?.forEach(row => {
                                column.filterOptions.filtervalue?.forEach(val => {
                                    if(row[column.field] === (val)){
                                        filterValues.push(row)
                                    }
                                })
                            })
                        }else{
                            filteredData?.forEach(row => {
                                column.filterOptions.filtervalue?.forEach(val => {
                                    if(row[column.field]?.toLowerCase()?.includes(val.toLowerCase())){
                                        filterValues.push(row)
                                    }
                                })
                            })
                        }
                        filteredData =  filterValues
                        // row[column.field]?.toLowerCase()?.includes(column.filterOptions.filtervalue?.toLowerCase())
                    }
                    else if(column.config && column.config.filter && column.filterOptions.filtervalue){
                        if(column.config.searchType === 'number'){
                            filteredData = filteredData?.filter(row => row[column.field] === (column.filterOptions.filtervalue))
                        }else  if(column.config.searchType === 'boolean'){
                            filteredData = filteredData?.filter(row => row[column.field] === (column.filterOptions.filtervalue === column.config.trueValue ? true : false))
                        }else{
                            filteredData = filteredData?.filter(row => row[column.field]?.toLowerCase()?.includes(column.filterOptions.filtervalue?.toLowerCase()))

                        }
                    }
                })
                rows = filteredData
            }
            this.filteredResult =  rows
        },

        resetFilter(){
          this.filteredResult = this.rows
          let new_column = []
          this.columns.forEach(el => {
              if(el.filterOptions && el.filterOptions.filtervalue){
                  let new_element = el.filterOptions
                  new_element = {...new_element, filtervalue:null}
                  new_column.push({...el, filterOptions:new_element})
              }else{
                  new_column.push(el)
              }
          })
            this.columns = new_column
            this.$refs['dv-table-dropdown'].map(dropdownRef => dropdownRef.value = null )

            return this.columns
        },

        // sortList(sortBy) {
        //     let field_type = this.columns.find(el => el.field === sortBy)
        //     if (this.sortedbyASC) {
        //         if(field_type.config && field_type.config.searchType === 'number'){
        //             this.filteredResult.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        //         }else{
        //             this.filteredResult.sort((x, y) => x[sortBy].localeCompare(y[sortBy], { ignorePunctuation: true, numeric:true }));
        //         }
        //         this.sortedbyASC = false;
        //     } else {
        //         if(field_type.config && field_type.config.searchType === 'number'){
        //             this.filteredResult.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        //         }else{
        //             this.filteredResult.sort((x, y) => x[sortBy].localeCompare(y[sortBy], { ignorePunctuation: true, numeric:true })).reverse();
        //         }
        //         this.sortedbyASC = true;
        //     }
        // },
        sortList(sortBy){
            let tableCopy = this.filteredResult
            tableCopy.sort((a, b) => {
                const valueA = a[sortBy]
                const valueB = b[sortBy]
                if(typeof valueA === 'number' && typeof valueB === 'number'){
                    return valueA - valueB
                }else if(typeof valueA === 'string' && typeof valueB === 'string'){
                    return valueA.localeCompare(valueB)
                }else{
                    return 0
                }
            })  
            if(!this.sortedbyASC){
                tableCopy.reverse()
            }
            this.sortedbyASC = !this.sortedbyASC
            this.filteredResult = tableCopy
        }
        

    },
};
</script>



var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-info transition-all overflow-y-auto scroll-bar bg-card-bg flex flex-col gap-6",class:_vm.isCollapsed ? 'w-0 px-0 py-4' : 'w-full py-4 px-6'},[_c('div',{staticClass:"icons-section bg-gray-200 rounded-xl p-3"},[_c('icons-list',{attrs:{"handleClearCallActiveLog":_vm.handleClearCallActiveLog,"icons":_vm.btns,"onSelect":_vm.handleShowModal,"activeIcon":_vm.activeIcon,"activity_count":_vm.activity_count},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(item.svg,{tag:"component"})]}}])})],1),(_vm.showCheckList)?_c('div',{staticClass:"list-section overflow-y-auto scroll-bar pb-0.5"},[_c('ul',{staticClass:"static-list list-none bg-white p-0 mb-2"},[_c('li',{staticClass:"lists lists__static flex justify-between items-center gap-6 max-h-10",style:([
                    _vm.selectedCheck == 'overview'
                        ? {
                              'background-color': '#cce3ff',
                              'border-right': '4px solid #3b2cd4',
                          }
                        : {
                              'background-color': '#f2f2f2',
                              'border-right': '1px solid #a8a8a8',
                          },
                ]),on:{"click":function($event){return _vm.$emit('isClicked', { key: 'overview', type: 'static' })}}},[_c('OverviewIcon'),_c('span',{staticClass:"flex-1 py-1"},[_vm._v("Overview")])],1)]),(_vm.checkLists)?_c('ul',{directives:[{name:"allow",rawName:"v-allow:auth",value:('case.read_check_screen'),expression:"'case.read_check_screen'",arg:"auth"}],staticClass:"list-none bg-white p-0"},_vm._l((_vm.checkLists),function(list,idx){return _c('li',{key:list.check_id + list.check_name,staticClass:"flex justify-between cursor-pointer items-center px-4 py-2.5 gap-6 max-h-10",class:[
                _vm.isTimelineLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                _vm.selectedCheck == list.check_id
                ? 'border-primary-300  border-b bg-blue-100 border-r-4 hover:bg-blue-200  border '
                : ' border hover:bg-base-200',
                idx == _vm.checkLists.length - 1 ? '' : 'border-b-0',
                _vm.CaseCheckFormPreviewLoader ? 'pointer-events-none text-gray-500' : ''
            ],attrs:{"title":list.check_name},on:{"click":function($event){return _vm.$emit('isClicked', {
                key: list.check_id,
                type: list.check_category,
                subtype: list.check_subtype,
                stage_id: list.stage_id,
                title: list.check_name,
                id: list.id,
                status: list.status,
                statuses: list.statuses,
                integration_source_id: list?.integration_source_id
                })}}},[_c('span',{staticClass:"flex-3 w-6 relative",class:{'opacity-50': _vm.CaseCheckFormPreviewLoader}},[(list.svgName === 'employment')?_c('EmploymentIcon'):_vm._e(),(list.svgName === 'education')?_c('EducationIcon'):_vm._e(),(list.svgName === 'difference')?_c('DifferenceIcon'):_vm._e(),(list.svgName === 'other')?_c('OthersIcon'):_c('OthersIcon')],1),_c('span',{staticClass:"flex-1 text-left text-sm truncate",staticStyle:{"max-width":"270px"}},[_vm._v(_vm._s(list.check_name))]),_c('span',{staticClass:"dots flex-1 gap-1 flex items-center justify-end",staticStyle:{"max-width":"25px"}},_vm._l((list.references),function(ref){return _c('span',{key:ref.case_reference_id,staticClass:"dots dots__dot1 transition-all border border-solid border-gray-200",style:({ backgroundColor: ref.status_color }),attrs:{"title":ref.status_name + '  ' + ref.status_description}})}),0)])}),0):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
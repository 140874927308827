import axios from "@/axios";
import NeoLoader from "@shared/components/loader";
import Tags from "@shared/components/tags";
import Tag from "@shared/components/tag";

import {startCase} from "lodash";
import bvd from "./../bvd";
export default {
    name: "person-bvd",
    components: {
      
        NeoLoader,
        "neo-tags": Tags,
        "neo-tag": Tag,
        bvd,
    },
    props: ["personDetails"],
    data() {
        return {
            startCase: startCase,
            stabilizing: false,
            expandedCompanies: [],
            companyDetails: null,
        };
    },
    computed: {},
    mounted() {},
    watch: {
        personDetails() {},
    },
    methods: {
        async expandCompany(details, index) {
            let match = this.expandedCompanies.findIndex((e) => e === index);
            if (match > -1) this.expandedCompanies.splice(match, 1);
            else this.expandedCompanies.push(index);
            this.fetchCompanyData(details.CONTACTS_MEMBERSHIP_IdCompany);
        },

        async fetchCompanyData(id) {
            let requestBody = {
                bvd_id: id,
            };
            let url = `/company/bvd/details`;
            const {data} = await axios.post(url, requestBody);
            this.companyDetails = {
                api_all_data: data?.data[0],
            };
        },
    },
};

<template>
  <div>
    <div class="flex flex-col gap-2">
      <div class="flex items-center justify-between gap-3 relative">
        <input
          type="text"
          placeholder="Search"
          v-model="searchQuery"
          class="w-full rounded-xl p-2 placeholder-black px-4 bg-base-300 placeholders-input" @keyup.enter="focusList()"
        />
        <Search class="absolute right-2 bottom-2" />
      </div>
      <div style="height: 200px" class="overflow-y-auto scroll-bar">
        <div
          v-if="isLoading"
          class="min-h-full max-h-full flex items-center justify-center"
        >
          <Loader height="32px" width="32px" :loading="isLoading" />
        </div>
        <div class="placeholders-list"
          v-else-if="filteredPlaceholders && filteredPlaceholders.length > 0"
        >
          <div
            v-for="(placeholder, index) in filteredPlaceholders"
            :key="placeholder.id"
            :tabindex="index+1"
            class="
              flex
              cursor-pointer
              items-center
              gap-3
              py-2
              select-none
              px-2
              hover:text-blue-500
              border-t border-t-base-200
            "
            @click="setPlaceholder(placeholder)"
            @keyup.enter="setPlaceholder(placeholder)"
            @keyup.down="focusElement(index+1)"
            @keyup.up="focusElement(index-1)"
          >
            {{ placeholder.name }}
          </div>
        </div>
        <div v-else>
          <Empty text="No Placeholders found" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Search from "@/assets/icons/email-admin/icon-search.svg";
import Loader from "@shared/loader";
import Empty from "@shared/empty";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EmailPlaceholder",
  components: {
    Search,
    Loader,
    Empty,
  },
  data() {
    return {
      placeholders: [],
      isLoading: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters(["getFormRows"]),
    filteredPlaceholders() {
      if (this.searchQuery) {
        return this.placeholders.filter((item) => {
          return item.name
            ?.toLowerCase()
            ?.includes(this.searchQuery.toLowerCase());
        });
      } else {
        return this.placeholders;
      }
    },
  },
  async mounted() {
    await this.fetchPlaceholders();
  },
  methods: {
    ...mapActions(["fetchFormRows"]),
    setPlaceholder(placeholder) {
      this.$emit("insertPlaceholder", placeholder);
      document.getElementsByClassName("placeholders-input")[0]?.focus();
    },
    async fetchPlaceholders() {
      this.isLoading = true;
      await this.fetchFormRows();
      try {
        this.placeholders = this.getFormRows || [];
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    focusList() {
        document.getElementsByClassName("placeholders-list")[0]?.children?.[0]?.focus();
    },
    focusElement(index) {
        if (index >= this.filteredPlaceholders.length) 
            index = this.filteredPlaceholders.length - 1;
        else if (index < 0) index = 0;
        document.getElementsByClassName("placeholders-list")[0]?.children?.[index]?.focus();
    },
  },
};
</script>

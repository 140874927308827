<template>
<div class="newItem">
  <!--    :hideOnFocus="hideModal" -->
    <modal-content
      id="sequenceModal"
      :ref="`sequenceModal-${this.item.id}`"
      title="Email Sequence"
      @beforeClose="beforeModalClose"
      :name="`sequenceModal-${this.item.id}`"
      class="sm  overflow-y-auto w-full h-full max-h-full max-w-full py-6 bg-black bg-opacity-60 relative modalItem"
    >
      <div class="fixed top-6 z-20 right-20 bg-primary p-2 rounded-full overflow-y-auto crossIconBtn" role="button" @click.stop="hideModal">
        <CloseIcon />
      </div>
      <div class="relative my-12 p-4 rounded-2xl bg-white mx-auto  w-full">
        <div
          v-for="(sequence,i) in item.templates"
          :key="i"
          :class="sequence.status === 'hold' || sequence.status === 'exit' ? 'opacity-50': ''"
        >
          <div class="relative flex flex-col justify-center items-center" v-if="i">
            <div class="h-4 w-1 bg-gray-400"></div>
            <div class="flex gap-3 bg-gray-300 z-2 py-1 px-4 items-center rounded-md relative">
                <span class="pointer-none select-none">Due in {{ sequence.due_days }} Days </span>
            </div>
            <div class="h-4 w-1 bg-gray-400"></div>
            <!-- <div class="w-1 h-full absolute bg-base-100 -z-10 "></div> -->
          </div>

          <div class="rounded-2xl flex flex-col bg-base-100 relative">
            <card-email
              :item="sequence"
              :emailSequenceActions="true"
            >
                <template #actions v-if="sequence.status !== 'sent'">
                      <SendIcon title="Send" class="send-icon" 
                          :class="sequence.status === 'exit' ? 'cursor-auto gray-fill': 'cursor-pointer'"
                          @click="sendEmail(sequence)"  />

                      <dropdown
                        width="200px"
                        ref="sequence-actions"
                        class="field sequence-status-field"
                        :class="sequence.status === 'exit' ? 'cursor-auto': ''"
                        :disabled="sequence.status === 'exit'"
                        :placeholder="getPlaceHolderValue(sequence)"
                        :colors="getDropdownColors(sequence)"
                        :options="options.schedule.filter((opt) => {
                          if (sequence.status === 'hold') return opt.value !== 'pause' && opt.value !== 'reschedule'
                          else if (sequence.status !== 'hold') return opt.value !== 'resume'
                        })"
                        menu
                        :config="{ label: 'title', trackBy: 'value' }"
                        @input="(value) => handleSequenceAction(value, sequence)"
                      >
                        <template #before>
                          <pause-icon class="field__icon" v-if="sequence.status === 'hold'" />
                          <cancel-icon class="field__icon" v-else-if="sequence.status === 'exit'" />
                          <clock-icon class="field__icon" v-else />
                        </template>
                      </dropdown>
                </template>
            </card-email>
          </div>
        </div>
      </div>

      <ModalConfirm ref="modalConfirm" v-bind="modalConfirmData.config" :isSequenceModalOpen="isSequenceModalOpen">
        <template #content>
          <div class="flex gap-x-4">
            <font-awesome-icon class="mx-2 confirmation-icon" icon="exclamation-triangle" size="4x"></font-awesome-icon>
            {{ modalConfirmData.config.confirmationMessage }}
          </div>
        </template>

        <template #footer>
          <div class="flex gap-4 mx-auto">
            <Button
                :disabled="loading"
                :bg="loading ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'"
                class="btn w-auto h-8 min-h-8"
                type="primary"
                :loading="loading"
                text="Yes"
                @click="submitModal()"
                @keyup.enter="submitModal()"
            >
              Yes
            </Button>
            <button
                :bg="loading ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'"
                color="black"
                hoverBg="var(--brand-hover-on)"
                hoverText="black"
                padding="0.4rem 0.7rem"
                borderRadius="0.3rem"
                class="btn btn-outline btn-info w-auto mr-2.5 h-8 min-h-8"
                @click="closeModal"
            > No 
          </button>
          </div>
        </template>
      </ModalConfirm>
    </modal-content>

    <reschedule-sequence
      ref="reschedule-sequence" 
      :date="rescheduleSequence?.due_date"
      :loading="loading"
      @submit="handleReschedule"
      @close="resetReschedule"
    />
    </div>
</template>

<script>
import modalContent from "@shared/modal-content";
const cardEmail = () => import("./cards/card-email.vue");
const rescheduleSequence = () => import("./cards/reschedule-sequence.vue");
const ModalConfirm = () => import("@shared/modal-confirm");
import dropdown from "@/components/dropdown-base";
import calendarIcon from "@/assets/icons/dashboard/calendar.svg";
import clockIcon from '@/assets/icons/dashboard/clock.svg';
import pauseIcon from "@/assets/icons/dashboard/pause.svg";
import resumeIcon from "@/assets/icons/dashboard/resume.svg";
import cancelIcon from "@/assets/icons/dashboard/cancel.svg";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import SendIcon from "@/assets/icons/svg/send.svg";

import { DateTime } from 'luxon'
import axios from "@/axios";
import { EventBus } from "@/main.js";

export default {
  name: "email-sequence-modal",
  props: {
    type: String,
    to: String,
    item: {
      type: Object,
      default: () => {},
    },
    isSequenceModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    cardEmail,
    "reschedule-sequence": rescheduleSequence,
    ModalConfirm,
    dropdown,
    clockIcon,
    pauseIcon,
    CloseIcon,  
    cancelIcon,
    SendIcon,
    modalContent
  },
  data() {
    return {
      options: {
        // TODO; get options from list
        schedule: [
          { title: "Reschedule", value: "reschedule", icon: calendarIcon },
          { title: "Pause", value: "pause", icon: pauseIcon },
          { title: "Resume", value: "resume", icon: resumeIcon },
          { title: "Cancel", value: "cancel", icon: cancelIcon },
        ],
      },
      reschedule_date: null,
      sequenceAction: null,
      modalConfirmData: {
          config: {
              showHeader: true,
              confirmationMessage: '',
          },
      },
      loading: false, 
      rescheduleSequenceID: null,
      rescheduleSequence: null,
    };
  },
  mounted() {
  },
  watch: {
    isSequenceModalOpen: function (val) {
      if (val) {
        this.$refs[`sequenceModal-${this.item.id}`].showModal();
        this.$refs?.["sequence-actions"]?.[0]?.$refs?.["multiselect"]?.deactivate();
      } else this.hideModal();
    },
    rescheduleSequence: function (val) {
      if (val) {
        this.$refs['reschedule-sequence'].showModal();
      }
    }
  },
  methods: {
    beforeModalClose() {
      this.$emit("update:isSequenceModalOpen", false);
      this.$emit("closeSequenceModal"); 
    },
    getPlaceHolderValue(sequence) {
      if (sequence.status === 'exit') return 'Cancelled';
      else if (sequence.status === 'hold') return 'Paused';
      else return this.formatDate(sequence.due_date)
    },
    getDropdownColors(sequence) {
      if (sequence.status === 'exit') return {
        placeholder: '#fff',
        bg: 'rgba(248, 113, 113, 1)',
        text: '#000',
        border: 'rgba(248, 113, 113, 1)',
      };
      else if (sequence.status === 'hold') return {
        placeholder: '#fff',
        bg: 'rgba(251, 191, 36, 1)',
        text: '#000',
        border: 'rgba(251, 191, 36, 1)',
      };
      else return {
        placeholder: '#000',
        bg: 'rgba(243, 244, 246, 1)',
        text: '#000',
        border: 'rgba(243, 244, 246, 1)',
      }
    },
    formatDate(date) {
      return DateTime.fromJSDate(new Date(date)).toFormat("dd-LLL-yyyy");
    },
    hideModal() {
      this.$emit("update:isSequenceModalOpen", false);
      this.$emit("closeSequenceModal"); // this event neds to close model in Customized forms
      this.$refs[`sequenceModal-${this.item.id}`].hideModal()
      // this.$refs.sequenceModal.close();
    },
    handleSequenceAction(action, sequence) {
      this.sequenceAction = {
        action: action,
        sequence
      };
      if (action.value === 'reschedule') {
        this.rescheduleSequence = sequence;
        this.rescheduleSequenceID = sequence.id;
      } else {
        this.updateCaseSequence(action);
        this.resetReschedule();
      }
    },
    resetReschedule() {
        this.rescheduleSequence = null;
        this.rescheduleSequenceID = null;
    },
    handleReschedule(date) {
      this.reschedule_date = date;
      this.resetReschedule();
      this.updateCaseSequence(this.sequenceAction.action);
    },
    async updateCaseSequence(action) {
      this.modalConfirmData.config.confirmationMessage = '';

      let message;
      if (action.value === 'reschedule') {
        message = "Are you sure you want to reschedule this email? Doing so will update upcoming email(s) scheduling accordingly.";
      } else if (action.value === 'pause') {
        message = "Are you sure you want to pause this email? Doing so will also pause all the upcoming scheduled email(s).";
      } else if (action.value === 'resume') {
        message = "Are you sure you want to resume this email? Doing so will also resume all the upcoming scheduled email(s).";
      } else if (action.value === 'cancel') {
        message = "Are you sure you want to cancel this email? Doing so will also cancel all the upcoming scheduled email(s).";
      }
      this.modalConfirmData.config.confirmationMessage = message;
      let promise = await this.$refs.modalConfirm.show({
          message: message,
          title: `${action.title} Sequence`,
      });
      if (promise)
        this.submitModal();
        this.loading = false;
    },
    async submitModal() {
      this.loading = true;
      const case_id = this.$route.query.caseid;
      const action = this.sequenceAction.action;
      const url = `/case/${case_id}/email-sequence/${this.sequenceAction.sequence.email_schedule_id}`;
      const payload = {
          action: action.value,
      };
      if (action.value === 'reschedule')
          payload["reschedule_date"] = this.reschedule_date;
      let successMessage, errorMessage;
      if (action.value === 'reschedule') {
        successMessage = "Email rescheduled successfully";
        errorMessage = "Email cannot be rescheduled";
      } else if (action.value === 'pause') {
        successMessage = "Email paused successfully";
        errorMessage = "Email cannot be paused";
      } else if (action.value === 'resume') {
        successMessage = "Email resumed successfully";
        errorMessage = "Email cannot be resumed";
      } else if (action.value === 'cancel') {
        successMessage = "Email cancelled successfully";
        errorMessage = "Email cannot be cancelled";
      }
      try {
          await axios.post(url, payload);
          this.hideModal();
          this.closeModal();
          this.$toast.success(successMessage);
          EventBus.$emit('refreshHistory');
          EventBus.$emit('onSequenceReschedule', this.item.data_collection_id);
      } catch (error) {
          this.hideModal();
          this.closeModal();
          this.$toast.error(error?.response?.data?.detail || errorMessage || "Something went wrong");
      }
      this.loading = false;
    },
    closeModal() {
      this.$refs.modalConfirm.close();
    },
    async sendEmail(sequence) {
      if (sequence.status === 'exit')
        return;
        let emailData = {
            ...sequence,
            tag: sequence.external_id_type,
        };
        try {
            this.loading = true;
            await axios.post("email/send", emailData);
            this.hideModal();
            this.$toast.success("Email sent successfully");
        } catch (error) {
            let message;
            if (error?.response?.data?.detail && typeof error?.response?.data?.detail === "string") message = error?.response?.data?.detail;
            this.hideModal();
            this.$toast.error(message || "Couldn't send email");
        }
        this.loading = false;
    },
  }
};
</script>
<style lang="scss">
.sequence-status-field {
  .multiselect--disabled {
    background-color: transparent!important;;
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .modalItem .vm--modal {
    top: 0% !important;
    left: 9% !important;
    min-width: 80% !important;
    width: 83% !important;
    max-height: max-content !important;
    height: max-content !important;
    overflow-y: auto !important;
    background: #0000000f;
    .restModal {
      max-height: max-content !important;
    }
    .modalContent__header{
        display: none;
    }
    .modalContent__footer{
        display: none;
    }
  }
  // .confirm_modal{
  //   z-index: 9 !important;;
  // }
  .previewFormModal .confirm_modal .vm--modal{
    left: 38% !important;
    width: 26% !important;
    height: auto !important;
    min-width: 25% !important;
    top: 38% !important;
    .modalContent__header{
        display: flex;
    }
    .modalContent__footer{
        display: flex;
    }
  }
  
  .previewFormModal .confirm_modal {
    z-index: 9 !important
  }
}
.action-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .row {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    .field {
      flex-grow: 1;
      &__icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.crossIconBtn {
  // position: absolute;
  // top: 1px;
  // right: 9px;
    top: 27px !important;
    right: 51px !important;
}
.send-icon {
  path {
    fill: #0d6ad5;
  }
}
.gray-fill {
  path {
    fill: rgba(209, 213, 219, 1)!important;
  }
}
.confirmation-icon {
  path {
    fill:white;
  }
  stroke: orange;
  stroke-width: 20;
}
</style>
// extend if main screen names are needed:
export const screenList = {
	DASHBOARD: "dashboard",
	CASE_SCREEN: "case screen",
	CHECK_OUTPUT: "check output",
}

// add other configured screens to display here
export const subScreenList = {
	// provide Boolean or Object value (config for the configuration screen variants)
	// [screenList.CASE_SCREEN]: {
	// 	INPUT_TAB: "input tab",
	// },
	[screenList.CHECK_OUTPUT]: true,
}
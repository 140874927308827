var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-h-full min-h-full flex flex-col w-full"},[_c('sub-header',{attrs:{"backButton":true,"handleClick":_vm.showSectionModal,"showBtn":['check-form-editor', 'check-output-form-editor'].includes(_vm.currentTab.nav) && _vm.checkPermission('check.edit'),"buttonText":"Add Section","backRedirectionUrl":`/check-admin/`,"customCrumbLists":_vm.customCrumbs,"setEditModeUnlock":_vm.setEditModeUnlock,"mode":this.$route.params.action !=='view'}}),_c('div',{directives:[{name:"allow",rawName:"v-allow:auth",value:('check.edit'),expression:"'check.edit'",arg:"auth"}],staticClass:"flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-y-auto scroll-bar"},[(_vm.isLoading)?_c('span',{staticClass:"flex h-44 min-w-full bg-card-bg items-center flex-col justify-center rounded-md"},[_c('loader',{staticClass:"my-5",attrs:{"height":"32px","width":"32px","loading":_vm.isLoading}}),_c('span',[_vm._v(" Loading... ")])],1):_c('div',{staticClass:"w-full flex gap-4 max-h-full overflow-y-auto"},[_c('div',{staticClass:"flex h-full gap-4 flex-1 overflow-hidden"},[_c('div',{staticClass:"w-2/12 rounded-lg self-start bg-card-bg shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"},_vm._l((_vm.navigations),function(nav,idx){return _c('div',{key:idx,staticClass:"px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3 cursor-pointer",class:{
                            'bg-primary text-white-text': _vm.currentTab.nav === nav.nav,
                            'hover:bg-gray-100 hover:text-base-content-600': _vm.currentTab.nav !== nav.nav,
                            'border-t border-line border-0': idx !== 0
                        },on:{"click":function($event){return _vm.handleCurrentTab(nav)}}},[_c('font-awesome-icon',{attrs:{"icon":[nav.iconPrefix || 'fa', nav.icon]}}),_vm._v(" "+_vm._s(nav.title)+" ")],1)}),0),_c('div',{staticClass:"flex flex-col flex-1 gap-3 w-full h-full overflow-hidden"},[(_vm.currentTab.nav != 'workflow')?_c('UISection',{class:{
                            'description_height': _vm.currentTab.nav === 'description',
                            'overflow-y-hidden': _vm.currentTab.nav !== 'description',
                        },scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"text-white-text"},[_vm._v(_vm._s(_vm.currentTab.title))])]},proxy:true}],null,false,2473556122)},[_c('div',{staticClass:"w-full scroll-bar",staticStyle:{"max-height":"72vh"}}),_c(_vm.currentTab.el,_vm._g(_vm._b({ref:_vm.currentTab.nav,tag:"component",attrs:{"currentTab":_vm.currentTab.nav}},'component',_vm.currentTab.props,false),_vm.currentTab.listeners))],1):(_vm.currentTab.nav =='workflow')?_c('div',{staticClass:"flex-1"},[_c('div',[_c(_vm.currentTab.el,_vm._g(_vm._b({ref:"workflow",tag:"component"},'component',_vm.currentTab.props,false),_vm.currentTab.listeners))],1)]):_c('div',{staticClass:"flex flex-1 bg-card-bg p-3 h-full overflow-y-scroll scroll-bar justify-center items-center"},[_vm._v(" No Options Selected ")])],1)])])]),_c('modal-confirm',{ref:"confirm-popup",attrs:{"title":"Are you sure?","message":"Please confirm you're about to delete the field."}}),(_vm.isGlobalLoader.status)?_c('loader-full',{attrs:{"loadingText":_vm.isGlobalLoader.message}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
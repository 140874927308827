import { TRIGGER_FIELDS_CONFIG_MAP } from "./constants.js";

export const getPartsFromCombinedValue = (combinedValue) => {
    const [id, configId] = combinedValue.split("/");
    return { id, configId };
};

export const getConfigKeys = (field) => {
    const { idKey, configIdKey, configValueKey, endpoint } = TRIGGER_FIELDS_CONFIG_MAP[field];
    return { idKey, configIdKey, configValueKey, endpoint };
};

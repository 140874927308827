<template>
    <div id="uboList" class="mt-3" v-if="Object.keys(uboList).length > 0 && fetchubocmpbol">
        <div class="tabActions flex justify-between items-center">
            <div class="tabContainer">
                <div class="tabs space-x-2">
                    <div
                        @click="selectedInfo(tab)"
                        v-for="(tab, index) in getCompanyInfoTabs"
                        :key="index"
                        class="tab px-6 text-sm"
                        :style="{
                            borderBottomColor: selected === tab ? 'var(--brand-color)' : '',
                        }"
                        @mouseover="hoveredOver = true"
                        @mouseleave="hoveredOver = false"
                    >
                        <span :class="{'text-blue-600': selected === tab, 'text-brand': ''}" class="tab-name">
                            {{ tab }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Organization'">
            <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                <div class="font-medium text-sm text-gray-400 mr-2">DUNS:</div>
                <div v-if="uboList.duns" class="text-gray-500 no-underline break-all">
                    {{ uboList.duns }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Operating Status:</div>
                <div v-if="uboList.dunsControlStatus && uboList.dunsControlStatus.isOutOfBusiness !== null">
                    <div v-if="uboList.dunsControlStatus && !uboList.dunsControlStatus.isOutOfBusiness" class="text-gray-500 no-underline break-all">Active</div>
                    <div v-else>Inactive</div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Primary Name:</div>
                <div v-if="uboList.primaryName" class="text-gray-500 no-underline break-all">
                    {{ uboList.primaryName }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Address:</div>
                <div v-if="uboList.address && Object.keys(uboList.address).length > 0" class="text-gray-500 no-underline break-all">{{ uboList.address.streetAddress.line1 }} {{ uboList.address.streetAddress.line2 }} {{ uboList.address.postalCode }} {{ uboList.address.addressLocality.name }} {{ uboList.address.addressRegion && uboList.address.addressRegion.name ? uboList.address.addressRegion.name : "" }} {{ uboList.address.addressCountry.name }}</div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Primary Industry Code:</div>
                <div v-if="uboList && uboList.primaryIndustryCode" class="text-gray-500 no-underline break-all">{{ uboList.primaryIndustryCode.usSicV4 }} - {{ uboList.primaryIndustryCode.usSicV4Description }}</div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Business Entity Type:</div>
                <div v-if="uboList && uboList.businessEntityType && uboList.businessEntityType.description" class="text-gray-500 no-underline break-all">
                    {{ uboList.businessEntityType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Control Ownership Type:</div>
                <div v-if="uboList && uboList.controlOwnershipType && uboList.controlOwnershipType.description" class="text-gray-500 no-underline break-all">
                    {{ uboList.controlOwnershipType.description }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Confidence Level:</div>
                <div v-if="uboList && uboList.controlOwnershipType && uboList.controlOwnershipType.confidenceLevel" class="text-gray-500 no-underline break-all">
                    {{ uboList.controlOwnershipType.confidenceLevel }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Ownership Unavailable Reasons:</div>
                <div v-if="uboList.ownershipUnavailableReasons && uboList.ownershipUnavailableReasons.length > 0" class="text-gray-500 no-underline break-all">
                    <div v-for="(reason, index) in uboList.ownershipUnavailableReasons" :key="index">
                        {{ reason.description }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4">
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">PSC Unavailable Reasons:</div>
                    <div v-if="uboList.pscUnavailableReasons && uboList.pscUnavailableReasons.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(reason, index) in uboList.pscUnavailableReasons" :key="index">
                            {{ reason.description }}
                        </div>
                    </div>
                    <div v-else>-</div>
                    <div class="ml-2 cursor-pointer flex items-center" @click="showModal('pscUnavailable')">
                        <svg xmlns="http://www.w3.org/2000/svg" v-b-tooltip.hover.top title="PSC Unavailable Reasons" width="20" height="20" fill="var(--brand-color)" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">Start Date:</div>
                    <div v-if="uboList.pscUnavailableReasons && uboList.pscUnavailableReasons.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(reason, index) in uboList.pscUnavailableReasons" :key="index">
                            {{ formatDate(reason.startDate) }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4">
                <strong> Global Ultimate </strong>
                <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                    <div class="font-medium text-sm text-gray-400 mr-2">Duns:</div>
                    <div v-if="uboList.corporateLinkage && uboList.corporateLinkage.globalUltimate && Object.keys(uboList.corporateLinkage.globalUltimate.length > 0)" class="text-gray-500 no-underline break-all">
                        <div v-if="uboList.corporateLinkage.globalUltimate.duns">{{ uboList.corporateLinkage.globalUltimate.duns }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">Primary Name:</div>
                    <div v-if="uboList.corporateLinkage && uboList.corporateLinkage.globalUltimate && Object.keys(uboList.corporateLinkage.globalUltimate.length > 0)" class="text-gray-500 no-underline break-all">
                        <div v-if="uboList.corporateLinkage.globalUltimate.primaryName">{{ uboList.corporateLinkage.globalUltimate.primaryName }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <strong> Domestic Ultimate </strong>
                <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                    <div class="font-medium text-sm text-gray-400 mr-2">Duns:</div>
                    <div v-if="uboList.corporateLinkage && uboList.corporateLinkage.domesticUltimate && Object.keys(uboList.corporateLinkage.domesticUltimate.length > 0)" class="text-gray-500 no-underline break-all">
                        <div v-if="uboList.corporateLinkage.domesticUltimate.duns">{{ uboList.corporateLinkage.domesticUltimate.duns }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">Primary Name:</div>
                    <div v-if="uboList.corporateLinkage && uboList.corporateLinkage.domesticUltimate && Object.keys(uboList.corporateLinkage.domesticUltimate.length > 0)" class="text-gray-500 no-underline break-all">
                        <div v-if="uboList.corporateLinkage.domesticUltimate.primaryName">{{ uboList.corporateLinkage.domesticUltimate.primaryName }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <strong> Parent </strong>
                <div class="mt-4 flex" v-if="!dbResellerCorpRecord && dbCorpRecord">
                    <div class="font-medium text-sm text-gray-400 mr-2">Duns:</div>
                    <div v-if="uboList.corporateLinkage && uboList.corporateLinkage.parent && Object.keys(uboList.corporateLinkage.parent.length > 0)" class="text-gray-500 no-underline break-all">
                        <div v-if="uboList.corporateLinkage.parent.duns">{{ uboList.corporateLinkage.parent.duns }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">Primary Name:</div>
                    <div v-if="uboList.corporateLinkage && uboList.corporateLinkage.parent && Object.keys(uboList.corporateLinkage.parent.length > 0)" class="text-gray-500 no-underline break-all">
                        <div v-if="uboList.corporateLinkage.parent.primaryName">{{ uboList.corporateLinkage.parent.primaryName }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Benificial Owners'">
            <div>
                <div class="mt-4">
                    <div class="flex flex-col mt-1">
                        <div class="overflow-x-auto scroll">
                            <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes " :class="uboList.beneficialOwnership && uboList.beneficialOwnership.beneficialOwners && uboList.beneficialOwnership.beneficialOwners.length ? 'print-overflow-table' : ''">
                                <div class="shadow rounded border border-solid border-gray-300 fixTableHead">
                                    <table id="formerRegisteredNames" class="formerRegisteredNamesTable min-w-full divide-y divide-gray-200">
                                        <thead class="bg-gray-200">
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-left w-16 whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Is Benificiary
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Entity Name
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" v-if="!dbResellerCorpRecord && dbCorpRecord">
                                                    Duns
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Person Id
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Benificiary Type
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Business Entity Type
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Address
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Nationality
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date of Birth
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Residence Country Name
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Legal Authority
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Legal Form
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Nature Of Control Type
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Control Class
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Control Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Contorl Reasons
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Is Direct Control
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Nature Of Control Class
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Nature Of Control Start Date
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    PSC
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Direct Ownership Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Indirect Ownership Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Benificial Ownership Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Ownership Unavailable Reasons
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Is Out Of Business
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Control Ownership Type
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Confidence Level
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Degree Of Separation
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Is Undisclosed
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Applied Control Type
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Implied Direct Ownership Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Implied Indirect Ownership Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Implied Beneficial Ownership Percentage
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="uboList.beneficialOwnership && uboList.beneficialOwnership.beneficialOwners && uboList.beneficialOwnership.beneficialOwners.length > 0">
                                            <!-- Odd row -->
                                            <tr v-for="(owner, index) in uboList.beneficialOwnership.beneficialOwners" :key="index">
                                                <td scope="col" class="px-6 py-3 w-16 whitespace-nowrap text-left text-sm font-medium text-gray-500 tracking-wider">
                                                    {{ owner.isBeneficiary }}
                                                </td>
                                                <td class="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                                                    {{ owner.name }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500" v-if="!dbResellerCorpRecord && dbCorpRecord">
                                                    {{ owner.duns }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.personId }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.beneficiaryType && owner.beneficiaryType.description ? owner.beneficiaryType.description : "-" }}
                                                </td>
                                                <td class="px-6 py-2  text-sm text-gray-500">
                                                    {{ owner.businessEntityType && owner.businessEntityType.description ? owner.businessEntityType.description : "" }}
                                                </td>
                                                <td class="px-6 py-2  text-sm text-gray-500">
                                                    {{ owner.address && owner.address.streetAddress && owner.address.streetAddress.line1 ? owner.address.streetAddress.line1 : "" }} {{ owner.address && owner.address.streetAddress && ", " + owner.address.streetAddress.line2 ? owner.address.streetAddress.line2 : "" }} {{ owner.address && owner.address.addressLocality && owner.address.addressLocality.name ? ", " + owner.address.addressLocality.name : "" }}
                                                    {{ owner.address && owner.address.postalCode ? ", " + owner.address.postalCode : "" }}
                                                    {{ owner.address && owner.address.addressRegion ? ", " + owner.address.addressRegion.name : "" }}
                                                    {{ owner.address && owner.address.addressCountry && owner.address.addressCountry.name ? ", " + owner.address.addressCountry.name : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.nationality ? owner.nationality : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ formatDate(owner.birthDate) }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.residenceCountryName }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.legalAuthority }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.legalForm }}
                                                </td>
                                                <td>
                                                    <div v-if="owner.natureOfControlType && owner.natureofControlClass && owner.natureofControlClass.length > 0">
                                                        <div v-for="(control, index) in owner.natureOfControlType" :key="index">
                                                            <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ control.description ? control.description : "" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="owner.natureOfControlType && owner.natureOfControlType.length > 0">
                                                        <div v-for="(control, index) in owner.natureOfControlType" :key="index">
                                                            <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ control.controlClass && control.controlClass.description ? control.controlClass.description : "" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="owner.natureOfControlType && owner.natureOfControlType.length > 0">
                                                        <div v-for="(control, index) in owner.natureOfControlType" :key="index">
                                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ control.controlPercentage ? control.controlPercentage : "" }}
                                                            </td>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="owner.natureOfControlType && owner.natureOfControlType.length > 0">
                                                        <div v-for="(control, index) in owner.natureOfControlType" :key="index">
                                                            <div v-if="control.controlReasons && control.controlReasons.length > 0">
                                                                <div v-for="(reason, index) in control.controlReasons" :key="index">
                                                                    <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                        {{ reason.description ? reason.description : "" }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="owner.natureOfControlType && owner.natureOfControlType.length > 0">
                                                        <div v-for="(control, index) in owner.natureOfControlType" :key="index">
                                                            <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ control.isDirectControl ? control.isDirectControl : "" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="owner.natureofControlClass && owner.natureofControlClass.length > 0">
                                                        <div v-for="(control, index) in owner.natureofControlClass" :key="index">
                                                            <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ control.description ? control.description : "" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ formatDate(owner.natureOfControlStartDate) }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.isPSC }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.directOwnershipPercentage }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.indirectOwnershipPercentage }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.beneficialOwnershipPercentage }}
                                                </td>
                                                <td>
                                                    <div v-if="owner.ownershipUnavailableReasons && owner.ownershipUnavailableReasons.length > 0">
                                                        <div v-for="(reason, index) in owner.ownershipUnavailableReasons" :key="index">
                                                            <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ reason.description ? reason.description : "" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.isOutofBusiness }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.controlOwnershipType && owner.controlOwnershipType.description ? owner.controlOwnershipType.description : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.controlOwnershipType && owner.controlOwnershipType.confidenceLevel && owner.controlOwnershipType.confidenceLevel.description ? owner.controlOwnershipType.confidenceLevel.description : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.degreeOfSeparation ? owner.degreeOfSeparation : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.controlOwnershipType && owner.controlOwnershipType.isUndisclosed ? owner.controlOwnershipType.isUndisclosed : "" }}
                                                </td>
                                                <td>
                                                    <div v-if="owner.appliedControlType && owner.appliedControlType.length > 0">
                                                        <div v-for="(control, index) in owner.appliedControlType" :key="index">
                                                            <div class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ control.description ? control.description : "" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.impliedDirectOwnershipPercentage ? owner.impliedDirectOwnershipPercentage : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.impliedDirectOwnershipPercentage ? owner.impliedIndirectOwnershipPercentage : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.impliedDirectOwnershipPercentage ? owner.impliedBeneficialOwnershipPercentage : "" }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                    No data available
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Summary'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Beneficial Owners Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.beneficialOwnersCount ? uboList.beneficialOwnershipSummary.beneficialOwnersCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Relationships Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.relationshipsCount ? uboList.beneficialOwnershipSummary.relationshipsCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Maximum Degree Of Separation:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.maximumDegreeOfSeparation ? uboList.beneficialOwnershipSummary.maximumDegreeOfSeparation : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Total Allocated Ownership Percentage:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.totalAllocatedOwnershipPercentage ? uboList.beneficialOwnershipSummary.totalAllocatedOwnershipPercentage : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Organizations Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.organizationsCount ? uboList.beneficialOwnershipSummary.organizationsCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Individuals Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.individualsCount ? uboList.beneficialOwnershipSummary.individualsCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Unclassified Owners Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.unclassifedOwnersCount ? uboList.beneficialOwnershipSummary.unclassifedOwnersCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Corporate Beneficiaries Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.corporateBeneficiariesCount ? uboList.beneficialOwnershipSummary.corporateBeneficiariesCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">State Owned Organizations Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.stateOwnedOrganizationsCount ? uboList.beneficialOwnershipSummary.stateOwnedOrganizationsCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Government Organizations Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.governmentOrganizationsCount ? uboList.beneficialOwnershipSummary.governmentOrganizationsCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Publicly Trading Organizations Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.publiclyTradingOrganizationsCount ? uboList.beneficialOwnershipSummary.publiclyTradingOrganizationsCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Privately Held Organizations Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    <div>{{ uboList.beneficialOwnershipSummary.privatelyHeldOrganizationsCount ? uboList.beneficialOwnershipSummary.privatelyHeldOrganizationsCount : 0 }}</div>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Total Implied Ownership Percentage:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.totalImpliedOwnershipPercentage ? uboList.beneficialOwnershipSummary.totalImpliedOwnershipPercentage : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">UnIdentified Share Owners Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.unIdentifiedShareOwnersCount ? uboList.beneficialOwnershipSummary.unIdentifiedShareOwnersCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Implied Percentage Beneficial Owners Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.impliedPercentageBeneficialOwnersCount ? uboList.beneficialOwnershipSummary.totalImpliedOwneimpliedPercentageBeneficialOwnersCountrshipPercentage : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Country Code:</div>
                <div v-if="uboList.beneficialOwnershipSummary.countryWiseSummary && uboList.beneficialOwnershipSummary.countryWiseSummary.length > 0" class="text-gray-500 no-underline break-all">
                    <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.countryWiseSummary" :key="index">
                        {{ summary.countryISOAlpha2Code ? summary.countryISOAlpha2Code : "-" }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Beneficial Owners Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary.countryWiseSummary && uboList.beneficialOwnershipSummary.countryWiseSummary.length > 0" class="text-gray-500 no-underline break-all">
                    <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.countryWiseSummary" :key="index">
                        {{ summary.beneficialOwnersCount ? summary.beneficialOwnersCount : 0 }}
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">PSC Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.pscCount ? uboList.beneficialOwnershipSummary.pscCount : 0 }}
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">PSC Unique Type Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.pscUniqueTypeCount ? uboList.beneficialOwnershipSummary.pscUniqueTypeCount : 0 }}
                </div>
            </div>
            <div class="mt-4">
                <strong> Type Wise PSC Summary</strong>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">PSC Type:</div>
                    <div v-if="uboList.beneficialOwnershipSummary.typeWisePSCSummary && uboList.beneficialOwnershipSummary.typeWisePSCSummary.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.typeWisePSCSummary" :key="index">
                            {{ summary.pscType && summary.pscType.description ? summary.pscType.description : "-" }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">PSC Count:</div>
                    <div v-if="uboList.beneficialOwnershipSummary.typeWisePSCSummary && uboList.beneficialOwnershipSummary.typeWisePSCSummary.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.typeWisePSCSummary" :key="index">
                            {{ summary.pscType.pscCount ? summary.pscType.pscCount : 0 }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4">
                <strong>Nationality Wise PSC Summary</strong>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">Nationality:</div>
                    <div v-if="uboList.beneficialOwnershipSummary.nationalityWisePSCSummary && uboList.beneficialOwnershipSummary.nationalityWisePSCSummary.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.nationalityWisePSCSummary" :key="index">
                            {{ summary.nationality ? summary.nationality : "-" }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">PSC Count:</div>
                    <div v-if="uboList.beneficialOwnershipSummary.nationalityWisePSCSummary && uboList.beneficialOwnershipSummary.nationalityWisePSCSummary.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.nationalityWisePSCSummary" :key="index">
                            {{ summary.pscCount ? summary.pscCount : 0 }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Unknown Nationality PSC Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    <div v-if="uboList.beneficialOwnershipSummary.unknownNationalityPSCCount">{{ uboList.beneficialOwnershipSummary.unknownNationalityPSCCount }}</div>
                    <div v-else>-</div>
                </div>
            </div>
            <div class="mt-4">
                <strong>Country Wise PSC Summary</strong>
                <div class="mt-4 flex">
                    <div class="font-medium text-sm text-gray-400 mr-2">PSC Count:</div>
                    <div v-if="uboList.beneficialOwnershipSummary.countryWisePSCSummary && uboList.beneficialOwnershipSummary.countryWisePSCSummary.length > 0" class="text-gray-500 no-underline break-all">
                        <div v-for="(summary, index) in uboList.beneficialOwnershipSummary.countryWisePSCSummary" :key="index">
                            {{ summary.pscCount ? summary.pscCount : 0 }}
                        </div>
                    </div>
                    <div v-else>0</div>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Country Unknown PSC Count:</div>
                <div v-if="uboList.beneficialOwnershipSummary" class="text-gray-500 no-underline break-all">
                    {{ uboList.beneficialOwnershipSummary.countryUnknownPSCCount ? uboList.beneficialOwnershipSummary.countryUnknownPSCCount : 0 }}
                </div>
            </div>
        </div>
        <modal name="pscUnavailable">
            <div class="flex mt-2  justify-end mr-4 hover:text-brand-hover cursor-pointer" @click="hideModal('pscUnavailable')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>
            <div class="p-5 pt-2 space-y-2 h-full overflow-y-auto scroll-bar">
                <div class="mt-2"><span class="font-bold">PSC Unavailable Reasons:</span> Details why Persons of Significant control (PSC) information for this entity is unavailable.</div>
                <div class="mt-2"><span class="font-bold">Start Date: </span> Date from when the PSC information is unavailable for this entity.</div>
            </div>
        </modal>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import {DateTime} from "luxon";
export default {
    name: "Ubo List",
    props: {
        uboList: {
            type: Object,
            default: ()=>[],
        },
        disableFetchMore: {
            type: Boolean,
        },
        fetchubocmpbol: {
            type: Boolean,
        },
    },
    data() {
        return {
            selected: "Organization",
        };
    },
    computed: {
        ...mapGetters(["getUserFeatures"]),
        getCompanyInfoTabs() {
            let tabs = [];
            tabs = ["Organization", "Benificial Owners", "Summary"];
            return tabs;
        },
        dbResellerCorpRecord() {
            return this.getUserFeatures.includes("dnb_reseller__corp_record");
        },
        dbCorpRecord() {
            return this.getUserFeatures.includes("db__corp_record");
        },
    },
    methods: {
        selectedInfo(info) {
            this.selected = info;
        },
        hideModal(modalname) {
            this.$modal.hide(modalname);
        },
        showModal(modalname) {
            this.$modal.show(modalname);
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
    },
};
</script>
<style lang="scss" scoped>
.tabActions {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
}
/* .scroll-bar {
    height: 10px;
} */
.tabs {
    width: 100%;
    border-bottom: 3px solid var(--brand-accent-neutral);
    cursor: pointer;
    margin: 0.4rem 0;
    font-family: var(--brand-font);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    display: flex;
}
.tabContainer {
    width: 100%;
}

.tab {
    transform: translateY(3px);
    border-bottom: 3px solid transparent;
    transition: var(--transition-mnml);
    border-radius: var(--component-border) var(--component-border) 0 0;
    font-size: 16px;
    text-align: center;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fixTableHead {
    overflow-y: auto;
    height: 400px;
}
.fixTableHead thead th {
    position: sticky;
    background: rgb(230, 227, 227);
    top: 0;
}
</style>

export const TRIGGER_FIELDS = Object.freeze({
    Events: "events",
    Conditions: "conditions",
    Actions: "actions",
});

export const TRIGGER_FIELDS_LIST = [TRIGGER_FIELDS.Events, TRIGGER_FIELDS.Conditions, TRIGGER_FIELDS.Actions];

export const FIELD_KEYS = Object.freeze({
    Type: "type",
    Configuration: "configuration",
    SubConfiguration: "subConfiguration",
});

export const PAYLOAD_MAP = new Map([
    [TRIGGER_FIELDS.Events, [FIELD_KEYS.Type, FIELD_KEYS.Configuration, FIELD_KEYS.SubConfiguration]],
    [TRIGGER_FIELDS.Conditions, [FIELD_KEYS.Type, FIELD_KEYS.Configuration, FIELD_KEYS.SubConfiguration]],
    [TRIGGER_FIELDS.Actions, [FIELD_KEYS.Type, FIELD_KEYS.Configuration, FIELD_KEYS.SubConfiguration]],
]);

export const TRIGGER_FIELDS_CONFIG_MAP = {
    [TRIGGER_FIELDS.Events]: {
        idKey: "event_id",
        configIdKey: "event_config_id",
        configValueKey: "event_config_value",
        endpoint: "event",
    },
    [TRIGGER_FIELDS.Conditions]: {
        idKey: "condition_id",
        configIdKey: "condition_config_id",
        configValueKey: "condition_config_value",
        endpoint: "condition",
    },
    [TRIGGER_FIELDS.Actions]: {
        idKey: "action_id",
        configIdKey: "action_config_id",
        configValueKey: "action_config_value",
        endpoint: "action",
    },
};

export const TRIGGER_FIELDS_ENDPOINTS = {
    [TRIGGER_FIELDS.Events]: TRIGGER_FIELDS_CONFIG_MAP[TRIGGER_FIELDS.Events].endpoint,
    [TRIGGER_FIELDS.Conditions]: TRIGGER_FIELDS_CONFIG_MAP[TRIGGER_FIELDS.Conditions].endpoint,
    [TRIGGER_FIELDS.Actions]: TRIGGER_FIELDS_CONFIG_MAP[TRIGGER_FIELDS.Actions].endpoint,
};

export const OPERATORS_LIST = [
    { name: "All", id: "and" },
    { name: "Any", id: "or" },
];

export const MAX_ITEMS_COUNT = 5;

export const MIN_ITEMS_COUNT = 1;

// Register a global custom directive called `v-allow`
// which will check if the permission exists in  the global permissions 
import Vue from "vue"
import { checkPermission } from "../utils/functions"
import { ErrorMessages } from "../utils/constants";

Vue.directive('allow', {
    // When the bound element is inserted into the DOM...

    bind: function (el, binding, vNode) {
        let needToCheckPermission = true;
        let editValues = ['package.edit', 'field.edit', 'block.edit', 'check.view'];
        if(editValues.includes(binding.value) && vNode.context.$route?.params?.action === 'view') {
            needToCheckPermission = false;
        }
        let isPermitted = needToCheckPermission ? checkPermission(binding.value) : true;
        let userFeatures = vNode.context.$store.state.userFeatures
        let licenseFound = false
        if (binding.arg === 'license') {
            licenseFound = binding.value.some(res=>userFeatures.includes(res))
        }
        // if permission not found
        if (!isPermitted && binding.arg==='auth') {
                // let display =  el.style.display 
            el.innerHTML = `<div class='flex p-4 mb-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800' role='alert'><svg aria-hidden='true' class='flex-shrink-0 inline w-5 h-5 mr-3' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clip-rule='evenodd'></path></svg><span class='sr-only'>Info</span><div><span class='font-medium'>Access Denied!</span> ${ErrorMessages.Authorization} </div></div>`
                // } else {
        }
        if (!licenseFound && binding.arg === 'license') {
            el.innerHTML = `<div class='flex p-4 mb-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800 h-20' role='alert'><svg aria-hidden='true' class='flex-shrink-0 inline w-5 h-5 mr-3' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z' clip-rule='evenodd'></path></svg><span class='sr-only'>Info</span><div><span class='font-medium'>Access Denied!</span> ${ErrorMessages.License} </div></div>`
        }
    }
})
export const CASE_CREATION_STEPS = {
    ENTITY_DETAILS: 'entity_details',
    COLLECT_INFO: 'collect_info',
    CHECKS: 'checks',
    SUBMIT: 'submit',
    CORPORATE_RECORD: 'corporate_records', // remove?
    ADVERSE_CHECK: 'adverse_checks', // remove?
    ENTITY_WORKFLOW: 'entity_workflow',
};

export const CHANGE_STATUSES = {
    ADDED: 'added',
    UPDATED: 'updated',
    DELETED: 'deleted',
};

export const CASE_CREATION_STEPS_LIST  = [
    {
      id: CASE_CREATION_STEPS.ENTITY_DETAILS,
      label: "Entity Details",
    },
    {
      id: CASE_CREATION_STEPS.COLLECT_INFO,
      label: "Collect Info",
    },
    {
      id: CASE_CREATION_STEPS.CHECKS,
      label: "Checks",
    },
    {
      id: CASE_CREATION_STEPS.SUBMIT,
      label: "Submit",
    },
];

export const CASE_CREATION_STEPS_LIST_V2  = [ // remove?
    {
      id: CASE_CREATION_STEPS.ENTITY_DETAILS,
      hidden: true,
    },
    {
      id: CASE_CREATION_STEPS.CORPORATE_RECORD,
      label: "Initial data entry",
    },
    {
      id: CASE_CREATION_STEPS.ADVERSE_CHECK,
      label: "KYC",
    },
];

// Use for the Entity Workflow case:
export const CASE_CREATION_STEPS_ENTITY_FLOW  = [
    {
      id: CASE_CREATION_STEPS.ENTITY_DETAILS,
      hidden: true, // TODO check
    },
    // TODO: Uncomment if it is better to show the CaseScreen in the separate step:
    // {
    //   id: CASE_CREATION_STEPS.ENTITY_WORKFLOW,
    //   hidden: true, // TODO configure timeline logic
    // },
];

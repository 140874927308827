<template>
    <div>
        <tabs class="ml-10" @select="selectTab" :selected="tabs.selected" :tabs="tabs.list" :isVerificationCheck="true" :isTabDisable="isTabDisable"/>
        <div class="flex parentBusinessCheckDiv">
            <div v-if="tabs.selected == 'run_check' || tabs.selected  == 'output'" class="my-6 absolute queryContainer">
                <template>
                    <div class="bg-gray-200 p-4 rounded-t-lg flex justify-between">
                        <span class="font-semibold searchQueryField">
                            Search Query History
                        </span>
                        <!-- <span class="font-semibold text-primary searchQueryField" @click="clearSearchQuery">
                            Clear
                        </span> -->
                    </div>
                    <div v-if="queryDataFiltered?.length > 0" class="queryCard overflow-y-auto">
                        <div v-for="(item,id) in queryDataFiltered" :key="id"> 
                            <div @click="getOutputScreen(item,id,false)" class="pt-1.5 bg-white hover:bg-gray-300" :class="{'bg-gray-300' : currentOutputId==id}">
                                <div class="px-4">
                                    <span class="font-semibold searchQueryField">{{item?.query?.identity?.completename ? item?.query?.identity?.completename : ""}}</span>
                                </div> 
                                <div class="px-4 pb-2" :title="item?.query?.address?.addressLine1 +' '+ item?.query?.address?.addressLine2+' '+ item?.query?.address?.countryCode">
                                    <span class="text-sm text-gray-500">{{item?.query?.address?.addressLine1 || item?.query?.address?.addressLine2 ? (item?.query?.address?.addressLine1 +' '+ item?.query?.address?.addressLine2).length > 32 ? (item?.query?.address?.addressLine1 +' '+ item?.query?.address?.addressLine2).substring(0,32).trim()+'...' : item?.query?.address?.addressLine1 +' '+ item?.query?.address?.addressLine2+' '+ item?.query?.address?.countryCode : "No address found"}}</span>
                                </div> 
                                <div class="w-full border-b"></div>
                            </div> 
                        </div>
                    </div>
                    <div v-else class="px-4 py-2 bg-white flex justify-center">
                        No Data Found
                    </div>
                </template>
                <!-- <template v-else>
                    <div class="flex justify-center items-center w-full p-10">
                        <Loader />
                    </div>
                </template> -->
            </div>
            <div v-if="this.tabs.selected=='check_data'" class="scroll-bar flex flex-col gap-4 w-full ml-10 pt-5 pr-5 max-h-full">
                <template v-if="!CaseCheckFormPreviewLoader">
                    <div class="overflow-hidden rounded-2xl shadow-xl bg-card-bg w-full flex flex-col fixed-height" style="height: calc(100% - 48px)">
                        <FormBuilder :data="formData" :saveSectionMethod="saveSection" :removeBlockMethod="removeBlockMethod" :removeFieldMethod="removeFieldMethod" :isDBSCheck="integratedCheckSubType === 'dbs'" @submit="saveForm" :userInfo="candidateData"/>
                    </div>
                </template>
                <template v-else>
                    <div class="flex justify-center items-center">
                        <Loader />
                    </div>
                </template>
            </div>
            <div v-if="this.tabs.selected=='run_check'">
                <div v-if="!isRunCheckLoaded" class="w-full ml-10 pt-5">
                    <div class="w-full h-full flex flex-col gap-6 layout rounded-lg">
                        <div class="bg-gray-100 rounded-lg" style="width: 880px">
                            <div class="flex items-center rounded-t-lg justify-between py-1.5 bg-blue-600 text-white-text px-4 font-bold"> 
                                <div class="w-1/4"></div>
                                <div class="flex w-full justify-evenly">
                                    <div class="flex mr-7 cursor-default" @click="resetFields">
                                        <span class='m-auto'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 13" fill="none">
                                                <g clip-path="url(#clip0_9220_1920)">
                                                <path d="M11.7265 0.5H10.6153C10.5772 0.499957 10.5395 0.50765 10.5045 0.522612C10.4695 0.537574 10.4379 0.559494 10.4116 0.587042C10.3853 0.61459 10.3648 0.647193 10.3515 0.682873C10.3382 0.718553 10.3323 0.756567 10.3341 0.794609L10.4278 2.7343C9.88274 2.09199 9.20426 1.57615 8.43959 1.22268C7.67492 0.8692 6.84242 0.686572 6 0.6875C2.79703 0.6875 0.185158 3.30148 0.187502 6.50445C0.189845 9.71257 2.79141 12.3125 6 12.3125C7.43931 12.3145 8.8278 11.7805 9.89484 10.8146C9.92335 10.7891 9.94635 10.758 9.96244 10.7233C9.97853 10.6886 9.98737 10.651 9.98843 10.6128C9.98948 10.5745 9.98272 10.5365 9.96856 10.5009C9.95441 10.4654 9.93315 10.4331 9.90609 10.4061L9.10922 9.60922C9.05879 9.5588 8.99108 9.52942 8.91981 9.52702C8.84853 9.52463 8.77901 9.5494 8.72531 9.59632C8.09565 10.1503 7.3121 10.4992 6.47909 10.5965C5.64609 10.6938 4.80322 10.5349 4.06285 10.1409C3.32247 9.74693 2.71977 9.13665 2.33509 8.39141C1.9504 7.64618 1.80202 6.80139 1.90971 5.96967C2.01739 5.13794 2.37604 4.3588 2.93784 3.73612C3.49964 3.11343 4.2379 2.67677 5.05419 2.48437C5.87049 2.29196 6.72604 2.35294 7.5068 2.65918C8.28755 2.96542 8.95642 3.50236 9.42422 4.19844L7.04461 4.0843C7.00657 4.08249 6.96855 4.08842 6.93287 4.10175C6.89719 4.11507 6.86459 4.1355 6.83704 4.1618C6.80949 4.18809 6.78757 4.21971 6.77261 4.25474C6.75765 4.28976 6.74996 4.32746 6.75 4.36555V5.47672C6.75 5.55131 6.77963 5.62285 6.83238 5.67559C6.88512 5.72834 6.95666 5.75797 7.03125 5.75797H11.7265C11.8011 5.75797 11.8726 5.72834 11.9254 5.67559C11.9781 5.62285 12.0077 5.55131 12.0077 5.47672V0.78125C12.0077 0.706658 11.9781 0.635121 11.9254 0.582376C11.8726 0.529632 11.8011 0.5 11.7265 0.5Z" fill="#DCECFF"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_9220_1920">
                                                <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <span class="ml-2 m-auto">Reset</span>
                                    </div>
                                    <div class="w-2/5 mr-7">
                                        <vue-multiselect 
                                            :multiple="false" 
                                            :taggable="false" 
                                            :close-on-select="true" 
                                            :clear-on-select="true" 
                                            :preserve-search="true" 
                                            placeholder="Select Country" 
                                            :show-labels="false" 
                                            :maxHeight="200" 
                                            :options="countryList"
                                            :allow-empty="false"
                                            v-model="isCountrySelected"
                                            :title="selectedCountry"
                                            @input="updateSelectedReport($event)"
                                            class="gdc_country_dropdown ml-2 h-10 relative bottom-4"> 

                                            <span
                                                slot="caret"
                                                slot-scope="{ toggle }"
                                                class="arrow"
                                                @mousedown.prevent.stop="toggle"
                                            >
                                                <font-awesome-icon
                                                class="icon"
                                                icon="chevron-down"
                                                />
                                            </span>
                                            <template slot="option" slot-scope='options'>        
                                                <div>          
                                                    <span :title='options.option'> {{ options.option.length > 22 ? options.option.substring(0,22)+ '...' : options.option }}</span>        
                                                </div>      
                                            </template>
                                        </vue-multiselect>
                                    </div>
                                    <div class="w-2/6 mr-7">
                                        <vue-multiselect 
                                            :multiple="false" 
                                            :taggable="false" 
                                            :close-on-select="true" 
                                            :clear-on-select="true" 
                                            :preserve-search="true" 
                                            placeholder="Type" 
                                            :show-labels="false" 
                                            :maxHeight="200" 
                                            :options="getListedCountriesType"
                                            :allow-empty="false"
                                            v-model="selectedType"
                                            @input="updatedSelectedType($event)"
                                            class="gdc_country_dropdown ml-2 h-10 relative bottom-4"> 

                                            <span
                                                slot="caret"
                                                slot-scope="{ toggle }"
                                                class="arrow"
                                                @mousedown.prevent.stop="toggle"
                                            >
                                                <font-awesome-icon
                                                class="icon"
                                                icon="chevron-down"
                                                />
                                            </span>
                                        </vue-multiselect>
                                    </div>
                                </div>
                            </div>
                            <div v-if="(filteredData['Address']?.length && filteredData['Company ID']?.length && filteredData['Company Name']?.length) || !selectedCountry" class="p-4 overflow-y-auto scroll-bar flex flex-col gap-2">
                                <div v-if="(!selectedCountry && isFieldsReset)" class="h-80 flex flex-col justify-center items-center">
                                    <span class="font-bold text-xl">No Data Here</span>
                                    <span class="mt-2">Select any country first</span>
                                </div>
                                <div v-else-if="!countryList.length && !isFieldsReset" class="bg-card-bg p-5 flex justify-center items-center">
                                    <loader />
                                </div>
                                <div v-else>
                                    <div v-for="[id, items, currIndex] in Object.entries(filteredData)" :key="id" class="px-2 pt-3.5 pb-2.5 mb-2 bg-white rounded-lg flex justify-between">
                                        <div class="flex flex-col relative top-1">
                                            <div class="flex mb-3">
                                                <input type="checkbox" class="checkbox checkbox-primary ml-2 runCheckCheckbox" :key="id+(currIndex)" @change="selectAll($event.target.checked, id)" :ref="id" :checked="true"/>
                                                <span class="font-semibold px-2 ml-3">{{ id }}</span>
                                                <vue-multiselect 
                                                    v-if="id=='Address'"
                                                    :title="!currentAddress ? filteredData['Address'][0]?.title : currentAddress"
                                                    :multiple="false" 
                                                    :taggable="false" 
                                                    :close-on-select="true" 
                                                    :clear-on-select="true" 
                                                    :preserve-search="true" 
                                                    placeholder="Select ID" 
                                                    :show-labels="false" 
                                                    :maxHeight="200" 
                                                    :options="selectedVal('Address')"
                                                    :allow-empty="false"
                                                    v-model="selectedAddress"
                                                    @input="updateAddress($event,id)"
                                                    class="relative right-3.5 mt-1 bottom-2 addressDropdown idDropdown"> 

                                                    <span
                                                        slot="caret"
                                                        slot-scope="{ toggle }"
                                                        class="idArrow"
                                                        @mousedown.prevent.stop="toggle"
                                                    >
                                                        <font-awesome-icon
                                                        class="icon"
                                                        icon="chevron-down"
                                                        />
                                                    </span>
                                                    <template slot="option" slot-scope='options'>        
                                                        <div>          
                                                            <span :title='options.option'> {{ options.option.length > 42 ? options.option.substring(0,42)+'...' : options.option }}</span>        
                                                        </div>      
                                                    </template>
                                                </vue-multiselect>
                                            </div>
                                            <div v-if="id=='Company Name'" style="width: 832px">
                                                <!-- <div class="flex w-full justify-between relative top-1 mb-3 mt-3">
                                                    <span class="font-semibold px-2 ml-5">Full Company Name</span>
                                                    <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                        <span :title="items?.[0]?.full_company_name">{{ items?.[0].full_company_name }}</span>
                                                    </span>
                                                </div> -->
                                                <div class="flex w-full justify-between relative top-1 mt-3 mb-3">
                                                    <span class="font-semibold px-2 ml-5">Organization Name</span>
                                                    <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                        <span :title="items?.[0]?.organization_name" >{{ items?.[0]?.organization_name.length > 35 ? items?.[0]?.organization_name.substring(0,35)+'...' : items?.[0]?.organization_name }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-if="id=='Company ID'" style="width: 832px">
                                                <div class="flex w-full justify-between relative top-1 mb-3 mt-3">
                                                    <span class="font-semibold px-2 ml-5">{{ id }}</span>
                                                    <div class="flex mr-3">
                                                        <vue-multiselect 
                                                            :multiple="false" 
                                                            :taggable="false" 
                                                            :close-on-select="true" 
                                                            :clear-on-select="true" 
                                                            :preserve-search="true" 
                                                            placeholder="Select ID" 
                                                            :show-labels="false" 
                                                            :maxHeight="200" 
                                                            :options="selectedVal('Company ID')"
                                                            :allow-empty="false"
                                                            v-model="selectedId"
                                                            :title="!currentId ? filteredData['Company ID'][0].idName : currentId"
                                                            @input="updateId($event,id)"
                                                            class="relative right-3.5 bottom-2 userDetails idDropdown"> 
        
                                                            <span
                                                                slot="caret"
                                                                slot-scope="{ toggle }"
                                                                class="idArrow"
                                                                @mousedown.prevent.stop="toggle"
                                                            >
                                                                <font-awesome-icon
                                                                class="icon"
                                                                icon="chevron-down"
                                                                />
                                                            </span>
                                                            <template slot="option" slot-scope='options'>        
                                                                <div>          
                                                                    <span :title='options.option'> {{ options.option.length > 11 ? options.option.substring(0,11)+ '...' : options.option }}</span>        
                                                                </div>      
                                                            </template>
                                                        </vue-multiselect>
                                                        <div class="p-2"></div>
                                                        <vue-multiselect 
                                                            :multiple="false" 
                                                            :taggable="false" 
                                                            :close-on-select="true" 
                                                            :clear-on-select="true" 
                                                            :preserve-search="true" 
                                                            placeholder="Select Value" 
                                                            :show-labels="false" 
                                                            :maxHeight="200" 
                                                            :options="selectCompIdVal()"
                                                            :allow-empty="false"
                                                            @input="updateIdVal($event,id)"
                                                            v-model="selectedIdVal"
                                                            :title="!currentIdVal ? filteredData['Company ID'][0][filteredData['Company ID'][0]?.idName] : currentIdVal"
                                                            class="relative right-3.5 bottom-2 userDetails idValDropdown"> 
        
                                                            <span
                                                                slot="caret"
                                                                slot-scope="{ toggle }"
                                                                class="idArrowVal"
                                                                @mousedown.prevent.stop="toggle"
                                                            >
                                                                <font-awesome-icon
                                                                class="icon"
                                                                icon="chevron-down"
                                                                />
                                                            </span>
                                                            <template slot="option" slot-scope='options'>        
                                                                <div>          
                                                                    <span :title='options.option'> {{ options.option.length > 11 ? options.option.substring(0,11)+'...' : options.option }}</span>        
                                                                </div>      
                                                            </template>
                                                        </vue-multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show=false v-if="id=='Address'" style="width: 832px">
                                                <div v-for="(val,index) in items" :key="index">
                                                    <div v-if="((currentAddress == selectedAddress) && val.title == selectedAddress) || (val.title == currentAddress || val.full_address == selectedAddress || val.title == selectedAddress)">
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Address Line 1</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.addressLine1">{{ val?.addressLine1 ? val?.addressLine1.length > 35 ? val?.addressLine1.substring(0,35)+'...' : val?.addressLine1 : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Address Line 2</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.addressLine2">{{ val?.addressLine2 ? val?.addressLine2.length > 32 ? val?.addressLine2.substring(0,32)+'...' : val?.addressLine2 : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">House Number</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.houseNumber">{{ val?.houseNumber ? val?.houseNumber : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Additional House Number</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.additionalHouseNumber">{{ val?.additionalHouseNumber ? val?.additionalHouseNumber : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Thoroughfare</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.thoroughfare">{{ val?.thoroughfare ? val?.thoroughfare : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">District</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.district">{{ val?.district ? val?.district : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Locality</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.locality">{{ val?.locality ? val?.locality : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Post Code</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.postalCode">{{ val?.postalCode ? val?.postalCode : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Province</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.province">{{ val?.province ? val?.province : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                        <div class="flex w-full justify-between relative top-1 mb-3">
                                                            <span class="font-semibold px-2 ml-5">Country</span>
                                                            <span class="ml-4 w-2/4 mr-6 py-2 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                                <span :title="val?.country">{{ val?.country ? val?.country : '--' }}</span>
                                                            </span>    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="bg-card-bg p-5 flex justify-center items-center">
                                <loader />
                            </div>
                        </div> 
                    </div>
                </div> 
                <template v-else>
                    <div class="flex justify-center items-center w-full p-10">
                        <Loader />
                    </div>
                </template>
                <div class="w-full flex runGdcButton">
                    <button :class="!selectedCountry || isFieldsReset || activateLoader || isRunCheckLoaded || (!filteredData['Address']?.length && !filteredData['Company ID']?.length && !filteredData['Company Name']?.length) ? 'bg-gray-300' : 'bg-blue-600'" class="py-2 px-3 rounded-lg ml-auto text-white font-bold w-52 flex justify-center items-center" :disabled="isFieldsReset || !selectedCountry || activateLoader || isRunCheckLoaded || (!filteredData['Address']?.length && !filteredData['Company ID']?.length && !filteredData['Company Name']?.length)" @click="runCheck">
                        Run Check
                        <template>
                            <div v-if="activateLoader" class="flex justify-center items-center ml-4">
                                <Loader class='runCheckLoader'/>
                            </div>
                        </template>
                    </button>
                </div>     
            </div>
            <div v-if="this.tabs.selected=='output'">
                <caseOutput :outputData="getOutputData" :updatedFilteredData="filteredData" @backToSearch="backToSearch" :showOutputScreen="showOutputScreen" :currentOutputData="currentOutputData" :gdcListedCountries="gdcListedCountries"/>
            </div>    
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import axios from "@shared/light-ray/axios";
import {default as rawAxios} from "@shared/light-ray/axios/gdc-osint-axios.js";
import {default as blackboxAxios} from "@shared/light-ray/axios/gdc-blackbox-axios.js";
import VueCookies from "vue-cookies";
import Loader from "@/components/loader";
import tabs from "@/components/dashboard-components/tabs";
import FormBuilder from "@/components/input-form-builder";
import caseOutput from "./outputScreen.vue";

export default {
    title: "GDC Business Check",
    name: "gdc-business-check",
    components: {
        tabs,
        FormBuilder,
        Loader,
        VueMultiselect,
        caseOutput,
    },
    props: {
        CaseCheckFormPreviewData: {
            type: Object,
            default: () => {},
        },
        CaseCheckFormPreviewLoader: {
            type: Boolean,
        },
        saveSection: {
            type: Function,
        },
        integratedCheckSubType: {
            type: String,
        },
        saveForm: {
            type: Function,
        },
        candidateData: {
            type: Object,
        },
        clientId: {
            type: String,
        },
        nonRefCheckData: {
            type: Object,
        },
        removeBlockMethod: {
            type: Function,
        },
        removeFieldMethod: {
            type: Function,
        },
        isClient: {
            type: Boolean,
        },
    },
    data() {
        return {
            tabs: {
                selected: "Run Checks",
                list: [
                    {
                        id: "check_data",
                        label: "Check Data",
                    },
                    {
                        id: "run_check",
                        label: "Run Check",
                    },
                    {
                        id: "output",
                        label: "Output",
                    },
                ],
            },
            countryList: [],
            selectedCountry: null,
            selectedType: null,
            gdcListedCountries: [],
            filteredData: {},
            isRunCheckSelected: false,
            isFieldsReset: false,
            selectedId: null,
            selectedIdVal: null,
            selectedAddress: null,
            currentAddress: null,
            outputData: [],
            queryDataFiltered: null,
            activateLoader: false,
            isRunCheckLoaded: false,
            isRunCheckedClicked: false,
            searchQueryData: null,
            isSelectAllChecked: false,
            checkFormData: null,
            currentSelectAllId: '',
            currentId: '',
            currentIdVal: '',
            showOutputScreen: false,
            currentOutputData: null,
            currentOutputId: -1,
            isCurrentDivClicked: false,
            currentClientId: '',
            caseAndCheckData: {},
            isTabDisable: false,
            isBusinessIdValid: false,
            isBusinessIdValValid: false,
        };
    },
    async mounted() {
        this.currentClientId = this.clientId
        this.tabs.selected = "run_check";
        this.caseAndCheckData = this.CaseCheckFormPreviewData
        this.user_details = this.$store.getters.getUserDetails;
        await this.selectTab(this.tabs.selected);
    },
    watch: {
        selectedId(newVal,oldVal){
            if(this.isBusinessIdValid) {
                this.selectedId = oldVal.length > 11 ? oldVal.substring(0,11) + '...' : oldVal; 
            }
            else this.selectedId = newVal.length > 11 ? newVal.substring(0,11) + '...' : newVal; 
            this.isBusinessIdValid = false;
        },
        selectedIdVal(newVal,oldVal){
            if(this.isBusinessIdValValid) {
                this.selectedIdVal = oldVal && oldVal !==null && oldVal.length > 11 ? oldVal.substring(0,11) + '...' : oldVal; 
            }
            else this.selectedIdVal = newVal && newVal !== null &&  newVal.length > 11 ? newVal.substring(0,11) + '...' : newVal; 
            this.isBusinessIdValValid = false;
        },
    },
    computed: {
        getGdcListedCountries() {
            const allSecBlocks = this.caseAndCheckData?.sections?.find(el => el.entities)?.entities || []
            const addressDetails = allSecBlocks?.find(el => el.block_name == 'Address Details')?.entities
                    
            const updateFilteredData =  new Set();

            addressDetails?.forEach(data => { 
                if (data?.field_internal_name === "country" && data?.options) { 
                    const matchingOption = data?.options.find(optVal => optVal?.option_value === data?.value);
                    if (matchingOption) {
                        data.actual_value = matchingOption?.option_label;
                        updateFilteredData.add(data.actual_value);
                    }
                }
            });

            return Array.from(updateFilteredData);
        },
        getListedCountriesType() {
            let gdcCountries = [...this.gdcListedCountries];
            let verificationType = [];
            let countryType = gdcCountries.find(el => this.selectedCountry == el?.country_name);
            if(countryType) verificationType =  countryType.verification_type;
            return verificationType;
        },
        isCountrySelected() {
            if(this.selectedCountry && this.selectedCountry.length > 18) return this.selectedCountry.substring(0,18)+'...';
            else return this.selectedCountry
        },
        getOutputData() {
            return this.outputData;
        },
        formData(){
            return this.caseAndCheckData;
        },
    },
    methods: {
        getAllCountries() {
            // const allSecBlocks = this.caseAndCheckData?.sections?.find(el => el.entities)?.entities || []

            const sectionsArray = this.caseAndCheckData?.sections || [];
            const flattenedArray = sectionsArray.flatMap(section => section.entities || []);
            const addressDetails = flattenedArray?.find(el => el.block_name == 'Address Details')
                    
            const updateFilteredData =  new Set();

            addressDetails?.entities?.forEach(data => { 
                if (data?.field_internal_name === "country" && data?.options) { 
                    const matchingOption = data?.options.find(optVal => optVal?.option_value === data?.value);
                    if (matchingOption) {
                        data.actual_value = matchingOption?.option_label;
                        updateFilteredData.add(data.actual_value);
                    }
                }
            });

            this.countryList =  Array.from(updateFilteredData);
            this.countryList = [...new Set(this.countryList)];
            this.selectedCountry = this.countryList?.[0];
        },
        resetFields() {
            this.isFieldsReset = true;
            this.selectedCountry = ""
            this.selectedType = ""
        },
        async fetchCaseCheckFormPreview() {
            const case_id = this.$route.query.caseid;
            const tenant_check_id = this.nonRefCheckData.key;
            try {
                const { data } = await axios(`case/form/${case_id}?tenant_check_id=${tenant_check_id}`);
                data.sections = data.sections?.map((entities) => {
                    entities.entities = entities.entities?.map((block) => {
                        if(block?.form_entity_type == 'BLOCK' && block.block_fe_id == null) {
                            block.block_fe_id = block?.block_id;
                        }
                        if(block?.form_entity_type == 'BLOCK' && block?.block_fe_id == block?.block_id) {
                            block.is_og_block = true;
                        }
                        return block;
                    })
                    return entities
                })
                this.filteredGDCCheckData(data)
                this.$emit('getGdcFilteredData',this.filteredData)
                this.isRunCheckLoaded = false;

            } catch (error) {
                this.isRunCheckLoaded = false;
                this.isFieldsReset = true
                console.log(error); 
            }
        },
        async fetchCaseCheckFormPreviewFromClient() {
            const case_id = this.$route.query.caseid;
            const tenant_check_id = this.nonRefCheckData.key;
            try {
                this.isRunCheckLoaded = true;
                const { data } = await axios(`case/form/${case_id}/client?tenant_check_id=${tenant_check_id}`);
                data.sections = data.sections?.map((entities) => {
                    entities.entities = entities.entities?.map((block) => {
                        if(block?.form_entity_type == 'BLOCK' && block.block_fe_id == null) {
                            block.block_fe_id = block?.block_id;
                        }
                        if(block?.form_entity_type == 'BLOCK' && block?.block_fe_id == block?.block_id) {
                            block.is_og_block = true;
                        }
                        return block;
                    })
                    return entities
                })
                this.filteredGDCCheckData(data)
                this.$emit('getGdcFilteredData',this.filteredData)
                this.isRunCheckLoaded = false;

            } catch (error) {
                this.isRunCheckLoaded = false;
                this.isFieldsReset = true
                console.log(error); 
            }
        },
        async selectTab(id) {
            if(this.isTabDisable && (id == 'check_data' || id == 'output')) {
                return;
            }
            this.tabs.selected = id;
            if(this.tabs.selected == 'run_check') {
                this.isTabDisable = true;
                this.getAllCountries();
                this.filteredData = {};
                this.currentAddress = '';
                this.currentId = '';
                this.currentOutputId = -1,
                this.currentIdVal = '';
                await this.getSaasToken();
                await this.getQueryData();
                if(this.isClient) {
                    if(this.caseAndCheckData?.sections && !this.CaseCheckFormPreviewLoader) await this.fetchCaseCheckFormPreviewFromClient();
                }
                else {
                    if(this.caseAndCheckData?.sections && !this.CaseCheckFormPreviewLoader) await this.fetchCaseCheckFormPreview();
                }
                if(this.filteredData?.['Company ID'] && this.filteredData?.['Company ID'].length > 0) {
                    this.selectedId = this.truncateAndAddDots(this.filteredData?.['Company ID']?.[0]?.idName, 12);
                    this.selectedIdVal = this.truncateAndAddDots(this.filteredData?.['Company ID']?.[0]?.[this.filteredData?.['Company ID']?.[0]?.idName], 12);
                    this.currentId = this.filteredData?.['Company ID']?.[0]?.idName;
                    this.currentIdVal = this.filteredData?.['Company ID']?.[0]?.[this.filteredData?.['Company ID']?.[0]?.idName];
                    this.selectedAddress = this.truncateAndAddDots(this.filteredData?.['Address']?.[0]?.title, 40);
                }
                this.isTabDisable = false;
                // this.selectedId = this.filteredData?.['Company ID']?.[0]?.idName.length > 11 ? this.filteredData?.['Company ID']?.[0]?.idName.substring(0,11) + "..." : this.filteredData?.['Company ID']?.[0]?.idName;
                // this.selectedIdVal = this.filteredData?.['Company ID']?.[0]?.[this.filteredData?.['Company ID']?.[0]?.idName]?.length > 11 ? this.filteredData?.['Company ID']?.[0]?.[this.filteredData?.['Company ID']?.[0]?.idName]?.substring(0,11) + "..." : this.filteredData?.['Company ID']?.[0]?.[this.filteredData?.['Company ID']?.[0]?.idName];
                // this.currentId = this.filteredData?.['Company ID']?.[0]?.idName;
                // this.currentIdVal = this.filteredData?.['Company ID']?.[0]?.[this.filteredData?.['Company ID']?.[0]?.idName];
                // this.selectedAddress = this.filteredData?.['Address']?.[0]?.title.length > 40 ? this.filteredData?.['Address']?.[0]?.title.substring(0,40) + "..." : this.filteredData?.['Address']?.[0]?.title;

                this.filteredData['Company Name'][0].checked = true;
                this.filteredData['Company ID'][0].checked = true;
                this.filteredData['Address'][0].checked = true;
            }
            if(this.isFieldsReset)
                (this.tabs.selected == 'output'|| this.tabs.selected == 'check_data') ? this.isFieldsReset = false : this.isFieldsReset = true;
            this.$emit('selectedGDCTab', id);

            if(this.tabs.selected == 'output' && !this.isRunCheckedClicked && this.currentOutputId < 0) {
                let payload = this.queryDataFiltered[0]?.query
                try {
                    this.activateLoader = true;
                    // const token = VueCookies.get("saas-token")
                    const token = VueCookies.get("token")
                    await this.verifyPersonIdentity(token, payload, this.queryDataFiltered[0]?.query, false);
                    this.activateLoader = false
                } catch(error) {
                    this.activateLoader = false
                    this.$toast.error(error?.response?.data?.detail[0]?.msg);
                }
                this.currentOutputId = 0;
            }
        },
        updateSelectedReport(country){
            this.gdcLoading = true
            this.isFieldsReset = false
            this.selectedCountry = country;
            
            const fieldsToClear = ['Company Name', 'Address', 'Company ID'];
            fieldsToClear.forEach(fieldName => {
                if (this.$refs[fieldName]?.length > 0) this.$refs[fieldName][0].checked = false;
                this.selectAll(false, fieldName);
            });

            let compIdVal = [];
            this.filteredGDCCheckData(this.caseAndCheckData);
            this.filteredData?.['Company ID']?.map(el => {
                if(el?.currentCountry == this.selectedCountry)
                    compIdVal.push({'id': el?.idName, 'value':el[el?.idName]})
            });
            if(compIdVal.length > 0) {
                this.selectedId = compIdVal[0].id.length > 11 ? compIdVal[0].id.substring(0,11) + "..." : compIdVal[0].id
                this.selectedIdVal = compIdVal[0].value.length > 11 ? compIdVal[0].value.substring(0,11) + "..." : compIdVal[0].value
            } 
            else {
                this.selectedId = "";
            }
            this.filteredData['Address'].map(el => {
                if(el.country == this.selectedCountry) {
                    this.currentAddress = el.title
                    return;
                }
            })
            this.filteredData['Address'] = this.filteredData['Address'].map(el => {
                if(el.country != this.selectedCountry) {
                    el.isVisible = false
                } else {
                    el.isVisible = true
                }
                return el;
            })
            this.selectedAddress = this.filteredData?.['Address'].find(el => el?.title == this.currentAddress)?.full_address;
            this.gdcLoading = false

        },
        updatedSelectedType(type){
            if(this.isSelectAllChecked && this.currentSelectAllId) {
                this.$refs[this.currentSelectAllId][0].checked = true;
                this.selectAll(true, this.currentSelectAllId);
            }
            this.selectedType = type;
        },
        selectedVal(id) {
            let compIdVal = [];
            if(id=='Company ID') {
                this.filteredData?.['Company ID']?.forEach(el => {
                    if(el?.currentCountry == this.selectedCountry)
                        compIdVal.push(el?.idName)
                });
                if(compIdVal.length > 0) {
                    return compIdVal
                } 
                else {
                    this.selectedId = "";
                    return ['List is empty']
                }
            }
            if(id=='Address') return Object.values(this.filteredData['Address']?.map(el => el?.title));
        },
        selectCompIdVal() {
            let compIdVal = [];
            this.filteredData?.['Company ID']?.map(el => {
                if(el?.currentCountry == this.selectedCountry)
                    if(el[el?.idName] != null )  compIdVal.push(el[el?.idName])
            });
            if(compIdVal?.length > 0) {
                return compIdVal
            }
            else {
                this.selectedIdVal = ""
                 return ['List is empty']
            }
        },
        updateId(idName, id) {
            this.isBusinessIdValid = false;
            const selectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            let businessId = selectedCountry?.business_id_details?.find(el => el.name == idName)?.name;
            if(!businessId) {
                this.$toast.error(' This ID cannot be used for verification, please select other option')
                this.isBusinessIdValid = true;
                return;
            }
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentId = idName;
            this.selectedId = idName.length > 11 ? idName.substring(0,11) + '...' : idName ; 
            if(this.selectedId) {
                for(const el of  this.filteredData?.['Company ID']) {
                    if(idName == el?.idName){
                        this.currentIdVal = el[el?.idName]
                        console.log("currentIdVal",this.currentIdVal);
                        this.selectedIdVal =  el[el?.idName] &&  el[el?.idName] !== null && el[el?.idName]?.length > 11 ? el[el?.idName].substring(0,11) + '...' : el[el?.idName];
                        break;
                    }
                }
                // this.filteredData?.['Company ID'].map(el => {
                //     if(idName == el?.idName){
                //         this.currentIdVal = el[el?.idName]
                //         this.selectedIdVal = el[el?.idName]?.length > 11 ? el[el?.idName].substring(0,11) + '...' : el[el?.idName]
                //     }
                // })
            }
            this.isSelectAllChecked = false
        },
        updateIdVal(idVal, id) {
            this.isBusinessIdValValid = false;
            let idKey = this.filteredData['Company ID'].find(el => el[el.idName] == idVal)?.idName
            const selectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            let businessId = selectedCountry?.business_id_details?.find(el => el.name == idKey)?.name;
            if(!businessId) {
                this.$toast.error(' This ID cannot be used for verification, please select other option')
                this.isBusinessIdValValid = true;
                return;
            }
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentIdVal = idVal
            this.selectedIdVal = idVal.length > 11 ? idVal.substring(0,11) + '...' : idVal ;
            if(this.selectedIdVal) {
                for(const el of this.filteredData?.['Company ID']) {
                    if(el[el?.idName] == idVal) {
                        this.currentId = el?.idName
                        this.selectedId =  el?.idName && el?.idName !== null &&  el?.idName?.length > 11 ? el?.idName.substring(0,11) + '...' : el?.idName ;
                        break;
                    }
                }
                // this.filteredData?.['Company ID'].map(el => {
                //     if(el[el?.idName] == idVal) {
                //         this.currentId = el?.idName
                //         this.selectedId = el?.idName?.length > 11 ? el?.idName.substring(0,11) + '...' : el?.idName ;
                //     }
                // })
            }
            this.isSelectAllChecked = false
        },
        updateAddress(address,id) {
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentAddress = address;
            this.selectedAddress = address.length > 40 ? address.substring(0,40)+'...' : address;
        },
        selectAll(e, id){
            this.isSelectAllChecked = e;
            this.currentSelectAllId = id;
            if (e) {
                this.filteredData?.[id]?.map(el => {
                    if(id == 'Company Name') {
                        el.checked = true;
                    }
                    else if(id == 'Company ID') {
                        if(el[el?.idName] == this.currentIdVal) el.checked = true;
                    } 
                    else if(id == 'Address') {
                        if(el?.full_address == this.selectedAddress) el.checked = true;
                    }
                    return el;
                })
            } else {
                this.filteredData?.[id]?.map(el => {
                    el.checked = false
                    return el;
                })
            }
        },
        async getSaasToken() {
            const payload = {
                client_id: this.clientId ? this.clientId : sessionStorage.client_id
            }
            try {
                const url = `/user/saas-token`
                const data = await axios.post(url, payload)
                VueCookies.set("saas-token", data.data.token)
                if(!this.CaseCheckFormPreviewLoader) await this.getCountryList();
            }
            catch(error) {
                console.log(error);
                this.$toast.error( error?.response?.data?.detail[0]?.msg || "Error getting the Saas Token")
            }
        },
        async getCountryList() {
            try {
                const url = `api/v1/gdc/countries`
                const data = await rawAxios.get(url,
                {
                    headers: {                       
                           "x-entity-id": this.$route.query.entityId
                    },
                })
                this.gdcListedCountries = data.data.data.country_details;
                // if(this.gdcListedCountries) this.selectedCountry = this.getGdcListedCountries?.[0]
            }
            catch(error) {
                console.log(error)
                this.$toast.error("Error fetching the country list")
            }
        }, 
        async runCheck() {
            this.isRunCheckedClicked = true;
            this.activateLoader = false;
            this.isCurrentDivClicked = false;
            let isSelectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            if(!isSelectedCountry && this.getListedCountriesType.length == 0) {
                this.$toast.error('Not able to run the check for the selected country !')
                return;
            }   

            if(this.isSelectAllChecked && this.currentSelectAllId) {
                this.$refs[this.currentSelectAllId][0].checked = true;
                this.selectAll(true, this.currentSelectAllId);
            }

            let checkedId = this.filteredData?.['Company ID'].find(el => el?.checked == true )

            const selectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            let businessId = selectedCountry?.business_id_details?.find(el => el.name == this.currentId)?.name;
            if(!businessId && checkedId) {
                this.$toast.error(' This ID cannot be used for verification, please select other option')
                return;
            }
            
            if(!this.selectedType) {
                this.$toast.error("Type is required to run the check!");
                return;
            } 
            let checkedCompName = this.filteredData?.['Company Name'].find(el => el.checked == true);
            let checkedAddress = this.filteredData?.['Address'].filter(el => el.checked == true);
            
            if(!checkedId && !checkedCompName && !checkedAddress.length) {
                this.$toast.error("Atleast one field is required to run the check!")
                return;
            }
            
            let payloadObj = {
                sources: ["gdc"],
                address: {
                    "addressLine1": "",
                    "addressLine2": "",
                    "houseNumber": "",
                    "houseNumberAddition": "",
                    "thoroughfare": "",
                    "locality": "",
                    "postalCode": "",
                    "district": "",
                    "province": "",
                },
                identity: {
                    "completename": "",
                    "organization_name": "",
                    "businessid": []
                },
                verification_type: ""
            }
            payloadObj.address.addressLine1 = checkedAddress?.[0]?.addressLine1;
            payloadObj.address.addressLine2 = checkedAddress?.[0]?.addressLine2;
            payloadObj.address.houseNumber = checkedAddress?.[0]?.houseNumber;
            payloadObj.address.houseNumberAddition = checkedAddress?.[0]?.additionalHouseNumber;
            payloadObj.address.thoroughfare = checkedAddress?.[0]?.thoroughfare;
            payloadObj.address.locality = checkedAddress?.[0]?.locality;
            payloadObj.address.postalCode = checkedAddress?.[0]?.postalCode;
            payloadObj.address.district = checkedAddress?.[0]?.district;
            payloadObj.address.province = checkedAddress?.[0]?.province;
            payloadObj.address.countryCode = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry).country_code;

            payloadObj.identity.completename = checkedCompName?.organization_name
            payloadObj.identity.organization_name = checkedCompName?.organization_name
            if(checkedId){
                let val = checkedId[checkedId?.idName] == null ? '' : checkedId[checkedId?.idName] ;
                payloadObj.identity.businessid.push(val);
            }

            payloadObj.verification_type = this.selectedType

            try {
                this.activateLoader = true;
                // const token = VueCookies.get("saas-token")
                const token = VueCookies.get("token")
                const url = `api/v1/gdc/business/search_sources`;
                const data = await rawAxios.post(url, payloadObj, {
                headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-visited': false,
                        'x-monitoring': false,
                        'x-case-id': this.$route.params.caseid,
                        'x-user-id': this.user_details.id,
                        'x-tool-name': 'gdc-tool',
                        'x-status': 'RUNNING',
                        "x-entity-id": this.$route.query.entityId
                    },
                    
                })
                if (data) {
                    await this.verifyPersonIdentity(token, payloadObj, data.data);
                    this.activateLoader = false
                    await this.selectTab('output');
                }
            
            } catch(error) {
                this.activateLoader = false
                let message = "";
                const errorMessage = error?.response?.data?.detail[0]?.msg || "";
                const fieldName = error?.response?.data?.detail[0]?.loc?.[2] || "";
                if(errorMessage && fieldName) {
                    message = errorMessage.replace("this", fieldName);
                }
                this.$toast.error(message || 'Something went wrong!');
            }
            this.isSelectAllChecked = false;
            this.currentSelectAllId = '';
        },
        async verifyPersonIdentity(token,payloadData,responseData, cacheBypass=true) {
            const payload = payloadData;
            payload['request_id'] = responseData.request_id;
            payload['saved'] = false;
            payload['monitoring_status'] = false;
            payload['cache_bypass'] = cacheBypass;
            payload['cache_only'] = false;
            payload['refresh'] = cacheBypass;
            payload['visited'] = false;
            payload['monitoring'] = false;
            payload['case_id'] = this.$route.query.caseid;
            payload['case_check_id'] = this.nonRefCheckData.id;
            payload['case_name'] = this.$route.params.caseid;
            payload['user-id'] = this.user_details.id;
            payload['tool-name'] = 'gdc-tool';
            payload['status'] = 'RUNNING';
            payload['entity-id']= this.$route.query.entityId
            try {
                const url = `integrations/gdc/business/verify`
                let {data} = await axios.post(url, payload)
                // data.data.data.map(dta => {
                //     this.outputData.push(dta);
                // })
                if(!data.osint_status)
                    this.$toast.error(data.data.detail) 

                if(data?.data?.message && data?.data?.status == 1) this.$toast.success(`(${data?.data?.status_code})`+' '+data?.data?.message)
                else if(data?.data?.message && data?.data?.status == 0) this.$toast.error(`(${data?.data?.status_code})`+' '+data?.data?.message)

                this.outputData = data.data.data
                if(!this.isCurrentDivClicked) await this.getQueryData();
                this.$emit("updateStatus");
            }
            catch(error) {
                let message = "";
                const errorMessage = error?.response?.data?.detail[0]?.msg || "";
                const fieldName = error?.response?.data?.detail[0]?.loc?.[2] || "";
                if(errorMessage && fieldName) {
                    message = errorMessage.replace("this", fieldName);
                }
                this.$toast.error(message || 'Something went wrong!');
                this.selectTab('run_check');
            }
        },
        async getQueryData(){
            let query_id =  this.$route.params.caseid;
            let entity_id = this.$route.query.entityId;
            const url = `api/v1/tools-status`;
            const token = VueCookies.get("saas-token")
            // "x-tool-name": "company-name","x-case-id": payload.case_id,"x-status":"RUNNING"
            try {
                const data = await blackboxAxios.post(url, 
                {
                    case_id: query_id,
                    entity_id: entity_id   
                }, {
                    headers: {
                           'Authorization': `Bearer ${token}`,
                           'cache_bypass': true,
                           'cache_only': false,
                           'x-refresh': true,
                           'x-visited': false,
                           'x-monitoring': false,
                           'x-user-id': this.user_details.id,
                           'x-case-id': query_id,
                           "x-tool-name": "gdc-tool",
                           "x-status":"RUNNING",
                           "x-entity-id": this.$route.query.entityId
                       },
                }) 
                this.searchQueryData = data.data;
                const filteredQueryData = data?.data?.data?.filter(el => el.tool == 'gdc-tool').map((el) => ({
                    ...el,
                    ...{ 
                        query: {
                            ...el.query,
                            ... { request_id: el._id },
                        },
                    },
                })).sort((a, b) => {
                    const dateA = a.updated_at ? new Date(a.updated_at) : new Date(0); 
                    const dateB = b.updated_at ? new Date(b.updated_at) : new Date(0);
                    return dateB - dateA; // Desending order
                });
                this.queryDataFiltered = filteredQueryData
                this.$emit('gdcSearchQuery',filteredQueryData);
            }catch(error) {
                console.log(error);
            }
        },
        filteredGDCCheckData(data) {
            this.caseAndCheckData = data;
            let selectedCountry = "";
            let currenCunIds = [];
            this.isRunCheckSelected = true;
            this.isRunCheckLoaded = false;
            let address_countries = [];
            let identity = {};
            this.filteredData =  {
                'Company Name': [],
                'Company ID': [],
                'Address': [],
            }
            
            data?.sections?.forEach(item => {
                this.isRunCheckLoaded = true;
                item?.entities?.forEach(blockData => {
                    blockData?.entities?.forEach(val => {
                        // if(val?.field_internal_name == 'full_company_name') {
                        //     identity.full_company_name = val?.value
                        // }
                        if(val?.field_internal_name == 'organization_name') {
                            identity.organization_name = val?.value
                        }
                        else if(val?.field_internal_name == 'country_for_business_verification' && blockData?.block_name != 'Address Details') {
                            selectedCountry = val;
                            return;
                        }
                    });
                    if(blockData?.block_name == "Address Details") {
                        let countryData = blockData?.entities?.find(el => el?.field_internal_name == 'country')
                        address_countries.push(countryData?.options.find(el => el?.option_value == countryData?.value)?.option_label)
                        let address = {
                            addressLine1: blockData?.entities?.find(el => el?.field_internal_name == 'address_line_1')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'address_line_1')?.value ,
                            addressLine2: blockData?.entities?.find(el => el?.field_internal_name == 'address_line_2')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'address_line_2')?.value,
                            houseNumber: blockData?.entities?.find(el => el?.field_internal_name == 'house_number')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'house_number')?.value,
                            additionalHouseNumber: blockData?.entities?.find(el => el?.field_internal_name == 'additional_house_number')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'additional_house_number')?.value,
                            thoroughfare: blockData?.entities?.find(el => el?.field_internal_name == 'street')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'street')?.value,
                            district: blockData?.entities?.find(el => el?.field_internal_name == 'neighbourhood/district')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'neighbourhood/district')?.value,
                            locality: blockData?.entities?.find(el => el?.field_internal_name == 'locality')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'locality')?.value,
                            postalCode: blockData?.entities?.find(el => el?.field_internal_name == 'post_code')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'post_code')?.value,
                            province: blockData?.entities?.find(el => el?.field_internal_name == 'county_/_provience_/_state')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'county_/_provience_/_state')?.value,
                            country: countryData?.options?.find(el => el?.option_value == countryData?.value).option_label,
                            countryCode:  (countryData?.options?.find(el => el?.option_value == countryData?.value)?.option_value)?.substring(0,2),
                            checked: false
                        }
                        address.title = address?.houseNumber+' '+address?.addressLine1+' '+address?.addressLine2+' '+address?.locality+' '+address?.postalCode
                        let fullAddress =  address?.houseNumber+' '+address?.addressLine1+' '+address?.addressLine2+' '+address?.locality+' '+address?.postalCode
                        fullAddress = fullAddress.length > 40 ? fullAddress.substring(0,40)+'...' : fullAddress
                        address.full_address = fullAddress
                        this.filteredData?.['Address']?.push(address);
                    }
                    if (selectedCountry && selectedCountry != '' && selectedCountry != undefined && blockData?.block_name != 'Address Details') {
                        for (const el of blockData?.entities) {
                            let selectedElement = el?.options?.find((el) => el?.["option_value"] === selectedCountry?.value);
                            currenCunIds = selectedElement?.cascading_fields?.map((el) => el);
                            // currenCunIds = blockData?.entities?.filter(el => el?.condition?.dependence_value == selectedCountry?.value && blockData?.block_name != 'Address Details')
                            currenCunIds?.forEach(el => {
                                this.filteredData?.['Company ID']?.push({
                                    idName: el?.label,
                                    [el.label]: el?.value,
                                    currentCountry: selectedCountry?.options?.find(opel => opel.option_value == selectedCountry?.value)?.option_label,
                                    isVisible: true,
                                    checked: false, 
                                });
                            });
                        }
                    }
                })
            }) 
            if(address_countries.length) {
                address_countries = [...new Set(address_countries)]
                this.countryList = address_countries;
                // this.selectedCountry = address_countries?.[0];
            }
            if(identity?.organization_name) {
                identity.checked = false
                this.filteredData?.['Company Name']?.push(identity);
            }
            this.filteredData['Address'] = this.filteredData?.Address.filter( item => item?.country == this.selectedCountry);
            if(!this.filteredData?.Address?.length && !this.filteredData?.['Company ID']?.length && !this.filteredData?.['Company Name']?.length) {
                this.isFieldsReset = true;
            }
            else this.isFieldsReset = false;
            this.isRunCheckLoaded = false;
        },
        async getOutputScreen(item,id, cacheBypass) {
            this.isCurrentDivClicked = true;
            this.currentOutputId = id
            this.showOutputScreen = true;
            this.currentOutputData = item;
            let payload = item?.query
            try {
                this.activateLoader = true;
                // const token = VueCookies.get("saas-token")
                const token = VueCookies.get("token")
                await this.verifyPersonIdentity(token, payload, item?.query, cacheBypass);
                this.activateLoader = false
                await this.selectTab('output');
                // const url = `api/v1/gdc/business/search_sources`;
                // const data = await rawAxios.post(url, payload, {
                // headers: {
                //         'Authorization': `Bearer ${token}`,
                //         'x-visited': false,
                //         'x-monitoring': false,
                //         'x-case-id': this.$route.params.caseid,
                //         'x-user-id': this.user_details.id,
                //         'x-tool-name': 'gdc-tool',
                //         'x-status': 'RUNNING'
                //     },
                    
                // })
                // if (data) {
                // }
            
            } catch(error) {
                this.activateLoader = false
                this.$toast.error(error?.response?.data?.detail[0]?.msg);
            }
        },
        filteredQueryData(item) {
            // let name = (item?.query?.identity?.first_name +' '+ item?.query?.identity?.middle_name +' '+ item?.query?.identity?.last_name).replace(/\s+/g, ' ').trim()
            if(item?.query?.identity?.completename) {
                let name = (item?.query?.identity?.completename).replace(/\s+/g, ' ').trim()
                let address = (item?.query?.address?.addressLine1 +' '+ item?.query?.address?.addressLine2).replace(/\s+/g, ' ').trim()
                let nameSection = this.CaseCheckFormPreviewData.sections.find(el => el.section_name == 'Company Name')
                let addressSection = this.CaseCheckFormPreviewData.sections.find(el => el.section_name == 'Address')
                let temp1 = 0; 
                let temp2 = 0; 
                addressSection.entities?.map(blockdata => {
                    let addressline1 = blockdata.entities.find(el => el.label == 'Address Line 1').value;
                    let addressline2 = blockdata.entities.find(el => el.label == 'Address Line 2').value;
                    let updatedAddressLine1 = addressline1 == null ? '' :  addressline1;
                    let updatedAddressLine2 = addressline2 == null ? '' :  addressline2;
                    let fullAddress = (updatedAddressLine1+' '+updatedAddressLine2).replace(/\s+/g, ' ').trim()
                    if(fullAddress == address) {
                        temp1 += 1;
                    }
                })
                let companyName = nameSection?.entities?.[0]?.entities.find(el => el?.field_internal_name == 'organization_name')?.value
                if(companyName == name) {
                    temp2 += 1;
                }
                if((temp1 == temp2) && temp1!=0 && temp2!=0) return true
                else return false
            }
        },
        async backToSearch(data){
            if(data) {
                this.selectedType = "";
                this.outputData = [];
                await this.selectTab("run_check");
            }
        },
        clearSearchQuery(){
            this.queryDataFiltered = [];
        },
        truncateAndAddDots(truncString, numberOfChar) {
            let truncatedString = '';
            if (truncString && truncString !== null && truncString !== 'null' && truncString?.length > 12) {
                truncatedString = truncString.substring(0, numberOfChar) + "..."
            } else {
                truncatedString = truncString;
            }
            return truncatedString;
        }
    }
}

</script>

<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;

.parentBusinessCheckDiv{
    position: relative;
}

.queryCard{
    height: 25rem;
}

@media screen and (min-width: 769px) {
    .queryContainer{
        max-width: 16rem;
        left: -16rem;
        top: 4rem;
    }
}
@media screen and (min-width: 1707px) {
    .queryContainer{
        min-width: 18rem;
        left: -18rem;
        top: 5.5rem;
        max-width: 19rem;  
    }
}
.runCheckCheckbox{
    border: 3px solid gray ;
    border-radius: 2px !important;
}
.userDetails {
    width: 12.3rem !important;
    min-width: 12.3rem !important;
    border-radius: 7px !important;
    border: 1px solid #E7E6E8;
}
.addressDropdown{
    width: 25.8rem !important;
    min-width: 25.8rem !important;
    border-radius: 7px !important;
    margin-left: 51.5%;
    border: 1px solid #E7E6E8;
}
.runGdcButton {
    padding: 10px;
    margin: 20px 0px 10px 0px;
}
.runCheckLoader {
    width: 30px ;
    height: 30px;
}

::v-deep {
    .gdc_country_dropdown .multiselect__tags {
        border-radius: 5px !important;
    }
    .gdc_country_dropdown .multiselect--active .icon {
        transform: rotate(180deg);
    }
    .multiselect .arrow{
        display: flex;
        flex-direction: row-reverse;
        position:relative;
        z-index: 1;
        right: 10px;
        top:27px;
    }
    .gdc_country_dropdown .multiselect__placeholder{
        padding-top: 0 !important;
        font-weight: 500 !important;
        color: black;
    }
    .userDetails .arrow{
        display: flex;
        flex-direction: row-reverse;
        position:relative;
        z-index: 1;
        left: 20px;
        top:15px;
    }
    .userDetails .idArrowVal,.idArrow {
        display: flex;
        flex-direction: row-reverse;
        position:relative;
        z-index: 1;
        right: 10px;
        top:12px;
    }
    .userDetails .multiselect__tags {
        border-radius: 10px !important;
        margin-top: -16px;
        position: relative;
        top:0;
        border: none;
    }
    .addressDropdown .multiselect__tags {
        border-radius: 10px !important;
        margin-top: -16px;
        position: relative;
        top:0;
        border: none;
    }
    .userDetails .multiselect--active .icon {
        transform: rotate(180deg);
    }
    .fixed-height{
        height: 72vh !important;
    }
}

</style>

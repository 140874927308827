<template>
    <div id="ownershipInsights" class="mt-3" v-if="Object.keys(ownershipInsight).length > 0 && fetchOwnership">
        <div class="tabActions flex justify-between items-center">
            <div class="tabContainer">
                <div class="tabs space-x-2">
                    <div
                        @click="selectedInfo(tab)"
                        v-for="(tab, index) in getOwnershipTabs"
                        :key="index"
                        class="tab px-6 text-sm"
                        :style="{
                            borderBottomColor: selected === tab ? 'var(--brand-color)' : '',
                        }"
                        @mouseover="hoveredOver = true"
                        @mouseleave="hoveredOver = false"
                    >
                        <span :class="{'text-blue-600': selected === tab, 'text-brand': ''}" class="tab-name">
                            {{ tab }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Ownership'">
            <div>
                <div class="mt-4">
                    <div class="flex flex-col mt-1">
                        <div class="overflow-x-auto scroll">
                            <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes" :class="ownershipInsight.shareOwnership && ownershipInsight.shareOwnership.length ? 'print-overflow-table' : ''">
                                <div class="shadow rounded border border-solid border-gray-300 fixTableHead">
                                    <table id="formerRegisteredNames" class="formerRegisteredNamesTable min-w-full divide-y divide-gray-200">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Entity Type
                                                </th>
                                                <th scope="col" v-if="!dbResellerCorpRecord && dbCorpRecord" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Duns
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Entity Name
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Other Language Names
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date of Birth
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Ownership percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Indirect Ownership Percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Voting Percentage High
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Last Update Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="ownershipInsight.shareOwnership && ownershipInsight.shareOwnership.length > 0">
                                            <!-- Odd row -->
                                            <tr v-for="(owner, index) in ownershipInsight.shareOwnership" :key="index">
                                                <td class="px-6 py-2  text-sm text-gray-500">
                                                    {{ owner.subjectType && owner.subjectType.description ? owner.subjectType.description : "-" }}
                                                </td>
                                                <td v-if="!dbResellerCorpRecord && dbCorpRecord" class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.duns }}
                                                </td>
                                                <td scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{{ owner.namePrefix }} {{ owner.fullName }} {{ owner.nameSuffix }} {{ owner.organizationName}}</td>
                                                <td>
                                                    <span v-if="owner.otherLanguageNames && owner.otherLanguageNames.length > 0">
                                                        <span v-for="(language, index) in owner.otherLanguageNames" :key="index">
                                                            <span class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                {{ language.fullName }} {{ language.language.description }}
                                                                <span v-if="owner.otherLanguageNames.length > 1 && owner.otherLanguageNames.length - 1">
                                                                    ,
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.dateOfBirth ? formatDate(owner.dateOfBirth) : "" }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.ownershipPercentage }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.indirectOwnershipPercentage }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.votingPercentageHigh }}
                                                </td>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {{ owner.lastUpdateDate ? formatDate(owner.lastUpdateDate) : "" }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                    No data available
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Stock Details'">
            <div>
                <div class="mt-4">
                    <div class="flex flex-col mt-1">
                        <div class="overflow-x-auto scroll">
                            <div class="align-middle inline-block min-w-full overflow-x-auto scroll industry_codes" :class="ownershipInsight.shareOwnership && ownershipInsight.shareOwnership.length ? 'print-overflow-table' : ''">
                                <div class="shadow rounded border border-solid border-gray-300 fixTableHead" >
                                    <table id="formerRegisteredNames" class="formerRegisteredNamesTable min-w-full divide-y divide-gray-200" style="display:block">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Entity Name
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Stock Type
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Stock Subtype
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Ownership percentage
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Share Quantity
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Share Amount
                                                </th>

                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Has Voting Rights
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Is Trustee Nominee
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Is Principal
                                                </th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Registration Numbers
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="height:100px;overflow:scroll" v-if="ownershipInsight.shareOwnership && ownershipInsight.shareOwnership.length > 0">
                                            <!-- Odd row -->
                                            <template v-for="owner in ownershipInsight.shareOwnership">
                                                <tr v-for="(stock, index) in owner.stockDetails" :key="index">
                                                    <td scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{{ owner.namePrefix }} {{ owner.fullName }} {{ owner.nameSuffix }}</td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {{ stock.stockType.description }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {{ stock.stockSubType }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {{ stock.ownershipPercentage }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {{ stock.shareQuantity }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{{ stock.shareAmount.value }} {{ stock.shareAmount.currency }}</td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {{ stock.hasVotingRights }}
                                                    </td>
                                                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                        {{ stock.isTrusteeNominee }}
                                                    </td>
                                                    <td>
                                                        <span v-if="stock.registrationNumbers && stock.registrationNumbers.length > 0">
                                                            <span v-for="(registration, index) in owner.registrationNumbers" :key="index">
                                                                <span class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                                                    {{ registration.registrationNumber }} {{ registration.typeDescription }}
                                                                    <span v-if="stock.registrationNumbers.length > 1 && stock.registrationNumbers.length < stock.registrationNumbers.length - 1">
                                                                        ,
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="3">
                                                    No data available
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selected === 'Capital Details'">
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Primary Name:</div>
                <div v-if="ownershipInsight && ownershipInsight.primaryName">
                    {{ ownershipInsight.primaryName }}
                </div>
                <div v-else>-</div>
            </div>

            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Capital Type:</div>
                <div v-if="ownershipInsight && ownershipInsight.capitalDetails.length > 0">
                    <div v-for="(details, index) in ownershipInsight.capitalDetails" :key="index">
                        <div v-if="details.capitalType && details.capitalType.description">
                            {{ details.capitalType.description }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Capital Amount:</div>
                <div v-if="ownershipInsight && ownershipInsight.capitalDetails.length > 0">
                    <div v-for="(details, index) in ownershipInsight.capitalDetails" :key="index">
                        <div v-if="details.capitalAmount && details.capitalAmount.value">{{ details.capitalAmount.value }} {{ details.capitalAmount.currency }}</div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
            <div class="mt-4 flex">
                <div class="font-medium text-sm text-gray-400 mr-2">Start Date:</div>
                <div v-if="ownershipInsight && ownershipInsight.capitalDetails.length > 0">
                    <div v-for="(details, index) in ownershipInsight.capitalDetails" :key="index">
                        <div v-if="details.startDate">
                            {{ details.startDate }}
                        </div>
                        <div v-else>-</div>
                    </div>
                </div>
                <div v-else>-</div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
    name: "OwnershipInsights",
    props: {
        ownershipInsight: {
            type: Object,
            default: ()=>{},
        },
        disableFetchMore: {
            type: Boolean,
        },
        fetchOwnership: {
            type: Boolean,
        },
    },
    data() {
        return {
            selected: "Ownership",
        };
    },
    computed: {
        ...mapGetters(["getUserFeatures"]),
        getOwnershipTabs() {
            let tabs = [];
            tabs = ["Ownership", "Stock Details", "Capital Details"];
            return tabs;
        },
        dbResellerCorpRecord() {
            return this.getUserFeatures.includes("dnb_reseller__corp_record");
        },
        dbCorpRecord() {
            return this.getUserFeatures.includes("db__corp_record");
        },
    },
    methods: {
        selectedInfo(info) {
            this.selected = info;
        },
        hideModal(modalname) {
            this.$modal.hide(modalname);
        },
        showModal(modalname) {
            this.$modal.show(modalname);
        },
    },
};
</script>
<style lang="scss" scoped>
.tabActions {
    display: flex;
    overflow: hidden;
    overflow-x: auto;
}
/* .scroll-bar {
    height: 10px;
} */
.tabs {
    width: 100%;
    border-bottom: 3px solid var(--brand-accent-neutral);
    cursor: pointer;
    margin: 0.4rem 0;
    font-family: var(--brand-font);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    display: flex;
}
.tabContainer {
    width: 100%;
}

.tab {
    transform: translateY(3px);
    border-bottom: 3px solid transparent;
    transition: var(--transition-mnml);
    border-radius: var(--component-border) var(--component-border) 0 0;
    font-size: 16px;
    text-align: center;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fixTableHead {
      overflow-y: auto;
      height: 400px;
}
.fixTableHead thead th {
    position: sticky;
    background: rgb(230, 227, 227);
    top: 0;
}
</style>

<template>
  <div class="flex flex-1 flex-col max-h-full w-full">
    <!-- <sub-header
      :handleSearchFilter="handleSearchFilter"
      buttonText="Add New Assessment"
      :handleClick="addAssessment"
      :showBreadCrumbs="false"
      :showBtn="!isViewMode && checkPermission('riskAssessment.create')"
    /> -->

    <Table
      id="riskTable"
      :columns="columnOption"
      :key="pageData?.total"
      :rows="risks"
      :isLoading="isLoading"
      :filters="serverParams"
      :filterOptions="columnFilterOptions"
      :filterOptionsAsync="columnFilterOptionsAsync"
      @per-page-change="onPageChange($event, true)"
      @page-change="onPageChange($event)"
      @column-filter="onColumnFilters($event)"
      :paginationOptions="paginationOptions"
      :totalRows="pageData?.total"
      @filters-clear="clearFilters"
      sortingEnabled
      @sort-change="onSortChange"
      @search-filter="getSearchOptionsTick($event)"
    >
      <template #link="customData">
        <div class="truncate max-w-full whitespace-nowrap cursor-pointer">
          <span v-tippy :content="customData.customData.name">
            {{ customData.customData.name }}
          </span>
        </div>
      </template>
      <template #actions="customData">
        <div class=" flex justify-around">
          <button
            class="h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer"
            v-tippy
            :content="'Edit'"
            :disabled="isViewMode || !checkPermission('riskAssessment.edit')"
            @click="editAssessment(customData.customData)"
          >
            <font-awesome-icon
              icon="edit"
              class="text-primary text-lg fa-3x"
            />
          </button>
          <router-link
            :to="{
              path: `${$route.path}/view/${customData.customData.id}`,
            }"
          >
            <button
              class="h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer"
              v-tippy
              :content="'View'"
            >
              <font-awesome-icon
                icon="eye"
                class="text- text-lg fa-3x"
                :class="
                  customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500'
                "
              />
            </button>
          </router-link>
          <button
            class="h-8 w-4 disabled:opacity-50 mx-2 text-danger whitespace-nowrap cursor-pointer"
            v-tippy
            :content="'Delete'"
            :disabled="isViewMode || !checkPermission('riskAssessment.edit')"
            @click="deleteAssessment(customData.customData)"
          >
            <font-awesome-icon
              icon="trash"
              class="text-red-600 text-lg fa-3x"
            />
          </button>
        </div>
      </template>
    </Table>

    <div class="flex justify-end pt-4" v-if="!isViewMode">
      <div class="">
        <Button
          text="Save and Next"
          class="py-2"
          type="primary"
          :disabled="!risks.length"
          @click="handleSubmit"
        />
      </div>
    </div>

    <modalContent
      ref="addAssessmentModal"
      name="addAssessmentModal"
      title="Add New Risk Assessment"
      :max-width="400"
      :show-footer="false"
      class="modal-class"
      @hidden="handleCloseModal"
    >
      <template #content>
        <FormulateForm class="min-h-full flex-1">
          <div class="add-check__modal-content h-full overflow-hidden">
            <div class="form flex w-full flex-col flex-1 overflow-auto scroll-bar pr-1">
              <FormulateInput
                v-model="assessmentData.name"
                validation="required"
                class="w-full"
                label="Assessment Name"
                placeholder="Assessment Name"
                type="text"
                variant="alt"
                :element-class="
                  (context, classes) => ['flex-1 min-w-full'].concat(classes)
                "
              />

              <div class="flex flex-col mb-4">
                <label for="#" class="px-0.5 pb-1">
                  Risk Category
                  <span class="text-red-500 ml-1 text-lg leading-3 mr-auto"> * </span>
                </label>
                <Dropdown
                  reposition
                  searchable
                  placeholder="Select Category" 
                  :options="options.categories"
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :config="{ label: 'name', trackBy: 'id' }"
                  :value="assessmentData.category"
                  @input="(value) => setValue(value, 'category')"
                  :limit="1"
                  minWidth="102px"
                  maxWidth="100%"
                />
              </div>

               <div class="flex flex-col mb-4">
                  <label for="#" class="px-0.5 pb-1">
                    Type
                    <span class="text-red-500 ml-1 text-lg leading-3 mr-auto"> * </span>
                  </label>
                  <Dropdown
                    reposition
                    searchable
                    placeholder="Select Type" 
                    :options="options.types"
                    :colors="{
                      text: '#282828',
                      bg: 'white',
                      svg: '#282828',
                      border: '#bcbcbc',
                    }"
                    :config="{ label: 'name', trackBy: 'name' }"
                    :value="assessmentData.type"
                    @input="(value) => setValue(value, 'type')"
                    :limit="1"
                    minWidth="102px"
                    maxWidth="100%"
                  /> 
              </div>
              
              <div class="flex flex-col mb-4" v-if="assessmentData.type === 'Field'">
                  <label for="#" class="px-0.5 pb-1">
                    Field
                    <span class="text-red-500 ml-1 text-lg leading-3 mr-auto"> * </span>
                  </label>
                  <Dropdown
                    reposition
                    searchable
                    placeholder="Select Field" 
                    :options="options.fields"
                    :colors="{
                      text: '#282828',
                      bg: 'white',
                      svg: '#282828',
                      border: '#bcbcbc',
                    }"
                    :config="{ label: 'name', trackBy: 'name' }"
                    :value="assessmentData.field"
                    @input="(value) => setValue(value, 'field')"
                    :limit="1"
                    minWidth="102px"
                    maxWidth="100%"
                  />
              </div>


              <div class="flex flex-col mb-4" v-if="assessmentData.type === 'Field'">
                  <label for="#" class="px-0.5 pb-1">
                    Calculations
                    <span class="text-red-500 ml-1 text-lg leading-3 mr-auto"> * </span>
                  </label>
                  <Dropdown
                    reposition
                    searchable
                    placeholder="Select calculation" 
                    :options="options.calculations"
                    :colors="{
                      text: '#282828',
                      bg: 'white',
                      svg: '#282828',
                      border: '#bcbcbc',
                    }"
                    :config="{ label: 'name', trackBy: 'name' }"
                    :value="assessmentData.calculation"
                    @input="(value) => setValue(value, 'calculation')"
                    :limit="1"
                    minWidth="102px"
                    maxWidth="100%"
                  />
              </div>

              <div class="flex flex-col mb-4" v-if="assessmentData.type === 'Check'">
                <label for="#" class="px-0.5 pb-1">
                  Check
                  <span class="text-red-500 ml-1 text-lg leading-3 mr-auto"> * </span>
                </label>
                <Dropdown
                  reposition
                  searchable
                  placeholder="Select check" 
                  :options="options.checks"
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :config="{ label: 'name', trackBy: 'id' }"
                  :value="assessmentData.check"
                  @input="(value) => setValue(value, 'check')"
                  :limit="1"
                  minWidth="102px"
                  maxWidth="100%"
                />
              </div>
            </div>
            <div class="card-actions justify-end">
              <Button
                :loader="createAssessmentLoading"
                text="Create"
                type="primary"
                :disabled="!assessmentData.name || !assessmentData.category ||
                  !assessmentData.type || 
                  (assessmentData.type === 'Field' && (!assessmentData.field || !assessmentData.calculation)) ||
                  (assessmentData.type === 'Check' && !assessmentData.check)"
                @click="handleCreateAssessment()"
              />
            </div>
          </div>
        </FormulateForm>
      </template>
    </modalContent>
  </div>
</template>
<script>
// import SubHeader from "@/components/SubHeader";
import Table from "@shared/dashboard-components/table-large";
import modalContent from "@shared/modal-content";
import axios from "@/axios";
import { debounce } from "lodash";
import Button from "@/components/button";
import Dropdown from "@/components/dropdown-base";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { EventBus } from "@/main.js";
import { DateTime } from "luxon";
import { FormatDateNew } from "@/plugins/functions";
import { mapActions, mapState } from "vuex";
import {checkPermission} from "@shared/utils/functions"


export default {
  name: "risk-admin",
  title: "Risk Assessment Details",

  components: {
    // SubHeader,
    Table,
    modalContent,
    Button,
    Dropdown,
  },
  props: {
    riskData: {
      type: Object,
      default: () => {},
    }
  },
  data: () => ({
    searchTimeout: null,
    searchLoading: {},
    columnFilterOptionsAsync: null,
    isEntityTypesLoading: false,
    columns: [
      {
        label: "Action",
        field: "actions",
        config: { filter: false, type: "actions" },
        tdClass: "truncate max-w-xs",
        width: "180px",
        sortable: false,
      },
      {
        label: "Name",
        field: "name",
        config: { filter: true },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Category",
        field: "risk_category_name",
        config: { filter: true },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Type",
        field: "type",
        config: { filter: true },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Field",
        field: "field_name",
        config: { filter: true },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      {
        label: "Check",
        field: "tenant_check_name",
        config: { filter: true },
        tdClass: "truncate max-w-sm",
        sortable: true,
      },
      // {
      //   label: "Subtype",
      //   field: "subtype",
      //   config: { filter: true },
      //   tdClass: "truncate max-w-sm",
      //   sortable: true,
      // },
      // {
      //   label: "Score",
      //   field: "score",
      //   config: { filter: true },
      //   tdClass: "truncate max-w-sm",
      //   sortable: true,
      // },
      {
        label: "Created at",
        field: "created_at",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-xs",
        width: "120px",
      },
      {
        label: "Last Updated By",
        field: "modified_by",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-xs",
        width: "120px",
      },
      {
        label: "Last Updated At",
        field: "modified_at",
        config: { filter: true, type: "tooltip_text" },
        tdClass: "truncate max-w-xs",
        width: "120px",
      },
      // {"label":"Automatic/Manual","field":"is_automatic"},
    ],
    serverParams: null,
    risks: [],
    isLoading: false,
    isCloning: false,
    searchValue: "",
    paginationOptions: {
      enabled: true,
      mode: "remote",
      perPage: 10,
    },
    pageData: {
      total: 0,
    },
    currentPageData: {
      page: 1,
      count: 10,
    },
    fetchingCheckPackages: false,
    searchParams: {
      package: "",
    },
    sortParams: null,
    assessmentData: {
      name: '',
      category: '', 
      type: '',
      form: '',
      field: '',
      calculation: '',
      check: '',
    },
    loading:  {
      category: false, 
      type: false,
      form: false,
      field: false,
      calculation: false,
    },
    options: {
      categories: [],
      types: ["Field", "Check"],
      forms: [],
      fields: [],
      calculations: ["Sum", "Average", "Maximum"],
      checks: [],
    },
    createAssessmentLoading: false,
  }),
  async created() {
    EventBus.$on("fetchRiskAssessmentList", () => {
      this.fetchRiskAssessmentList();
    });
  },
  computed: {
    ...mapState({
      entityTypeOptions: "entityTypeList",
    }),
    getAssessmentId() {
      return this.$route.params?.assessmentId;
    },

    isViewMode() {
      return this.$route.params.action == "view" ? true : false;
    },
    columnFilterOptions() {
      let cols = this.getFilterOptions(this.columns, this.risks);
      return cols;
    },
    columnOption() {
      return this.columns.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
  },

  async mounted() {
    if (this.$route.params.id)
      await this.fetchRiskAssessmentList();
  },

  methods: {
    ...mapActions(["fetchEntityTypeList"]),
    FormatDateNew,
    checkPermission,
    currentPageDataOpt() {
      return { ...this.currentPageData, perPage: this.currentPageData.count };
    },

    handleSubmit() {
      this.$emit("saveAndNext", { from: "assessment" });
    },

    async fetchRiskAssessmentList() {
      this.isLoading = true;
      let qParams = new URLSearchParams(this.currentPageData);
      let filterParams = {};

      const getFilterValue = (key) => {
        if (["source"].includes(key)) {
          return this.serverParams[key]["value"];
        } else return this.serverParams[key]["name"];
      };
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.serverParams[key]
            ? getFilterValue(key)
            : delete filterParams[key];
        });
        if (filterParams.created_at) {
          filterParams.created_at = DateTime.fromFormat(
            filterParams.created_at,
            "dd MMM yyyy"
          )
            .plus({ days: 0 })
            .toISODate();
        }
        if (filterParams.modified_at) {
          filterParams.modified_at = DateTime.fromFormat(
            filterParams.modified_at,
            "dd MMM yyyy"
          )
            .plus({ days: 0 })
            .toISODate();
        }
      }
      let url = `/risk-assessment/${this.$route.params.id}/assessment?${qParams}`;
      let { data } = await axios.get(url, {
        params: {
          ...filterParams,
          ...this.sortParams,
        },
      });
      try {
        // const formatDate = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
        let risksData = data?.map((el) => {
          if (el.created_at || el.modified_at) {
            el.created_at = FormatDateNew(new Date(el.created_at));
            el.modified_at = FormatDateNew(new Date(el.modified_at));
            return el;
          }

          if (el.type === "Check") 
            return {...el, subtype: el.tenant_check_name };
          else if (el.type === "Field") 
            return {...el, subtype: el.field_name };
          
          if (el.parameters && el.parameters !== "null") {
            let data = JSON.parse(el.parameters);
            return { ...el, parameters: data.parameters };
          } else {
            return { ...el };
          }
        });
        this.risks = risksData;
        this.pageData = data?.page;
        this.paginationOptions.perPage = data?.page?.per_page;
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.isLoading = false;
    },

    async fetchCategories() {
      this.isLoading = true;
      let url = `/risk-assessment/${this.$route.params.id}/category`;
      let { data } = await axios.get(url);
      try {
        this.options.categories = data.filter((cat) => !cat.default);
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.isLoading = false;
    },

    async fetchRiskFields() {
      this.isLoading = true;
      let url = `/risk-assessment/field`;
      let { data } = await axios.get(url);
      try {
        this.options.fields = data;
      } catch (error) {
        console.log(error, "Error");
      }
      // this.columns = columns;
      this.isLoading = false;
    },

    async fetchRiskChecks() {
      this.isLoading = true;
      let url = `/risk-assessment/tenant-check`;
      let { data } = await axios.get(url);
      try {
        this.options.checks = data;
      } catch (error) {
        console.log(error, "Error");
      }
      this.isLoading = false;
    },

    setValue(value, column) {
      this.assessmentData[column] = value;
    },

    async addAssessment() {
      await this.fetchCategories();
      await this.fetchRiskFields();
      await this.fetchRiskChecks();
      this.$refs["addAssessmentModal"].showModal();
      // this.$router.push({
      //   params: {
      //     action: `create`,
      //   },
      // })
    },

    async handleCreateAssessment() {
      //  let url = `/risk-assessment/${this.$route.params.id}/assessment`;
        let payload = {
          name: this.assessmentData.name,
          risk_category_id: this.assessmentData.category?.id,
          type: this.assessmentData.type,
          calculation_operator: this.assessmentData.type === 'Field' ? this.assessmentData.calculation : null,
          field_name: this.assessmentData.type === 'Field' ? this.assessmentData.field?.name : null,
          tenant_check_id: this.assessmentData.type === 'Check' ? this.assessmentData.check?.id : null,
          tenant_check: this.assessmentData.type === 'Check' ? this.assessmentData.check : null,
        };
        await this.$store.dispatch("updateAssessment", payload);
        this.$router.push({
           path: `${this.$route.path}/create`,
           query: this.$route.query
        });
        // try {
        //   let { data } = await axios.post(url, payload);
        //   this.$router.push({
        //     params: { assessmentId: data.id, action: "edit" },
        //   });
        //   this.$toast.success(data.message);
        // } catch (error) {
        //   this.$toast.error(
        //     error.response.data.detail || "Unable to create assessment"
        //   );
        // }
    },

    handleCloseModal() {
      this.assessmentData = {
        name: '',
        category: '', 
        type: '',
        form: '',
        field: '',
        calculation: '',
        check: '',
      };
    },

    editAssessment(risk) {
      if (this.isViewMode || !this.checkPermission('riskAssessment.edit')) return;
      this.$router.push({
        path: `${this.$route.path}/edit/${risk.id}`,
      });
    },

    async deleteAssessment(risk) {
      if (this.isViewMode || !this.checkPermission('riskAssessment.edit')) return;
      let url = `/risk-assessment/${this.$route.params.id}/assessment/${risk.id}`;
      this.isLoading = true;
      try {
          let result = await axios.delete(url);
          this.$toast.success(result?.data?.message || "Risk assessment deleted successfully!");
          await this.fetchRiskAssessmentList();
      } catch (error) {
          this.$toast.error(error.response.data.detail || "Something went wrong!");
      }
      this.isLoading = false;
    },

    handleSearchFilter: debounce(function(value) {
      this.searchValue = value;
    }, 1000),

    async onPageChange(data, resetPage = false) {
      this.currentPageData = {
        page: data.currentPage,
        count: data.currentPerPage,
      };
      if (resetPage) this.currentPageData.page = 1;
      if (!resetPage) {
        await this.$nextTick();
        const scrollTarget = document.getElementById("riskTable");
        if (scrollTarget) {
          scrollTarget.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
      await this.fetchRiskAssessmentList();
    },

    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },

    async getSearchOptions(payload) {
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          const requestData = {
            ...this.serverParams,
            [payload.column.query_key || payload.column.field]: payload.value,
          };
          const { data } = await axios.get(
            `/tenant-check/${this.$store.getters.getTenantId}`,
            {
              params: requestData,
            }
          );
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columns,
            data.data
          );
        } catch (error) {
          console.log("getSearchOptions :>> ", error);
        }

        this.$set(this.searchLoading, payload.column.field, false);
      }
    },

    async onColumnFilters({ columnFilters }) {
      this.currentPageData.page = 1;
      this.paginationOptions.perPage = this.currentPageData.count;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchRiskAssessmentList();
    },

    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },

    async clearFilters() {
      this.serverParams = null;
      await this.fetchRiskAssessmentList();
    },
    handleFilterResult(data) {
      this.searchParams.package = data;
    },
    async onSortChange(data) {
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        this.sortParams = {
          sort: data.field + ":" + data.type,
        };
        await this.fetchRiskAssessmentList();
      } else {
        this.sortParams = null;
        await this.fetchRiskAssessmentList();
      }
    },
  },
};
</script>

<style lang="scss">
.tooltip-text {
  display: none;
  position: absolute;
  z-index: 999 !important;
  width: auto;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  display: block;
}

#right {
  left: 70px;
  top: -30px;
}

.hover-text {
  position: relative;
}
</style>

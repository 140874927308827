<template>
  <div class="card-base shadow-md relative rounded-2xl bg-card-bg flex gap-1 flex-col" :class="{'overflow-hidden': handleDropdownClass}">
    <div
      v-if="$slots.title"
      class="card-base__title flex items-center justify-between font-bold text-base-content bg-primary gap-3 px-4 lg:px-5 py-3 lg:py-4 rounded-t-2xl w-full"
    >
      <p class="card-base__title-label">
        <slot name="title" />
      </p>
      <div class="card-base__title-append">
        <slot name="title-append" />
      </div>
    </div>
    <div
      v-if="$slots.default"
      class="card-base__content p-4 flex flex-col w-full"
      :class="{'overflow-auto scroll-bar': handleDropdownClass}"
    >
      <slot />
    </div>
    <div
      v-if="$slots.controls"
      class="card-base__controls p-4 flex items-center justify-end w-full"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "UISection",
  props: {
    addedDropdown: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  computed: {
    handleDropdownClass() {
      return !this.addedDropdown
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
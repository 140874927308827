<template>
  <div class="relationship-main-container">
      <div v-if="loadingRelations" class="flex justify-center">
          <div>
          <Loader  :size="20"/></div>
      </div>
    <div v-else>
      <div class="flex gap-2">
        <h2>Relationships</h2>
        <!-- <Loader class="mt-1" v-if="relationsUpdating" /> -->
      </div>
      <div class="relationship-container" id="middle">
        <div class="flex flex-row ">
          <div class="flex flex-col  mr-2">
            <label for="first_name" class="r_label">Related to</label>
            <select
              class="form-select select text-gray-700  bg-white bg-clip-padding bg-no-repeat
                         border border-solid rounded-l 
                       transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                       focus:border-red-100 focus:outline-none  my-2"
              v-model="data.entity"
              placeholder="Select"
              @change="handleChange"
              name="entity"
            >
              <option disabled :value="null" hidden>Select Person</option>
              <option
                v-for="option in getMainentity"
                :key="option.id"
                :value="option.id"
              >
                {{ option.entity }}
              </option>
            </select>
          </div>
          <div class=" flex flex-col ml-2">
            <label for="first_name" class="r_label">Relation type</label>
            <select
              class="form-select select text-gray-700 
                       bg-white bg-clip-padding bg-no-repeat border border-solid rounded-l 
                       transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                       focus:border-red-100 focus:outline-none  my-2"
              v-model="data.relation_type"
              placeholder="Select"
              name="relation_type"
              @change="handleChange"
            >
              <option disabled :value="null" hidden>Select Relation</option>
              <option
                v-for="(option, idx) in getRelations"
                :key="idx"
                :value="option._id"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <Button
            type="primary"
            v-if="this.buttonType == 'Add'"
            class="button_custom mt-5 mb-2 ml-2"
            @click="addData"
            :loader="this.loadingBtn"
            >{{ this.buttonType }}
          </Button>
          <Button
            type="primary"
            v-if="this.buttonType == 'Update'"
            class="button_custom mt-5 mb-2 ml-2"
            :disabled="this.loadingBtn"
            @click="updateData"
            :loader="this.loadingBtn"
            >{{ this.buttonType }}
          </Button>
        </div>
        <div class="flex flex-row w-full py-4 ">
          <div class="flex flex-col w-full">
            <label for="first_name" class="r_label">Line type</label>
            <div class="flex flex-row w-full my-2">
              <div
                class="svg-wrapper"
                id="solid"
                @click="setLineType($event)"
                :class="{ selected: data.line_type === 'solid' }"
              >
                <BoldLine />
              </div>
              <div
                class="svg-wrapper"
                id="dashed"
                @click="setLineType($event)"
                :class="{ selected: data.line_type === 'dashed' }"
              >
                <DashedLine />
              </div>
              <div
                class="svg-wrapper"
                id="dotted"
                @click="setLineType($event)"
                :class="{ selected: data.line_type === 'dotted' }"
              >
                <DottedLine />
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full ml-4">
            <label for="first_name" class="r_label">Arrow type</label>
            <div class="flex flex-row w-full my-2">
              <div
                class="svg-wrapper"
                id="arrow_none"
                @click="setArrowType($event)"
                :class="{ selected: this.data.arrow_type === 'arrow_none' }"
              >
                <BoldLine />
              </div>
              <div
                class="svg-wrapper"
                id="arrow_from"
                @click="setArrowType($event)"
                :class="{ selected: this.data.arrow_type === 'arrow_from' }"
              >
                <ArrowLeft />
              </div>
              <div
                class="svg-wrapper"
                id="arrow_bi"
                @click="setArrowType($event)"
                :class="{ selected: this.data.arrow_type === 'arrow_bi' }"
              >
                <ArrowLeftRight />
              </div>
              <div
                class="svg-wrapper"
                id="arrow_to"
                @click="setArrowType($event)"
                :class="{ selected: this.data.arrow_type === 'arrow_to' }"
              >
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-start ">
          <label for="toogle" class="flex items-center cursor-pointer">
            <!-- <div class="relative">
              <input
                id="toogle"
                type="checkbox"
                v-model="data.showGraph"
                class="sr-only"
              />
              <div class="w-8 h-3 bg-red-100 rounded-full shadow-inner"></div>
              <div
                class=" dot absolute w-5 h-5 bg-white rounded-full shadow -left-1 -top-1 transition"
              ></div>
            </div> -->
            <!-- <div class="label ml-3 ">
              Show on Graph
            </div> -->
          </label>
        </div>
      </div>
      <div class="devider">
        <Devider />
      </div>
      <h2>Previous Relationships</h2>
      <div class="relationship-container" id="middle">
        <div
          class="flex flex-row w-full my-2"
          v-for="relation in relationshipData?.relations"
          :key="relation._id"
        >
          <div class="flex flex-col w-full mr-2" v-if="getEntityName(relation)">
            <input
              :value="getEntityName(relation)"
              type="text"
              id="relation_name"
              class="input"
              :class="{ disabled: true }"
              :disabled="true"
            />
          </div>
          <div class=" flex flex-col w-full mr-2" v-if="getEntityName(relation)">
            <input
              :value="getRelationName(relation)"
              type="text"
              id="realtion-type"
              class="input"
              :class="{ disabled: true }"
              :disabled="true"
            />
          </div>
          <div class=" flex flex-row w-full mr-2 justify" v-if="getEntityName(relation)">
            <div class="mr-2">
              <BoldLine v-if="relation.line_type === 'solid'" />
              <DottedLine v-if="relation.line_type === 'dotted'" />
              <DashedLine v-if="relation.line_type === 'dashed'" />
            </div>
            <div>
              <ArrowRight v-if="relation.arrow_type == 'arrow_to'" />
              <BoldLine v-if="relation.arrow_type == 'arrow_none'" />
              <ArrowLeft v-if="relation.arrow_type == 'arrow_from'" />
              <ArrowLeftRight v-if="relation.arrow_type == 'arrow_bi'" />
            </div>
          </div>
          <div class=" flex flex-row w-full" v-if="getEntityName(relation)">
            <div class="edit mr-2 cursor-pointer flex items-center" @click="handleEdit(relation)">
              <EditGreen /> <span class="btn-label success">Edit</span>
            </div>
            <div class="delete cursor-pointer flex items-center" @click="handleDelete(relation)">
              <TrashRed /> <span class="btn-label danger">Delete</span>
            </div>
          </div>
        </div>
      </div>
      <div class="devider">
        <Devider />
      </div>
      <h2 class="hidden">Relationships</h2>
      <div class="flex flex-col w-full hidden">
        <select
          class="form-select text-gray-700 
                       bg-white bg-clip-padding bg-no-repeat border border-solid rounded-l 
                       transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                       focus:border-red-100 focus:outline-none py-1 my-2"
          v-model="data.realtedTo"
          placeholder="Select"
        >
          <option value="undefined" disabled>Select Output</option>
          <option
            v-for="(option, idx) in getRiskOptions"
            :key="idx"
            :value="option.entity"
          >
            {{ option.entity }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import BoldLine from "@shared/light-ray/assets/svg/line.svg";
import ArrowLeft from "@shared/light-ray/assets/svg/arrow-left.svg";
import ArrowRight from "@shared/light-ray/assets/svg/arrow-right.svg";
import ArrowLeftRight from "@shared/light-ray/assets/svg/arrow-left-right.svg";
import DashedLine from "@shared/light-ray/assets/svg/dashed-line.svg";
import DottedLine from "@shared/light-ray/assets/svg/dotted-line.svg";
import Devider from "@shared/light-ray/assets/svg/devider.svg";
import EditGreen from "@shared/light-ray/assets/svg/greenEdit.svg";
import TrashRed from "@shared/light-ray/assets/svg/trash-red.svg";
import Loader from "@/components/loader";
import axios from "@/axios";
import { mapGetters } from "vuex";

export default {
  name: "Relationships",
  components: {
    BoldLine,
    DashedLine,
    DottedLine,
    ArrowLeft,
    ArrowLeftRight,
    ArrowRight,
    Devider,
    Loader,
    EditGreen,
    TrashRed
  },
  props: {
    mode: {
      type: String,
      default: 'default',
    },
    mainEntity: Array,
    individualData: Object,
    sourceName: String,
  },
  data() {
    return {
      loadingRelations: true,
      edit_id: null,
      initial: {
        entity: null,
        relation_type: null,
        show_on_graph: true,
        line_type: "solid",
        arrow_type: "arrow_from",
        attributes_data: [],
        deleted: false,
        _id: null,
        entity_name: "",
        relation_label: "",
      },
      loadingBtn: false,
      relationTypes: [],
      buttonType: "Add",
      data: {
        entity: null,
        relation_type: null,
        show_on_graph: true,
        line_type: "solid",
        arrow_type: "arrow_from",
        attributes_data: [],
        deleted: false,
        _id: null,
        entity_name: "",
        relation_label: "",
      },
      relationshipData: { relations: [] },
    };
  },
  async mounted() {
    if(this.currentCheckName!='search-engine-tool') {
    this.relationshipType();
    }
    this.relationshipData = this.individualData;
    await this.getRelationsData()
  },
  methods: {
    async getRelationsData() {
        if (this.currentCheckName === 'search-engine-tool' || this.mode === "main-entity") {
          this.relationshipData.relations = this.individualData?.relations ?? []
          this.loadingRelations = false;
        } else {
          const url = '/service/card-results'
          const payload = {
              case_id: this.getNeoCaseId,
              query_id: this.$route.query.query_id,
              tool_name: this.$route.query.check_name,
              entity_id: this.$route.query.entityId,
              card_ids: [this.individualData.card_id]
          }
          let {data} = await axios.post(url, payload)
          this.relationshipData.relations = data?.data[0]?.relations ? data?.data[0]?.relations : []
          this.loadingRelations = false;
        }
    },
    handleChange(e) {
      e.currentTarget.name = e.currentTarget.value;
    },
    handleEdit(relation) {
      (this.buttonType = "Update"),
        (this.data.entity = relation.entity),
        (this.data.relation_type = relation.relation_type?._id),
        (this.data.show_on_graph = relation.show_on_graph),
        (this.data.line_type = relation?.line_type),
        (this.data.arrow_type = relation?.arrow_type),
        (this.data.deleted = relation?.deleted),
        (this.data._id = relation?._id);
    },
    async handleDelete(relation) {
      this.relationshipData.relations = this.relationshipData.relations.filter(
        (item) => item._id != relation._id
      );
        let payload = this.relationshipData;
        payload.case_id = this.getNeoCaseId;
        payload.status = payload.status == 'UNKNOWN' ? 'None' : payload.status
        let url;
        let response;
        if (this.mode === "main-entity") {
          url = `/service/main-entity/${this.individualData._id}`;
          response = await axios.put(url, payload);
        } else if (this.currentCheckName === 'search-engine-tool') {
          if (this.individualData.card_type === 'media') url = `/service/active-passive-media`;
          else url = `/search-engine/${this.individualData._id}`;
          response = await axios.put(url, payload);
        } else {
          url = `/service/upsert-card-result`;
          payload.card_type = this.individualData.card_type;
          response = await axios.post(url, payload);
        }
        this.data = JSON.parse(JSON.stringify(this.initial));
        this.resetInitialRelationState();
        this.loadingBtn = false;
        if (response?.data?.data) {
          this.relationshipData = response?.data?.data;
          this.relationshipData.card_type = payload.card_type;
          this.individualData.relations = this.relationshipData.relations;
          // this.$emit('setIndividualData', response.data.data)
          this.loadingBtn = false;
          if (this.relationshipData.relations.length == 0) {
            this.buttonType = "Add";
          }
          this.$toast.success("Deleted sucessfully");
        } else {
          this.$toast.error("Something went wrong");
          this.individualData.relations.pop();
          this.loadingBtn = false;
        }
      
    },
    async addData() {
      if (
        this.data.entity !== null &&
        this.data.entity != "" &&
        this.relationTypeCheck
      ) {
        this.loadingBtn = true;
        this.relationshipData.relations.push(this.data);
        let payload = this.relationshipData;
        payload.case_id = this.getNeoCaseId;
        payload.status = payload.status == 'UNKNOWN' ? 'None' : payload.status
        let url;
        let response;
        if (this.mode === "main-entity") {
          url = `/service/main-entity/${this.individualData._id}`;
          response = await axios.put(url, payload);
        } else if (this.currentCheckName === 'search-engine-tool') {
          if (this.individualData.card_type === 'media') url = `/service/active-passive-media`;
          else url = `/search-engine/${this.individualData._id}`;
          response = await axios.put(url, payload);
        } else {
          url = `/service/upsert-card-result`;
          payload.card_type = this.individualData.card_type;
          response = await axios.post(url, payload);
        }
        this.data = JSON.parse(JSON.stringify(this.initial));
        this.resetInitialRelationState();
        this.loadingBtn = false;
        if (response?.data?.data) {
          this.relationshipData = response?.data?.data;
          this.relationshipData.card_type = payload.card_type;
          this.individualData.relations = this.relationshipData.relations;
          this.individualData.relations = this.individualData.relations.filter((res)=>res._id!=null)
          // this.$emit('setIndividualData', response.data.data)
          this.loadingBtn = false;
          this.$toast.success("Added sucessfully");
          
        } else {
          this.$toast.error("Something went wrong");
          this.individualData.relations.pop();
          this.loadingBtn = false;
        }
      } else {
        this.$toast.error("please select all Field");
      }
    },
    resetInitialRelationState() {
      this.data = JSON.parse(JSON.stringify(this.initial));
    },
    async updateData() {
      this.relationshipData.relations = this.relationshipData.relations.map(
        (item) => {
          if (item._id === this.data._id) {
            return this.data;
          } else {
            return item;
          }
        }
      );
      let url;
      let response;
      // TODO refactoring: move general functionality outside to not repeat
      if (this.mode === "main-entity") {
        url = `/service/main-entity/${this.individualData._id}`;
        let payload = this.relationshipData;
        payload.case_id = this.getNeoCaseId;
        payload.status = payload.status == 'UNKNOWN' ? 'None' : payload.status;
        response = await axios.put(url, payload);
      } else if (this.currentCheckName === 'search-engine-tool') {
        if (this.individualData.card_type === 'media') url = `/service/active-passive-media`;
        else url = `/search-engine/${this.individualData._id}`;
        let payload = this.relationshipData;
        payload.case_id = this.getNeoCaseId;
        payload.status = payload.status == 'UNKNOWN' ? 'None' : payload.status;
        response = await axios.put(url, payload);
      } else {
        url = `/service/upsert-card-result`;
        this.relationshipData.card_type = this.individualData.card_type;
        let payload = this.relationshipData;
        payload.case_id = this.getNeoCaseId;
        payload.status = payload.status == 'UNKNOWN' ? 'None' : payload.status;
        response = await axios.post(url, payload);
      }

      this.resetInitialRelationState();
      this.$emit("setIndividualData", response.data.data);
      this.relationshipData = response.data.data;
      this.loadingBtn = false;
      this.$toast.success("Updated Sucessfully");
      this.buttonType = "Add";
    },
    async relationshipType() {
      this.relationTypes = this.getEntitiesRelationsAttributesData.relations.filter(
        (item) => item.show_on_edge
      );
    },
    setLineType(e) {
      this.data.line_type = e.currentTarget.id;
    },
    setArrowType(e) {
      this.data.arrow_type = e.currentTarget.id;
    },
    getEntityName(relation) {
      const selectedData = this.mainEntity.find(
        (item) => item.id === relation.entity
      );
      return selectedData ? selectedData.entity : "";
    },
    getRelationName(relation) {
      if (this.relationTypes.length) {
        const relationData = this.relationTypes.find(
          (item) => item._id === relation.relation_type?._id
        );
        return relationData ? relationData.label : " ";
      }
    },
  },
  created() {
    // this.relationshipType();
  },
  computed: {
    ...mapGetters(["getNeoCaseId", "getEntitiesRelationsAttributesData"]),
    relationTypeCheck() {
      if (this.currentCheckName=='search-engine-tool') {
        return true
      }
      else {
        return this.data.relation_type !== null &&
               this.data.relation_type != "" 
      }
    },
    currentCheckName() {
        return this.$route.query?.check_name
    },

    getRelations() {
      return this.relationTypes;
    },
    getMainentity() {
      return [...this.mainEntity].filter((el) => el.entity);
    },
    getRiskOptions() {
      return [
        {
          entity: "Risk One",
          checked: true,
        },
        {
          entity: "Risk two",
          checked: true,
        },
        {
          entity: "Risk three",
          checked: true,
        },
        {
          entity: "Risk four",
          checked: true,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@shared/light-ray/assets/scss/color.scss";

.button_custom {
  display: flex;
  padding: 10px 30px;
  align-items: flex-start;
  gap: 10px;
  border-color: $lightray_product_color;
  border-radius: 6px;
  color: white !important;
  background-color: $lightray_product_color;
}

.relationship-container {
  /* display: flex; */
  padding: 24px;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  gap: 24px;
  align-self: stretch;
  background: #fafafc;
}

.r_label {
  color: var(--blue-shade, #1a2036) !important;
  font-family: Poppins;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 109.091% */
}

.svg-wrapper {
  border-radius: 10px;
  margin-right: 10px;
}

.svg-wrapper.selected {
  border: 3px solid #910000;
}

.parent {
  input[type="radio"] {
    height: 15px;
    appearance: auto;
  }
}

.dot {
  background-color: #e4e1e1;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #900009;
}

.label {
  color: var(--blue-shade, #1a2036);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.devider {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input {
  height: 2.3rem !important;
  border-radius: 8px !important;
  border: 1px solid var(--grey, #aeb1ba) !important;
  background: var(--white, #fff) !important;
  width: 100%;
}

.edit {
  padding: 10px 12px;
  gap: 8px;
  color: #42b85c;
  // width: 100px;
  border: 0.5px solid var(--light-ray-action-red, #42b85c);
  border-radius: 8px;
}

.delete {
  padding: 10px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 0.5px solid var(--light-ray-action-red, #ce1b22);
  background: var(--white, #fff);
  color: #eb3131;
  // width: 100px;
}

.btn-label {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.btn-label.danger {
  color: #eb3131;
}

.btn-label.success {
  color: #42b85c;
}

.disabled {
  background: var(--fill-grey, #f3f4f6) !important;
}

// .relationship-main-container {
//     max-height: 80vh;
//     overflow: scroll;

// }

.select {
  border-radius: 8px;
  border: 0.5px solid var(--grey, #aeb1ba);
  width: 27vw;
}
</style>

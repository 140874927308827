import AdverseMediaResultList from "@shared/saas-components/adverse-media-result-list";
const monitoringSourceTree = () => import("@shared/saas-components/monitoring-source-tree");

export default {
    name: "result-list",
    components: {
        monitoringSourceTree,
        AdverseMediaResultList,
    },
    props: {
        mainEntity: {
            type: Array
        },
        cardComponent: {
            type: String,
            required: true,
        },
        results: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Number,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isResultExpanded: {
            type: Boolean,
            default: false,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isAllRunQueries: {
            type: Boolean,
            default: false,
        },
        timeline: {
            type: Array,
            default: () => [],
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        /**
         * @typedef {Object} AutorunConfig
         * @property {Object} headers
         * @property {Object} params
         */
        /**
         * @type {AutorunConfig} autorun configurations
         */
        autorun: {
            type: Object,
            default: null,
        },
        checkId: {
            type: String,
            default: "",
        },
        entityId: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            monitoringHistoryIndex: null,
        };
    },
    computed: {
        /**
         * @property
         * @name isMonitoringTab
         * @return {Boolean} - check if monitoring tab is selected
         */

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
    },
    mounted() {},
    methods: {
        updateCount() {
            this.$emit("updateEvents")
        },
        updateCardStatus() {
            this.$emit("handleUpdateCard")
        },
        handle_more(result, current_index, i, isOldVersion) {
            if (this.disabled) return;

            if (this.cardComponent != "neo-news-card") {
                this.$emit("handle_more_content", {...result, index: current_index, isOldVersion: isOldVersion});
            }
            document.getElementById(`result-card-${i}`)?.scrollIntoView();
            // document.getElementById("result-expanded").scrollTo({
            //     top: 0,
            //     behavior: "smooth",
            // });
        },

        async showMonitoringHistory(detail) {
            const index = detail.api_all_data.doc_id;
            if (this.monitoringHistoryIndex !== index) {
                this.monitoringHistoryIndex = index;
            } else {
                this.monitoringHistoryIndex = null;
            }
            const data = {
                payload: {
                    doc_id: detail.api_all_data.doc_id,
                    query_id: detail.api_all_data.query_id,
                    tool: this.$route.params?.toolName,
                    source: detail.api_all_data.doc_source,
                    monitoringDate: detail.api_all_data.monitoringDate,
                    changes_viewed: detail.api_all_data.changes_viewed,
                    monitoringHistoryIndex: this.monitoringHistoryIndex,
                    parentMonitoringDate: detail.api_all_data.parentMonitoringDate,
                },
                detail: detail,
            };
            this.$emit("showMonitoringHistory", data);
        },

        handleConfirmationChange(value, item) {
            this.$emit("update:confirmation", {
                item,
                value,
            });
        },
    },
};

import SubHeader from "@/components/SubHeader";
import Input from "@/components/input";
import axios from "@/axios";
import loader from "@shared/loader";
import ModalContent from "@shared/modal-content";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
const modalConfirm = () => import("@/components/modal-confirm");
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
// Static use Only Remove after Proper API
import checkGapValidationInput from "../checkGapValidationInput";
import { EventBus } from "@/main.js";

import checkDetails from "./components/check-details";
import checkWorkflow from "./components/checks-workflow"
import sectionLists from "./components/sectionLists.vue";
import checkFormEditor from "./components/check-form-editor/";
import checkOutputFormEditor from "./components/check-output-form/";

import { mapState, mapActions } from 'vuex'
const addSectionModal = () => import("./components/check-modals/add-section-modal");
const expandedPanel = () => import("./components/expanded-panel");
const addBlockModal = () => import("./components/check-modals/add-block-modal.vue");
// const addFieldModal = () => import("./components/check-modals/add-field-modal.vue");
import FieldSettingsModal from "@shared/components/modals/field-settings-modal.vue";
import GapValidationModal from "@shared/components/modals/gap-validation-modal.vue";
const AddBlockFieldModal = () => import("../../block-admin/components/addBlockAndFieldModal.vue");
import { checkPermission } from "@shared/utils/functions";
import LoaderFull from "@/components/loader-full";
const GapTimeLineForBlock = () => import("@shared/gapTimeLine");

// end
export default {
    name: "two-list-headerslots",
    display: "Transitions",
    order: 16,
    components: {
        SubHeader,
        VueMultiselect,
        "neo-input": Input,
        loader,
        ModalContent,
        modalConfirm,
        Button,
        Checkbox,
        checkGapValidationInput,
        checkDetails,
        checkWorkflow,
        sectionLists,
        addSectionModal,
        expandedPanel,
        addBlockModal,
        // addFieldModal,
        FieldSettingsModal,
        GapValidationModal,
        AddBlockFieldModal,
        GapTimeLineForBlock,
        LoaderFull,
        checkFormEditor,
        checkOutputFormEditor
    },
    data() {
        return {
            isLoading: true,
            isEntityTypesLoading: false,
            sectionList: [],
            tenantId: null,
            customCrumbs: [],
            isGlobalLoader: {
                status: false,
                message: ''
            },
            currentTab: {},
        };
    },
    async mounted() {
        this.isLoading = true;
        this.tenantId = this.$store.getters.getTenantId;
        await this.fetchEntityTypeOptions();
        await this.getCheckDetails();
        this.currentTab = this.navigations[0];
    },
    computed: {
        ...mapState({
            checkData: state => state.checksAdmin.checksData,
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.checksAdmin.isDirty
        }),
        navigations() {
            var vm = this
            let sections = [
                {
                    title: "Configuration",
                    nav: "description",
                    icon: "file-lines",
                    subnav: false,
                    iconPrefix: "far",
                    el: checkDetails,
                    props: {
                        checkData: this.checkData
                    },
                    listeners:  Object.assign({},
                        this.$listeners,
                        {
                            handleSaveCheck: function () {
                                vm.handleSaveCheck()
                            },
                            handleDeleteCheck: function (event) {
                                vm.handleDeleteCheck(event)
                            },
                        }
                    )
                },
                {
                    title: "Input Form Editor",
                    nav: "check-form-editor",
                    icon: "table",
                    subnav: false,
                    el: checkFormEditor,
                    props: {
                        checkData: this.checkData,
                        getCheckDetails: this.getCheckDetails
                    }
                },
                {
                    title: "Workflow",
                    nav: "workflow",
                    icon: "file-lines",
                    subnav: false,
                    iconPrefix: "far",
                    el: checkWorkflow,
                },
            ]
            if (this.checkData.category=='Integrated Check') {
                const output_section = {
                    title: "Output Form Editor",
                    nav: "check-output-form-editor",
                    icon: "table",
                    subnav: false,
                    el: checkOutputFormEditor,
                    props: {
                        checkData: this.checkData,
                        getCheckDetails: this.getCheckDetailsOutput,
                        currentTab: this.currentTab
                    }
                }
                sections.splice(sections.length-1, 0 , output_section)
            }
            return sections     
        },
        isUserPermission() {
            let permissions = {
                SHOW_ALL_ACTIONS: this.checkPermission("check.edit"),
                SHOW_FIELD_ADD_ACTIONS: this.checkPermission("field.read"),
                SHOW_BLOCK_ADD_ACTIONS: this.checkPermission("block.read"),
            };
            return permissions;
        },
    },
    created() {
        let params = this.$route.params;
        console.log("params", params);
//        this.currentTab = this.navigations[0];
        // // reset to update package data:
        // this.RESET_PACKAGE()

        // if (params.packagetool) {
        //     this.currentTab = params.packagetool;
        //     this.$route.meta.customCrumbs = [{ name: params.packagetool }];
        //     this.$forceUpdate();
        // } else {
            // this.currentTab = this.navigations[1];
            // console.log('this.currentTab', this.currentTab)
        //     this.$router.push({
        //         name: "Package Tools",
        //         params: { packagetool: this.navigations[0].nav },
        //     });
        //     this.setBredCrumbs(this.currentTab)
        //     this.$forceUpdate();
        // }
    },
    methods: {
        ...mapActions([
            'setCheckData', 
            'fetchEntityTypeList'
        ]),
        async fetchEntityTypeOptions() {
            if (!this.entityTypeOptions.length) {
                this.isEntityTypesLoading = true;
                await this.fetchEntityTypeList()
                this.isEntityTypesLoading = false;
            }
        },
        showSectionModal() {
            if (this.$refs["check-output-form-editor"]) {
                this.$refs["check-output-form-editor"].showSectionModal();   
            }
            else {
                this.$refs["check-form-editor"].showSectionModal();
            }
        }, 
       /***
         *  triggers when tabs changed
         */
        
        
        async handleCurrentTab(tab) {
            let shouldLeaveTab = await this.beforeChangeDetails();

            if (!shouldLeaveTab || this.currentTab.name === tab.nav) return;
            this.currentTab = tab;

            // this.setBredCrumbs(this.currentTab);
            // if (this.currentTab!='workflow'){
            //     this.$router.push({
            //     name: "Package Tools",
            //     params: { packagetool: this.currentTab },
            // });
            // }
            // else {
            //         this.$router.push({
            //     name: "Package Tools",
            //     params: { packagetool: this.currentTab, type: "case" },
            // });   
            // }
        },
        async beforeChangeDetails() {
            if (!this.isDirty) {
                return true
            } else {
                const promise = await this.$refs["confirm-popup"].show({
                    title: "Are you sure?",
                    message: "Unsaved data will be lost. Please confirm to continue without saving.",
                });

                if (promise) {
                    await this.getCheckDetails();
                }
                return !!promise;
            }
        },
        checkPermission,

        async getCheckDetails() {
            let queryId = this.$route.params.id;
            if (queryId) {
                if(this.$route.params.action == 'edit'){
                    const lockResult  = await this.setEditmodeLock(queryId)
                    if(lockResult == false){
                        this.$router.push({
                            name: "check Admin",
                        });
                    }
                }
                
                try {
                    let url = `/tenant-check/${this.tenantId}/${queryId}`;
                    let { data } = await axios.get(url);
                    this.customCrumbs = [{ name: data?.data?.name ?? "" }];
                    this.setCheckData(data.data);
                    
                } catch (error) {
                    if(error?.response?.data?.detail == 'Invalid check/form id.'){
                        this.$router.push({
                            name: "check Admin",
                        });
                        this.$toast.error("Check is already deleted!");
                    }
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async getCheckDetailsOutput() {
            let queryId = this.$route.params.id;
            if (queryId) {
                if(this.$route.params.action == 'edit'){
                    const lockResult  = await this.setEditmodeLock(queryId)
                    if(lockResult == false){
                        this.$router.push({
                            name: "check Admin",
                        });
                    }
                }
                
                try {
                    let url = `/tenant-check/output/${this.tenantId}/${queryId}`;
                    let { data } = await axios.get(url);
                    this.customCrumbs = [{ name: data?.data?.name ?? "" }];
                    this.setCheckData(data.data);
                } catch (error) {
                    if(error?.response?.data?.detail == 'Invalid check/form id.'){
                        this.$router.push({
                            name: "check Admin",
                        });
                        this.$toast.error("Check is already deleted!");
                    }
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async setEditmodeLock(queryId, option = null) {
            try {
                let payload = { lock_status: true };
                if (option !== null) {
                    payload = { lock_status: false };
                }
                this.$store.getters.getTenantId
                let url = `/tenant-check/locked/${this.$store.getters.getTenantId}/${queryId}`;
                await axios.post(url, payload);
                EventBus.$emit('fetchCheckList');
                return true;
            } catch (error) {
                if (error.response && error.response.data && error.response.data.detail) {
                    this.$toast.error(error.response.data.detail);
                }
                return false; // Return false when there's an error
            }
        }
        ,
        async setEditModeUnlock(){
            if(this.$route.params.action === 'view'){
                return
            }
            let query = this.$route;
            if(query.params.id){
                await this.setEditmodeLock(query.params.id,'backMode')
            }
        },

        // Delete Check

        async handleDeleteCheck(check) {
            await this.$store.dispatch("fetchPermissions");
            if(!this.checkPermission("check.delete")) {
                this.$toast.error("You don't have permission to delete the check.");
                return;
            }
            
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Check  will be deleted permanently. Please confirm to continue deleting this check.",
            });
            if (promise) {
                this.handleControlGlobalLoader(true, 'Deleting check...')
                let url = `/tenant-check/${this.tenantId}/${check.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.$router.push({
                        name: "check Admin",
                    });
                    this.$toast.success(data.message, "Check deleted ");
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to delete check");
                }
            }
            this.$refs["confirm-popup"].close();
            this.handleControlGlobalLoader(false)
        },

        // End Delete Check

        // Handle Save Check -----------------

        async handleSaveCheck() {
            this.handleControlGlobalLoader(true, 'Updating check details...')
            let checkInfo = this.checkData;
            if(parseInt(checkInfo.sla) < 1) {
                this.handleControlGlobalLoader(false);
                return this.$toast.error('Sla period (days) must be at least 1');
            }
            let url = `/tenant-check/${this.tenantId}/${checkInfo.id}`;
            let playload = {
                name: checkInfo.name,
                description: checkInfo.description,
                sla: parseInt(checkInfo.sla),
                parameters: checkInfo.parameters && checkInfo.parameters.parameters ? JSON.stringify(checkInfo.parameters.parameters) : "[]",
                check_type: "check",
                entity_types: checkInfo.entity_types.map((el) => ({ entity_type_id: el.id }))
            };
            try {
                let { data } = await axios.put(url, playload);
                await this.getCheckDetails();
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Failed to update check");
            }
            this.handleControlGlobalLoader(false)

        },

        //  Save Check End -----------------

        handleControlGlobalLoader(status, message){
            this.isGlobalLoader = {
                status,
                message:message || 'Loading...'
            }
        }
    },
};

import CheckIcon from "@shared/light-ray/assets/svg/entity-details/circle_check.svg";
import MinusIcon from "@shared/light-ray/assets/svg/entity-details/circle_minus.svg";

export const confirmedStateValue = "CONFIRMED";
export const unknownStateValue = "UNKNOWN";

export const confirmedOption = {
    label: "Confirmed",
    value: confirmedStateValue,
    icon: CheckIcon,
}

export const unknownOption = {
    label: "Unknown",
    value: unknownStateValue,
    icon: MinusIcon,
}

export const confirmationOptions = [confirmedOption, unknownOption];
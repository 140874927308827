var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getRiskId || _vm.$route.params.action)?_c('RiskDetail'):_c('div',{staticClass:"flex-1 flex flex-col max-h-full"},[_c('sub-header',{attrs:{"handleSearchFilter":_vm.handleSearchFilter,"buttonText":"Add Risk Assessment","handleClick":_vm.addRiskAssessment,"showBreadCrumbs":true,"showBtn":_vm.checkPermission('riskAssessment.create')}}),_c('div',{staticClass:"flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar"},[_c('Table',{key:_vm.pageData?.total,attrs:{"id":"riskTable","columns":_vm.columnOption,"rows":_vm.risks,"isLoading":_vm.isLoading,"filters":_vm.serverParams,"filterOptions":_vm.columnFilterOptions,"filterOptionsAsync":_vm.columnFilterOptionsAsync,"paginationOptions":_vm.paginationOptions,"totalRows":_vm.pageData?.total,"sortingEnabled":""},on:{"handleClone":function($event){return _vm.handleCloneCheck($event)},"per-page-change":function($event){return _vm.onPageChange($event, true)},"page-change":function($event){return _vm.onPageChange($event)},"column-filter":function($event){return _vm.onColumnFilters($event)},"filters-clear":_vm.clearFilters,"sort-change":_vm.onSortChange,"search-filter":function($event){return _vm.getSearchOptionsTick($event)}},scopedSlots:_vm._u([{key:"link",fn:function(customData){return [_c('div',{staticClass:"truncate max-w-full whitespace-nowrap cursor-pointer"},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"content":customData.customData.name}},[_vm._v(" "+_vm._s(customData.customData.name)+" ")])])]}},{key:"actions",fn:function(customData){return [_c('div',{staticClass:"flex justify-around"},[_c('router-link',{attrs:{"to":customData.customData.lock || !customData.customData.editable || customData.customData.lock
                ? {}
                : {
                    path: `${_vm.$route.path}/edit/${customData.customData.id}?tab=details`,
                  }}},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer",attrs:{"content":customData.customData.lock ? 'Assessment in use': 'Edit',"disabled":!_vm.checkPermission('riskAssessment.edit') || !customData.customData.editable || customData.customData.lock}},[_c('font-awesome-icon',{staticClass:"text-blue-600 text-lg fa-3x",class:customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500',attrs:{"icon":"edit"}})],1)]),_c('router-link',{attrs:{"to":{
              path: `${_vm.$route.path}/view/${customData.customData.id}?tab=details`,
            }}},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2 text-primary whitespace-nowrap cursor-pointer",attrs:{"content":'View',"disabled":!_vm.checkPermission('riskAssessment.read')}},[_c('font-awesome-icon',{staticClass:"text-blue-600 text-lg fa-3x",class:customData.customData
                    ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer'
                    : 'text-gray-500',attrs:{"icon":"eye"}})],1)]),_c('button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"h-8 w-4 disabled:opacity-50 mx-2 text-danger whitespace-nowrap cursor-pointer",attrs:{"content":customData.customData.lock ? 'Assessment in use': 'Delete',"disabled":customData.customData.lock},on:{"click":function($event){return _vm.deleteRisk(customData.customData)}}},[_c('font-awesome-icon',{staticClass:"text-red-600 text-lg fa-3x",class:customData.customData
                  ? 'text-danger hover:text-dvbrandhoveron hover:underline cursor-pointer'
                  : 'text-gray-500',attrs:{"icon":"trash"}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
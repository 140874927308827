<template>
    <div class="w-full">
        <div v-if="showLoader" class="flex justify-center items-center w-full h-full">
           <Loader  />
        </div>

        <template v-else>
        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">Trigger</span>
            </template>

            <div class="rounded-xl border">
                <div class="px-4 py-2 bg-primary-100 rounded-t-xl text-sm">If the following is true</div>
                <div class="flex gap-4 px-4">
                    <FormulateInput
                        v-model="events.model.type"
                        validation="required"
                        class="flex-1"
                        label="Trigger Type"
                        placeholder="Select Trigger Type"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'type')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', 'type', 'configuration')"
                    />

                    <FormulateInput
                        v-model="events.model.configuration"
                        validation="required"
                        class="flex-1"
                        label="Configuration"
                        placeholder="Select Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'configuration')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event, 'events', 'configuration', 'subConfiguration')"
                    />

                    <FormulateInput
                        v-if="events.options.subConfiguration.length > 0"
                        v-model="events.model.subConfiguration"
                        validation="required"
                        class="flex-1"
                        label="Sub Configuration"
                        placeholder="Select Sub Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedObjectOptions('events', 'subConfiguration')"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleObjectInput($event)"
                    />
                </div>
            </div>
        </UISection>

        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">Condition</span>
            </template>

            <div class="rounded-xl border">
                <div class="flex items-center gap-2 px-4 py-2 bg-primary-100 rounded-t-xl text-sm">
                    <span>If</span>
                    <select class="w-14 h-6 rounded-md" v-model="conditions.operator">
                        <option
                            class="p-2 rounded-md"
                            v-for="operator in conditions.operators"
                            :value="operator.id"
                            :key="operator.id"
                        >
                           {{ operator.name }}
                        </option>
                    </select>
                    <span>of the following is true</span>
                </div>

                <div v-for="item, index in conditions.model" :key="getUUID()" class="flex gap-4 "> <!-- eslint-disable-line -->
                <div class="flex gap-4 px-4 flex-1">
                    <FormulateInput
                        v-model="item.type"
                        class="flex-1"
                        label="Condition Type"
                        placeholder="Select Condition Type"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'type', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, 'conditions', 'type', 'configuration', index)"
                    />

                    <FormulateInput
                        v-model="item.configuration"
                        class="flex-1"
                        label="Configuration"
                        placeholder="Select Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'configuration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, 'conditions', 'configuration', 'subConfiguration', index)"
                    />

                    <FormulateInput
                        v-if="checkShowSubConfig(item.configuration, 'conditions', 'configuration', index)"
                        v-model="item.subConfiguration"
                        class="flex-1"
                        label="Sub Configuration"
                        placeholder="Select Sub Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('conditions', 'subConfiguration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, undefined, undefined, undefined, index)"
                    />
                </div>

                <!--- TODO: move to the separate component -->
                <div class="flex items-center justify-start gap-2 pr-4 w-20">
                    <button
                        type="button"
                        :disabled="isLoading || isViewMode || disabledRemovingConditions"
                        class="flex items-center justify-center w-6 h-6 rounded-full"
                        :class="{
                            'bg-gray-300': isLoading || isViewMode || disabledRemovingConditions,
                            'bg-red-500 cursor-pointer': !isLoading && !isViewMode && !disabledRemovingConditions
                        }"
                        @click="removeItem('conditions', index)"
                    >
                        <font-awesome-icon class="text-sm text-white-text" :icon="['fa', 'minus']" />
                    </button>

                    <button
                        type="button"
                        :disabled="isLoading || isViewMode || disabledAddingConditions || conditions.model[index + 1]"
                        class="flex items-center justify-center w-6 h-6 rounded-full"
                        :class="{
                            'bg-gray-300': isLoading || isViewMode || disabledAddingConditions || conditions.model[index + 1],
                            'bg-primary cursor-pointer': !isLoading && !isViewMode && !disabledAddingConditions && !conditions.model[index + 1]
                        }"
                        @click="addItem('conditions')"
                    >
                        <font-awesome-icon class="text-sm text-white-text" :icon="['fa', 'plus']" />
                    </button>
                </div>
                </div>
            </div>
        </UISection>

        <UISection class="mb-4">
            <template #title>
                <span class="text-white-text">Action</span>
            </template>

            <div class="rounded-xl border">
                <div class="px-4 py-2 bg-primary-100 rounded-t-xl text-sm">Then perform the following.</div>
                <div v-for="item, index in actions.model" :key="getUUID()" class="flex gap-4 "> <!-- eslint-disable-line -->
                <div class="flex gap-4 px-4 flex-1">
                    <FormulateInput
                        v-model="item.type"
                        validation="required"
                        class="flex-1"
                        label="Action Type"
                        placeholder="Select Action Type"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'type', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, 'actions', 'type', 'configuration', index)"
                    />

                    <FormulateInput
                        v-model="item.configuration"
                        validation="required"
                        class="flex-1"
                        label="Configuration"
                        placeholder="Select Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'configuration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, 'actions', 'configuration', 'subConfiguration', index)"
                    />

                    <FormulateInput
                        v-if="checkShowSubConfig(item.configuration, 'actions', 'configuration', index)"
                        v-model="item.subConfiguration"
                        validation="required"
                        class="flex-1"
                        label="Sub Configuration"
                        placeholder="Select Sub Configuration"
                        type="select"
                        :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                        :options="getMappedArrayOptions('actions', 'subConfiguration', index)"
                        :disabled="isLoading || isViewMode"
                        :config="selectConfig"
                        @input="($event) => handleArrayInput($event, undefined, undefined, undefined, index)"
                    />
                </div>

                <!--- TODO: move to the separate component -->
                <div class="flex items-center justify-start gap-2 pr-4 w-20">
                    <button
                        type="button"
                        :disabled="isLoading || isViewMode || disabledRemovingActions"
                        class="flex items-center justify-center w-6 h-6 rounded-full"
                        :class="{
                            'bg-gray-300': isLoading || isViewMode || disabledRemovingActions,
                            'bg-red-500 cursor-pointer': !isLoading && !isViewMode && !disabledRemovingActions
                        }"
                        @click="removeItem('actions', index)"
                    >
                        <font-awesome-icon class="text-sm text-white-text" :icon="['fa', 'minus']" />
                    </button>

                    <button
                        type="button"
                        :disabled="isLoading || isViewMode || disabledAddingActions || actions.model[index + 1]"
                        class="flex items-center justify-center w-6 h-6 rounded-full"
                        :class="{
                            'bg-gray-300': isLoading || isViewMode || disabledAddingActions || actions.model[index + 1],
                            'bg-primary cursor-pointer': !isLoading && !isViewMode && !disabledAddingActions && !actions.model[index + 1]
                        }"
                        @click="addItem('actions')"
                    >
                        <font-awesome-icon class="text-sm text-white-text" :icon="['fa', 'plus']" />
                    </button>
                </div>
                </div>
            </div>
        </UISection>

        <div class="mt-4 bg-white rounded-2xl h-20 shadow-md" v-if="!isViewMode">
            <div class="flex justify-end p-4">
                <Button
                    type="button"
                    :text="getTriggerId ? 'Update' : 'Save'"
                    :disabled="isLoading || disabledSubmit"
                    class="bg-primary"
                    @click="handleSubmit"
                />
            </div>
        </div>
        </template>
    </div>
</template>

<script>
import axios from "../../workflow-axios";
import { uniqWith, isEqual } from "lodash";
import { uuid } from "vue-uuid";
import Button from "@shared/components/button";
import Loader from "@/components/loader";
import {
    getPartsFromCombinedValue,
    getConfigKeys,
} from "./utils.js";
import {
    TRIGGER_FIELDS,
    TRIGGER_FIELDS_LIST,
    FIELD_KEYS,
    PAYLOAD_MAP,
    TRIGGER_FIELDS_ENDPOINTS,
    OPERATORS_LIST,
    MAX_ITEMS_COUNT,
    MIN_ITEMS_COUNT,
} from "./constants.js";

export default {
    name: "createTriggersAndActions",

    components: {
        Button,
        Loader,
    },

    data() {
        return {
            events: {
                model: {
                    type: "",
                    configuration: "",
                    subConfiguration: "",
                },
                options: {
                    type: [],
                    configuration: [],
                    subConfiguration:[],
                },
            },
            actions: {
                model: [{
                    type: "",
                    configuration: "",
                    subConfiguration: "",
                }],
                options: [{
                    type: [],
                    configuration: [],
                    subConfiguration:[],
                }],
            },
            conditions: {
                model: [{
                    type: "",
                    configuration: "",
                    subConfiguration: "",
                }],
                options: [{
                    type: [],
                    configuration: [],
                    subConfiguration:[],
                }],
                operator: "and",
                operators: OPERATORS_LIST,
            },
            isLoading: false,
            showLoader: true,
        }
    },

    async mounted() {
        try {
            const { Events, Conditions, Actions } = TRIGGER_FIELDS;
            const { Type } = FIELD_KEYS;

            this[Events].options[Type] = await this.fetchOptions({
                endpoint: TRIGGER_FIELDS_ENDPOINTS[Events]
            });
            this[Conditions].options[0][Type] = await this.fetchOptions({
                endpoint: TRIGGER_FIELDS_ENDPOINTS[Conditions]
            });
            this[Actions].options[0][Type] = await this.fetchOptions({
                endpoint: TRIGGER_FIELDS_ENDPOINTS[Actions]
            });

            if (this.getTriggerId) {
                await this.getTriggerDetail();
            }
        } catch (err) {
            console.error(err);
        } finally {
            this.showLoader = false;
        }
    },

    computed: {
        getAction() {
            return this.$route.params.action;
        },
        getType() {
            return this.$route.params.type;
        },
        getWorkflowId() {
            return this.$route.params?.workflowId;
        },
        getTriggerId() {
            return this.$route.params?.toolId;
        },
        selectConfig() {
            return { label: "name", trackBy: "id" };
        },
        isViewMode() {
            return this.$route.params.action === "view"
                || this.$route.name == "check admin details"
                || this.$route.name == "Package Tools";
        },
        isInvalidEvent() {
            for (const key in this.events.model) {
                const optionsItem = this.getItemPayload({ el: this.events.model, field: TRIGGER_FIELDS.Events, key });
                if (this.events.model[key] && !optionsItem) return true;
            }

            const baseFields = !this.events.model.type || !this.events.model.configuration;

            if (this.events.options.subConfiguration.length > 0) {
                return baseFields || !this.events.model.subConfiguration;
            }

            return baseFields;
        },
        isInvalidActions() {
            let result = false;

            for (const [index, row] of this.actions.model.entries()) {
                for (const key in row) {
                    if (!row[key].length && this.actions.options[index][key].length > 0) {
                        result = true;
                        break;
                    }
                }
            }

            return result;
        },
        disabledSubmit() {
            return this.isInvalidEvent || this.isInvalidActions;
        },
        disabledAddingActions() {
            return this.actions.model.length === MAX_ITEMS_COUNT;
        },
        disabledRemovingActions() {
            return this.actions.model.length === MIN_ITEMS_COUNT;
        },
        disabledAddingConditions() {
            return this.conditions.model.length === MAX_ITEMS_COUNT;
        },
        disabledRemovingConditions() {
            return this.conditions.model.length === MIN_ITEMS_COUNT;
        },
    },

    watch: {
        "events.model.type"() {
            this.events.model.configuration = "";
            this.events.model.subConfiguration = "";
            this.events.options.subConfiguration = [];
        },
        "events.model.configuration"() {
            this.events.model.subConfiguration = "";
        },
    },

    methods: {
        async getTriggerDetail() {
            try {
                const { data } =  await axios.get(`/workflow/${this.getType}/triggers`, {
                    params: {
                        workflow_id: this.getWorkflowId,
                        trigger_id: this.getTriggerId,
                    },
                });

                await this.initModel(data?.data?.[0]);
            } catch (err) {
                console.error(err);
            } finally {
                this.showLoader = false;
            }
        },

        async initModel(triggerDetail = {}) {
            for (const field in triggerDetail) {
                if (TRIGGER_FIELDS_LIST.includes(field)) {
                    const configModelKeys = PAYLOAD_MAP.get(field);
                    const { idKey, configIdKey } = getConfigKeys(field);

                    if (Array.isArray(this[field].model)) {
                        for (const [fieldsSetIndex, fieldsSetArr] of triggerDetail[field].entries()) {
                            if (fieldsSetIndex > 0) this.addItem(field);

                            for (const [idx, mk] of configModelKeys.entries()) {
                                const item = fieldsSetArr[idx];
                                if (!item) continue;

                                const combinedKey = `${item[idKey]}/${item[configIdKey]}`;
                                await this.handleArrayInput(combinedKey, field, mk, configModelKeys[idx + 1], fieldsSetIndex);
                                this[field].model[fieldsSetIndex][mk] = combinedKey;
                            }
                        }
                    } else {
                        for (const [idx, mk] of configModelKeys.entries()) {
                            const item = triggerDetail[field][idx];
                            if (!item) continue;

                            const combinedKey = `${item[idKey]}/${item[configIdKey]}`;
                            await this.handleObjectInput(combinedKey, field, mk, configModelKeys[idx + 1]);
                            this[field].model[mk] = combinedKey;
                        }
                    }
                }
            }

            if (triggerDetail.conditions_operator) {
                this.conditions.operator = triggerDetail.conditions_operator;
            }
        },

        async fetchOptions({ endpoint }, params) {
            try {
                const { data } = await axios.get(`/workflow/${this.getType}/${endpoint}`, {
                    params: {
                        workflow_id: this.getWorkflowId,
                        ...params,
                    },
                });

                return uniqWith(data?.data ?? [], isEqual);
            } catch (err) {
                console.error(err);
            }
        },

        addItem(field) {
            this.$set(this[field].model, this[field].model.length, {
                type: "",
                configuration: "",
                subConfiguration: "",
            });

            this.$set(this[field].options, this[field].options.length, {
                type: this[field].options[0].type,
                configuration: [],
                subConfiguration: [],
            });
        },

        removeItem(field, index) {
            this.$delete(this[field].model, index);
            this.$delete(this[field].options, index);
        },

        checkShowSubConfig(combinedValue, field, currentKey, index) {
            return this.getArrayItemFromOptions(combinedValue, field, currentKey, index).has_child;
        },

        getArrayItemFromOptions(combinedValue, field, currentKey, index) {
            const { idKey, configIdKey } = getConfigKeys(field);
            const { id, configId } = getPartsFromCombinedValue(combinedValue);

             return this[field].options[index][currentKey]
                ?.find(el => el[idKey] === id && el[configIdKey] === configId) ?? {};
        },

        async handleSubmit() {
            const includeConditions = this.checkAddConditionsToPayload();

            const payload = {
                workflow_id: this.getWorkflowId,
                conditions_operator: includeConditions ? this.conditions.operator : null,
                events: this.getPayloadForArrayOfObjects(TRIGGER_FIELDS.Events),
                conditions: includeConditions ? this.getPayloadForArrayOfArrays(TRIGGER_FIELDS.Conditions) : [],
                actions: this.getPayloadForArrayOfArrays(TRIGGER_FIELDS.Actions),
            };

            try {
                if (this.getTriggerId) {
                    const resp = await axios.put(`/workflow/${this.getType}/triggers`, {
                        ...payload,
                        trigger_id: this.getTriggerId,
                    });
                    if (resp?.status_message === "Success") {
                        this.$toast.success("New Trigger successfully created");
                    }
                } else {
                    const resp = await axios.post(`/workflow/${this.getType}/triggers`, payload);
                    if (resp?.status_message === "Success") {
                        this.$toast.success("New Trigger successfully created");
                    }
                }
            } catch (err) {
                console.error(err);
                this.$toast.error(err.response.data.detail);
            }

            this.$router.push({
                name: "Workflow Tools",
                params: {
                    workflowTool: "triggerAndActions",
                    workflowId: this.getWorkflowId,
              },
            });
        },

        async handleObjectInput(combinedValue, field, currentKey, nextKey) {
            if (!field && !currentKey && !nextKey) return;

            const { idKey, configIdKey, endpoint } = getConfigKeys(field);
            const { id, configId } = getPartsFromCombinedValue(combinedValue);

            const item = this[field].options[currentKey]
                .find(el => el[idKey] === id && el[configIdKey] === configId);

            this.isLoading = true;

            if (item?.has_child) {
                this[field].options[nextKey] = await this.fetchOptions(
                    { endpoint },
                    { [idKey]: item[idKey], [configIdKey]: item[configIdKey] }
                );
            }

            this.isLoading = false;
        },

        async handleArrayInput(combinedValue, field, currentKey, nextKey, index) {
            if (!field && !currentKey && !nextKey) return;

            const { idKey, configIdKey, endpoint } = getConfigKeys(field);
            const item = this.getArrayItemFromOptions(combinedValue, field, currentKey, index);

            this.isLoading = true;

            if (item?.has_child) {
                this[field].options[index][nextKey] = await this.fetchOptions(
                    { endpoint },
                    { [idKey]: item[idKey], [configIdKey]: item[configIdKey] }
                );
            }

            this.resetChildModel(field, currentKey, index);

            this.isLoading = false;
        },

        resetChildModel(field, currentKey, index) {
            const { Type, Configuration, SubConfiguration } = FIELD_KEYS;

            switch (currentKey) {
                case Type:
                    this[field].model[index][Configuration] = "";
                    this[field].model[index][SubConfiguration] = "";
                    this[field].options[index][SubConfiguration] = [];
                    break;
                case Configuration:
                    this[field].model[index][SubConfiguration] = "";
                    break;
                default:
                    return;
            }
        },

        getMappedObjectOptions(field, key) {
            const { idKey, configIdKey, configValueKey } = getConfigKeys(field);

            return this[field].options[key].map(el => ({
                id: `${el[idKey]}/${el[configIdKey]}`,
                label: el[configValueKey],
                value: `${el[idKey]}/${el[configIdKey]}`,
            })) ?? [];
        },

        getMappedArrayOptions(field, key, index) {
            const { idKey, configIdKey, configValueKey } = getConfigKeys(field);

            return this[field].options?.[index]?.[key]?.map(el => ({
                id: `${el?.[idKey]}/${el?.[configIdKey]}`,
                label: el?.[configValueKey],
                value: `${el?.[idKey]}/${el?.[configIdKey]}`,
            })) ?? [];
        },

        getPayloadForArrayOfObjects(field) {
            const keys = PAYLOAD_MAP.get(field);

            const result = keys
              .map(key => this.getItemPayload({ el: this[field].model, field, key }))
              .filter(item => item);

            return result;
        },

        getPayloadForArrayOfArrays(field) {
            const keys = PAYLOAD_MAP.get(field);

            const result = this[field].model
                .map((el, index) => {
                    return keys
                        .map(key => this.getItemPayload({ el, field, key, index }))
                        .filter(item => item)
                })

            return result;
        },

        getItemPayload({ el, field, key, index }) {
            const combinedValue = el[key];
            const { id, configId } = getPartsFromCombinedValue(combinedValue);
            const { idKey, configIdKey } = getConfigKeys(field);

            const item = Array.isArray(this[field].options)
                ? this[field].options?.[index]?.[key]
                    ?.find(el => el?.[idKey] === id && el?.[configIdKey] === configId)
                : this[field].options[key]
                    .find(el => el[idKey] === id && el[configIdKey] === configId);

            return item;
        },

        checkAddConditionsToPayload() {
            let result = true;

            // TODO Temporary workaround
            if (!this.conditions.model[0].type) return false;

            for (const [index, row] of this.conditions.model.entries()) {
                for (const key in row) {
                    if (!row[key].length && this.conditions.options?.[index]?.[key]?.length > 0) {
                        result = false;
                        break;
                    }
                }
            }

            return result;
        },

        getUUID() {
            return uuid.v4();
        },
    }
}
</script>
